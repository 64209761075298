import { CircularProgress, IconButton } from '@mui/material'
import { Eye } from '@phosphor-icons/react'
import { useNavigate } from 'react-router-dom'
import { Row } from '~/components/Row'
import { useGetProcess } from '~/hooks/useGetProcess'
import { NodeStatement } from '~/services/Process.types'

type StatementsTitleProps = Pick<NodeStatement, 'isGeneratingProcess'>

export const StatementsTitle = (props: StatementsTitleProps) => {
  const { isGeneratingProcess } = props

  const navigate = useNavigate()
  const process = useGetProcess()

  return (
    <Row className="mb-2 justify-center">
      <div className="flex items-center backdrop-blur-sm">
        <h5 className="relative m-0 border border-white px-2 text-2xl font-medium text-shadow">
          {process.name}

          {isGeneratingProcess && (
            <CircularProgress
              className="absolute right-[110%] top-1/4"
              color="secondary"
              size={20}
            />
          )}
        </h5>

        <IconButton color="secondary" onClick={() => navigate('view-process')}>
          <Eye />
        </IconButton>
      </div>
    </Row>
  )
}
