/**
 * The query and mutation keys
 * for react query.
 */
export enum QueryKeysEnum {
  /** Query key for `conditional mappings`.  */
  CONDITIONAL_MAPPINGS = 'conditionalMappings',
  /** Query key for `domain read model types`.  */
  DOMAIN_READ_MODEL_TYPES = 'domainReadModelTypes',
  /** Query key for `get generation strategy by id`. */
  GENERATION_STRATEGY_BY_ID = 'generationStrategyById',
  /** Query key for `get generation strategy item for tree view`. */
  GENERATION_STRATEGY_ITEM = 'generationStrategyItem',
  /** Mutation key for `load git repository`. */
  LOAD_GIT_REPOSITORY = 'loadGitRepository',
}
