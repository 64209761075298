import { StyledTreeItem } from '../StyledTreeItem'
import { TreeItemLabel, type TreeItemLabelProps } from './TreeItemLabel'

type RenderTreeFilePathProps = TreeItemLabelProps & {
  /** Handler for context menu event. */
  handleContextMenu: (event: EventFor<'li', 'onContextMenu'>) => void
}

/**
 * Renders the file path `tree items`.
 */
export const RenderTreeFilePath = (props: RenderTreeFilePathProps) => {
  const { domainDictionaryParams, filteredValue, handleContextMenu, node } =
    props
  const { children, id, name } = node || {}

  return (
    <StyledTreeItem
      data-node-id={id}
      data-node-label={name}
      key={id}
      label={
        <TreeItemLabel
          domainDictionaryParams={domainDictionaryParams}
          filteredValue={filteredValue}
          node={node}
        />
      }
      nodeId={id}
      onContextMenu={handleContextMenu}
      size="small"
    >
      {children?.map((currNode) => (
        <RenderTreeFilePath
          domainDictionaryParams={domainDictionaryParams}
          filteredValue={filteredValue}
          handleContextMenu={handleContextMenu}
          key={currNode.id}
          node={currNode}
        />
      ))}
    </StyledTreeItem>
  )
}
