import RestartAltIcon from '@mui/icons-material/RestartAlt'
import SaveIcon from '@mui/icons-material/Save'
import { Button, Stack, TextField, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import {
  Form,
  generatePath,
  useLoaderData,
  useNavigation,
  useParams,
} from 'react-router-dom'
import { DeveloperRoutesEnum } from '~/models/enums/routes/DeveloperRoutesEnum'
import { FormFooterStyled } from '~/pages/developer/components/styled/FormFooter'
import { queryDevelopment } from '~/services/Development'
import { DomainByPlatformId } from '~/services/Development.types'

export function DeveloperPublishAll() {
  const navigation = useNavigation()
  const isButtonDisabled = navigation.state !== 'idle'

  const params = useParams()
  const initialData = useLoaderData() as Awaited<
    AxiosResponse<DomainByPlatformId | null, unknown>
  >
  const {
    data,
    // isInitialLoading, // etc
  } = useQuery({
    ...queryDevelopment(params.platformId),
    initialData: initialData,
    select: (data) => {
      if (data.data && params.aggregateId) {
        const boundedContext = data.data.boundedContexts.find((context) =>
          context.aggregates.find(
            (aggregate) => aggregate.identity === params.aggregateId,
          ),
        )

        const result = {
          status: boundedContext?.aggregates[0]?.status.name,
        }

        return result
      }

      return
    },
  })

  return (
    <>
      <Stack gap={2}>
        {data?.status === 'Uninitialised' ? (
          <Typography>
            You need to initialise the Service before publishing.
          </Typography>
        ) : (
          <>
            <Typography>
              This will publish all commands and reactions in this service.
            </Typography>
            <Form
              method="post"
              id="form-aggregate-confirm"
              action={`${generatePath(DeveloperRoutesEnum.DEVELOPER_AGGREGATE, {
                aggregateId: params.aggregateId || '',
                boundedContext: params.boundedContext || '',
                organisationId: params.organisationId || '',
                platformId: params.platformId || '',
              })}/publish-all`}
            >
              <Stack gap={2} mt={2}>
                <TextField
                  label="Git commit message"
                  variant="outlined"
                  name="commitMessage"
                  fullWidth
                />
                <input
                  type="hidden"
                  value={params.aggregateId}
                  name="aggregateId"
                />
                <TextField
                  label="Branch"
                  variant="outlined"
                  name="branch"
                  fullWidth
                />
                <FormFooterStyled>
                  <Button
                    variant="outlined"
                    startIcon={isButtonDisabled ? null : <RestartAltIcon />}
                    type="reset"
                    disabled={isButtonDisabled}
                  >
                    Reset
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={isButtonDisabled ? null : <SaveIcon />}
                    type="submit"
                    disabled={isButtonDisabled || !data}
                    fullWidth
                    sx={{ width: 'fit-content' }}
                  >
                    {isButtonDisabled ? 'Generating code...' : 'Generate code'}
                  </Button>
                </FormFooterStyled>
              </Stack>
            </Form>
          </>
        )}
      </Stack>
    </>
  )
}
