import { Box } from '@mui/material'
import { ControllerRenderProps, FieldValues } from 'react-hook-form'
import { MappingConditionFormEnum } from '~/models/enums/forms/MappingConditionFormEnum'
import { ExpressionList } from './ExpressionList'
import { ExpressionItem } from './MappingCondition.constants'
import { handleInsertExpression } from './MappingCondition.utils'

const scrollbarStyles = {
  height: '350px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
} as const

type CategoryContentProps = {
  /** Field data from a controlled field. */
  field: ControllerRenderProps<FieldValues, MappingConditionFormEnum>
  /** The items of the list. */
  items: ExpressionItem[]
  /** The ref of the input field. */
  inputRef: React.RefObject<HTMLTextAreaElement>
}

/**
 * The expression content.
 */
export const ExpressionContent = ({
  field,
  items,
  inputRef,
}: CategoryContentProps) => (
  <Box flexDirection="row" sx={scrollbarStyles}>
    <ExpressionList
      field={field}
      items={items}
      onItemClick={(value, field) =>
        handleInsertExpression(value, field, inputRef)
      }
    />
  </Box>
)
