import { Position, type Edge, type Node } from '@xyflow/react'
import dagre from 'dagre'
import { useParams, useSearchParams } from 'react-router-dom'
import { BoundedContextEnum } from '~/models/enums/BoundedContextEnum'
import { ServiceAndEventsActionTypeEnum } from '~/models/enums/developer/serviceMap/ServiceAndEventsActionTypeEnum'
import type {
  ServiceAndEventsAction,
  ServiceAndEventsType,
} from '~/models/types/developer/serviceMap/ServiceAndEventsType'
import { GenerateAttributes } from '~/pages/developer/components/ServiceMap/GenerateAttributes/GenerateAttributes'
import { PropertiesList } from '~/pages/developer/components/ServiceMap/PropertiesList/PropertiesList'
import type { DomainByPlatformId } from '~/services/Development.types'
import { useGlobalStore } from '~/store'
import { createQueryEdge } from './createQueryEdge'
import { createReactionEdge } from './utils/createReactionEdge'
import { generateTypeNodesAndEdges } from './utils/generateTypeNodesAndEdges'
import { colors } from './utils/reactFlowColors'

export const nodeWidth = 500
export const nodeHeight = 120

type Subscription = {
  eventId: string
  id: string
  name: string
  context: string
}

type ServiceAndEvents = ServiceAndEventsType[]

export const useGetColors = () => {
  const colorMode = useGlobalStore((state) => state.colorMode)

  return colorMode === 'dark' ? colors.dark : colors.light
}

export const useGetLayoutedElements = ({
  data,
  dagreGraph,
}: {
  data: ServiceAndEvents
  dagreGraph: dagre.graphlib.Graph
}) => {
  const { nodes, edges } = useGetElements(data)

  const direction = 'LR' // left to right
  dagreGraph.setGraph({ rankdir: direction, ranksep: 200, nodesep: 200 })

  nodes.forEach((node) => {
    const lineCount: number = (node.data.count as number) || 0

    // adding 15% on the width between the nodes to improve visibility
    dagreGraph.setNode(node.id, {
      width: nodeWidth + 250,
      height: nodeHeight + lineCount * 27,
    })

    // If not a "group node" set a parent node.
    if (node?.data?.parentNodeId) {
      dagreGraph.setParent(node.id, node.data.parentNodeId as string)
    }
  })

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target)
  })

  dagre.layout(dagreGraph)

  nodes.forEach((node, _) => {
    const isGroupNode = !node?.data?.parentNodeId

    const nodeWithPosition = dagreGraph.node(node.id)

    if (!isGroupNode) {
      node.targetPosition = Position.Left
      node.sourcePosition = Position.Left

      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - nodeWithPosition.width / 2,
        y: nodeWithPosition.y - nodeWithPosition.height / 2,
      }
    }

    if (isGroupNode) {
      const padding = 0

      const groupColor = '#0a2342'

      // Calculate the group node positions based on the default padding.
      node.position = {
        x: nodeWithPosition.x - (nodeWithPosition.width - padding) / 2,
        y: nodeWithPosition.y - (nodeWithPosition.height - padding) / 2,
      }

      // Calculate the group node dimensions based on the default padding.
      node.style = {
        width: nodeWithPosition.width - padding,
        height: nodeWithPosition.height - padding,
        backgroundColor: `${groupColor}05`, // the hex value for 5% opacity is 0D
        borderRadius: '20px',
        fontSize: '4rem', // Adjust font size for the watermark
        color: `${groupColor}20`, // Use a low-opacity version of the group color
      }
    }

    return node
  })

  return { nodes, edges }
}

type NodesAndEdges = {
  nodes: Node[]
  edges: Edge[]
}

function useGetElements(data: ServiceAndEvents): NodesAndEdges {
  // React Router Dom.
  const params = useParams()
  const { aggregateId } = params

  const [searchParams] = useSearchParams()
  const urlAggregate = searchParams.get('aggregate')
  const urlType = searchParams.get('type')

  const colors = useGetColors()

  if (!data) return { nodes: [], edges: [] }

  const nodes: Node[] = []
  const edges: Edge[] = []

  const defaultPosition = {
    x: 0,
    y: 0,
  }

  const nodesByContext: Map<string, Node> = new Map()

  const contextMap = new Map<string, string>()
  data.forEach((context) => {
    context.services.forEach((service) => {
      // Add aggregate name and ID to contextMap
      contextMap.set(`${context.id}.${service.name}`, service.id)

      // Add types for each service to contextMap
      service.types.forEach((type) => {
        contextMap.set(
          `${context.id}.${service.name}.${type.name}`,
          type.identity!,
        )
      })
    })
  })

  data.forEach((context) => {
    context.services.forEach((service) => {
      const aggregateGroupNode: Node = {
        id: `ag-${service.id}`,
        data: { aggregateId: service.id, context: service.name },
        position: defaultPosition,
        type: 'groupNode',
      }

      nodes.push(aggregateGroupNode)

      const serviceNode: Node = {
        id: service.id,
        data: {
          actions: service.actions,
          aggregateId: service.id,
          context: service.name,
          content: (
            <PropertiesList
              attributes={service.properties}
              contentIfEmpty={
                <GenerateAttributes
                  aggregateId={service.id}
                  entityType="Aggregate"
                  entityName={service.name}
                  typeId={service.id}
                />
              }
            />
          ),
          count:
            (service?.properties?.length || 0) +
            service.actions.length +
            service.actions.reduce(
              (final, item) => final + (item.events?.length ?? 0),
              0,
            ),
          isSelected: urlAggregate === service.id || aggregateId === service.id,
          typeDescription: service.typeDescription,
          parentNodeId: `ag-${service.id}`,
        },
        position: defaultPosition,
        type: 'serviceNode',
      }

      nodes.push(serviceNode)

      nodesByContext.set(context.context, serviceNode)

      service.actions.forEach((action) => {
        if (action.type === ServiceAndEventsActionTypeEnum.REACTION) {
          const newEdge = createReactionEdge(
            action,
            service.id,
            contextMap,
            colors,
          )

          if (newEdge == null) {
            return
          }

          edges.push(newEdge)
        }

        if (action.type === ServiceAndEventsActionTypeEnum.QUERY) {
          const boundedContext = context.context
          const serviceName = service.name

          const newEdge = createQueryEdge(
            `${boundedContext}.${serviceName}`,
            action,
            service.id,
            contextMap,
            colors,
          )

          if (newEdge == null) {
            return
          }

          edges.push(newEdge)
        }
      })

      // Set nodes and edges for aggregate type attributes.
      generateTypeNodesAndEdges(
        context.context,
        service!,
        nodes,
        edges,
        contextMap,
        colors,
        urlType,
      )
    })
  })

  return {
    nodes,
    edges,
  }
}

// TODO: Remove the next below two functions after getting the new Flow endpoint

const getSubscriptions = (data: DomainByPlatformId): Subscription[] => {
  const subs: Subscription[] = []
  data?.boundedContexts.forEach((context) => {
    context.aggregates.map((service) => {
      service.reactions.forEach((reaction) => {
        subs.push({
          eventId: reaction.subscription.domainEventName,
          id: service.identity,
          name: service.name,
          context: context.name,
        })
      })
    })
  })

  return subs
}

export const parseDomainToGetServicesAndEvents = (
  data: DomainByPlatformId | null,
  boundedContext: string,
): ServiceAndEvents => {
  if (!data) return []

  const dataFilteredByBoundedContext = data?.boundedContexts.filter(
    (context) => {
      if (boundedContext === BoundedContextEnum.NO_BOUNDED_CONTEXT) {
        return context.name.trim() === ''
      }
      return context.name.toLowerCase() === boundedContext
    },
  )

  const subs = getSubscriptions(data)

  const parsedData = dataFilteredByBoundedContext?.map((context) => ({
    id: context.name,
    context: context.name,
    services: context.aggregates.map((service) => {
      const commandRaisedEvents: any[] = []
      const reactRaisedEvents: any[] = []

      service.commands.forEach((command) => {
        command.domainEvents?.forEach((raisedEvents) => {
          commandRaisedEvents.push({
            id: raisedEvents.name,
            name: raisedEvents.name,
            serviceListeners: subs
              .filter((sub) => sub.eventId === raisedEvents.name)
              .map((sub) => ({
                id: sub.id,
                name: sub.name,
                context: sub.context,
              })),
          })
        })
      })

      service.reactions.forEach((reaction) => {
        return reaction.domainEvents?.forEach((raisedEvents) => {
          reactRaisedEvents.push({
            id: raisedEvents.name,
            name: raisedEvents.name,
            serviceListeners: subs
              .filter((sub) => sub.eventId === raisedEvents.name)
              .map((sub) => ({
                id: sub.id,
                name: sub.name,
                context: sub.context,
              })),
          })
        })
      })

      const actions = [service].reduce((final, curr) => {
        curr.commands.forEach((cmd) =>
          final.push({
            id: cmd.identity,
            type: ServiceAndEventsActionTypeEnum.COMMAND,
            name: cmd.name,
            properties: cmd.attributes,
            typeId: cmd.typeId,
            events: cmd.domainEvents,
            subscription: null,
            resultType: null,
          }),
        )

        curr.reactions.forEach((rct) =>
          final.push({
            id: rct.identity,
            type: ServiceAndEventsActionTypeEnum.REACTION,
            name: rct.actionName,
            properties: rct.attributes,
            typeId: rct.actionTypeId,
            events: rct.domainEvents,
            subscription: rct.subscription,
            resultType: null,
          }),
        )

        curr.queries.forEach((query) =>
          final.push({
            id: query.identity,
            type: ServiceAndEventsActionTypeEnum.QUERY,
            name: query.name,
            properties: query.parameters,
            typeId: query.typeId,
            events: [],
            subscription: null,
            resultType: query.result,
          }),
        )

        return final
      }, [] as ServiceAndEventsAction[])

      return {
        id: service.identity,
        actions,
        name: service.name,
        properties: service.properties,
        raisedEvents: [...commandRaisedEvents, ...reactRaisedEvents],
        typeDescription: service.typeDescription,
        types: service.types,
      }
    }),
  }))

  return parsedData
}
