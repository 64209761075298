import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { FieldValues } from 'react-hook-form'
import invariant from 'tiny-invariant'
import { ERROR_MESSAGES } from '~/constants/api/codeGenerationStrategy/errorMessages'
import { QueryKeysEnum } from '~/models/enums/api/QueryKeysEnum'
import { BindingContextFormEnum } from '~/models/enums/forms/BindingContextFormEnum'
import type { CodeGenerationStrategyApiProps } from '~/models/types/api/codeGenerationStrategy/CodeGenerationStrategyApiProps'
import type { ErrorResponseType } from '~/models/types/http/ErrorResponseType'
import type { PayloadUpdatePropertyBindingContext } from '~/services/GenerationStrategy.types'
import {
  GenerationStrategyAPI,
  serviceGenerationStrategy,
} from '~/services/base'
import { getQueryMutationError } from '~/utils/api/codeGenerationStrategy/getQueryMutationError'

/**
 * Hook that implements react query `mutation`
 * to update a property binding context.
 */
export const useUpdatePropertyBindingContext = (
  props: CodeGenerationStrategyApiProps,
) => {
  const { fileId, propertyBindingId } = props

  const queryClient = useQueryClient()

  const {
    error: mutationError,
    isError,
    ...restMutation
  } = useMutation({
    mutationFn: (fieldValues: FieldValues) => {
      invariant(fileId, ERROR_MESSAGES.fileId)
      invariant(propertyBindingId, ERROR_MESSAGES.propertyBindingId)

      const url = GenerationStrategyAPI.UpdatePropertyBindingContext

      const bindingContext = fieldValues[
        BindingContextFormEnum.BINDING_CONTEXT
      ] as string | null

      const postData: PayloadUpdatePropertyBindingContext = {
        fileId,
        propertyBindingId,
        bindingContext,
      }

      return serviceGenerationStrategy.post(url, postData)
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QueryKeysEnum.GENERATION_STRATEGY_ITEM, fileId],
      }),
  })

  const error = getQueryMutationError(
    isError,
    mutationError as ErrorResponseType,
  )

  return { ...restMutation, error, isError }
}
