import RestartAltIcon from '@mui/icons-material/RestartAlt'
import SaveIcon from '@mui/icons-material/Save'
import { Button, CircularProgress } from '@mui/material'
import React, { type RefObject } from 'react'
import { useNavigation } from 'react-router-dom'
import { FormFooterStyled } from '~/pages/developer/components/styled/FormFooter'

type FormFooterProps = {
  showReset?: boolean
  showConfirm?: boolean
  submitButtonRef?: RefObject<HTMLButtonElement>
  submitText?: string
  submittingText?: string
}
export function FormFooter(props: FormFooterProps) {
  const {
    showReset = true,
    showConfirm = true,
    submitButtonRef,
    submitText = 'Save',
    submittingText = 'Saving...',
  } = props

  const navigation = useNavigation()
  const isNavigating = navigation.state !== 'idle'
  const shouldDisableButtons = React.useMemo(() => {
    if (navigation.formData) {
      const checkIfButtonSubmitWasClicked = Object.fromEntries(
        navigation.formData,
      ).footerSubmit
      if (checkIfButtonSubmitWasClicked) {
        return true
      }

      const checkIfButtonResetWasClicked = Object.fromEntries(
        navigation.formData,
      ).footerReset
      if (checkIfButtonResetWasClicked) {
        return true
      }
    }

    return false
  }, [navigation.formData])

  return (
    <FormFooterStyled>
      {showReset ? (
        <Button
          color="secondary"
          disabled={isNavigating}
          name="footerReset"
          startIcon={<RestartAltIcon />}
          type="reset"
          value="true"
          variant="outlined"
        >
          Reset
        </Button>
      ) : null}

      {showConfirm && (
        <Button
          color="secondary"
          disabled={isNavigating}
          name="footerSubmit"
          ref={submitButtonRef}
          startIcon={
            shouldDisableButtons ? (
              <CircularProgress size="1rem" color="inherit" />
            ) : (
              <SaveIcon />
            )
          }
          sx={{ width: 'fit-content' }}
          type="submit"
          value="true"
          variant="contained"
        >
          {shouldDisableButtons ? submittingText : submitText}
        </Button>
      )}
    </FormFooterStyled>
  )
}
