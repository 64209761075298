import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { ERROR_MESSAGES } from '~/constants/api/codeGenerationStrategy/errorMessages'
import type { PayloadDeleteAction } from '~/models/types/api/developer/PayloadDeleteAction'
import type { ErrorResponseType } from '~/models/types/http/ErrorResponseType'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'
import { queryDevelopment } from '~/services/Development'
import { queryProcess } from '~/services/Process'
import { getQueryMutationError } from '~/utils/api/codeGenerationStrategy/getQueryMutationError'

/**
 * Hook that implements react query `mutation`
 * to delete a query.
 */
export const useDeleteQuery = () => {
  const params = useParams()
  const { platformId = '', processId = '' } = params

  // React Query.
  const queryClient = useQueryClient()

  // The `useMutation` implementation.
  const {
    error: mutationError,
    isError,
    mutate,
    ...restMutation
  } = useMutation({
    mutationFn: (data: PayloadDeleteAction) => {
      invariant(data.aggregateId, ERROR_MESSAGES.aggregateId)

      const postData = {
        aggregateId: data.aggregateId,
        queryId: data.actionId,
      }

      const url = DevelopmentAggregateAPI.DeleteQuery
      return serviceDevelopmentAggregate.post(url, postData)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryDevelopment(platformId))
      await queryClient.invalidateQueries(queryProcess({ processId }))
    },
  })

  const error = getQueryMutationError(
    isError,
    mutationError as ErrorResponseType,
  )

  return { ...restMutation, error, isError, mutate }
}
