import { useQuery } from '@tanstack/react-query'
import invariant from 'tiny-invariant'
import { QueryKeysEnum } from '~/models/enums/api/QueryKeysEnum'
import type { CodeGenerationStrategyApiProps } from '~/models/types/api/codeGenerationStrategy/CodeGenerationStrategyApiProps'
import type { ErrorResponseType } from '~/models/types/http/ErrorResponseType'
import {
  GenerationStrategyAPI,
  serviceGenerationStrategy,
} from '~/services/base'
import { getQueryMutationError } from '~/utils/api/codeGenerationStrategy/getQueryMutationError'

/**
 * Hook that implements react query
 * to get conditional mappings.
 */
export const useGetConditionalMappings = (
  props: Pick<CodeGenerationStrategyApiProps, 'generationStrategyId'>,
) => {
  const { generationStrategyId } = props

  const {
    error: queryError,
    isError,
    ...restQuery
  } = useQuery({
    queryKey: [QueryKeysEnum.CONDITIONAL_MAPPINGS, generationStrategyId],
    queryFn: async () => {
      invariant(generationStrategyId, 'Generation Strategy ID is required')

      const url = GenerationStrategyAPI.GetConditionalMappings
      return serviceGenerationStrategy.get(url, {
        params: { generationStrategyId },
      })
    },
    select: (response) => response.data,
  })

  const error = getQueryMutationError(isError, queryError as ErrorResponseType)

  return { ...restQuery, error, isError }
}
