import { Button, Paper, Stack, Tooltip } from '@mui/material'
import Typography from '@mui/material/Typography'
import { Plus } from '@phosphor-icons/react'
import { Link } from 'react-router-dom'
import { ConditionalWrapper } from '~/components/ConditionalWrapper'
import { FEATURE_TOGGLE } from '~/config/featureToggle'
import { AggregateInfoHeader } from '~/pages/developer/components/AggregateInfoHeader'
import { AggregateInfoHeaderNew } from '~/pages/developer/components/AggregateInfoHeaderNew'
import { Aggregate, DomainForCommand } from '~/services/Development.types'
import { PlatformByOrganisation } from '~/services/PlatformTeam.types'
import { ListCommands } from '../ListCommands'
import { ListQueries } from '../ListQueries'
import { ListReactions } from '../ListReactions'
import { ListTypes } from '../ListTypes'
import { SubMenuWrapper } from '../styled/SubMenuWrapper'

const iconProps = {
  className: 'text-secondary',
  size: 15,
}

type AggregateInfoProps = {
  aggregate: Aggregate
  domains: DomainForCommand | null
  menuSelected: string
  platformConfigured: PlatformByOrganisation | null
}

export function AggregateInfo(props: AggregateInfoProps) {
  const { aggregate, domains, menuSelected, platformConfigured } = props

  return (
    <Paper className="m-4 grid flex-grow grid-rows-[auto_1fr] px-4 py-3">
      {FEATURE_TOGGLE.CODE_GENERATION_STRATEGIES_CONFIG ? (
        <AggregateInfoHeaderNew aggregate={aggregate} />
      ) : (
        <AggregateInfoHeader
          aggregate={aggregate}
          menuSelected={menuSelected}
          platformConfigured={platformConfigured}
        />
      )}

      <Stack className="overflow-auto" gap={2}>
        <Stack direction="column" gap={1} justifyContent="space-between">
          <SubMenuWrapper direction="row" gap={2}>
            <Typography variant="h6">Commands</Typography>

            <Button
              color="secondary"
              component={Link}
              size="small"
              startIcon={<Plus {...iconProps} />}
              to={`add-command?context=${encodeURIComponent(aggregate.name)}`}
              variant="outlined"
            >
              Add
            </Button>
          </SubMenuWrapper>

          <ListCommands commands={aggregate.commands} />
        </Stack>

        <Stack direction="column" gap={1} justifyContent="space-between">
          <SubMenuWrapper direction="row" gap={2}>
            <Typography variant="h6">Reactions</Typography>

            <ConditionalWrapper
              condition={!domains?.domainEvents.length}
              wrapper={(children) => (
                <Tooltip
                  title="To add a reaction, please add a command first"
                  arrow
                >
                  <span>{children}</span>
                </Tooltip>
              )}
            >
              <Button
                color="secondary"
                component={Link}
                disabled={!domains?.domainEvents.length}
                size="small"
                startIcon={<Plus {...iconProps} />}
                to={`add-reaction?context=${encodeURIComponent(aggregate.name)}`}
                variant="outlined"
              >
                Add
              </Button>
            </ConditionalWrapper>
          </SubMenuWrapper>

          <ListReactions aggregate={aggregate} />
        </Stack>

        <Stack direction="column" gap={1} justifyContent="space-between">
          <SubMenuWrapper direction="row" gap={2}>
            <Typography variant="h6">Queries</Typography>

            <Button
              color="secondary"
              component={Link}
              size="small"
              startIcon={<Plus {...iconProps} />}
              to={`add-query?context=${encodeURIComponent(aggregate.name)}`}
              variant="outlined"
            >
              Add
            </Button>
          </SubMenuWrapper>

          <ListQueries queries={aggregate.queries} />
        </Stack>

        <Stack direction="column" gap={1} justifyContent="space-between">
          <SubMenuWrapper direction="row" gap={2}>
            <Typography variant="h6">Types</Typography>

            <Button
              color="secondary"
              component={Link}
              size="small"
              startIcon={<Plus {...iconProps} />}
              to={`add-type`}
              variant="outlined"
            >
              Add
            </Button>
          </SubMenuWrapper>

          <ListTypes types={aggregate.types} />
        </Stack>
      </Stack>
    </Paper>
  )
}
