import { useQueryClient } from '@tanstack/react-query'
import type { Node, NodeMouseHandler } from '@xyflow/react'
import type { AxiosResponse } from 'axios'
import { produce } from 'immer'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { TIMER } from '~/config/constants'
import type { AddLocalChildStatementProps } from '~/models/types/components/processInfo/AddLocalChildStatementProps'
import type { StatementsDiagramHandle } from '~/pages/business/components/ProcessInfo/StatementsDiagram'
import {
  formatStatementList,
  getFirstChildStatement,
  getLastStatement,
  getNextSibling,
  getPreviousSibling,
  getStatementById,
  insertLocalBetween,
  removeStatement,
} from '~/pages/business/components/ProcessInfo/utils'
import type { BusinessChildrenParams } from '~/routes/business/routes.types'
import { queryKeyProcess } from '~/services/Process'
import type {
  NodeStatement,
  PayloadUpdateStatementParsingInfoCommand,
  ResponseDiscoveryProcess,
  Statement,
} from '~/services/Process.types'
import { useLocalStorage } from './useLocalStorage'
import { useMutateStatements } from './useMutateStatements'

const newInitialStatement = {
  content: '',
  id: uuidv4(),
  isLocal: true,
  parentStatementId: null,
} as Statement

export type UseStatementsProps = {
  process: ResponseDiscoveryProcess
}

export const useStatements = (props: UseStatementsProps) => {
  const { process } = props

  const localStorageKey = `@dotstar/processMap/${process.identity}`

  // React Router Dom.
  const params = useParams<BusinessChildrenParams>()
  const { platformId } = params

  // States.
  const [statements, setStatements] = useState<Statement[]>([])
  const [isMultiSelectEnabled, setIsMultiSelectEnabled] = useState(false)
  const [currentProcess, setCurrentProcess] = useState(process)
  const [isExpanded, setIsExpanded] = useState(false)

  // Refs.
  const debounceTimeoutRefs = useRef<any>({})
  const previousIsGeneratingProcess = useRef(process.isGeneratingProcess)

  // The ref for the `statement diagram` component.
  const statementDiagramRef = useRef<StatementsDiagramHandle>(null)

  // Query Client.
  const queryClient = useQueryClient()

  // Local storage.
  const [selectedStatements, setSelectedStatements] = useLocalStorage(
    localStorageKey,
    () => {
      const found = getLastStatement(formatStatementList(process))
      if (found) return [found]

      return []
    },
  )

  // Constants.
  const selectedStatement = selectedStatements[0] || null

  const shouldEdit = selectedStatements.length === 1

  const submitOrInsert = selectedStatement?.isInsert
    ? insertStatement
    : submitStatement

  // Base methods: required for `useMutateStatements` hook.
  const setSelectedStatement = useCallback(
    (statement: Statement) => setSelectedStatements([statement]),
    [setSelectedStatements],
  )

  const removeLocalStatement = useCallback((): Statement[] => {
    const newList = removeStatement(statements, selectedStatement)

    queryClient.setQueryData(
      queryKeyProcess(process.identity),
      (currentProcess: AxiosResponse<ResponseDiscoveryProcess>) =>
        produce(
          currentProcess,
          (draft: AxiosResponse<ResponseDiscoveryProcess>) => {
            draft.data.statements = newList
          },
        ),
    )

    return newList
  }, [process, queryClient, selectedStatement, statements])

  /**
   * Set selected statement state, and remove
   * any local statement that was not submitted.
   */
  const selectNewAndRemoveCurrentIfNeed = useCallback(
    (props: AddLocalChildStatementProps): void => {
      const { force, nodeFit, targetStatement: newStatement, zoom } = props

      let updatedSelected = newStatement

      // Remove any local statement that was not submitted.
      if (
        selectedStatement?.isLocal &&
        !selectedStatement?.isFetching &&
        !selectedStatement?.isEditMutationError &&
        !force
      ) {
        const newList = removeLocalStatement()

        updatedSelected = newList.find(
          (statement) => statement.id === newStatement?.id,
        )
      }

      // Set selected statement state.
      setSelectedStatement(updatedSelected || {})

      // Node fit the selected statement.
      if (nodeFit && !!statementDiagramRef.current) {
        const timeout = newStatement?.isLocal ? 200 : 1

        setTimeout(() => {
          statementDiagramRef.current!.handleNodeFit(
            newStatement as Node<NodeStatement>,
            zoom,
          )
        }, timeout)
      }
    },
    [
      removeLocalStatement,
      selectedStatement,
      setSelectedStatement,
      statementDiagramRef,
    ],
  )

  // Add a new statement to the viewport.
  const addLocalChildStatement = useCallback(
    async (props?: AddLocalChildStatementProps) => {
      const { force, nodeFit, suggestedDescription, targetStatement } =
        props || {}

      // The reference statement to add a local from.
      const referencedStatement = targetStatement || selectedStatement
      // Don't do anything in case the local statement was not submitted.
      if (
        referencedStatement?.isLocal &&
        (!referencedStatement.isFetching ||
          !referencedStatement?.isEditMutationError) &&
        !force
      )
        return

      const newStatement = {
        childStatements: [],
        content: '',
        id: uuidv4(),
        isLocal: true,
        parentStatementId: referencedStatement?.id || null,
        parsingInfo: {
          boundedContext: referencedStatement.parsingInfo.boundedContext,
        },
        platformId,
        processId: process.identity,
        suggestedDescription,
      } as unknown as Statement

      // Set query cache.
      await queryClient.setQueryData(
        queryKeyProcess(process.identity),
        (currentProcess: AxiosResponse<ResponseDiscoveryProcess>) =>
          produce(
            currentProcess,
            (draft: AxiosResponse<ResponseDiscoveryProcess>) => {
              draft.data.statements.push(newStatement)
            },
          ),
      )

      // Set selected statement state (and remove in case of need).
      selectNewAndRemoveCurrentIfNeed({
        force,
        nodeFit,
        targetStatement: newStatement,
      })
    },
    [
      platformId,
      process,
      queryClient,
      selectedStatement,
      selectNewAndRemoveCurrentIfNeed,
      statements,
    ],
  )

  // Hook.
  const {
    editMutation,
    insertMutation,
    removeMutation,
    swapMutation,
    updateStatementParsingInfoMutation,
  } = useMutateStatements({
    addLocalChildStatement,
    process,
    selectedStatement,
    setSelectedStatements,
  })

  // Methods.
  const addInitialLocalStatement = useCallback(() => {
    queryClient.setQueryData(
      queryKeyProcess(process.identity),
      (currentProcess: AxiosResponse<ResponseDiscoveryProcess>) =>
        produce(
          currentProcess,
          (draft: AxiosResponse<ResponseDiscoveryProcess>) => {
            // Initialize draft and data if undefined.
            if (!draft) draft = {} as AxiosResponse<ResponseDiscoveryProcess>
            if (!draft?.data) draft.data = {} as ResponseDiscoveryProcess

            draft.data.statements = [newInitialStatement]
          },
        ),
    )
    return newInitialStatement
  }, [process.identity, queryClient])

  const selectStatementByNode: NodeMouseHandler = (_, node) => {
    const statement = getStatementById(statements, node.id)

    if (
      statement?.id === selectedStatement?.id &&
      selectedStatements?.length === 1 &&
      statement?.id === selectedStatements?.[0]?.id
    ) {
      return
    }

    if (statement) {
      if (isMultiSelectEnabled) {
        selectMultiAndRemoveCurrentIfNeed(statement)
      } else {
        selectNewAndRemoveCurrentIfNeed({ targetStatement: statement })
      }
    }
  }

  function toggleMultiSelectEnablement(newState: boolean) {
    setIsMultiSelectEnabled(newState)
  }

  function toggleIsExpanded() {
    setIsExpanded((current) => !current)
  }

  function submitStatement(value: string, shouldDebounce: boolean) {
    if (selectedStatement && selectedStatement.content !== value) {
      // Check if it is the very first statement.
      const isRootStatement = !selectedStatement.parentStatementId

      // In case it is a root, get the child node.
      const rootChildStatement =
        isRootStatement &&
        statements.find(
          (item) => item.parentStatementId === selectedStatement.id,
        )

      const payload = produce(selectedStatement, (draft: Statement) => {
        draft.content = value
        draft.isFetching = true
        draft.platformId = platformId
        draft.processId = process.identity

        if (isRootStatement && !!rootChildStatement) {
          draft.beforeStatementId = rootChildStatement.id
        }
      }) as unknown as Statement

      if (shouldDebounce) {
        debounce(
          () => {
            editMutation.mutate({ updatedStatement: payload })
          },
          TIMER.DEBOUNCE_DELAY_IN_MS,
          payload.id,
        )
      } else {
        editMutation.mutate({ updatedStatement: payload })
      }
    }
  }

  function insertStatement(value: string, shouldDebounce: boolean = true) {
    if (selectedStatement && selectedStatement.content !== value) {
      const payload = produce(selectedStatement, (draft: Statement) => {
        draft.content = value
        draft.isFetching = true
        draft.platformId = platformId
        draft.processId = process.identity
      }) as unknown as Statement

      if (shouldDebounce) {
        debounce(
          () => {
            insertMutation.mutate({ newStatement: payload })
          },
          TIMER.DEBOUNCE_DELAY_IN_MS,
          payload.id,
        )
      } else {
        insertMutation.mutate({ newStatement: payload })
      }
    }
  }

  function deleteSelectedStatement(toggleDeleteModal: () => void) {
    const payload = {
      processId: process.identity,
      statementId: selectedStatement?.id,
    }

    toggleDeleteModal()
    removeMutation.mutate(payload)
  }

  function swapStatements() {
    if (selectedStatements.length !== 2) {
      return
    }

    const [firstStatement, secondStatement] = selectedStatements
    swapMutation.mutate({
      firstStatement,
      secondStatement,
    })
  }

  function debounce(callback: any, delay: any, statementId: any) {
    clearTimeout(debounceTimeoutRefs.current[statementId])
    debounceTimeoutRefs.current[statementId] = setTimeout(() => {
      callback()
    }, delay)
  }

  function cancelDebounce() {
    if (selectedStatement) {
      clearTimeout(debounceTimeoutRefs.current[selectedStatement.id])
    }
  }

  function onHandleType({
    value,
    shouldDebounce = true,
  }: {
    value: string
    shouldDebounce?: boolean
  }) {
    if (shouldDebounce) {
      submitOrInsert(value, shouldDebounce)
      return
    }
    cancelDebounce()
    selectedStatement?.isInsert
      ? insertStatement(value, shouldDebounce)
      : submitStatement(value, shouldDebounce)
  }

  function selectMultiAndRemoveCurrentIfNeed(newStatement: Statement): void {
    if (selectedStatement?.isLocal && !selectedStatement?.isFetching) {
      removeLocalStatement()
    }
    const alreadySelected = selectedStatements.find(
      (s: Statement) => s.id === newStatement.id,
    )
    if (alreadySelected) {
      setSelectedStatements(
        selectedStatements.filter((s: Statement) => s.id !== newStatement.id),
      )
    } else {
      const updatedSelectedStatement = statements.find(
        (statement) => statement.id === newStatement.id,
      )

      if (updatedSelectedStatement) {
        const data = produce(selectedStatements, (draft: Statement[]) => {
          draft.push(updatedSelectedStatement)
        })
        setSelectedStatements(data)
      }
    }
  }

  // Add a statement to the end.
  const addLocalLastChildStatement = async (
    props?: AddLocalChildStatementProps,
  ) => {
    const { force, nodeFit, targetStatement } = props || {}

    // The reference statement to add a local from.
    const referencedStatement = targetStatement || selectedStatement

    // Don't do anything in case the local statement was not submitted.
    if (
      referencedStatement?.isLocal &&
      (!referencedStatement.isFetching ||
        !referencedStatement?.isEditMutationError) &&
      !force
    )
      return

    const newStatement = {
      childStatements: [],
      content: '',
      id: uuidv4(),
      isLocal: true,
      parentStatementId: referencedStatement?.parentStatementId || null,
      parsingInfo: {
        boundedContext: referencedStatement.parsingInfo.boundedContext,
      },
      platformId,
      processId: process.identity,
    }

    // Set query cache.
    await queryClient.setQueryData(
      queryKeyProcess(process.identity),
      (currentProcess: AxiosResponse<ResponseDiscoveryProcess>) =>
        produce(
          currentProcess,
          (draft: AxiosResponse<ResponseDiscoveryProcess>) => {
            draft.data.statements.push(newStatement)
          },
        ),
    )

    // Set selected statement state (and remove in case of need).
    selectNewAndRemoveCurrentIfNeed({
      force,
      nodeFit,
      targetStatement: newStatement,
    })
  }

  // Add a statement to the beginning.
  const addLocalFirstStatement = async (
    props?: AddLocalChildStatementProps,
  ) => {
    const { force, nodeFit, targetStatement } = props || {}

    // The reference statement to add a local from.
    const referencedStatement = targetStatement || selectedStatement

    // Don't do anything in case the local statement was not submitted.
    if (
      referencedStatement?.isLocal &&
      (!referencedStatement.isFetching ||
        !referencedStatement?.isEditMutationError) &&
      !force
    )
      return

    const newStatement = {
      childStatements: [],
      content: '',
      id: uuidv4(),
      isLocal: true,
      parentStatementId: referencedStatement?.parentStatementId || null,
      parsingInfo: {
        boundedContext: referencedStatement.parsingInfo.boundedContext,
      },
      platformId,
      processId: process.identity,
    } as unknown as Statement

    // Set query cache.
    await queryClient.setQueryData(
      queryKeyProcess(process.identity),
      (currentProcess: AxiosResponse<ResponseDiscoveryProcess>) =>
        produce(
          currentProcess,
          (draft: AxiosResponse<ResponseDiscoveryProcess>) => {
            draft.data.statements.unshift(newStatement)
          },
        ),
    )

    // Set selected statement state (and remove in case of need).
    selectNewAndRemoveCurrentIfNeed({
      force,
      nodeFit,
      targetStatement: newStatement,
    })
  }

  // Insert a local statement between nodes.
  const insertLocalStatementBetween = (props?: AddLocalChildStatementProps) => {
    const { force, nodeFit, targetStatement } = props || {}

    // The reference statement to add a local from.
    const referencedStatement = targetStatement || selectedStatement

    // Don't do anything in case the local statement was not submitted.
    if (
      referencedStatement?.isLocal &&
      (!referencedStatement.isFetching ||
        !referencedStatement?.isEditMutationError) &&
      !force
    )
      return

    const newStatement = {
      content: '',
      id: uuidv4(),
      isLocal: true,
      isInsert: true,
      parentStatementId: referencedStatement?.id || null,
      parsingInfo: {
        boundedContext: referencedStatement.parsingInfo.boundedContext,
      },
      platformId: platformId,
      processId: process.identity,
    } as unknown as Statement

    const newStatementList = insertLocalBetween(statements, newStatement)

    queryClient.setQueryData(
      queryKeyProcess(process.identity),
      (currentProcess: any) =>
        produce(currentProcess, (draft: any) => {
          draft.data.statements = newStatementList
        }),
    )

    selectNewAndRemoveCurrentIfNeed({
      force,
      nodeFit,
      targetStatement: newStatement,
    })
  }

  // Add a parent statement to the provided target node.
  const addLocalParentStatement = async (
    props?: AddLocalChildStatementProps,
  ) => {
    const { force, nodeFit, targetStatement } = props || {}

    // The reference statement to add a local parent from.
    const referencedStatement = targetStatement || selectedStatement

    // Don't do anything in case the local statement was not submitted.
    if (
      referencedStatement?.isLocal &&
      (!referencedStatement.isFetching ||
        !referencedStatement?.isEditMutationError) &&
      !force
    )
      return

    // The local parent statement to be added.
    const newStatement = {
      childStatements: [],
      content: '',
      id: uuidv4(),
      isLocal: true,
      parsingInfo: {
        boundedContext: referencedStatement.parsingInfo.boundedContext,
      },
      platformId,
      processId: process.identity,
    }

    // Set query cache.
    await queryClient.setQueryData(
      queryKeyProcess(process.identity),
      (currentProcess: AxiosResponse<ResponseDiscoveryProcess>) =>
        produce(
          currentProcess,
          (draft: AxiosResponse<ResponseDiscoveryProcess>) => {
            // Update the parent statement ID in the target node.
            const refStatementIndex = currentProcess.data.statements.findIndex(
              (statement) => statement.id === referencedStatement.id,
            )
            if (refStatementIndex > -1) {
              ;(
                draft.data.statements[refStatementIndex] as Statement
              ).parentStatementId = newStatement.id
            }

            // Add the new statement at the begging of statements list.
            draft.data.statements.unshift(newStatement)
          },
        ),
    )

    // Set selected statement state (and remove in case of need).
    selectNewAndRemoveCurrentIfNeed({
      force,
      nodeFit,
      targetStatement: newStatement,
    })
  }

  function selectParentStatement() {
    const parentStatement = getStatementById(
      statements || [],
      selectedStatement?.parentStatementId || '',
    )

    if (parentStatement) {
      selectNewAndRemoveCurrentIfNeed({
        nodeFit: true,
        targetStatement: parentStatement,
      })
    } else {
      addLocalParentStatement({ nodeFit: true })
    }
  }

  function selectFirstChildStatementOrAdd() {
    const firstChildStatement = getFirstChildStatement(
      statements || [],
      selectedStatement?.id || '',
    )

    if (firstChildStatement) {
      selectNewAndRemoveCurrentIfNeed({
        nodeFit: true,
        targetStatement: firstChildStatement,
      })
    } else {
      addLocalChildStatement({ nodeFit: true })
    }
  }

  function selectNextSiblingStatement() {
    const siblingStatement = getNextSibling(statements || [], selectedStatement)

    if (siblingStatement) {
      selectNewAndRemoveCurrentIfNeed({
        nodeFit: true,
        targetStatement: siblingStatement,
      })
    } else {
      addLocalLastChildStatement({ nodeFit: true })
    }
  }

  function selectPreviousSiblingStatement() {
    const siblingStatement = getPreviousSibling(
      statements || [],
      selectedStatement,
    )

    if (siblingStatement) {
      selectNewAndRemoveCurrentIfNeed({
        nodeFit: true,
        targetStatement: siblingStatement,
      })
    } else {
      addLocalFirstStatement({ nodeFit: true })
    }
  }

  function updateStatement(data: PayloadUpdateStatementParsingInfoCommand) {
    updateStatementParsingInfoMutation.mutate(data)
  }

  function getLocalOrFistStatement() {
    const storedValue = JSON.parse(
      localStorage.getItem(localStorageKey) || 'null',
    )
    const existsInStepList = process.statements.some((step) => {
      let found = false
      storedValue?.forEach((storedStep: Statement) => {
        if (storedStep?.id === step?.id) {
          found = true
        }
      })
      return found
    })

    const selectedStep =
      (existsInStepList ? storedValue : null) ||
      process.statements?.[0] ||
      newInitialStatement

    return Array.isArray(selectedStep) ? selectedStep : [selectedStep]
  }

  // Lifecycle.
  useEffect(() => {
    setStatements(formatStatementList(process, addInitialLocalStatement))

    if (process.identity !== currentProcess.identity) {
      setCurrentProcess(process)
      const found = getLocalOrFistStatement()
      if (found) {
        setSelectedStatements(found)
      }
    }
  }, [addInitialLocalStatement, currentProcess.identity, process])

  useEffect(() => {
    if (process.isGeneratingProcess) {
      const found = getLastStatement(process.statements)

      if (found) {
        setSelectedStatement(found)
      }
    } else {
      const found =
        previousIsGeneratingProcess.current &&
        process.identity === currentProcess.identity
          ? process.statements?.[0]
          : null

      if (found) {
        setSelectedStatement(found)
      }
    }
    previousIsGeneratingProcess.current = process.isGeneratingProcess
  }, [process.isGeneratingProcess, process.statements])

  return {
    addLocalChildStatement,
    addLocalFirstStatement,
    addLocalLastChildStatement,
    addLocalParentStatement,
    deleteSelectedStatement,
    insertLocalStatementBetween,
    isExpanded,
    isUpdatingParsingInfo: updateStatementParsingInfoMutation.isPending,
    isUpdatingStatementContent:
      editMutation.isPending || insertMutation.isPending,
    onHandleType,
    selectedStatement,
    selectedStatements,
    selectFirstChildStatementOrAdd,
    selectNextSiblingStatement,
    selectNewAndRemoveCurrentIfNeed,
    selectParentStatement,
    selectPreviousSiblingStatement,
    selectStatementByNode,
    shouldEdit,
    statementDiagramRef,
    statements,
    swapStatements,
    toggleIsExpanded,
    toggleMultiSelectEnablement,
    updateStatement,
  }
}
