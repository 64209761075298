import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { Plus } from '@phosphor-icons/react'
import { useQuery } from '@tanstack/react-query'
import { twMerge } from '^/tailwind.config'
import { AxiosResponse } from 'axios'
import React from 'react'
import {
  Form,
  Link as LinkRouter,
  Outlet,
  useLoaderData,
  useNavigation,
  useOutlet,
  useParams,
} from 'react-router-dom'
import { Column } from '~/components/Column'
import { LayoutConfiguration } from '~/components/LayoutConfiguration'
import { Text } from '~/components/Text'
import { shouldShowOnlyInDev } from '~/config/environments'
import { queryUsersByOrganisation } from '~/services/Discovery'
import {
  ResponseDiscoveryOrganisation,
  ResponseGetUsersByOrganisationId,
} from '~/services/Discovery.types'

type User = { id: GUID; name?: string; email?: string }

export function ConfigurationUser() {
  const initialData = useLoaderData() as {
    organisation: Awaited<
      AxiosResponse<ResponseDiscoveryOrganisation | null, unknown>['data']
    >
    users: Awaited<
      AxiosResponse<ResponseGetUsersByOrganisationId | null | [], unknown>
    >
  }
  const { data: dataUsers } = useQuery({
    ...queryUsersByOrganisation(initialData.organisation?.identity),
    initialData: initialData.users,
    select: (response) => response.data,
  })
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = React.useState(false)
  const [userSelected, setUserSelected] = React.useState<User>()
  const outlet = useOutlet()
  const { organisationId, platformId } = useParams()
  const navigation = useNavigation()

  function handleCloseRemoveDialog() {
    setIsRemoveDialogOpen(false)
  }

  function handleSelectUserToRemove(event: EventFor<'button', 'onClick'>) {
    const id = event.currentTarget.dataset.userId
    const name = event.currentTarget.dataset.userName
    const email = event.currentTarget.dataset.userEmail
    if (id) {
      setUserSelected({ id, name, email })
    }
    setIsRemoveDialogOpen(true)
  }

  return (
    <LayoutConfiguration selected="Organisation Users">
      <Text className="pl-2 text-2xl">
        Manage users for the organisation: {initialData?.organisation?.name}
      </Text>

      <Column className="mt-2 gap-2 rounded-lg bg-neutral-low-100 p-4 shadow-md">
        {shouldShowOnlyInDev() ? (
          <Alert severity="warning">
            <AlertTitle>Warning!</AlertTitle>This screen does not work locally.{' '}
            <strong>It may throw errors.</strong>
          </Alert>
        ) : null}

        <div>
          <Text>
            Manage the users in your organisation. In the table below you can
            view or remove users that will have access to this organisation
            data. Use the button below to add new users.
          </Text>
        </div>

        <div
          className={twMerge(
            outlet &&
              'rounded-md border border-solid border-highlight-200 bg-white p-4',
            'mt-4 transition-all',
          )}
        >
          <div>
            {outlet ? null : (
              <Button
                color="secondary"
                component={LinkRouter}
                startIcon={<Plus size={15} />}
                to={`/${organisationId}/${platformId}/configuration/users/add`}
                variant="contained"
              >
                Add a User
              </Button>
            )}
          </div>

          <div>
            <Outlet />
          </div>
        </div>

        <div>
          <TableContainer component={Paper}>
            <Table size="small">
              <caption className="sr-only">
                Table of users, with their name, email and removal action
              </caption>

              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '60%' }}>E-mail</TableCell>
                  <TableCell sx={{ width: '40%' }}>Name</TableCell>
                  <TableCell sx={{ width: 'fit-content' }}>Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {dataUsers?.length === 0 ? (
                  <>
                    <TableRow>
                      <TableCell colSpan={3}>
                        No other user in this organisation.
                      </TableCell>
                    </TableRow>
                    {navigation.state === 'submitting' && (
                      <TableRow>
                        <TableCell>
                          <Skeleton width="100%" />
                        </TableCell>
                        <TableCell>
                          <Skeleton width="100%" />
                        </TableCell>

                        <TableCell>
                          <Skeleton width="100%" />
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ) : null}

                {Array.isArray(dataUsers) &&
                  dataUsers?.map((user) => (
                    <React.Fragment key={user.email}>
                      <TableRow key={user.email}>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.name ? user.name : '-'}</TableCell>
                        <TableCell sx={{ textAlign: 'right' }}>
                          <IconButton
                            aria-label="remove user"
                            title="Remove user"
                            edge="end"
                            size="small"
                            onClick={handleSelectUserToRemove}
                            data-user-id={user.email}
                            data-user-name={user.name}
                            data-user-email={user.email}
                          >
                            <RemoveCircleOutlineIcon className="text-pink-400" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Column>

      <Dialog
        open={isRemoveDialogOpen}
        onClose={handleCloseRemoveDialog}
        aria-labelledby="remove-dialog-title"
        aria-describedby="remove-dialog-description"
      >
        <DialogTitle id="remove-dialog-title">Confirm user removal</DialogTitle>

        <DialogContent>
          <div id="remove-dialog-description">
            Do you really want to remove this user from the organisation?
            <div className="mt-2">
              <Text className="text-slate-700">
                <strong>Name:</strong>{' '}
                {userSelected?.name ? userSelected.name : '-'}
              </Text>

              <Text className="text-slate-700">
                <strong>E-mail:</strong>{' '}
                {userSelected?.email ? userSelected.email : '-'}
              </Text>
            </div>
          </div>
        </DialogContent>

        <Form
          method="POST"
          action="delete"
          onSubmit={(event) => {
            if (!userSelected?.id) {
              event.preventDefault()
            }
          }}
        >
          <input type="hidden" value={userSelected?.id} name="userId" />

          <input
            type="hidden"
            value={initialData?.organisation?.identity}
            name="organisationId"
          />

          <DialogActions>
            <Button
              color="secondary"
              onClick={handleCloseRemoveDialog}
              variant="outlined"
            >
              No
            </Button>

            <Button
              autoFocus
              color="secondary"
              onClick={handleCloseRemoveDialog}
              type="submit"
              variant="contained"
            >
              Yes
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </LayoutConfiguration>
  )
}
