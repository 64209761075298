import { createTheme } from '@mui/material/styles'
import { CSSProperties } from 'react'
import { designSystemTokens } from '~/styles/designSystemTokens'
import { tailwindColors } from '~/styles/tailwindColors'

type ColorsNames =
  | 'rose'
  | 'pink'
  | 'fuchsia'
  | 'purple'
  | 'violet'
  | 'indigo'
  | 'blue'
  | 'sky'
  | 'cyan'
  | 'teal'
  | 'emerald'
  | 'green'
  | 'lime'
  | 'yellow'
  | 'amber'
  | 'orange'
  | 'red'
  | 'warmGray'
  | 'trueGray'
  | 'gray'
  | 'coolGray'
  | 'blueGray'

type ColorsVariations = 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900

type Colors = Record<
  ColorsNames,
  {
    [K in ColorsVariations]: string
  }
>

declare module '@mui/material/styles' {
  interface TypeBackground {
    highlight: string
    bright: string
    panel: string
  }

  interface TypographyVariants {
    subhero: CSSProperties
    subHeader: CSSProperties
    welcomeWizardStepper: CSSProperties
    welcomeTitle: CSSProperties
    displayGiant: CSSProperties
    displayLarge: CSSProperties
    displayMedium: CSSProperties
    displaySmall: CSSProperties
    headingLarge: CSSProperties
    headingMedium: CSSProperties
    headingSmall: CSSProperties
    headingNumberLarge: CSSProperties
    headingNumberMedium: CSSProperties
    headingNumberSmall: CSSProperties
    subheadLarge: CSSProperties
    subheadMedium: CSSProperties
    subheadSmall: CSSProperties
    bodyExtraLargeRegular: CSSProperties
    bodyExtraLargeBold: CSSProperties
    bodyLargeRegular: CSSProperties
    bodyLargeBold: CSSProperties
    bodyMediumRegular: CSSProperties
    bodyMediumBold: CSSProperties
    bodySmallRegular: CSSProperties
    bodySmallBold: CSSProperties
    overline: CSSProperties
    caption: CSSProperties
    badge: CSSProperties
    labelLarge: CSSProperties
    labelMedium: CSSProperties
    labelSmall: CSSProperties
    inputLarge: CSSProperties
    inputMedium: CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    subhero?: CSSProperties
    subHeader?: CSSProperties
    welcomeWizardStepper?: CSSProperties
    welcomeTitle?: CSSProperties
    displayGiant: CSSProperties
    displayLarge: CSSProperties
    displayMedium: CSSProperties
    displaySmall: CSSProperties
    headingLarge: CSSProperties
    headingMedium: CSSProperties
    headingSmall: CSSProperties
    headingNumberLarge: CSSProperties
    headingNumberMedium: CSSProperties
    headingNumberSmall: CSSProperties
    subheadLarge: CSSProperties
    subheadMedium: CSSProperties
    subheadSmall: CSSProperties
    bodyExtraLargeRegular: CSSProperties
    bodyExtraLargeBold: CSSProperties
    bodyLargeRegular: CSSProperties
    bodyLargeBold: CSSProperties
    bodyMediumRegular: CSSProperties
    bodyMediumBold: CSSProperties
    bodySmallRegular: CSSProperties
    bodySmallBold: CSSProperties
    overline: CSSProperties
    caption: CSSProperties
    badge: CSSProperties
    labelLarge: CSSProperties
    labelMedium: CSSProperties
    labelSmall: CSSProperties
    inputLarge: CSSProperties
    inputMedium: CSSProperties
  }

  interface Theme {
    colors: Colors
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subhero: true
    subHeader: true
    welcomeWizardStepper: true
    welcomeTitle: true
    displayGiant: true
    displayLarge: true
    displayMedium: true
    displaySmall: true
    headingLarge: true
    headingMedium: true
    headingSmall: true
    headingNumberLarge: true
    headingNumberMedium: true
    headingNumberSmall: true
    subheadLarge: true
    subheadMedium: true
    subheadSmall: true
    bodyExtraLargeRegular: true
    bodyExtraLargeBold: true
    bodyLargeRegular: true
    bodyLargeBold: true
    bodyMediumRegular: true
    bodyMediumBold: true
    bodySmallRegular: true
    bodySmallBold: true
    overline: true
    caption: true
    badge: true
    labelLarge: true
    labelMedium: true
    labelSmall: true
    inputLarge: true
    inputMedium: true
  }
}

const paletteDark = {
  primary: {
    // main: '#94afff',
    main: '#97F0E2',
    contrastText: '#03231E',
  },
  secondary: {
    main: '#54ffe2',
  },
  background: {
    // default: '#121212',
    default: '#1E1F2C',
    paper: '#272b39',
    highlight: '#1E1F2C',
    bright: '#121212',
    panel: '#474b57',
  },
}

export const theme = (prefersDarkMode: boolean) =>
  createTheme(
    {
      components: {
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              backgroundColor: '#334155',
              fontSize: '0.75rem',
            },
            arrow: {
              color: '#334155',
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: 'none',
            },
            containedPrimary: {
              backgroundColor: designSystemTokens.colors.primary.DEFAULT,
              color: designSystemTokens.colors.neutral.low[100],
              '&:hover': {
                backgroundColor: designSystemTokens.colors.primary[200],
              },
            },
            containedSecondary: {
              backgroundColor: designSystemTokens.colors.secondary.DEFAULT,
              color: designSystemTokens.colors.neutral.low[100],
              '&:hover': {
                backgroundColor: designSystemTokens.colors.secondary[200],
              },
            },
            outlinedPrimary: {
              borderColor: designSystemTokens.colors.primary.DEFAULT,
              background: 'transparent',
              color: designSystemTokens.colors.primary.DEFAULT,
              '&:hover': {
                borderColor: designSystemTokens.colors.primary[100],
              },
            },
            outlinedSecondary: {
              borderColor: designSystemTokens.colors.secondary.DEFAULT,
              background: 'transparent',
              color: designSystemTokens.colors.secondary.DEFAULT,
              '&:hover': {
                borderColor: designSystemTokens.colors.secondary[200],
              },
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              background: prefersDarkMode ? '#272b39' : '#ffffff',
            },
          },
        },
        MuiCssBaseline: {
          styleOverrides: `
          :root {
            /* Font Families */
            --font-family-100: ${designSystemTokens.typography.fontFamily[100]};
            --font-family-200: ${designSystemTokens.typography.fontFamily[200]};
            --font-system-mac: ${designSystemTokens.typography.fontFamily.systemMac};
            --font-system-ios: ${designSystemTokens.typography.fontFamily.systemIos};
            --font-system-android: ${designSystemTokens.typography.fontFamily.systemAndroid};
            --font-system-windows: ${designSystemTokens.typography.fontFamily.systemWindows};
            
            /* Font Sizes */
            --font-size-50: ${designSystemTokens.typography.fontSize[50]};
            --font-size-100: ${designSystemTokens.typography.fontSize[100].default};
            --font-size-200: ${designSystemTokens.typography.fontSize[200].default};
            --font-size-300: ${designSystemTokens.typography.fontSize[300].default};
            --font-size-400: ${designSystemTokens.typography.fontSize[400].default};
            --font-size-500: ${designSystemTokens.typography.fontSize[500].default};
            --font-size-600: ${designSystemTokens.typography.fontSize[600].default};
            --font-size-700: ${designSystemTokens.typography.fontSize[700].default};
            --font-size-800: ${designSystemTokens.typography.fontSize[800].default};
            --font-size-900: ${designSystemTokens.typography.fontSize[900].default};
            --font-size-giant: ${designSystemTokens.typography.fontSize.giant};
            
            /* Line Heights */
            --line-height-100: ${designSystemTokens.typography.lineHeight[100].default};
            --line-height-200: ${designSystemTokens.typography.lineHeight[200].default};
            --line-height-300: ${designSystemTokens.typography.lineHeight[300].default};
            --line-height-400: ${designSystemTokens.typography.lineHeight[400].default};
            --line-height-500: ${designSystemTokens.typography.lineHeight[500].default};
            --line-height-600: ${designSystemTokens.typography.lineHeight[600].default};
            --line-height-700: ${designSystemTokens.typography.lineHeight[700].default};
            --line-height-800: ${designSystemTokens.typography.lineHeight[800].default};
            --line-height-900: ${designSystemTokens.typography.lineHeight[900].default};
            --line-height-display: ${designSystemTokens.typography.lineHeight.display};
            
            /* Letter Spacing */
            --letter-spacing-100: ${designSystemTokens.typography.letterSpacing[100]};
            --letter-spacing-200: ${designSystemTokens.typography.letterSpacing[200]};
            --letter-spacing-300: ${designSystemTokens.typography.letterSpacing[300]};
            --letter-spacing-400: ${designSystemTokens.typography.letterSpacing[400]};
            --letter-spacing-500: ${designSystemTokens.typography.letterSpacing[500]};
            --letter-spacing-600: ${designSystemTokens.typography.letterSpacing[600]};
            --letter-spacing-700: ${designSystemTokens.typography.letterSpacing[700]};
            --letter-spacing-800: ${designSystemTokens.typography.letterSpacing[800]};
            --letter-spacing-900: ${designSystemTokens.typography.letterSpacing[900]};
          }
          
          @font-face {
            font-family: "Visby CF";
            font-style: normal;
            font-display: swap;
            src: local("Visby CF");
            /* Add your font file paths here */
          }
          
          @font-face {
            font-family: "Noto Sans";
            font-style: normal;
            font-display: swap;
            src: local("Noto Sans");
            /* Add your font file paths here */
          }
          
          html, body {
            height: 100%;
            font-family: var(--font-family-100);
          }

          body {
            height: auto;
          }
        `,
        },
      },
      palette: {
        mode: prefersDarkMode ? 'dark' : 'light',
        text: {
          primary: prefersDarkMode
            ? designSystemTokens.colors.neutral.low[100]
            : designSystemTokens.colors.neutral.high[300],
        },
        primary: {
          main: designSystemTokens.colors.primary.DEFAULT,
        },
        secondary: {
          main: prefersDarkMode
            ? designSystemTokens.colors.neutral.low[100]
            : designSystemTokens.colors.secondary.DEFAULT,
        },
        background: {
          default: prefersDarkMode
            ? designSystemTokens.colors.secondary.DEFAULT
            : '#ffffff',
          paper: prefersDarkMode ? paletteDark.background.paper : '#ffffff',
          highlight: prefersDarkMode
            ? paletteDark.background.highlight
            : '#f4f5ff',
          bright: prefersDarkMode ? paletteDark.background.bright : '#fafaff',
          panel: prefersDarkMode ? paletteDark.background.panel : '#ffffff',
        },
      },
      typography: {
        // Set default font family to Visby CF.
        fontFamily: 'var(--font-family-100)',
        welcomeWizardStepper: {
          fontFamily: 'var(--font-family-100)',
          fontSize: '1.25rem',
          fontWeight: 400,
          color: prefersDarkMode ? '#686bb6' : '#ffffff',
        },
        welcomeTitle: {
          fontFamily: 'var(--font-family-100)',
          fontSize: '1.25rem',
          fontWeight: 700,
          color: prefersDarkMode ? 'rgba(255, 255, 255, 0.7)' : '#565888',
        },
        subhero: {
          fontFamily: 'var(--font-family-100)',
          fontSize: '2rem',
        },
        subHeader: {
          fontFamily: 'var(--font-family-100)',
          color: prefersDarkMode ? 'rgba(255, 255, 255, 0.7)' : '#686bb6',
          fontSize: '0.875rem',
        },

        // Display variants.
        displayGiant: {
          fontFamily: 'var(--font-family-100)',
          fontSize: 'var(--font-size-giant)',
          fontStyle: 'normal',
          fontWeight: 900,
          lineHeight: 'var(--line-height-display)',
          letterSpacing: 'var(--letter-spacing-100)',
        },
        displayLarge: {
          fontFamily: 'var(--font-family-100)',
          fontSize: 'var(--font-size-900)',
          fontStyle: 'normal',
          fontWeight: 900,
          lineHeight: 'var(--line-height-900)',
          letterSpacing: 'var(--letter-spacing-100)',
        },
        displayMedium: {
          fontFamily: 'var(--font-family-100)',
          fontSize: 'var(--font-size-800)',
          fontStyle: 'normal',
          fontWeight: 900,
          lineHeight: 'var(--line-height-800)',
          letterSpacing: 'var(--letter-spacing-200)',
        },
        displaySmall: {
          fontFamily: 'var(--font-family-100)',
          fontSize: 'var(--font-size-700)',
          fontStyle: 'normal',
          fontWeight: 900,
          lineHeight: 'var(--line-height-700)',
          letterSpacing: 'var(--letter-spacing-200)',
        },

        // Heading variants.
        headingLarge: {
          fontFamily: 'var(--font-family-100)',
          fontSize: 'var(--font-size-700)',
          fontStyle: 'normal',
          fontWeight: 900,
          lineHeight: 'var(--line-height-700)',
          letterSpacing: 'var(--letter-spacing-300)',
        },
        headingMedium: {
          fontFamily: 'var(--font-family-100)',
          fontSize: 'var(--font-size-600)',
          fontStyle: 'normal',
          fontWeight: 900,
          lineHeight: 'var(--line-height-600)',
          letterSpacing: 'var(--letter-spacing-300)',
        },
        headingSmall: {
          fontFamily: 'var(--font-family-100)',
          fontSize: 'var(--font-size-500)',
          fontStyle: 'normal',
          fontWeight: 900,
          lineHeight: 'var(--line-height-500)',
          letterSpacing: 'var(--letter-spacing-300)',
        },

        // Heading Number variants.
        headingNumberLarge: {
          fontFamily: 'var(--font-family-200)',
          fontSize: 'var(--font-size-700)',
          fontStyle: 'normal',
          fontWeight: 800,
          lineHeight: 'var(--line-height-700)',
          letterSpacing: 'var(--letter-spacing-300)',
        },
        headingNumberMedium: {
          fontFamily: 'var(--font-family-200)',
          fontSize: 'var(--font-size-600)',
          fontStyle: 'normal',
          fontWeight: 800,
          lineHeight: 'var(--line-height-600)',
          letterSpacing: 'var(--letter-spacing-300)',
        },
        headingNumberSmall: {
          fontFamily: 'var(--font-family-200)',
          fontSize: 'var(--font-size-500)',
          fontStyle: 'normal',
          fontWeight: 800,
          lineHeight: 'var(--line-height-500)',
          letterSpacing: 'var(--letter-spacing-300)',
        },

        // Subhead variants.
        subheadLarge: {
          fontFamily: 'var(--font-family-200)',
          fontSize: 'var(--font-size-400)',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'var(--line-height-400)',
          letterSpacing: 'var(--letter-spacing-400)',
        },
        subheadMedium: {
          fontFamily: 'var(--font-family-200)',
          fontSize: 'var(--font-size-300)',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'var(--line-height-400)',
          letterSpacing: 'var(--letter-spacing-400)',
        },
        subheadSmall: {
          fontFamily: 'var(--font-family-200)',
          fontSize: 'var(--font-size-200)',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'var(--line-height-300)',
          letterSpacing: 'var(--letter-spacing-500)',
        },

        // Body variants.
        bodyExtraLargeRegular: {
          fontFamily: 'var(--font-family-200)',
          fontSize: 'var(--font-size-400)',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'var(--line-height-400)',
          letterSpacing: 'var(--letter-spacing-500)',
        },
        bodyExtraLargeBold: {
          fontFamily: 'var(--font-family-200)',
          fontSize: 'var(--font-size-400)',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'var(--line-height-400)',
          letterSpacing: 'var(--letter-spacing-500)',
        },
        bodyLargeRegular: {
          fontFamily: 'var(--font-family-200)',
          fontSize: 'var(--font-size-300)',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'var(--line-height-400)',
          letterSpacing: 'var(--letter-spacing-600)',
        },
        bodyLargeBold: {
          fontFamily: 'var(--font-family-200)',
          fontSize: 'var(--font-size-300)',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'var(--line-height-400)',
          letterSpacing: 'var(--letter-spacing-600)',
        },
        bodyMediumRegular: {
          fontFamily: 'var(--font-family-200)',
          fontSize: 'var(--font-size-200)',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'var(--line-height-400)',
          letterSpacing: 'var(--letter-spacing-700)',
        },
        bodyMediumBold: {
          fontFamily: 'var(--font-family-200)',
          fontSize: 'var(--font-size-200)',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'var(--line-height-400)',
          letterSpacing: 'var(--letter-spacing-700)',
        },
        bodySmallRegular: {
          fontFamily: 'var(--font-family-200)',
          fontSize: 'var(--font-size-100)',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'var(--line-height-300)',
          letterSpacing: 'var(--letter-spacing-800)',
        },
        bodySmallBold: {
          fontFamily: 'var(--font-family-200)',
          fontSize: 'var(--font-size-100)',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'var(--line-height-300)',
          letterSpacing: 'var(--letter-spacing-800)',
        },

        // Other variants.
        overline: {
          fontFamily: 'var(--font-family-100)',
          fontSize: 'var(--font-size-100)',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'var(--line-height-100)',
          letterSpacing: 'var(--letter-spacing-900)',
          textTransform: 'uppercase',
        },
        caption: {
          fontFamily: 'var(--font-family-200)',
          fontSize: 'var(--font-size-100)',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'var(--line-height-100)',
          letterSpacing: 'var(--letter-spacing-800)',
        },
        badge: {
          fontFamily: 'var(--font-family-200)',
          fontSize: 'var(--font-size-50)',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'var(--line-height-100)',
          letterSpacing: 'var(--letter-spacing-800)',
        },

        // Label variants.
        labelLarge: {
          fontFamily: 'var(--font-family-200)',
          fontSize: 'var(--font-size-300)',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'var(--line-height-300)',
          letterSpacing: 'var(--letter-spacing-700)',
        },
        labelMedium: {
          fontFamily: 'var(--font-family-200)',
          fontSize: 'var(--font-size-200)',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'var(--line-height-300)',
          letterSpacing: 'var(--letter-spacing-700)',
        },
        labelSmall: {
          fontFamily: 'var(--font-family-200)',
          fontSize: 'var(--font-size-100)',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'var(--line-height-200)',
          letterSpacing: 'var(--letter-spacing-900)',
        },

        // Input variants.
        inputLarge: {
          fontFamily: 'var(--font-family-200)',
          fontSize: 'var(--font-size-300)',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'var(--line-height-300)',
          letterSpacing: 'var(--letter-spacing-600)',
        },
        inputMedium: {
          fontFamily: 'var(--font-family-200)',
          fontSize: 'var(--font-size-200)',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'var(--line-height-200)',
          letterSpacing: 'var(--letter-spacing-600)',
        },
      },
      ...tailwindColors,
    },
    () => ({
      palette: {
        // Status.
        success: {
          main: designSystemTokens.colors.feedback.success.DEFAULT,
          50: designSystemTokens.colors.feedback.success[50],
          100: designSystemTokens.colors.feedback.success[100],
          200: designSystemTokens.colors.feedback.success[200],
          300: designSystemTokens.colors.feedback.success[300],
        },
        info: {
          main: designSystemTokens.colors.feedback.info.DEFAULT,
          50: designSystemTokens.colors.feedback.info[50],
          100: designSystemTokens.colors.feedback.info[100],
          200: designSystemTokens.colors.feedback.info[200],
          300: designSystemTokens.colors.feedback.info[300],
        },
        warning: {
          main: designSystemTokens.colors.feedback.warning.DEFAULT,
          50: designSystemTokens.colors.feedback.warning[50],
          100: designSystemTokens.colors.feedback.warning[100],
          200: designSystemTokens.colors.feedback.warning[200],
          300: designSystemTokens.colors.feedback.warning[300],
        },
        error: {
          main: designSystemTokens.colors.feedback.error.DEFAULT,
          50: designSystemTokens.colors.feedback.error[50],
          100: designSystemTokens.colors.feedback.error[100],
          200: designSystemTokens.colors.feedback.error[200],
          300: designSystemTokens.colors.feedback.error[300],
        },
      },
    }),
  )
