import CheckIcon from '@mui/icons-material/Check'
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Switch,
  Tooltip,
} from '@mui/material'
import { twMerge } from '^/tailwind.config'
import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Column } from '~/components/Column'
import { Text } from '~/components/Text'
import type { GenerateCodeTilesBaseProps } from '~/models/types/components/generateCode/GenerateCodeTilesBaseProps'
import type { ResponseListGenerationStrategies } from '~/services/GenerationStrategy.types'

export type CustomAiBlueprintTileProps = GenerateCodeTilesBaseProps & {
  /** Array of AI blueprints (code generation strategies). */
  blueprints?: ResponseListGenerationStrategies[]
}

/**
 * The `custom Ai blueprint tile`
 * from the generate code modal.
 */
export const CustomAiBlueprintTile = (props: CustomAiBlueprintTileProps) => {
  const { blueprints, onTileClick, selectedStrategyType, shouldDisableFields } =
    props

  // URL params.
  const params = useParams<Record<string, string>>()
  const { organisationId, platformId } = params

  // Common props for the link component.
  const aiBlueprintsLinkProps = {
    rel: 'noopener noreferrer',
    to: `/${organisationId}/${platformId}/configuration/code-generation-strategies`,
    target: '_blank',
  }

  // Add search state
  const [searchQuery, setSearchQuery] = useState('')
  const [includePublic, setIncludePublic] = useState(false)

  // Updated filter logic
  const filteredBlueprints = blueprints?.filter(
    (strategy) =>
      (strategy.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        strategy.description
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        strategy.type.toLowerCase().includes(searchQuery.toLowerCase())) &&
      (strategy.type.toLowerCase() !== 'public' ||
        (includePublic && strategy.type.toLowerCase() === 'public')),
  )

  return (
    <Column
      className={twMerge(
        'flex flex-1 gap-2 rounded-lg border-2 border-solid border-slate-200 p-4',
        selectedStrategyType === 'custom' && 'border-primary-300',
      )}
      onClick={onTileClick}
      data-type="custom"
    >
      <div
        className={twMerge(
          '-mx-4 -mt-4 flex cursor-pointer justify-between gap-2 rounded-lg rounded-b-none bg-highlight-50 px-4 py-2',
          selectedStrategyType === 'custom' && 'bg-primary-50',
        )}
      >
        <Text>AI Blueprint</Text>

        {selectedStrategyType === 'custom' ? (
          <CheckIcon className="text-primary" />
        ) : (
          <CheckIcon className="invisible text-primary" />
        )}
      </div>

      {!!blueprints?.length && (
        <>
          <div className="mb-2 flex items-center justify-between gap-4">
            <FormControlLabel
              control={
                <Switch
                  checked={includePublic}
                  onChange={(e) => setIncludePublic(e.target.checked)}
                />
              }
              label="Show public blueprints"
            />
            <Text className="text-gray-500">
              Can't find the blueprint you're looking for?{' '}
              <Link
                className="transition hover:text-teal-700"
                {...aiBlueprintsLinkProps}
              >
                Create a new AI Blueprint
              </Link>
            </Text>
          </div>
        </>
      )}

      {!blueprints?.length && (
        <>
          <Text>
            No AI Blueprints available. Click the button below to create your
            first AI Blueprint:
          </Text>

          <Button
            component={Link}
            variant="outlined"
            {...aiBlueprintsLinkProps}
          >
            Create an AI Blueprint
          </Button>
        </>
      )}

      {!!blueprints?.length && (
        <>
          <FormGroup>
            {filteredBlueprints?.map((strategy) => (
              <FormControlLabel
                key={strategy.identity}
                disabled={
                  (selectedStrategyType !== 'custom' &&
                    selectedStrategyType !== undefined) ||
                  shouldDisableFields
                }
                label={
                  <Tooltip title={strategy.description} placement="right">
                    <div>
                      <span>{strategy.name}</span>
                      {strategy.type == 'Public' && (
                        <span className="text-gray-500"> {strategy.type}</span>
                      )}
                    </div>
                  </Tooltip>
                }
                name="strategy"
                value={strategy.identity}
                control={<Checkbox />}
              />
            ))}
          </FormGroup>
        </>
      )}
    </Column>
  )
}
