import { LoadingButton } from '@mui/lab'
import {
  Button,
  TextField,
  Typography,
  styled,
  type TextFieldProps,
} from '@mui/material'
import { useRef, type ElementRef } from 'react'
import { useParams } from 'react-router-dom'
import ControlledSelect from '~/components/form/ControlledSelect/ControlledSelect'
import InfoLabelIconTooltip from '~/components/tooltips/InfoLabelIconTooltip/InfoLabelIconTooltip'
import { useGetBindingContextOptions } from '~/hooks/api/codeGenerationStrategy/useGetBindingContextOptions'
import { useSelectedTextSectionsContext } from '~/hooks/contexts/useSelectedTextSectionsContext'
import { ValueBindingTreeItemFormEnum } from '~/models/enums/forms/ValueBindingTreeItemFormEnum'
import type { CodeGenerationStrategyBaseProps } from '~/models/types/components/codeGenerationStrategy/CodeGenerationStrategyBaseProps'
import {
  TextSectionDelimitersEnum,
  type FolderOrFileStructure,
  type PropertyBinding,
} from '~/services/GenerationStrategy.types'
import { EditableFieldFormFields } from '../../EditableFieldFormFields/EditableFieldFormFields'
import { MappingCondition } from '../../MappingCondition'
import { MenuToReplaceText } from '../../MenuToReplaceText/MenuToReplaceText'
import { RevertToOriginalValue } from '../../RevertToOriginalValue/RevertToOriginalValue'
import { revertToOriginalValueClasses } from '../../RevertToOriginalValue/RevertToOriginalValue.styles'
import { useMenuReplaceText } from '../../ValueBindingTreeItem/useMenuReplaceText'
import { useValueBindingTreeItem } from '../../ValueBindingTreeItem/useValueBindingTreeItem'

// Constants.
const DELIMITERS: DelimiterFieldOptions[] = [
  { label: '<>', value: TextSectionDelimitersEnum.ANGLED_BRACKETS },
  { label: '{}', value: TextSectionDelimitersEnum.BRACES },
  { label: '()', value: TextSectionDelimitersEnum.PARENTHESIS },
  { label: '[]', value: TextSectionDelimitersEnum.SQUARE_BRACKETS },
  { label: 'None', value: TextSectionDelimitersEnum.NONE },
]

const TEXT_FIELD_PROPS = {
  className: 'mb-5',
  InputLabelProps: { shrink: true },
  multiline: true,
  size: 'small',
} as Partial<TextFieldProps>

// Types.
export type TextSectionPropertiesProps = CodeGenerationStrategyBaseProps & {
  /** Handler for delete property binding click. */
  onDeletePropertyBindingClick?: (
    propertyBinding?: FolderOrFileStructure | PropertyBinding,
  ) => void
}

type DelimiterFieldOptions = { label: string; value: TextSectionDelimitersEnum }

// Styled Components.
const RevertToOriginal = styled(RevertToOriginalValue)`
  .${revertToOriginalValueClasses.row} {
    padding: ${({ theme }) => theme.spacing(0.5, 0)};
    color: inherit !important;
  }

  .${revertToOriginalValueClasses.label} {
    font-weight: bold;
  }
`

/**
 * Text section properties panel content.
 */
export const TextSectionProperties = (props: TextSectionPropertiesProps) => {
  const {
    dataDomainDictionary = [],
    fileId,
    onDeletePropertyBindingClick,
    selectedDomainDictionaryItem,
  } = props

  const params = useParams()
  const { generationStrategyId } = params

  // Hooks.
  const { data: bindingContextOptions } = useGetBindingContextOptions({
    generationStrategyId,
  })

  // Selected text sections state.
  const { selectedTextSections } = useSelectedTextSectionsContext()
  const selectedNodes = selectedTextSections?.selected
  const selectedTextSection = selectedTextSections?.selectedNode

  // Ref.
  const fieldRef = useRef<ElementRef<'textarea'>>(null)
  // Value binding hook
  const {
    configForMenuToReplace,
    control,
    handleFormSubmit,
    handleSubmit,
    isFormDirty,
    isPending,
    resetFormToInitialValues,
    setConfigForMenuToReplace,
    setFormValue,
    watchForm,
  } = useValueBindingTreeItem({
    initialValue: selectedTextSection?.value,
    node: selectedTextSection,
    fileId,
  })

  // Context menu hook.
  const { handleContextMenu, handleContextMenuClose, handleMenuItemClick } =
    useMenuReplaceText({
      dataDomainDictionary,
      fieldRef,
      selectedDomainDictionaryItem,
      setConfigForMenuToReplace,
      setFormValue,
      watchForm,
    })

  // Field values.
  const textFieldValue = watchForm?.(ValueBindingTreeItemFormEnum.VALUE) || ''

  return (
    <form className="flex flex-col" onSubmit={handleSubmit?.(handleFormSubmit)}>
      <Typography component="h3" variant="h6" className="mb-3 font-bold">
        Properties
      </Typography>

      {(!selectedNodes?.length || selectedNodes.length === 0) && (
        <>There is not a selected node.</>
      )}

      {!!selectedNodes?.length && selectedNodes.length > 1 && (
        <>There are more than one selected node.</>
      )}

      {!!selectedNodes?.length && selectedNodes.length === 1 && (
        <>
          <TextField
            {...TEXT_FIELD_PROPS}
            disabled
            label={
              <span className="flex items-center">
                Original value
                <InfoLabelIconTooltip title="The original value which appeared in the file loaded from the git repository." />
              </span>
            }
            value={selectedTextSection?.originalValue || ''}
          />

          <EditableFieldFormFields
            control={control}
            isEditable
            isPending={isPending}
            onValueContextMenu={handleContextMenu}
            valueClassName="mb-1 text border-transparent bg-transparent hover:border-solid hover:border-gray-300 hover:bg-white focus:bg-white"
            valueFieldLabel={
              <span className="flex items-center">
                Current value
                <InfoLabelIconTooltip title="The value to write when generating code from a process step." />
              </span>
            }
            valueRef={fieldRef}
          />

          <RevertToOriginal
            className="mb-5 self-start p-0"
            onlyButton
            originalValue={selectedTextSection?.originalValue}
            setFormValue={setFormValue}
            value={textFieldValue}
          />

          <MenuToReplaceText
            dataDomainDictionary={dataDomainDictionary}
            onMenuClose={handleContextMenuClose}
            onMenuItemClick={handleMenuItemClick}
            options={configForMenuToReplace}
          />

          <ControlledSelect
            className="mb-5"
            control={control}
            label={
              <span className="flex items-center">
                Bind to collection
                <InfoLabelIconTooltip title="Selecting this will generate the value for each item in the selected collection." />
              </span>
            }
            labelId="binding-context-label"
            id="binding-context"
            name={ValueBindingTreeItemFormEnum.BINDING_CONTEXT}
            options={bindingContextOptions}
          />

          <MappingCondition control={control!} />

          <div className="flex flex-row justify-between">
            <Button
              color="error"
              onClick={() =>
                onDeletePropertyBindingClick?.(selectedTextSection)
              }
              variant="outlined"
            >
              Delete
            </Button>

            <div className="flex flex-row">
              <Button
                className="ml-3"
                color="secondary"
                disabled={!isFormDirty}
                onClick={resetFormToInitialValues}
                variant="outlined"
              >
                Reset
              </Button>

              <LoadingButton
                className="ml-2"
                color="secondary"
                loading={isPending}
                type="submit"
                variant="contained"
              >
                Save
              </LoadingButton>
            </div>
          </div>
        </>
      )}
    </form>
  )
}
