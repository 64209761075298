import CloseIcon from '@mui/icons-material/Close'
import { IconButton, LinearProgress } from '@mui/material'
import type { MouseEvent } from 'react'
import { Text } from '~/components/Text'

export type UploadFilesSnackbarProps = {
  /** Indicates if the upload files mutation is pending. */
  isPending?: boolean
  /** Indicates if files processing is running on the background. */
  isProcessingFiles?: boolean
  /** Callback for close button click event. */
  onCloseClick: (e: MouseEvent) => void
  /** Array of rejected file names. */
  rejectedFiles?: string[]
}

/**
 * Upload files snackbar content,
 * it will render:
 * - In progress text;
 * - Or, rejected files.
 */
export const UploadFilesSnackbar = (props: UploadFilesSnackbarProps) => {
  const { isPending, isProcessingFiles, onCloseClick, rejectedFiles } = props

  return (
    <div className="relative max-h-[300px] min-w-[200px] overflow-y-auto rounded-lg border-node-green bg-white px-4 py-3 shadow-4">
      {(isPending || isProcessingFiles) && (
        <Text className="mb-2 text-center text-sm">
          {isPending && <>Uploading ...</>}
          {isProcessingFiles && <>Processing files ...</>}
        </Text>
      )}

      {!!rejectedFiles?.length && (
        <>
          <Text className="mb-2 text-sm text-primary">Rejected files:</Text>

          <ul className="m-0 pl-6">
            {rejectedFiles?.map((file: string) => (
              <li className="text-xs text-primary" key={file}>
                {file}
              </li>
            ))}
          </ul>
        </>
      )}

      {(isPending || isProcessingFiles) && (
        <LinearProgress color="secondary" sx={{ borderRadius: 1 }} />
      )}

      <IconButton
        aria-label="close"
        className="absolute right-0 top-0"
        color="inherit"
        onClick={onCloseClick}
        size="small"
      >
        <CloseIcon className="text-sm text-slate-600" />
      </IconButton>
    </div>
  )
}
