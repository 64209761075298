import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined'
import { Box, Button, Dialog, Paper, Stack, Typography } from '@mui/material'
import {
  Link,
  Outlet,
  useNavigate,
  useOutlet,
  useParams,
} from 'react-router-dom'
import { BusinessChildrenParams } from '../routes.types'

export function ConfigBusiness() {
  const outlet = useOutlet()
  const params = useParams<BusinessChildrenParams>()
  const navigate = useNavigate()

  function handleClose() {
    navigate(
      `/${params.organisationId}/${params.platformId}/business/config-business`,
    )
  }

  return (
    <>
      <Paper sx={{ py: 4, px: 6, width: 'fit-content' }} elevation={5}>
        <Stack>
          <Typography variant="h6">Let&#39;s get started</Typography>

          <Typography mt={2} variant="subtitle1">
            Let&#39;s start by mapping a business process, and{' '}
            <strong>ArcPilot</strong> will help you visualise and optimise it.
          </Typography>

          <Button
            color="secondary"
            component={Link}
            replace
            size="large"
            sx={{
              width: 'fit-content',
              alignSelf: 'flex-end',
              mt: 5,
              gap: 1,
            }}
            to={`add-process`}
            variant="contained"
          >
            <PlayArrowOutlinedIcon />
            Let&#39;s get started
          </Button>
        </Stack>
      </Paper>

      <Dialog
        open={!!outlet}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            minHeight: '600px',
            maxHeight: '90vh',
            minWidth: '550px',
          },
        }}
      >
        <Box p={3} className="h-full">
          <Outlet />
        </Box>
      </Dialog>
    </>
  )
}
