/**
 * Removes any ending brackets by replacing for an empty string.
 * @param value The string value to be checked.
 */
export const removeEndingBrackets = (value: string) => {
  // If the string ends with '[]', remove the '[]'
  if (typeof value === 'string' && value.endsWith('[]'))
    return value.slice(0, -2)

  // Otherwise, return the original value.
  return value
}
