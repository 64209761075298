import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { generatePath, LoaderFunctionArgs, redirect } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { platforms } from '~/config'
import { DeveloperRoutesEnum } from '~/models/enums/routes/DeveloperRoutesEnum'
import { queryPlatformTeam } from '~/services/PlatformTeam'
import { getUserEmail } from '~/utils/getUserEmail'
import { getUserId } from '~/utils/getUserId'

export const loaderDeveloperHosting =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ params }: LoaderFunctionArgs) => {
    const userId = getUserId(pca)
    const email = getUserEmail(pca)
    invariant(userId, `Must be logged in: ${userId}`)
    invariant(email, `Must be logged in: ${userId}`)
    const { boundedContext, organisationId, platformId, aggregateId } = params

    const qPlatform = queryPlatformTeam(organisationId)
    const fetchPlatform = await queryClient.ensureQueryData(qPlatform)

    if (!fetchPlatform?.data?.cloudPlatform) {
      const developerPath = generatePath(
        DeveloperRoutesEnum.DEVELOPER_AGGREGATE,
        {
          aggregateId: aggregateId || '',
          boundedContext: boundedContext || '',
          organisationId: organisationId || '',
          platformId: platformId || '',
        },
      )

      return redirect(
        `${developerPath}/config-cloud-provider?redirect=${developerPath}/edit-hosting`,
      )
    }

    // return null
    const hostings = platforms.find(
      (platform) => platform.identity === fetchPlatform.data?.cloudPlatform,
    )?.services.hostingStrategies
    return hostings ?? null
  }
