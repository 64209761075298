import type {
  FileStructure,
  FolderOrFileStructure,
  FolderStructure,
} from '~/services/GenerationStrategy.types'

/**
 * Filters the data directory items against the provided name.
 * @param data The file/folder data.
 * @param name The name to filter the data.
 */
export const filterDataDirectoryItemsByName = (
  data: FolderOrFileStructure,
  name: string = '',
): FolderOrFileStructure | null => {
  if (!name) return data

  // Helper function to check if the node's name matches the provided name.
  const nameMatches = (nodeName: string) => {
    return nodeName.toLowerCase().includes(name?.toLowerCase())
  }

  // Base case: if the node doesn't exist, return null.
  if (!data) return null

  // Check if the current node matches the provided name.
  if (nameMatches(data.name)) {
    // If it matches, return the node with its original children.
    return data
  }

  // If the current node doesn't match, check its children.
  if (data.children && data.children.length > 0) {
    const filteredChildren = data.children
      .map((child) => filterDataDirectoryItemsByName(child, name))
      .filter(Boolean) as (FolderStructure | FileStructure)[]

    // If any children remain after filtering, return the node with only those children.
    if (filteredChildren.length > 0) {
      return {
        ...data,
        children: filteredChildren,
      }
    }
  }

  // If no match is found in this branch, return null.
  return null
}
