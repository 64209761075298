import { TextField } from '@mui/material'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Form, useActionData } from 'react-router-dom'
import { ButtonAutoGenerate } from '~/components/ButtonAutoGenerate'
import { Column } from '~/components/Column'
import { FormActionName } from '~/pages/developer/components/FormActionName/index'
import { FormAttributes } from '~/pages/developer/components/FormAttributes'
import { FormFooter } from '~/pages/developer/components/FormFooter'
import { Attribute } from '~/services/Development.types'
import { useAggregateActionForm } from '../hooks/useAggregateActionForm'
import { renamePropertiesToAttributes } from '../utils'

export function DeveloperAddType() {
  const dataAutoGeneratedAttributes = useActionData() as {
    formName: string
    attributes: Attribute[]
  }

  const methods = useForm({
    defaultValues: {
      action: {
        name: '',
        family: '',
        attributes: [],
      },
    },
  })

  const { formProps, submitButtonRef } = useAggregateActionForm()

  useEffect(() => {
    if (
      dataAutoGeneratedAttributes &&
      dataAutoGeneratedAttributes?.formName === 'type' &&
      dataAutoGeneratedAttributes?.attributes.length > 0
    ) {
      methods.setValue(
        'action.attributes',
        renamePropertiesToAttributes(dataAutoGeneratedAttributes?.attributes),
      )
    }
  }, [dataAutoGeneratedAttributes, methods])

  return (
    <>
      <Column className="gap-4 pb-12">
        <FormProvider {...methods}>
          <Form method="post" id="form-aggregate-add-type" {...formProps}>
            <Column className="gap-4">
              <FormActionName inputLabel="Name" inputName="type.name">
                <TextField
                  label="Family"
                  variant="outlined"
                  name="type.family"
                  fullWidth
                  sx={{
                    mb: 2,
                  }}
                />

                <FormAttributes
                  autoGenerateComponent={<ButtonAutoGenerate name="type." />}
                  formName="action"
                />
              </FormActionName>
            </Column>

            <FormFooter submitButtonRef={submitButtonRef} />
          </Form>
        </FormProvider>
      </Column>
    </>
  )
}
