import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CheckIcon from '@mui/icons-material/Check'
import { Button, IconButton, Stack, TextField, Typography } from '@mui/material'
import { twMerge } from '^/tailwind.config'
import { useState } from 'react'
import { BadgeUsesLeft } from '~/components/BadgeUsesLeft'
import { Column } from '~/components/Column'
import { Row } from '~/components/Row'
import { FEATURE_TOGGLE } from '~/config/featureToggle'
import { WizardStepIcon } from '~/styled/WizardBullet'

type ProcessFormDefaultValues = {
  name: string
  description: string
  generateProcessBasedOnDescription: boolean
}

type ProcessFormProps = {
  currentStep: number
  descriptionFieldLabel: string
  defaultValues?: ProcessFormDefaultValues
  nameFieldLabel: string
  setCurrentStep: (step: number) => void
  withCloseBtn?: boolean
}

export function ProcessForm(props: ProcessFormProps) {
  const {
    nameFieldLabel,
    descriptionFieldLabel,
    defaultValues,
    currentStep,
    setCurrentStep,
    withCloseBtn,
  } = props

  const [shouldAutoGenerate, setShouldAutoGenerate] = useState(
    defaultValues?.generateProcessBasedOnDescription || false,
  )

  const handleBack = () => {
    setCurrentStep(1)
  }

  const handleGenerationMethodSelect = (isAuto: boolean) => {
    setShouldAutoGenerate(isAuto)
    setCurrentStep(2)
  }

  if (currentStep === 1) {
    return (
      <Stack gap={2} className="h-full">
        <Typography variant="h4" className="mb-4">
          Design Your Process
        </Typography>

        <RadioButton
          title="SmartMap™"
          description="Map your business process, our AI will help you along."
          isDisabled={!!defaultValues}
          isSelected={!shouldAutoGenerate}
          onClick={() => handleGenerationMethodSelect(false)}
          perfectFor={[
            'Detailed process discovery',
            'Automatic Document and Code Analysis',
          ]}
        />

        <RadioButton
          title="Auto-generate SmartMap"
          description="Describe your process in natural language and let ArcPilot generate an initial process map for you."
          isDisabled={!!defaultValues}
          isSelected={shouldAutoGenerate}
          onClick={() => handleGenerationMethodSelect(true)}
          shouldShowBadge={FEATURE_TOGGLE.UPGRADE_PLAN}
          perfectFor={[
            'Quick process prototyping',
            'Initial process discovery',
          ]}
        />
      </Stack>
    )
  }

  if (currentStep === 2) {
    return (
      <Stack gap={6}>
        <Row className="w-full items-center justify-between">
          <Typography variant="h4">
            {shouldAutoGenerate
              ? 'Auto-generate Process'
              : 'Manual Process Design'}
          </Typography>

          <IconButton
            className={twMerge('text-gray-600', withCloseBtn && '-mt-4 mr-7')}
            onClick={handleBack}
            size="small"
          >
            <ArrowBackIcon />
          </IconButton>
        </Row>

        <Stack gap={3}>
          <Stack gap={2}>
            <TextField
              name="name"
              label="Process Name"
              fullWidth
              placeholder="Enter a name for your process"
              defaultValue={defaultValues?.name}
              disabled={!!defaultValues}
              variant="outlined"
              required
            />
          </Stack>

          <Stack gap={2}>
            <Typography variant="h6"></Typography>

            <TextField
              name="description"
              label={
                shouldAutoGenerate
                  ? 'Describe Your Process in Detail'
                  : 'Process Description'
              }
              fullWidth
              multiline
              minRows={8}
              maxRows={12}
              defaultValue={defaultValues?.description}
              disabled={!!defaultValues}
              placeholder={
                shouldAutoGenerate
                  ? 'Describe your entire business process in natural language. Include all steps, actors, and business rules. For example:'
                  : 'Add a brief description of your process'
              }
              required={shouldAutoGenerate}
            />
            {shouldAutoGenerate && (
              <Typography variant="body2" className="mt-2 text-gray-500">
                Provide as much detail as possible. Include business rules,
                conditions, and any specific requirements.
              </Typography>
            )}
          </Stack>
        </Stack>

        <input
          readOnly
          name="generateProcessBasedOnDescription"
          hidden
          value={JSON.stringify(shouldAutoGenerate)}
        />
      </Stack>
    )
  }

  return (
    <Stack gap={2} className="max-w-lg">
      <Row className="w-full items-center justify-between">
        <Row className="items-center gap-2">
          <WizardStepIcon icon="2" completed={false} active={true} />
          <Typography className="text-xl">Describe your process</Typography>
        </Row>

        <IconButton onClick={handleBack} size="small" className="text-gray-600">
          <ArrowBackIcon />
        </IconButton>
      </Row>

      <TextField
        className="shadow-1"
        name={'name'}
        label={nameFieldLabel}
        defaultValue={defaultValues?.name}
        fullWidth
        type="text"
        disabled={!!defaultValues}
        inputProps={{
          'aria-label': `name`,
          placeholder: 'E.g., Customer Onboarding, Invoice Approval, etc.',
        }}
        required
      />

      <TextField
        className="shadow-1"
        name={'description'}
        label={descriptionFieldLabel}
        defaultValue={defaultValues?.description}
        disabled={!!defaultValues}
        fullWidth
        type="text"
        multiline
        size="medium"
        sx={{
          padding: 0,
        }}
        inputProps={{
          style: {
            height: '100px',
          },
          'aria-label': `description`,
          placeholder:
            'Starting from the start of the process describe it in detail...',
        }}
        required={shouldAutoGenerate}
      />

      <input
        readOnly
        name="generateProcessBasedOnDescription"
        hidden
        value={JSON.stringify(shouldAutoGenerate)}
      />
    </Stack>
  )
}

type RadioButtonProps = {
  title: string
  description: string
  isSelected: boolean
  isDisabled: boolean
  onClick: () => void
  shouldShowBadge?: boolean
  perfectFor?: string[]
}

function RadioButton({
  isDisabled,
  isSelected,
  onClick,
  title,
  description,
  shouldShowBadge = false,
  perfectFor = [],
}: RadioButtonProps & { perfectFor?: string[] }) {
  return (
    <Button
      className={twMerge(
        'flex h-[280px] flex-col items-start justify-start p-5',
        !isSelected && 'border-gray-400 bg-transparent',
      )}
      color="secondary"
      disabled={isDisabled}
      onClick={onClick}
      variant="outlined"
    >
      <Column className="h-full gap-4">
        <Row className="items-center gap-2">
          <Typography
            className={twMerge(
              'text-start text-xl font-semibold',
              !isSelected && 'text-gray-400',
            )}
          >
            {title}
          </Typography>

          {shouldShowBadge && (
            <BadgeUsesLeft featureType="PROCESS_AUTO_GENERATION" />
          )}
        </Row>

        <Typography
          className={twMerge('text-start', !isSelected && 'text-gray-400')}
        >
          {description}
        </Typography>

        {perfectFor.length > 0 && (
          <Column className="mt-auto gap-2">
            <Typography
              className={twMerge(
                'text-start font-semibold',
                !isSelected && 'text-gray-400',
              )}
            >
              Perfect for:
            </Typography>

            <ul
              className={twMerge(
                'list-disc pl-5',
                !isSelected && 'text-gray-400',
              )}
            >
              {perfectFor.map((item, index) => (
                <li key={index}>
                  <Typography className="text-start">{item}</Typography>
                </li>
              ))}
            </ul>
          </Column>
        )}

        <CheckIcon
          fontSize="large"
          className={!isSelected ? 'invisible' : 'absolute right-5 top-5'}
        />
      </Column>
    </Button>
  )
}
