import AbcIcon from '@mui/icons-material/AbcRounded'
import { type SvgIconProps } from '@mui/material'
import type { ExpressionItem } from './MappingCondition.constants'

/**
 * Provides the expected icon for the provided type.
 * @param returnType The type.
 * @param props The icon props.
 */
export const getReturnTypeIcon = (
  returnType: ExpressionItem['returnType'],
  props: SvgIconProps,
) => {
  switch (returnType) {
    case 'boolean':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            id="i-16x16-Bool"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="TrueFalse_16x" fillRule="nonzero">
              <polygon
                id="canvas"
                fill="#F6F6F6"
                opacity="0"
                points="4.85722573e-16 0 16 0 16 16 0 16"
              />
              <g
                id="iconBg"
                transform="translate(2.000000, 2.000000)"
                fill="currentColor"
              >
                <path d="M11.112,4.558 L6.041,9.63 L4.064,7.653 L3.18,8.538 L6.041,11.398 L11.997,5.443 L11.112,4.558 Z M5.01,6.163 L5.894,5.278 L3.928,3.312 L5.894,1.346 L5.01,0.461 L3.044,2.428 L1.078,0.461 L0.194,1.346 L2.16,3.312 L0.194,5.278 L1.078,6.163 L3.044,4.196 L5.01,6.163 Z" />
              </g>
            </g>
          </g>
        </svg>
      )
    case 'string':
      return <AbcIcon {...props} />
  }
}
