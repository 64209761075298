import { useMsal } from '@azure/msal-react'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { Form, useLoaderData } from 'react-router-dom'
import { WizardContent } from '~/components/WizardContent'
import { WizardFooterButtons } from '~/components/WizardFooterButtons'
import { queryOrganisation } from '~/services/Discovery'
import { ResponseDiscoveryOrganisation } from '~/services/Discovery.types'
import { WizardTextField } from '~/styled/WizardTextField'
import { getUserEmailByMsal, getUserIdByMsal } from '~/utils/func'

export function Step2Ecosystem() {
  const { accounts } = useMsal()
  const userId = getUserIdByMsal(accounts)
  const email = getUserEmailByMsal(accounts)
  const initialData = useLoaderData() as Awaited<
    AxiosResponse<ResponseDiscoveryOrganisation | null, unknown>
  >
  const { data } = useQuery({
    ...queryOrganisation(userId, email),
    initialData,
    select: (response) => response.data,
  })

  return (
    <WizardContent
      step="2"
      title={
        <>
          Please enter your <strong>System Name</strong>
        </>
      }
      intro="We're going to build a system together, let's start by giving it a name"
    >
      <Form method="post" id="form-welcome-step2">
        <WizardTextField label="System Name" name="name" fullWidth autoFocus />
        <input
          type="hidden"
          defaultValue={data?.identity}
          name="organisationId"
        />
        <WizardFooterButtons />
      </Form>
    </WizardContent>
  )
}
