// Define a common type for all items.
export type ExpressionItem = {
  name: string
  description: string | null
  value: string
  category: 'operator' | 'function' | 'parameter' | 'expression'
  returnType: 'boolean' | 'string'
}

export const SUGGESTIONS: ExpressionItem[] = [
  {
    name: 'Check for Query that returns collection',
    description: `Expression will evaluate the query and check if the query result is a collection.

      Examples
      1. {Aggregates.Queries.Result.IsCollection} -> true`,
    value: '{Aggregates.Queries.Result.IsCollection}',
    category: 'expression',
    returnType: 'boolean',
  },
  {
    name: 'Check for Query that returns single object',
    description: `Expression will evaluate the query and check if the query result is a single object (not collection).

      Examples
      1. {Aggregates.Queries.Result.IsCollection} -> false -> !false -> true`,
    value: '!{Aggregates.Queries.Result.IsCollection}',
    category: 'expression',
    returnType: 'boolean',
  },
  {
    name: "Check for Command that name starts with 'Create'",
    description: `Expression will evaluate the command and check if the name starts with 'Create' (case insensitive).

      Examples
      1. {Aggregates.Commands.Name} -> 'CreateCustomer' -> startsWith('CreateCustomer', 'Create') -> true`,
    value: "startsWith({Aggregates.Commands.Name}, 'Create')",
    category: 'expression',
    returnType: 'boolean',
  },
  {
    name: "Check for Command Attribute that name is 'Id'",
    description: `Expression will evaluate the command attribute and check if the name is 'Id' (case insensitive).

      Examples
      1. {Aggregates.Commands.Attributes.Name} -> 'Id' -> true`,
    value: "{Aggregates.Commands.Attributes.Name} == 'Id'",
    category: 'expression',
    returnType: 'boolean',
  },
  {
    name: "Check for Type that is 'Entity'",
    description: `Expression evaluates the type and check if the family is 'Entity' (case insensitive).

      Examples
      1. {Aggregates.Types.Family} -> 'Entity' -> true`,
    value: "{Aggregates.Types.Family} == 'Entity'",
    category: 'expression',
    returnType: 'boolean',
  },
  {
    name: "Check for Type that is 'Value Object'",
    description: `Expression will evaluate the type and check if the type family is 'Value Object' (case insensitive).

      Examples
      1. {Aggregates.Types.Family} -> 'Value Object' -> true`,
    value: "{Aggregates.Types.Family} == 'Value Object'",
    category: 'expression',
    returnType: 'boolean',
  },
  {
    name: "Check for Type that is 'DTO'",
    description: `Expression will evaluate if the type family is 'DTO' (case insensitive).

      Examples
      1. {Aggregates.Types.Family} -> 'DTO' -> true`,
    value: "{Aggregates.Types.Family} == 'DTO'",
    category: 'expression',
    returnType: 'boolean',
  },
  {
    name: "Check for Type that is 'DAO'",
    description: `Expression will evaluate if the type family is 'DAO' (case insensitive).

      Examples
      1. {Aggregates.Types.Family} -> 'DAO' -> true`,
    value: "{Aggregates.Types.Family} == 'DAO'",
    category: 'expression',
    returnType: 'boolean',
  },
  {
    name: "Check for Type that is 'ENUM'",
    description: `Expression will evaluate the type and check if the type family is 'ENUM' (case insensitive).

      Examples
      1. {Aggregates.Types.Family} -> 'ENUM' -> true`,
    value: "{Aggregates.Types.Family} == 'ENUM'",
    category: 'expression',
    returnType: 'boolean',
  },
]

export const PREVIOUSLY_CONFIGURED: ExpressionItem[] = [
  {
    name: '{Aggregates.Queries.Result.Type.Name} == "ProductDto"',
    description: null,
    value: '{Aggregates.Queries.Result.Type.Name} == "ProductDto"',
    category: 'expression',
    returnType: 'boolean',
  },
]

export const PARAMETERS: ExpressionItem[] = [
  {
    name: 'Aggregates.Name',
    description: null,
    value: '{Aggregates.Name}',
    category: 'parameter',
    returnType: 'string',
  },
  {
    name: 'Aggregates.Commands.Name',
    description: null,
    value: '{Aggregates.Commands.Name}',
    category: 'parameter',
    returnType: 'string',
  },
]

export const FUNCTIONS: ExpressionItem[] = [
  {
    name: 'endsWith',
    description: `Checks if the string ends with the supplied string (case insesitive).

Examples
1. endsWith('dumbo', 'mbo') -> true
2. endsWith('DUMBO', 'mbo') -> true`,
    value: 'endsWith(string, substring_to_check)',
    category: 'function',
    returnType: 'boolean',
  },
  {
    name: 'startsWith',
    description: `Checks if the string starts with the supplied string (case insesitive).

      Examples
      1. startsWith('dumbo', 'du') -> true
      2. startsWith('Dumbo', 'du') -> true`,
    value: 'startsWith(string, substring_to_check)',
    category: 'function',
    returnType: 'boolean',
  },
  {
    name: 'contains',
    description: `Checks if the string contains the supplied substring (case insesitive). 

Examples
1. contains('dumbo', 'mb') -> true
2. contains('DUMBO', 'mb') -> true`,
    value: 'contains(string, substring_to_check)',
    category: 'function',
    returnType: 'boolean',
  },
  {
    name: 'toPascal',
    description: `Converts string to PascalCase format

Examples
1. toPascal('hello world') -> 'HelloWorld'`,
    value: 'toPascal(string)',
    category: 'function',
    returnType: 'string',
  },
]

export const OPERATORS: ExpressionItem[] = [
  {
    name: 'Equal',
    description: `Relational operator that compares two values for equality. Case insensitive for strings.
  
        Examples:
        1. 5 == 5 -> true
        2. 'hello' == 'Hello' -> true`,
    value: '==',
    category: 'operator',
    returnType: 'boolean',
  },
  {
    name: 'Not Equal',
    description: `Relational operator that compares two values for inequality. Case insensitive for strings.
  
        Examples:
        1. 5 != 6 -> true
        2. 'hello' != 'Hello' -> false`,
    value: '!=',
    category: 'operator',
    returnType: 'boolean',
  },
  {
    name: 'And',
    description: `Logical AND operator that returns true when both conditions are true. Used as: and, &&
  
        Examples:
        1. true and true -> true
        2. true && false -> false`,
    value: '&&',
    category: 'operator',
    returnType: 'boolean',
  },
  {
    name: 'Or',
    description: `Logical OR operator that returns true when at least one condition is true. Used as: or, ||
  
        Examples:
        1. true or false -> true
        2. false || false -> false`,
    value: '||',
    category: 'operator',
    returnType: 'boolean',
  },
  {
    name: 'Not',
    description: `Logical NOT operator that inverts the boolean value. Used as: not, !
  
        Examples:
        1. not true -> false
        2. !false -> true`,
    value: '!',
    category: 'operator',
    returnType: 'boolean',
  },
]
