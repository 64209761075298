import { AGGREGATE_TYPES } from '~/constants/components/aggregateTypes/aggregateTypes'
import { removeEndingBrackets } from './removeEndingBrackets'

/**
 * Determines if a type is a default aggregate type.
 * @param typeName The name of the type to be checked.
 */
export const isDefaultAggregateType = (typeName: string): boolean =>
  AGGREGATE_TYPES.some(
    (defaultType) => defaultType.name === removeEndingBrackets(typeName),
  )
