import type { RefObject } from 'react'
import type { ControllerRenderProps } from 'react-hook-form'

/**
 * Helper function to insert expressions.
 * @param expression The expression name.
 * @param field The controlled field data.
 * @param inputRef The input field ref.
 */
export const handleInsertExpression = (
  expression: string,
  field: ControllerRenderProps,
  inputRef: RefObject<HTMLTextAreaElement>,
) => {
  const input = inputRef.current
  if (!input) return

  const start = input.selectionStart ?? 0
  const end = input.selectionEnd ?? 0
  const currentValue = String(field.value ?? '')
  const newValue =
    currentValue.substring(0, start) + expression + currentValue.substring(end)

  field.onChange(newValue)

  requestAnimationFrame(() => {
    if (input) {
      const newCursorPos = start + expression.length
      input.focus?.()
      input.setSelectionRange?.(newCursorPos, newCursorPos)
    }
  })
}
