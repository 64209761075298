import { memo } from 'react'
import type { RenderTreeItemProps } from '~/models/types/components/codeGenerationStrategy/RenderTreeItemProps'
import RenderTreeCollection from './RenderTreeCollection'
import { RenderTreeObject } from './RenderTreeObject'

export const RenderTreeItem = memo((props: RenderTreeItemProps) => {
  const { node } = props

  // Nothing to render if there's no node
  if (!node) return null

  // Get the rendered nodes.
  const collectionNode = <RenderTreeCollection {...props} />
  const objectNode = <RenderTreeObject {...props} />

  return (
    <>
      {collectionNode}
      {objectNode}
    </>
  )
})
