import type { Attribute } from '~/services/Development.types'

type PropertiesListProps = {
  attributes: Attribute[] | undefined
  contentIfEmpty?: JSX.Element | undefined
}

export const PropertiesList = (props: PropertiesListProps) => {
  const { attributes, contentIfEmpty } = props

  return attributes?.length ? (
    <div className="flex flex-1 flex-col justify-center px-3 py-2">
      {attributes?.map((attrType: Attribute) => (
        <div key={attrType.name} className="m-0 flex justify-between">
          <p className="m-0 font-bold">{attrType.name}:</p>
          <p className="m-0 font-mono">{attrType.type}</p>
        </div>
      ))}
    </div>
  ) : (
    <div className="flex justify-center">
      {contentIfEmpty ? contentIfEmpty : null}
    </div>
  )
}
