import CheckIcon from '@mui/icons-material/Check'
import { InputAdornment, MenuItem, TextField } from '@mui/material'
import { useRef, useState, type ChangeEvent, type KeyboardEvent } from 'react'
import { Controller, type UseFormReturn } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import ActionIconButton from '~/components/buttons/ActionIconButton/ActionIconButton'
import { useAddTypeProperty } from '~/hooks/api/developer/useAddTypeProperty'

export type TypeSelectOption = {
  /**
   * The depth level:
   * - 0: means this option is a category one.
   * - 1: means it is an actual option.
   */
  depth?: number
  /** The option id. */
  id?: GUID
  /** The option label. */
  label?: string
  /** The option name. */
  name?: string
}

type TypeSelectProps = {
  /** The select field default value. */
  defaultValue?: string
  /** Indicates if the field should be disabled. */
  disabled?: boolean
  /** The `family` which one the `type` belongs to. */
  family: string
  /** Result form `useFormContext` hook from react hook form. */
  formContext: UseFormReturn
  /** Any index related to the field (eg: in case the field is part of an array). */
  index?: number
  /** The select field label. */
  label?: string
  /** The select field name. */
  name: string
  /** The callback handler for the select field `key down` event. */
  onKeyDown?: (e: KeyboardEvent<HTMLElement>) => void
  /** The array of options that will compose the select field. */
  options?: TypeSelectOption[]
  /** Indicates if the field is required (mandatory). */
  required?: boolean
}

/**
 * A controlled select field for types.
 * This is a specific components attached to `useAddTypeProperty` hook,
 * that will allow to add custom types for some resources in the app.
 
* **Important:** Since this is a component design for a
 * specific use case, change it with caution.
 */
const TypeSelect = (props: TypeSelectProps) => {
  const {
    defaultValue,
    disabled,
    family,
    formContext,
    index,
    label,
    name,
    onKeyDown,
    options,
    required = false,
  } = props
  const { control, setValue } = formContext || {}

  // States.
  const [customType, setCustomType] = useState('')
  const [isSelectOpen, setIsSelectOpen] = useState(false)

  // Ref.
  const customInputRef = useRef<HTMLInputElement>(null)

  // React Router Dom.
  const params = useParams()
  const { aggregateId = '' } = params

  // Hooks.
  const { mutateAsync, isPending, reset: addTypeReset } = useAddTypeProperty()

  // Methods.
  const handleCloseSelect = () => {
    setIsSelectOpen(false)
    setCustomType('')
    addTypeReset()
  }

  const handleCustomTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCustomType(event.target.value)
  }

  const createCustomType = async (name: string) => {
    // Form data to submit.
    const data = {
      aggregateId,
      autoGenerateAttributes: true,
      family,
      name: customType,
      onlyCreateType: true,
      relationshipType: 'Single',
      typeId: null,
    }

    try {
      const result = await mutateAsync(data)

      // Set the new type as a value for the field.
      if (!!result.data.value.typeId) {
        setValue(name, customType)
        handleCloseSelect()
      }
    } catch (error) {
      console.error('Mutation failed', error)
    }
  }

  const handleCustomTypeKeyDown = (
    event: KeyboardEvent<HTMLDivElement>,
    fieldName: string,
  ) => {
    if (event.key === 'Enter' && customType) {
      event.preventDefault()

      createCustomType(fieldName)
    }
  }

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextField
          data-index={index}
          disabled={disabled || isPending}
          fullWidth
          label={label}
          onKeyDown={onKeyDown}
          required={required}
          select
          {...field}
          onChange={(e) => {
            if (e.target.value !== 'custom') field.onChange(e)
          }}
          SelectProps={{
            open: isSelectOpen,
            onOpen: () => setIsSelectOpen(true),
            onClose: handleCloseSelect,
          }}
          value={field?.value?.replace('[]', '')}
        >
          <MenuItem
            className="p-0"
            disabled={isPending}
            onKeyDown={(e) => e.stopPropagation()}
            value="custom"
          >
            <TextField
              disabled={isPending}
              fullWidth
              inputRef={customInputRef}
              inputProps={{
                className: 'py-2 px-4',
              }}
              InputProps={{
                className: 'bg-transparent',
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment className="m-0 mr-1" position="end">
                    <ActionIconButton
                      Icon={CheckIcon}
                      loading={isPending}
                      onClick={() => createCustomType(field.name)}
                      tooltipTitle="Create type"
                    />
                  </InputAdornment>
                ),
              }}
              onChange={handleCustomTypeChange}
              onClick={(e) => e.stopPropagation()}
              onFocus={() => setIsSelectOpen(true)}
              onKeyDown={(e) => handleCustomTypeKeyDown(e, field.name)}
              placeholder="Add custom type"
              value={customType}
              variant="standard"
            />
          </MenuItem>

          {options?.map(({ depth, id, label, name }: TypeSelectOption) => (
            <MenuItem
              className={
                depth === 0
                  ? 'bg-slate-50 font-bold text-primary opacity-100'
                  : ''
              }
              disabled={depth === 0 || isPending}
              key={id || name}
              style={{
                ...(typeof depth === 'number' && {
                  marginLeft: `${depth * 15}px`,
                }),
              }}
              value={name}
            >
              {label}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  )
}

export default TypeSelect
