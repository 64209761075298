import { Form } from 'react-router-dom'
import { WizardContent } from '~/components/WizardContent'
import { WizardFooterButtons } from '~/components/WizardFooterButtons'
import { WizardTextField } from '~/styled/WizardTextField'

export function Step1Organisation() {
  return (
    <WizardContent intro="Enter the name of your organisation">
      <Form method="post" id="form-welcome-step1">
        <WizardTextField
          label="Organisation Name"
          name="name"
          fullWidth
          autoFocus
        />
        <WizardFooterButtons />
      </Form>
    </WizardContent>
  )
}
