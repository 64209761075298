import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { ERROR_MESSAGES } from '~/constants/api/codeGenerationStrategy/errorMessages'
import type { PayloadDeleteAction } from '~/models/types/api/developer/PayloadDeleteAction'
import type { ErrorResponseType } from '~/models/types/http/ErrorResponseType'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'
import {
  queryKeyDevelopment,
  queryKeyDevelopmentDomainsForCommands,
} from '~/services/Development'
import { getQueryMutationError } from '~/utils/api/codeGenerationStrategy/getQueryMutationError'

/**
 * Hook that implements react query `mutation`
 * to delete a reaction.
 */
export const useDeleteReaction = () => {
  const params = useParams()
  const { platformId = '' } = params

  // React Query.
  const queryClient = useQueryClient()

  // The `useMutation` implementation.
  const {
    error: mutationError,
    isError,
    mutate,
    ...restMutation
  } = useMutation({
    mutationFn: (data: PayloadDeleteAction) => {
      invariant(data.aggregateId, ERROR_MESSAGES.aggregateId)

      const postData = {
        aggregateId: data.aggregateId,
        reactionId: data.actionId,
      }

      const url = DevelopmentAggregateAPI.DeleteReaction
      return serviceDevelopmentAggregate.post(url, postData)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeyDevelopment(platformId),
      })
      await queryClient.invalidateQueries({
        queryKey: queryKeyDevelopmentDomainsForCommands(platformId),
      })
    },
  })

  const error = getQueryMutationError(
    isError,
    mutationError as ErrorResponseType,
  )

  return { ...restMutation, error, isError, mutate }
}
