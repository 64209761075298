import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { produce } from 'immer'
import { ActionFunctionArgs, generatePath, redirect } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { IS_DEV } from '~/config/constants'
import { BusinessRoutesEnum } from '~/models/enums/routes/BusinessRoutesEnum'
import {
  getAttributesFromForm,
  getRaisedDomainEvents,
  parseReactionData,
  redirectToDeveloperHomeOrAggregate,
} from '~/routes/developer/utils'
import {
  queryKeyDevelopment,
  queryKeyDevelopmentDomainsForCommands,
} from '~/services/Development'
import { queryKeyOrganisation } from '~/services/Discovery'
import { FREE_FEATURES_USAGE } from '~/services/Discovery.types'
import { queryKeyProcess } from '~/services/Process'
import { STATEMENT_PARSING_INFO_TYPE } from '~/services/Process.types'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'
import { getUserEmail } from '~/utils/getUserEmail'
import { getUserId } from '~/utils/getUserId'

export const actionDeveloperEditReaction =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ request, params }: ActionFunctionArgs) => {
    const userId = getUserId(pca)
    const email = getUserEmail(pca)

    const { organisationId, platformId, processId, reactionId } = params

    invariant(platformId, 'No platformId found')
    invariant(userId, 'No userId found')

    // Get form data
    const formData = await request.formData()
    const aggregateId = formData.get('aggregateId')
    const aggregateName = formData.get('aggregateName')
    const boundedContext = formData.get('boundedContext')
    const allForm = Object.fromEntries(formData)
    const action = allForm['action.name']
    const typeId = allForm['action.typeId']

    // This will return early in case we are only auto generating attributes for reactions
    // It will also return the data from it available at useActionData()
    const autoGenerateButton = Object.keys(allForm).find((key) =>
      key.includes('AutoGenerateAttributes'),
    )

    if (autoGenerateButton) {
      if (aggregateId && typeof aggregateId === 'string') {
        const isAction = autoGenerateButton?.includes('action')
        const reqUrl = DevelopmentAggregateAPI.GenerateAttributes
        const data = {
          aggregateId: aggregateId?.toString() || '',
          entityType: isAction
            ? STATEMENT_PARSING_INFO_TYPE.Reaction
            : 'DomainEvents',
          entityName:
            (isAction
              ? action?.toString()
              : allForm[
                  autoGenerateButton?.replace(
                    'AutoGenerateAttributes',
                    'name',
                  ) || ''
                ]?.toString()) || '',
        }

        try {
          const res = await serviceDevelopmentAggregate.post(reqUrl, data)
          queryClient.setQueryData(
            queryKeyOrganisation(userId, email),
            (organisation: any) =>
              produce(organisation, (draft: any) => {
                draft.data.subscriptionPlan[
                  `${FREE_FEATURES_USAGE.ATTRIBUTE_AUTO_GENERATION}Remaining`
                ] =
                  organisation.data.subscriptionPlan[
                    `${FREE_FEATURES_USAGE.ATTRIBUTE_AUTO_GENERATION}Remaining`
                  ] - 1 || 0
              }),
          )
          return {
            formName: autoGenerateButton?.replace(
              '.AutoGenerateAttributes',
              '',
            ),
            attributes: res.data?.value?.attributes,
          }
        } catch (error) {
          if (IS_DEV) {
            console.log(error)
          }
          return []
        }
      }
    }

    // end of auto generation of attributes for reactions
    const isCopyButtonPressed = Object.keys(allForm).find((key) =>
      key.includes('.copyButton'),
    )

    if (isCopyButtonPressed) {
      const attributes = getAttributesFromForm({
        formEntries: allForm,
        kind: isCopyButtonPressed.replace('copyButton', ''),
      })
      await navigator.clipboard.writeText(JSON.stringify(attributes))
      return null
    }

    invariant(allForm.subscriptionValues, 'No form data found')

    const parsedData = parseReactionData(allForm.subscriptionValues)

    invariant(
      parsedData && Object.values(parsedData).length,
      'Could not parse reaction data',
    )

    const {
      aggregate: subscriptionAggregate,
      boundedContext: subscriptionBoundedContext,
      subscriptionName,
      subscriptionTypeId,
    } = parsedData

    const actionAttributes = getAttributesFromForm({
      formEntries: allForm,
      kind: 'action.',
    })
    const raisedDomainEvents = getRaisedDomainEvents({
      formEntries: allForm,
    })

    // Builds the data to send to server
    const postData = {
      aggregateId,
      aggregateName,
      boundedContext,
      subscription: {
        aggregate: subscriptionAggregate,
        attributes: actionAttributes,
        boundedContext: subscriptionBoundedContext,
        name: subscriptionName,
        typeId: subscriptionTypeId,
      },
      action: {
        name: action,
        typeId: typeId,
        attributes: actionAttributes,
        domainEvents: raisedDomainEvents,
      },
      reactionId,
    }

    // Setups and send to server
    const url = DevelopmentAggregateAPI.EditReaction
    const req = await serviceDevelopmentAggregate.post(url, postData)

    // Invalidates the cache
    queryClient.invalidateQueries({ queryKey: queryKeyDevelopment(platformId) })
    queryClient.invalidateQueries({
      queryKey: queryKeyDevelopmentDomainsForCommands(platformId),
    })
    await queryClient.invalidateQueries({
      queryKey: queryKeyProcess(processId),
    })

    const requestUrl = new URL(request.url)
    const isBusiness = requestUrl.pathname.includes('business')

    if (isBusiness) {
      return redirect(
        generatePath(BusinessRoutesEnum.BUSINESS_PROCESS, {
          organisationId: organisationId || '',
          platformId: platformId || '',
          processId: processId || '',
        }),
      )
    }

    // redirects
    return redirectToDeveloperHomeOrAggregate(req, params)
  }
