import { Checkbox, FormControlLabel, Stack } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Row } from '../Row'
import TypeSelect, {
  type TypeSelectOption,
} from '../form/TypeSelect/TypeSelect'

export type ResultTypeOptions = TypeSelectOption

type ResultTypeProps = {
  isArray?: boolean
  formName?: string
  handleKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void
  handleRemoveAttribute?: (event: React.MouseEvent<HTMLButtonElement>) => void
  index?: number
  inputLabel?: string
  isDisabled?: boolean
  isRemoveDisabled?: boolean
  options?: ResultTypeOptions[]
}

export function ResultType({
  formName,
  handleKeyDown,
  index,
  inputLabel = 'Return type',
  isDisabled,
  options,
}: ResultTypeProps) {
  const formContext = useFormContext()

  const isArrayControlName = `${formName}.is_array`
  const typeControlName = `${formName}.type`

  return (
    <Stack direction="column">
      <Controller
        name={isArrayControlName}
        shouldUnregister
        render={({ field: { onChange, value, name } }) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={value}
                onChange={(e) => onChange(Boolean(e.target.checked))}
                name={name}
                disabled={isDisabled}
              />
            }
            label="Is Array"
          />
        )}
      />

      <Row>
        <TypeSelect
          disabled={isDisabled}
          family="DTO"
          formContext={formContext}
          index={index}
          label={inputLabel}
          name={typeControlName}
          onKeyDown={handleKeyDown}
          options={options}
          required
        />
      </Row>
    </Stack>
  )
}
