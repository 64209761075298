import { TextField } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  Form,
  Outlet,
  useActionData,
  useLoaderData,
  useNavigation,
  useParams,
} from 'react-router-dom'
import { ButtonAutoGenerate } from '~/components/ButtonAutoGenerate'
import { Column } from '~/components/Column'
import { FormActionName } from '~/pages/developer/components/FormActionName'
import { FormAttributes } from '~/pages/developer/components/FormAttributes'
import { FormFooter } from '~/pages/developer/components/FormFooter'
import { DeveloperChildrenParams } from '~/routes/developer/routes.types'
import { queryDevelopment } from '~/services/Development'
import {
  Attribute,
  CommandDomainEvent,
  DomainByPlatformId,
} from '~/services/Development.types'
import { useAggregateActionForm } from '../hooks/useAggregateActionForm'
import { renamePropertiesToAttributes } from '../utils'

export type AttributesFormValues = {
  aggregate: {
    boundedContext: string | undefined
    name: string | undefined
    attributes: Attribute[]
  }
}

export function DeveloperEditDetails() {
  // React Router Dom.
  const dataAutoGeneratedAttributes = useActionData() as {
    formName: string
    attributes: Attribute[]
  }
  const initialData = useLoaderData() as {
    dataFetched: Awaited<AxiosResponse<DomainByPlatformId | null, unknown>>
  }
  const navigation = useNavigation()
  const params = useParams<DeveloperChildrenParams & { commandId: GUID }>()

  // Queries.
  const { data } = useQuery({
    ...queryDevelopment(params.platformId),
    initialData: initialData.dataFetched,
    select: (response) => response.data,
  })

  // Memoized resources.
  const selectedAggregate = useMemo(() => {
    const boundedContext = data?.boundedContexts.find((context) =>
      context.aggregates.find(
        (aggregate) => aggregate.identity === params.aggregateId,
      ),
    )
    const aggregate = boundedContext?.aggregates.find(
      (aggregate) => aggregate.identity === params.aggregateId,
    )

    if (aggregate) {
      return {
        aggregateId: aggregate?.identity,
        boundedContext: boundedContext?.name,
        name: aggregate?.name,
        attributes: aggregate?.properties,
      }
    }

    return
  }, [data?.boundedContexts, params.aggregateId])

  const shouldDisableAttributes = useMemo(() => {
    if (navigation.formData) {
      const checkIfButtonWasClicked = Object.fromEntries(
        navigation.formData,
      ).commandAutoGenerateAttributes
      if (checkIfButtonWasClicked) {
        return true
      }
    }

    return false
  }, [navigation.formData])

  // React Hook Form.
  const methods = useForm({
    defaultValues: {
      aggregate: {
        boundedContext: selectedAggregate?.boundedContext,
        name: selectedAggregate?.name,
        attributes: renamePropertiesToAttributes(
          (dataAutoGeneratedAttributes?.formName === 'aggregate.' &&
            dataAutoGeneratedAttributes?.attributes) ||
            selectedAggregate?.attributes,
        ) as CommandDomainEvent[] | undefined,
      },
    },
  })

  // Hooks.
  const { formProps, submitButtonRef } = useAggregateActionForm()

  // Lifecycle.
  useEffect(() => {
    if (
      dataAutoGeneratedAttributes &&
      dataAutoGeneratedAttributes?.formName === 'aggregate' &&
      dataAutoGeneratedAttributes?.attributes.length > 0
    ) {
      methods.setValue(
        'aggregate.attributes',
        renamePropertiesToAttributes(
          dataAutoGeneratedAttributes?.attributes,
        ) as CommandDomainEvent[],
      )
    }
  }, [dataAutoGeneratedAttributes, methods])

  return (
    <>
      <Column className="gap-4 pb-12">
        <FormProvider {...methods}>
          <Form method="post" id="form-aggregate-edit-aggregate" {...formProps}>
            <input
              name="aggregateId"
              id="aggregateId"
              type="hidden"
              value={selectedAggregate?.aggregateId}
            />

            <Column className="gap-4">
              <FormActionName
                defaultValue={selectedAggregate?.boundedContext}
                inputLabel="Bounded Context"
                inputName="aggregate.boundedContext"
              >
                <TextField
                  label="Aggregate"
                  variant="outlined"
                  name="aggregate.name"
                  required
                  defaultValue={selectedAggregate?.name}
                  fullWidth
                  sx={{
                    mb: 2,
                  }}
                />

                <FormAttributes
                  aggregateId={selectedAggregate?.aggregateId}
                  autoGenerateComponent={
                    <ButtonAutoGenerate name="aggregate." />
                  }
                  formName="aggregate"
                  isDisabled={shouldDisableAttributes}
                />
              </FormActionName>
            </Column>

            <FormFooter submitButtonRef={submitButtonRef} />
          </Form>
        </FormProvider>
      </Column>

      <Outlet />
    </>
  )
}
