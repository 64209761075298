import TypeIcon from '@mui/icons-material/Category'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import { Handle, Position, type Node, type NodeProps } from '@xyflow/react'
import { useState, type MouseEvent } from 'react'
import { twMerge } from 'tailwind-merge'
import EditIcon from '~/assets/icons/edit.svg?react'
import { ServiceAndEventsActionTypeEnum } from '~/models/enums/developer/serviceMap/ServiceAndEventsActionTypeEnum'
import type { ServiceAndEventsAction } from '~/models/types/developer/serviceMap/ServiceAndEventsType'
import type { NodeStatement } from '~/services/Process.types'
import { AddType } from '../AddType/AddType'
import { NodeToolbar } from '../NodeToolbar/NodeToolbar'
import { useNodeToolbar } from '../NodeToolbar/useNodeToolbar'
import { ServiceActionSection } from '../ServiceActionSection/ServiceActionSection'
import { nodeWidth } from '../config'

export function ServiceNode(
  props: NodeProps<Node<NodeStatement<ServiceAndEventsAction[]>>>,
) {
  const { data, id } = props
  const { isSelected } = data

  // Hooks.
  const [expanded, setExpanded] = useState(true)
  const { handleEditClick } = useNodeToolbar({ data })

  const actionTypes = [
    ServiceAndEventsActionTypeEnum.COMMAND,
    ServiceAndEventsActionTypeEnum.REACTION,
    ServiceAndEventsActionTypeEnum.QUERY,
  ]

  const totalWidth = nodeWidth * 1.5 // Total width including action sections

  const toggleExpanded = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setExpanded(!expanded)
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: `${nodeWidth}px`,
        gap: '20px',
        width: `${totalWidth}px`,
        position: 'relative',
      }}
    >
      {isSelected && (
        <Box
          sx={{
            position: 'absolute',
            top: '-10px',
            left: `${nodeWidth / 2}px`,
            transform: 'translateX(-50%)',
            zIndex: 2,
          }}
        >
          <NodeToolbar data={data} />
        </Box>
      )}

      <Box
        className={twMerge(
          'flex cursor-pointer flex-col rounded-[6px] border-[1px] border-solid border-[#d3d3d3] bg-white',
          isSelected && 'border-primary',
        )}
        sx={{ position: 'relative' }}
      >
        <Box
          borderBottom={'1px solid #d3d3d3'}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="8px"
        >
          <Box display="flex" alignItems="center">
            <Tooltip
              title={!data.isAggregateType ? 'Aggregate' : data.family}
              arrow
              placement="top"
            >
              <TypeIcon
                style={{ width: '20px', marginRight: '8px', color: 'primary' }}
              />
            </Tooltip>

            <Typography fontWeight={'bold'}>{data.context}</Typography>
          </Box>

          <Box>
            <Tooltip
              arrow
              placement="top"
              title={expanded ? 'Collapse' : 'Expand'}
            >
              <IconButton size="small" onClick={toggleExpanded}>
                {expanded ? (
                  <ExpandLessIcon fontSize="small" />
                ) : (
                  <ExpandMoreIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>

            <Tooltip arrow placement="top" title="Edit">
              <IconButton size="small" onClick={handleEditClick}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <Collapse in={expanded}>
          <Grid container>
            <Grid item xs={12} className="p-3">
              {data.content}
            </Grid>
          </Grid>
        </Collapse>

        <Handle
          id={`${id}-target-handle`}
          type="target"
          position={Position.Left}
          style={{ left: '2px', visibility: 'hidden' }}
        />
        <Handle
          id={`${id}-source-handle`}
          type="source"
          position={Position.Right}
          style={{ right: '2px', visibility: 'hidden' }}
        />

        {isSelected && (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              right: `0px`,
              transform: 'translateY(-50%)',
              zIndex: 2,
            }}
          >
            <AddType context={data.context} {...props} />
          </Box>
        )}
      </Box>

      {actionTypes.map((actionType) => (
        <Box
          key={actionType}
          sx={{
            marginLeft: '50%',
            width: '75%',
          }}
        >
          <ServiceActionSection
            actions={data.actions}
            actionType={actionType}
            aggregateId={data.aggregateId}
            typeId={data.isAggregateType ? data.identity : undefined}
            label={actionType}
            tooltipTitle={`Add ${actionType.toLowerCase()}`}
          />
        </Box>
      ))}
    </Box>
  )
}
