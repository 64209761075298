import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { twMerge } from '^/tailwind.config'
import {
  forwardRef,
  useImperativeHandle,
  useState,
  type MouseEvent,
} from 'react'

export type DialogConfirmProps = {
  /** The class name applied to the action button. */
  actionButtonClassName?: string
  /** An icon that will be rendered with the action button. */
  actionButtonIcon?: JSX.Element
  /** The confirm action button text. */
  confirmBtnText?: string
  /** The dialog content text. */
  content?: string
  /**
   * Indicates if there is a loading in progress:
   * This will disabled the buttons.
   */
  isLoading?: boolean
  /** The callback handler for the confirm action button click. */
  onConfirmClick?: () => void
  /** The dialog title text. */
  title?: string
}

export type DialogConfirmHandle = {
  /** Close the dialog. */
  handleCloseDialog: () => void
  /** Open the dialog. */
  handleOpenDialog: () => void
}

/**
 * Dialog (modal) to confirm a certain action.
 */
const DialogConfirm = forwardRef<DialogConfirmHandle, DialogConfirmProps>(
  (props: DialogConfirmProps, ref) => {
    const {
      actionButtonClassName,
      actionButtonIcon,
      confirmBtnText = 'Confirm',
      content = 'Once confirmed, this action cannot be undone.',
      isLoading,
      onConfirmClick,
      title = 'Are you sure?',
    } = props

    const [open, setOpen] = useState<boolean>(false)

    const handleOpenDialog = () => {
      setOpen(true)
    }

    const handleCloseDialog = () => {
      setOpen(false)
    }

    // Use imperative to have access to it from the parent component.
    useImperativeHandle(ref, () => ({
      handleCloseDialog,
      handleOpenDialog,
    }))

    const handleCancelClick = (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      handleCloseDialog()
    }

    // This only calls the confirmation callback,
    // the close of the dialog must be handled separately.
    const handleConfirmClick = (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      onConfirmClick?.()
    }

    return (
      <Dialog
        onClose={() => setOpen(false)}
        open={open}
        PaperProps={{
          className: 'rounded-lg p-1',
        }}
      >
        <DialogTitle>{title}</DialogTitle>

        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            color="secondary"
            disabled={isLoading}
            onClick={handleCancelClick}
            variant="outlined"
          >
            Cancel
          </Button>

          <LoadingButton
            className={twMerge('px-3', actionButtonClassName)}
            loading={isLoading}
            onClick={handleConfirmClick}
            startIcon={!isLoading && actionButtonIcon}
            variant={isLoading ? 'outlined' : 'contained'}
          >
            {confirmBtnText}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    )
  },
)

export default DialogConfirm
