import {
  Button,
  CircularProgress,
  Fade,
  LinearProgress,
  Popper,
} from '@mui/material'
import {
  DataGrid,
  type DataGridProps,
  type GridColDef,
  type GridRowId,
  type GridRowModel,
} from '@mui/x-data-grid'
import type { GridApiCommunity } from '@mui/x-data-grid/internals'
import {
  useMemo,
  useState,
  type MouseEvent,
  type MutableRefObject,
} from 'react'
import { Form } from 'react-router-dom'
import EditIcon from '~/assets/icons/edit.svg?react'
import MagicBatIcon from '~/assets/icons/magic-bat.svg?react'
import { Column } from '~/components/Column'
import { Text } from '~/components/Text'
import { useReplaceTokens } from '~/hooks/api/codeGenerationStrategy/useReplaceTokens'
import type { CodeGenerationStrategyBaseProps } from '~/models/types/components/codeGenerationStrategy/CodeGenerationStrategyBaseProps'
import type { CodeGenerationStrategyEditSubmitIntent } from '~/routes/configuration/code-generation-strategies/edit/action'
import type { ResponseListParametersByGenerationId } from '~/services/GenerationStrategy.types'
import { getDomainDictionaryPayloadData } from '../../../utils/getDomainDictionaryPayloadData'
import {
  dataGridStyles,
  handleCellClassName,
  handleRowClassName,
} from './DomainDictionary.styles'

export type DomainDictionaryProps = Pick<
  CodeGenerationStrategyBaseProps,
  'fileId'
> &
  Pick<DataGridProps, 'onRowSelectionModelChange'> & {
    /** The data domain dictionary data. */
    dataDomainDictionary?: ResponseListParametersByGenerationId[]
    /** The generation strategy ID. */
    generationStrategyId?: GUID
    /** The data grid ref. */
    gridApiRef?: MutableRefObject<GridApiCommunity>
    /** Indicates whether the submit of dictionary is in progress. */
    isSubmittingDictionary?: boolean
    /** Indicates whether the auth map is in progress. */
    isWaitingAutoMapDictionary?: boolean
    /** The callback handler for update parameters map. */
    onUpdateParametersMap: () => void
  }

/**
 * The Domain Dictionary panel content, part of the
 * Code Generation Strategy (AI Blueprint) edition.
 */
export const DomainDictionary = (props: DomainDictionaryProps) => {
  const {
    dataDomainDictionary = [],
    generationStrategyId,
    gridApiRef,
    fileId = '',
    isSubmittingDictionary,
    isWaitingAutoMapDictionary,
    onRowSelectionModelChange,
    onUpdateParametersMap,
  } = props

  // Popper states.
  const [popperAnchorEl, setPopperAnchorEl] = useState<HTMLElement | null>(null)
  const [popperValue, setPopperValue] = useState('')

  // Grid columns data.
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'label',
        headerName: 'Domain',
        flex: 2,
      },
      {
        field: 'token',
        headerName: 'Editable display name',
        flex: 2,
        editable: true,
        renderHeader: (_) => (
          <>
            <span>Display name</span> <EditIcon />
          </>
        ),
      },
    ],
    [],
  )

  // React Query Mutation.
  const { isPending: isReplaceTokensPending, mutate: replaceTokensMutate } =
    useReplaceTokens({
      generationStrategyId,
    })

  // Handler for popper open.
  const handlePopperOpen = (event: MouseEvent<HTMLElement>) => {
    const field = event.currentTarget.dataset.field!
    const id = event.currentTarget.parentElement!.dataset.id!
    const rowsModel = gridApiRef?.current?.getRowModels() || new Map()
    const rows = Array.from(
      rowsModel,
      (entry: [number, ResponseListParametersByGenerationId]) => entry[1],
    )
    const row = rows.find(
      (r: ResponseListParametersByGenerationId) => r.id === id,
    )!

    // Popover only when there is a cell value.
    let value = row[field as 'token']
    if (field === 'label') value = row.key // If the field is label, display the token in the popper.
    if (row.isGrouping && row.label) value = row.label // If the row is a header, display the label.

    if (!value) return

    setPopperValue(value)
    setPopperAnchorEl(event.currentTarget)
  }

  // Handler for popper close.
  const handlePopperClose = () => {
    setPopperAnchorEl(null)
  }

  // Callback handler for replace tokens in current file.
  const handleReplaceTokensForCurrentFile = () => {
    const rowModels = gridApiRef?.current?.getRowModels()
    const mappedRowModels = [
      ...(rowModels?.values() as unknown as Map<GridRowId, GridRowModel>),
    ] as unknown as ResponseListParametersByGenerationId[]

    const domainDictionaryEntries =
      getDomainDictionaryPayloadData(mappedRowModels)

    replaceTokensMutate({ domainDictionaryEntries, fileId })
  }

  // Popper opened state.
  const openPopper = Boolean(popperAnchorEl)

  return (
    <>
      <Column className="mb-2">
        <Text className="font-semibol text-xl font-semibold">
          Domain Dictionary
        </Text>

        <Text className="hidden text-highlight [text-wrap:balance]">
          Select a parameter below to add a binding to the path of the file
          selected
        </Text>

        <Text className="text-highlight [text-wrap:balance]">
          Double click the &ldquo;Display name&rdquo; cell to edit its vale
        </Text>
      </Column>

      <div className="mb-2 flex">
        <Form method="POST">
          <Button
            className="w-fit"
            color="secondary"
            name="intent"
            size="small"
            startIcon={
              isWaitingAutoMapDictionary ? (
                <CircularProgress size={16} />
              ) : (
                <MagicBatIcon />
              )
            }
            type="submit"
            value={
              'auto-map-bindings-for-properties' satisfies CodeGenerationStrategyEditSubmitIntent
            }
            variant="outlined"
          >
            Auto-generate Domain Dictionary
          </Button>
        </Form>
      </div>

      <div className="h-[calc(100svh-161px-180px)] bg-white">
        <DataGrid
          apiRef={gridApiRef}
          columns={columns}
          getRowId={(row) => row.id}
          getRowClassName={handleRowClassName}
          getCellClassName={handleCellClassName}
          hideFooter
          isCellEditable={(params) => !params.row.isGrouping}
          loading={isWaitingAutoMapDictionary}
          onRowSelectionModelChange={onRowSelectionModelChange}
          rows={dataDomainDictionary}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          slotProps={{
            cell: {
              onMouseEnter: handlePopperOpen,
              onMouseLeave: handlePopperClose,
            },
          }}
          sx={dataGridStyles}
        />

        <Popper
          open={openPopper}
          anchorEl={popperAnchorEl}
          placement="bottom-start"
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Text className="rounded-sm bg-gray-800 p-2 text-xs text-white">
                {popperValue}
              </Text>
            </Fade>
          )}
        </Popper>
      </div>

      <div className="mt-2 flex justify-between gap-2">
        <Button
          color="secondary"
          disabled={isSubmittingDictionary}
          onClick={onUpdateParametersMap}
          size="small"
          startIcon={
            isSubmittingDictionary ? <CircularProgress size={16} /> : null
          }
          variant="contained"
        >
          Save
        </Button>

        {!!fileId && (
          <Button
            color="secondary"
            disabled={isReplaceTokensPending}
            onClick={handleReplaceTokensForCurrentFile}
            size="small"
            startIcon={
              isReplaceTokensPending ? <CircularProgress size={16} /> : null
            }
            variant="outlined"
          >
            Replace for current file
          </Button>
        )}
      </div>
    </>
  )
}
