import { BusinessDeveloperRoutesEnum } from './BusinessDeveloperRoutesEnum'
import { MainSubRoutesEnum } from './MainRoutesEnum'

/**
 * Routes related to `developer`:
 * these are the sub routes that
 * together will compose the full path.
 */
export enum DeveloperSubRoutesEnum {
  /** Base developer route. */
  DEVELOPER = ':platformId/developer',
  /** Developer bounded context route. */
  DEVELOPER_BOUNDED_CONTEXT = ':platformId/developer/:boundedContext?',
  /** Developer bounded context with aggregate ID route. */
  DEVELOPER_AGGREGATE = ':platformId/developer/:boundedContext?/:aggregateId',
}

/**
 * Routes related to `developer`.
 * Full route path.
 */
export enum DeveloperRoutesEnum {
  /** Base developer route. */
  DEVELOPER = `/${MainSubRoutesEnum.ORGANISATION}/${DeveloperSubRoutesEnum.DEVELOPER}`,
  /** Developer bounded context route. */
  DEVELOPER_BOUNDED_CONTEXT = `/${MainSubRoutesEnum.ORGANISATION}/${DeveloperSubRoutesEnum.DEVELOPER_BOUNDED_CONTEXT}`,
  /** Developer bounded context with aggregate ID route. */
  DEVELOPER_AGGREGATE = `/${MainSubRoutesEnum.ORGANISATION}/${DeveloperSubRoutesEnum.DEVELOPER_AGGREGATE}`,

  /** Edit command route. */
  EDIT_COMMAND = `/${MainSubRoutesEnum.ORGANISATION}/${DeveloperSubRoutesEnum.DEVELOPER_AGGREGATE}/${BusinessDeveloperRoutesEnum.EDIT_COMMAND}`,
  /** Commits history route from edit command. */
  EDIT_COMMAND_COMMITS_HISTORY = `/${MainSubRoutesEnum.ORGANISATION}/${DeveloperSubRoutesEnum.DEVELOPER_AGGREGATE}/${BusinessDeveloperRoutesEnum.EDIT_COMMAND}/${BusinessDeveloperRoutesEnum.COMMITS_HISTORY}`,

  /** Edit reaction route. */
  EDIT_REACTION = `/${MainSubRoutesEnum.ORGANISATION}/${DeveloperSubRoutesEnum.DEVELOPER_AGGREGATE}/${BusinessDeveloperRoutesEnum.EDIT_REACTION}`,
  /** Commits history route from edit reaction. */
  EDIT_REACTION_COMMITS_HISTORY = `/${MainSubRoutesEnum.ORGANISATION}/${DeveloperSubRoutesEnum.DEVELOPER_AGGREGATE}/${BusinessDeveloperRoutesEnum.EDIT_REACTION}/${BusinessDeveloperRoutesEnum.COMMITS_HISTORY}`,

  /** Read model reaction route. */
  EDIT_READ_MODEL = `/${MainSubRoutesEnum.ORGANISATION}/${DeveloperSubRoutesEnum.DEVELOPER_AGGREGATE}/${BusinessDeveloperRoutesEnum.EDIT_READ_MODEL}`,

  /** Commits history route from edit read model. */
  EDIT_READ_MODEL_COMMITS_HISTORY = `/${MainSubRoutesEnum.ORGANISATION}/${DeveloperSubRoutesEnum.DEVELOPER_AGGREGATE}/${BusinessDeveloperRoutesEnum.EDIT_READ_MODEL}/${BusinessDeveloperRoutesEnum.COMMITS_HISTORY}`,

  EDIT_COMMIT_HISTORY_QUERY = `/${MainSubRoutesEnum.ORGANISATION}/${DeveloperSubRoutesEnum.DEVELOPER_AGGREGATE}/${BusinessDeveloperRoutesEnum.EDIT_QUERY}/${BusinessDeveloperRoutesEnum.COMMITS_HISTORY}`,
  EDIT_QUERY = `/${MainSubRoutesEnum.ORGANISATION}/${DeveloperSubRoutesEnum.DEVELOPER_AGGREGATE}/${BusinessDeveloperRoutesEnum.EDIT_QUERY}`,

  /** Edit aggregate route. */
  EDIT_AGGREGATE = `/${MainSubRoutesEnum.ORGANISATION}/${DeveloperSubRoutesEnum.DEVELOPER_AGGREGATE}/${BusinessDeveloperRoutesEnum.EDIT_AGGREGATE}`,
  /** Commits history route from edit aggregate. */
  EDIT_AGGREATE_COMMITS_HISTORY = `/${MainSubRoutesEnum.ORGANISATION}/${DeveloperSubRoutesEnum.DEVELOPER_AGGREGATE}/${BusinessDeveloperRoutesEnum.EDIT_AGGREGATE}/${BusinessDeveloperRoutesEnum.COMMITS_HISTORY}`,

  /** Edit type route. */
  EDIT_TYPE = `/${MainSubRoutesEnum.ORGANISATION}/${DeveloperSubRoutesEnum.DEVELOPER_AGGREGATE}/${BusinessDeveloperRoutesEnum.EDIT_TYPE}`,
  /** Commits history route from edit type. */
  EDIT_TYPE_COMMITS_HISTORY = `/${MainSubRoutesEnum.ORGANISATION}/${DeveloperSubRoutesEnum.DEVELOPER_AGGREGATE}/${BusinessDeveloperRoutesEnum.EDIT_TYPE}/${BusinessDeveloperRoutesEnum.COMMITS_HISTORY}`,
}
