import {
  CallSplit,
  DeleteForever,
  Restore,
  TimesOneMobiledata,
} from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { twMerge } from '^/tailwind.config'
import { memo, type MouseEvent } from 'react'
import type { ValueBindingTreeItemProps } from '~/models/types/components/codeGenerationStrategy/ValueBindingTreeItemProps'

type ValueBindingToolbarProps = Pick<ValueBindingTreeItemProps, 'node'> & {
  /** Callback handler for the binding context click. */
  onBindingContextClick: (event: MouseEvent<HTMLSpanElement>) => void
  /** Callback handler for the open delete dialog click. */
  onOpenDeleteDialogClick: () => void
  /** Callback handler for the open mapping condition dialog click. */
  onOpenMappingConditionDialogClick: (
    event: MouseEvent<HTMLSpanElement>,
  ) => void
  /** Callback handler for the open revert dialog click. */
  onOpenRevertDialogClick: (hasValueChanged?: boolean | string | null) => void
  /** The text field value. */
  textFieldValue?: string
}

/**
 * Toolbar for the `value binding tree item` component.
 */
export const ValueBindingToolbar = memo((props: ValueBindingToolbarProps) => {
  const {
    node,
    onBindingContextClick,
    onOpenDeleteDialogClick,
    onOpenMappingConditionDialogClick,
    onOpenRevertDialogClick,
    textFieldValue,
  } = props

  if (!node) return null

  const hasValueChanged =
    node?.originalValue && node?.originalValue !== textFieldValue

  return (
    <div className="flex items-center gap-2">
      <span
        className={twMerge(
          'text-weight-light cursor-pointer text-xs',
          'rounded p-1 shadow',
          node.bindingContext
            ? 'border border-green-200 bg-green-50 text-green-700'
            : 'border border-gray-200 bg-gray-50 text-gray-500',
        )}
        onClick={onBindingContextClick}
      >
        <TimesOneMobiledata
          className={twMerge(
            'ml-0.5 mr-1 h-4 w-4 align-text-bottom',
            node.bindingContext ? 'text-green-600' : 'text-gray-400',
          )}
        />
        {node.bindingContext || ''}
      </span>

      <Tooltip
        arrow
        placement="top"
        title={node.mappingCondition || 'No mapping condition'}
      >
        <span
          className={twMerge(
            'text-weight-light cursor-pointer text-xs',
            'rounded p-1 shadow',
            node.mappingCondition
              ? 'border border-blue-200 bg-blue-50 text-blue-700'
              : 'border border-gray-200 bg-gray-50 text-gray-500',
          )}
          onClick={onOpenMappingConditionDialogClick}
        >
          <CallSplit
            className={twMerge(
              'ml-0.5 mr-1 h-4 w-4 rotate-90 align-text-bottom',
              node.mappingCondition ? 'text-blue-600' : 'text-gray-400',
            )}
          />
        </span>
      </Tooltip>

      <Tooltip
        arrow
        placement="top"
        title={
          hasValueChanged ? (
            <pre className="whitespace-pre-wrap rounded p-1 font-mono text-sm">
              {node?.originalValue || 'No original value'}
            </pre>
          ) : null
        }
      >
        <span
          className={twMerge(
            'text-weight-light cursor-pointer text-xs',
            'rounded p-1 shadow',
            hasValueChanged
              ? 'border border-amber-200 bg-amber-50 text-amber-700'
              : 'border border-gray-200 bg-gray-50 text-gray-500',
          )}
          onClick={() => onOpenRevertDialogClick(hasValueChanged)}
        >
          <Restore
            className={twMerge(
              'ml-0.5 mr-1 h-4 w-4 align-text-bottom',
              hasValueChanged ? 'text-amber-600' : 'text-gray-400',
            )}
          />
        </span>
      </Tooltip>

      <span
        className={twMerge(
          'text-weight-light cursor-pointer text-xs',
          'rounded p-1 shadow',
          'border border-gray-200 bg-gray-50 text-gray-500',
        )}
        onClick={onOpenDeleteDialogClick} // Update to open the dialog
      >
        <DeleteForever
          className={twMerge(
            'ml-0.5 mr-1 h-4 w-4 align-text-bottom',
            'text-secondary',
          )}
        />
      </span>
    </div>
  )
})
