import { useMutation, useQueryClient } from '@tanstack/react-query'
import invariant from 'tiny-invariant'
import { ERROR_MESSAGES } from '~/constants/api/codeGenerationStrategy/errorMessages'
import { QueryKeysEnum } from '~/models/enums/api/QueryKeysEnum'
import type { CodeGenerationStrategyApiProps } from '~/models/types/api/codeGenerationStrategy/CodeGenerationStrategyApiProps'
import type { FileType } from '~/models/types/components/files/FileType'
import type { ErrorResponseType } from '~/models/types/http/ErrorResponseType'
import {
  GenerationStrategyAPI,
  serviceGenerationStrategy,
} from '~/services/base'
import { getQueryMutationError } from '~/utils/api/codeGenerationStrategy/getQueryMutationError'

/**
 * Hook that implements react query `mutation`
 * to upload a code file.
 */
export const useUploadCode = (
  props: Pick<CodeGenerationStrategyApiProps, 'generationStrategyId'>,
) => {
  const { generationStrategyId } = props

  const queryClient = useQueryClient()

  const {
    error: mutationError,
    isError,
    ...restMutation
  } = useMutation({
    mutationFn: ({
      files,
      originalPath,
    }: {
      files: FileType[]
      originalPath?: string
    }) => {
      invariant(generationStrategyId, ERROR_MESSAGES.generationStrategyId)

      const url = GenerationStrategyAPI.UploadCode + `/${generationStrategyId}`

      const formData = new FormData()

      files.forEach((file: FileType) => {
        const basePath = originalPath ? `${originalPath}/` : ''
        const relativePath = `${basePath}${file.path || file.name}`
        formData.append('files', file, relativePath)
      })

      return serviceGenerationStrategy.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [
          QueryKeysEnum.GENERATION_STRATEGY_BY_ID,
          generationStrategyId,
        ],
      }),
  })

  const error = getQueryMutationError(
    isError,
    mutationError as ErrorResponseType,
  )

  return { ...restMutation, error, isError }
}
