import { useMsal } from '@azure/msal-react'
import { Button, Stack, Typography } from '@mui/material'
import ArcPilotLogo from '~/assets/icons/arc-pilot-logo.svg?react'
import { loginRequest } from '~/config/auth'
import { StackCentered } from '~/styled/StackCentered'

export function Unauthenticated() {
  const { instance } = useMsal()

  function handleLogin() {
    instance.loginRedirect(loginRequest)
  }

  return (
    <>
      <StackCentered gap={3}>
        <Stack
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
          direction="row"
          gap={2}
        >
          <Typography sx={{ fontSize: '1.5rem' }}>Welcome to</Typography>
          <ArcPilotLogo className="h-[2.2rem] w-auto" />
        </Stack>
        <Typography variant="subhero">
          <Button onClick={handleLogin} variant="contained">
            Please Login
          </Button>
        </Typography>
      </StackCentered>
    </>
  )
}
