import type { CodeGenerationStrategyDialogProps } from '~/models/types/components/codeGenerationStrategy/CodeGenerationStrategyDialogProps'

/**
 * Return valid conditional mappings
 * formatted as previously configured for expression builder.
 * @param conditionalMappings Conditional mappings data.
 */
export const getConditionalMappingsAsPreviouslyConfigured = (
  conditionalMappings?: string[],
) =>
  conditionalMappings
    ?.map((item) => ({
      name: item,
      description: null,
      value: item,
      category: 'expression',
      returnType: 'boolean',
    }))
    .filter(Boolean)

/**
 * Return valid data domain dictionary
 * formatted as parameters for expression builder.
 * @param dataDomainDictionary Dictionary data.
 */
export const getDataDomainDictionaryAsParameters = (
  dataDomainDictionary?: NonNullable<
    CodeGenerationStrategyDialogProps['dataDomainDictionary']
  >,
) =>
  dataDomainDictionary
    ?.map(({ key }) =>
      !!key
        ? {
            name: key,
            description: null,
            value: `{${key}}`,
            category: 'parameter',
            returnType: 'string',
          }
        : null,
    )
    .filter(Boolean)
