/**
 * The tokens from the design system.
 */
export const designSystemTokens = {
  typography: {
    fontFamily: {
      100: '"Visby CF", system-ui, -apple-system, "SF Pro Text", "SF Pro", Roboto, "Segoe UI", sans-serif',
      200: '"Noto Sans", system-ui, -apple-system, "SF Pro Text", "SF Pro", Roboto, "Segoe UI", sans-serif',
      systemMac:
        '"SF Pro Text", system-ui, -apple-system, Roboto, "Segoe UI", sans-serif',
      systemIos:
        '"SF Pro", system-ui, -apple-system, Roboto, "Segoe UI", sans-serif',
      systemAndroid:
        'Roboto, system-ui, -apple-system, "SF Pro Text", "Segoe UI", sans-serif',
      systemWindows:
        '"Segoe UI", system-ui, -apple-system, "SF Pro Text", Roboto, sans-serif',
    },
    fontSize: {
      50: '10px',
      100: {
        default: '12px',
        large: '14px',
        small: '11px',
      },
      200: {
        default: '14px',
        large: '16px',
        small: '13px',
      },
      300: {
        default: '16px',
        large: '18px',
        small: '14px',
      },
      400: {
        default: '18px',
        large: '24px',
        small: '16px',
      },
      500: {
        default: '24px',
        large: '28px',
        small: '20px',
      },
      600: {
        default: '32px',
        large: '36px',
        small: '24px',
      },
      700: {
        default: '40px',
        large: '48px',
        small: '32px',
      },
      800: {
        default: '48px',
        large: '64px',
        small: '40px',
      },
      900: {
        default: '64px',
        large: '180px',
        small: '156px',
      },
      giant: '96px',
    },
    lineHeight: {
      100: {
        default: '12px',
        large: '14px',
        small: '11px',
      },
      200: {
        default: '14px',
        large: '16px',
        small: '13px',
      },
      300: {
        default: '16px',
        large: '18px',
        small: '14px',
      },
      400: {
        default: '18px',
        large: '24px',
        small: '16px',
      },
      500: {
        default: '24px',
        large: '28px',
        small: '20px',
      },
      600: {
        default: '32px',
        large: '36px',
        small: '24px',
      },
      700: {
        default: '40px',
        large: '48px',
        small: '32px',
      },
      800: {
        default: '48px',
        large: '64px',
        small: '40px',
      },
      900: {
        default: '64px',
        large: '180px',
        small: '156px',
      },
      display: '80px',
    },
    letterSpacing: {
      100: '-0.5px',
      200: '-0.25px',
      300: '-0.1px',
      400: '0px',
      500: '0.1px',
      600: '0.15px',
      700: '0.25px',
      800: '0.4px',
      900: '0.5px',
    },
  },
  colors: {
    primary: {
      DEFAULT: '#FF5C3E',
      pure: '#FF5C3E',
      50: '#FFF3F1',
      100: '#FFE4DF',
      200: '#A5260F',
      300: '#4B0F04',
    },
    secondary: {
      DEFAULT: '#122F3A',
      pure: '#122F3A',
      50: '#EFF8FC',
      100: '#D7EEF6',
      200: '#7FC6E1',
      300: '#1D3D49',
    },
    contrast: {
      brand: '#22272A',
      focus: '#FF5C3E',
    },
    highlight: {
      DEFAULT: '#21C1EF',
      pure: '#21C1EF',
      50: '#ECFCFF',
      100: '#CFF6FE',
      200: '#0D6891',
      300: '#154764',
    },
    neutral: {
      low: {
        DEFAULT: '#E4E8E9',
        pure: '#E4E8E9',
        100: '#F4F7F7',
        200: '#CBD4D6',
        300: '#A7B5B9',
      },
      high: {
        DEFAULT: '#383E41',
        pure: '#383E41',
        100: '#7B8E95',
        200: '#475257',
        300: '#22272A',
      },
    },
    feedback: {
      success: {
        DEFAULT: '#01C2A1',
        pure: '#01C2A1',
        50: '#E5FFFB',
        100: '#C2FFF5',
        200: '#168D7A',
        300: '#065',
      },
      info: {
        DEFAULT: '#208AF3',
        pure: '#208AF3',
        50: '#ECF5FE',
        100: '#CFE6FC',
        200: '#0D6891',
        300: '#073D73',
      },
      warning: {
        DEFAULT: '#DC7704',
        pure: '#DC7704',
        50: '#FFF3E6',
        100: '#FEE4C7',
        200: '#A35700',
        300: '#663700',
      },
      error: {
        DEFAULT: '#D92E20',
        pure: '#D92E20',
        50: '#FEEDEB',
        100: '#FED1CD',
        200: '#731606',
        300: '#59170D',
      },
    },
  },
}
