import {
  Box,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  Slide,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { List as ListIcon } from '@phosphor-icons/react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useState } from 'react'
import {
  generatePath,
  useLoaderData,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { ButtonAddProcess } from '~/components/ButtonAddProcess'
import { ConfirmationButton } from '~/components/ConfirmationButton'
import { FEATURE_TOGGLE } from '~/config/featureToggle'
import { useMutateProcess } from '~/hooks/useMutateProcess'
import { BusinessRoutesEnum } from '~/models/enums/routes/BusinessRoutesEnum'
import { BusinessChildrenParams } from '~/routes/business/routes.types'
import { ResponseDiscoveryOrganisation } from '~/services/Discovery.types'
import { queryProcessListByOrganisationId } from '~/services/Process'
import { ResponseGetProcessListByOwnerId } from '~/services/Process.types'

export const SideMenuButton = () => {
  // States.
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  // Hooks.
  const { deleteProcess } = useMutateProcess()

  // React Router Dom.
  const initialData = useLoaderData() as {
    organisation: Awaited<
      AxiosResponse<ResponseDiscoveryOrganisation | null, unknown>
    >
    ownerId: string
    processList: Awaited<AxiosResponse<ResponseGetProcessListByOwnerId>>
  }
  const navigate = useNavigate()
  const params = useParams<BusinessChildrenParams>()
  const { organisationId, platformId, processId } = params

  // Queries.
  const queryClient = useQueryClient()

  const { data: responseProcessList } = useQuery({
    ...queryProcessListByOrganisationId(
      initialData.organisation.data?.identity || initialData.ownerId,
    ),
    initialData: initialData.processList,
  })

  // Methods.
  const toggleDrawer = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setIsDrawerOpen((current) => !current)
  }

  function handleSubMenuSelect(event: React.MouseEvent<HTMLDivElement>) {
    const eventProcessId = event.currentTarget?.dataset?.processId
    if (eventProcessId) {
      return navigate(
        generatePath(BusinessRoutesEnum.BUSINESS_PROCESS, {
          organisationId: organisationId || '',
          platformId: platformId || '',
          processId: eventProcessId || '',
        }),
      )
    }

    return
  }

  const handleConfirmDeletion = (currProcessId: string) => {
    deleteProcess.mutate(
      { processId: currProcessId },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(
            queryProcessListByOrganisationId(
              initialData.organisation.data?.identity ||
                initialData.ownerId ||
                '',
            ),
          )
          if (currProcessId === processId) {
            navigate(
              generatePath(BusinessRoutesEnum.BUSINESS, {
                organisationId: organisationId || '',
                platformId: platformId || '',
              }),
            )
          }
        },
      },
    )
  }

  return (
    <>
      <Tooltip title="Menu">
        <IconButton
          className="data-[is-opened=true]:bg-secondary-200 data-[is-opened=true]:bg-opacity-40"
          color="secondary"
          data-is-opened={isDrawerOpen}
          onClick={toggleDrawer}
        >
          <ListIcon />
        </IconButton>
      </Tooltip>

      {responseProcessList.data.length > 0 ? (
        <Popper
          open={isDrawerOpen}
          anchorEl={anchorEl}
          transition
          disablePortal
          sx={{
            paddingLeft: '15px',
            paddingTop: '24px',
            zIndex: 10,
          }}
        >
          {({ TransitionProps }) => (
            <>
              <Slide {...TransitionProps} direction="right">
                <Paper className="z-[999999] h-3/5 max-h-[calc(100svh-170px)] min-w-[260px] overflow-y-auto bg-white p-4 shadow-2">
                  <Stack gap={2}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                      gap={2}
                    >
                      <Typography
                        sx={{
                          color: '#76779C',
                          fontWeight: 300,
                        }}
                        variant="subtitle2"
                      >
                        Processes
                      </Typography>

                      <ButtonAddProcess />
                    </Box>
                  </Stack>

                  <List component="nav" disablePadding>
                    {responseProcessList.data?.map((process) => (
                      <ListItemButton
                        key={process.name}
                        onClick={handleSubMenuSelect}
                        data-process-id={process.identity}
                        selected={processId === process.identity}
                        sx={{
                          marginTop: 1,
                          border: '1px #ffffff solid',
                          ':hover': {
                            backgroundColor: '#DBFBF6',
                            border: '1px #6DD4C4 solid',
                            borderRadius: '4px',
                          },
                          '&.Mui-selected, &.Mui-selected:hover': {
                            backgroundColor: '#DBFBF6',
                            border: ' 1px #6DD4C4 solid',
                            borderRadius: '4px',
                          },
                        }}
                        className="group"
                      >
                        <ListItemText primary={process.name} />

                        {FEATURE_TOGGLE.BUSINESS.DELETE_PROCESS ? (
                          <ConfirmationButton
                            confirmButtonText="Delete process"
                            itemId={process.identity}
                            modalText={`Once removed, this action cannot be undone.`}
                            onConfirmDeleteClick={handleConfirmDeletion}
                            titleText={
                              <span>
                                Delete &quot;
                                <strong>{process.name}</strong>&quot; process?
                              </span>
                            }
                            tooltipText="Delete process"
                          />
                        ) : null}
                      </ListItemButton>
                    ))}
                  </List>
                </Paper>
              </Slide>
            </>
          )}
        </Popper>
      ) : null}
    </>
  )
}
