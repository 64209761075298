import { GenerateAttributes } from '~/pages/developer/components/ServiceMap/GenerateAttributes/GenerateAttributes'
import { PropertiesList } from '~/pages/developer/components/ServiceMap/PropertiesList/PropertiesList'
import { TypeData } from '../types/TypeData'

/**
 * Creates a reactflow node object for a given service type.
 * @param typeId The unique identifier for the node.
 * @param typeData An array of attributes associated with the type.
 * @param urlType The type from the url query param.
 */
export const createTypeNode = (
  typeId: string,
  typeData: TypeData,
  urlType?: string | null,
) => ({
  id: typeId,
  data: {
    actions: typeData.actions,
    aggregateId: typeData.aggregateId,
    context: typeData.name,
    content: (
      <PropertiesList
        attributes={typeData.attributes}
        contentIfEmpty={
          <GenerateAttributes
            aggregateId={typeData.aggregateId!}
            entityType="Types"
            entityName={typeData.name!}
            typeId={typeData.id}
          />
        }
      />
    ),
    count: typeData?.attributes?.length || 0,
    identity: typeData.identity,
    family: typeData.family,
    isSelected: typeId === urlType,
    isAggregateType: true, // Indicates if it is a aggregate type.
    parentNodeId: `ag-${typeData.aggregateId}`,
  },
  position: { x: 0, y: 0 },
  type: 'typeNode',
})
