import type {
  Aggregate,
  AggregateCommand,
  AggregateQuery,
  AggregateReaction,
  AggregateReadModel,
  AggregateType,
  BoundedContexts,
  CommitHistory,
} from '~/services/Development.types'

type CommandReactionType = {
  /** The current aggregate ID. */
  aggregateId?: string
  /** The current command ID. */
  commandId?: string
  /** The current reaction ID. */
  reactionId?: string
  queryId?: string
  /** The current type ID. */
  typeId?: string
}

const filter = (
  aggregation:
    | AggregateCommand[]
    | AggregateReaction[]
    | AggregateQuery[]
    | AggregateType[],
  id?: string,
) => (id ? aggregation?.find((x) => x?.identity === id) : undefined)

/**
 * Get the expected commit history:
 * From the provided command or reaction.
 * @param data The array of bounded contexts.
 * @param commandReaction The command or reaction ID.
 */
export const getCommitHistory = (
  data: BoundedContexts[],
  { aggregateId, commandId, reactionId, queryId, typeId }: CommandReactionType,
): CommitHistory[] | undefined => {
  for (const context of data) {
    for (const aggregate of context.aggregates || []) {
      if (aggregateId) {
        return getAggregateCommitHistory(aggregate, aggregateId)
      }

      const currItem =
        filter(aggregate.commands, commandId) ??
        filter(aggregate.reactions, reactionId) ??
        filter(aggregate.queries, queryId) ??
        filter(aggregate.types, typeId)

      if (currItem) return currItem.commitHistory
    }
  }

  return undefined
}

const getAggregateCommitHistory = (
  aggregate: Aggregate,
  aggregateId: GUID,
): CommitHistory[] => {
  const allCommitHistories: CommitHistory[] = []
  if (aggregate.identity !== aggregateId) return allCommitHistories

  aggregate.commands?.forEach((cmd: AggregateCommand) => {
    if (cmd.commitHistory) {
      allCommitHistories.push(...cmd.commitHistory)
    }
  })
  aggregate.reactions?.forEach((reaction: AggregateReaction) => {
    if (reaction.commitHistory) {
      allCommitHistories.push(...reaction.commitHistory)
    }
  })
  aggregate.types?.forEach((type: AggregateType) => {
    if (type.commitHistory) {
      allCommitHistories.push(...type.commitHistory)
    }
  })
  aggregate.readModels?.forEach((readModel: AggregateReadModel) => {
    if (readModel.commitHistory) {
      allCommitHistories.push(...readModel.commitHistory)
    }
  })
  aggregate.queries?.forEach((query: AggregateQuery) => {
    if (query.commitHistory) {
      allCommitHistories.push(...query.commitHistory)
    }
  })

  if (allCommitHistories.length > 0) {
    return allCommitHistories.sort(
      (a, b) =>
        new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
    )
  }

  return allCommitHistories
}
