import SearchIcon from '@mui/icons-material/Search'
import { Box, Stack, TextField } from '@mui/material'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Control, Controller, type FieldValues } from 'react-hook-form'
import { MappingConditionFormEnum } from '~/models/enums/forms/MappingConditionFormEnum'
import type { CodeGenerationStrategyDialogProps } from '~/models/types/components/codeGenerationStrategy/CodeGenerationStrategyDialogProps'
import type { ConfigForMenuToReplace } from '~/models/types/components/codeGenerationStrategy/ValueBindingTreeItemProps'
import type { FormHook } from '~/models/types/hooks/FormHook'
import { ExpressionEditor } from '../ExpressionEditor'
import { MenuToReplaceText } from '../MenuToReplaceText/MenuToReplaceText'
import { useMenuReplaceText } from '../ValueBindingTreeItem/useMenuReplaceText'
import { CategoryList } from './CategoryList'
import { ExpressionContent } from './ExpressionContent'
import { useExpressionBuilder } from './useExpressionBuilder'

export type MappingConditionProps = Pick<
  CodeGenerationStrategyDialogProps,
  'dataDomainDictionary' | 'selectedDomainDictionaryItem'
> &
  Pick<FormHook, 'setFormValue' | 'watchForm'> & {
    control: Control<FieldValues>
    small?: boolean
  }

export const MappingCondition = (props: MappingConditionProps) => {
  const {
    control,
    dataDomainDictionary,
    selectedDomainDictionaryItem,
    setFormValue,
    watchForm,
  } = props

  // Constants.
  const fieldMappingName = MappingConditionFormEnum.MAPPING_CONDITION

  // Ref.
  const inputRef = useRef<HTMLTextAreaElement>(null)

  // States.
  // Config options for `menu to replace text`.
  const [configForMenuToReplace, setConfigForMenuToReplace] =
    useState<ConfigForMenuToReplace>(null)

  // Expression builder hook.
  const {
    selectedCategory,
    setSelectedCategory,
    searchQuery,
    setSearchQuery,
    getCategoryItems,
  } = useExpressionBuilder({ dataDomainDictionary })

  // Context menu hook.
  const { handleContextMenu, handleContextMenuClose, handleMenuItemClick } =
    useMenuReplaceText({
      dataDomainDictionary,
      fieldValueName: fieldMappingName,
      fieldRef: inputRef,
      selectedDomainDictionaryItem,
      setConfigForMenuToReplace,
      setFormValue,
      watchForm,
    })

  return (
    <Stack spacing={1} justifyItems={'stretch'}>
      <Controller
        control={control}
        name={fieldMappingName}
        shouldUnregister
        render={({ field }) => {
          const {
            name: fieldName,
            onBlur: fieldOnBlur,
            onChange: fieldOnChange,
            ref: fieldRef,
            value: fieldValue,
            ...fieldRest
          } = field

          const actualValue = useMemo(
            () => String(fieldValue ?? ''),
            [fieldValue],
          )

          const handleChange = useCallback(
            (value: string) => {
              fieldOnChange(value)
            },
            [fieldOnChange],
          )

          return (
            <>
              <ExpressionEditor
                fieldRef={fieldRef}
                name={fieldName}
                onBlur={fieldOnBlur}
                onChange={handleChange}
                onContextMenu={handleContextMenu}
                placeholder="Build a conditional expression"
                ref={inputRef}
                value={actualValue}
                {...fieldRest}
              />

              {/* Expression Builder Panel */}
              <Box
                sx={{
                  bgcolor: 'background.paper',
                  borderRadius: 1,
                  border: 1,
                  borderColor: 'divider',
                  p: 1,
                  display: 'flex',
                  gap: 2,
                }}
              >
                {/* Left Panel - Categories */}
                <CategoryList
                  onCategoryChange={setSelectedCategory}
                  selectedCategory={selectedCategory}
                />

                {/* Right Panel - Expression Values */}
                <div className="flex-1 overflow-x-auto">
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Filter by keyword"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <SearchIcon
                          fontSize="small"
                          sx={{ mr: 1, color: 'text.secondary' }}
                        />
                      ),
                    }}
                    sx={{ mb: 2 }}
                  />

                  <ExpressionContent
                    field={field}
                    inputRef={inputRef}
                    items={getCategoryItems(selectedCategory)}
                  />
                </div>
              </Box>

              <MenuToReplaceText
                dataDomainDictionary={dataDomainDictionary}
                onMenuClose={handleContextMenuClose}
                onMenuItemClick={handleMenuItemClick}
                options={configForMenuToReplace}
              />
            </>
          )
        }}
      />
    </Stack>
  )
}
