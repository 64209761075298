const BRAND_COLORS = [
  '#2ca6a4', // teal
  '#0a2342', // navy
  //'#23c0c0', // cyan
  //'#ffc300', // yellow
  //'#ff6b6b', // coral
  //'#f0f4f8', // light gray
  //'#ff8c42', // orange
  //'#175467', // dark teal
]

/**
 * Provides brand colors based on a index:
 * This allows to set up different colors
 * when iterating over an array of nodes.
 * @param index The current item index.
 */
export const getNodeBrandColor = (index: number): string =>
  BRAND_COLORS[index % BRAND_COLORS.length]!
