import { useMutation, useQueryClient } from '@tanstack/react-query'
import invariant from 'tiny-invariant'
import { ERROR_MESSAGES } from '~/constants/api/codeGenerationStrategy/errorMessages'
import { QueryKeysEnum } from '~/models/enums/api/QueryKeysEnum'
import type { CodeGenerationStrategyApiProps } from '~/models/types/api/codeGenerationStrategy/CodeGenerationStrategyApiProps'
import type { ErrorResponseType } from '~/models/types/http/ErrorResponseType'
import type { PayloadDeletePropertyBinding } from '~/services/GenerationStrategy.types'
import {
  GenerationStrategyAPI,
  serviceGenerationStrategy,
} from '~/services/base'
import { getQueryMutationError } from '~/utils/api/codeGenerationStrategy/getQueryMutationError'

/**
 * Hook that implements react query `mutation`
 * to delete a property.
 */
export const useDeleteProperty = (props: CodeGenerationStrategyApiProps) => {
  const { generationStrategyId, fileId, propertyBindingId } = props

  const queryClient = useQueryClient()

  const {
    error: mutationError,
    isError,
    ...restMutation
  } = useMutation({
    mutationFn: () => {
      invariant(fileId, ERROR_MESSAGES.fileId)
      invariant(generationStrategyId, ERROR_MESSAGES.generationStrategyId)
      invariant(propertyBindingId, ERROR_MESSAGES.propertyBindingId)

      const url = GenerationStrategyAPI.DeletePropertyBinding

      const postData: PayloadDeletePropertyBinding = {
        fileId,
        generationStrategyId,
        propertyBindingId,
      }

      return serviceGenerationStrategy.post(url, postData)
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QueryKeysEnum.GENERATION_STRATEGY_ITEM, fileId],
      }),
  })

  const error = getQueryMutationError(
    isError,
    mutationError as ErrorResponseType,
  )

  return { ...restMutation, error, isError }
}
