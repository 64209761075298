import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { IS_DEV } from '~/config/constants'
import { queryDevelopment } from '~/services/Development'
import { Attribute } from '~/services/Development.types'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'

interface GenerateAttributesRequest {
  aggregateId: string
  entityType: string
  entityName: string
  typeId: string
}

interface GenerateAttributesResponse {
  attributes: Attribute[]
}

interface DevelopmentAggregateAPIResponse {
  value: {
    attributes: Attribute[]
  }
}

const useGenerateAttributes = () => {
  const params = useParams()
  const { platformId = '' } = params

  const queryClient = useQueryClient()

  return useMutation<
    GenerateAttributesResponse, // Success response type
    Error, // Error type
    GenerateAttributesRequest // Variables/params type
  >({
    mutationFn: async ({ aggregateId, entityType, entityName, typeId }) => {
      const reqUrl = DevelopmentAggregateAPI.GenerateAttributes
      const data = {
        aggregateId: aggregateId,
        entityType: entityType,
        entityName: entityName,
        saveAttributesOnType: true,
        existingTypeId: typeId,
      }

      try {
        const res =
          await serviceDevelopmentAggregate.post<DevelopmentAggregateAPIResponse>(
            reqUrl,
            data,
          )

        return {
          attributes: res.data?.value.attributes || [],
        }
      } catch (error) {
        if (IS_DEV) {
          console.error(error)
        }
        throw error instanceof Error
          ? error
          : new Error('An unknown error occurred')
      }
    },
    onError: (error: Error) => {
      if (IS_DEV) {
        console.error('Generate attributes error:', error)
      }
      return []
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryDevelopment(platformId))
    },
  })
}

export type { GenerateAttributesRequest, GenerateAttributesResponse }
export default useGenerateAttributes
