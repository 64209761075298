import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'

export type DeleteNodeDialogProps = {
  /** Indicates whether the modal should be opened. */
  isDeleteModalOpen: boolean
  /** Callback handler for delete statement button click. */
  onDeleteClick: () => void
  /** The statement content. */
  statementContent?: string
  /** Method to toggle the delete modal. */
  toggleDeleteModal: () => void
}

/**
 * The delete node modal (dialog) component.
 */
export const DeleteNodeModal = (props: DeleteNodeDialogProps) => {
  const {
    isDeleteModalOpen,
    onDeleteClick,
    statementContent,
    toggleDeleteModal,
  } = props

  return (
    <Dialog open={isDeleteModalOpen} onClose={toggleDeleteModal}>
      <DialogTitle>Do you want to delete this statement?</DialogTitle>

      <DialogContent>
        <DialogContentText component="div">
          <Typography sx={{ fontWeight: 'bold' }}>
            Statement content:
          </Typography>

          <Typography>{statementContent}</Typography>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={toggleDeleteModal}>
          Cancel
        </Button>

        <Button color="secondary" onClick={onDeleteClick}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
