import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { BusinessDeveloperRoutesEnum } from '~/models/enums/routes/BusinessDeveloperRoutesEnum'

/**
 * Hook to provide resources for `business` and
 * `developer` pages.
 */
export const useBizDevResources = () => {
  // React Router Dom.
  const { pathname } = useLocation()

  const params = useParams()

  const [searchParams] = useSearchParams()

  // Pathnames.
  const isCommandPage = pathname.includes('command')
  const isReactionPage = pathname.includes('reaction')
  const isReadModelPage = pathname.includes('readmodel')
  const isQueryPage = pathname.includes('query')
  const isTypePage = pathname.includes('type')
  const isEditPage = pathname.includes('edit')
  const isAddPage = pathname.includes('add')
  const isDeletePage = pathname.includes('delete')
  const isCommitsHistoryPage = pathname.includes(
    BusinessDeveloperRoutesEnum.COMMITS_HISTORY,
  )
  const isPlatformSetup =
    pathname.includes('platform-setup') || pathname.includes('generate-code')

  // URL params.
  const {
    aggregateId = '',
    boundedContext = '',
    commandId = '',
    organisationId = '',
    platformId = '',
    processId = '',
    reactionId = '',
    readModelId = '',
    queryId = '',
    typeId = '',
  } = params

  // Search params.
  const urlAggregate = searchParams.get('aggregate')
  // Indicates when only a type is in focus, eg: editing only type.
  // This is useful when navigating to type but not to an aggregate.
  const urlType = searchParams.get('type')

  // Constants.
  const eventType = isCommandPage
    ? 'commands'
    : isReactionPage
      ? 'reactions'
      : isQueryPage
        ? 'queries'
        : isTypePage
          ? 'types'
          : 'aggregate'

  const paramType = isCommandPage
    ? 'commandId'
    : isReactionPage
      ? 'reactionId'
      : isQueryPage
        ? 'queryId'
        : isTypePage
          ? 'typeId'
          : 'aggregateId'

  const singularEventType = isCommandPage
    ? 'command'
    : isReactionPage
      ? 'reaction'
      : isQueryPage
        ? 'query'
        : isTypePage
          ? 'type'
          : 'aggregate'

  // Refs.
  const dataId = params[paramType]
  const dataType = paramType.replace('Id', '')

  return {
    // Pathnames.
    isAddPage,
    isCommandPage,
    isCommitsHistoryPage,
    isDeletePage,
    isEditPage,
    isPlatformSetup,
    isReactionPage,
    isReadModelPage,
    isQueryPage,
    isTypePage,
    // URL params.
    aggregateId,
    boundedContext,
    commandId,
    organisationId,
    platformId,
    processId,
    reactionId,
    readModelId,
    queryId,
    typeId,
    // Constants.
    dataId,
    dataType,
    eventType,
    paramType,
    singularEventType,
    urlAggregate,
    urlType,
  }
}
