import { MarkerType } from '@xyflow/react'
import { v4 as uuid } from 'uuid'
import type { ServiceAndEventsAction } from '~/models/types/developer/serviceMap/ServiceAndEventsType'
import { ServiceMapThemePalette } from './utils/reactFlowColors'

const arrowWidth = '2px'

export function createQueryEdge(
  serviceName: string,
  action: ServiceAndEventsAction,
  serviceOrTypeId: string,
  contextMap: Map<string, string>,
  colors: ServiceMapThemePalette,
) {
  const source = serviceOrTypeId

  const targetKey = `${serviceName}.${action.resultType?.type}`
  const target = contextMap.get(targetKey!)

  if (source == null || target == null) {
    return null
  }

  return {
    id: `${action.name}-${uuid()}`,
    source: source,
    sourceHandle: `${source}-${action.id}-source-handle`,
    target: target,
    targetHandle: `${target}-target-handle`,
    style: {
      stroke: colors.typeRelationshipColors.line,
      strokeDasharray: 5,
      strokeWidth: arrowWidth,
    },
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 15,
      height: 15,
      color: colors.typeRelationshipColors.line,
    },
    label: '',
    labelStyle: {
      fontWeight: 'bold',
      fill: colors.typeRelationshipColors.label,
    },
    zIndex: 100,
    type: 'smoothstep',
  }
}
