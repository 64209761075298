import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material'
import type { ControllerRenderProps, FieldValues } from 'react-hook-form'
import { MappingConditionFormEnum } from '~/models/enums/forms/MappingConditionFormEnum'
import { getReturnTypeIcon } from './ExpressionList.utils'
import type { ExpressionItem } from './MappingCondition.constants'

type ExpressionListProps = {
  /** Field data from a controlled field. */
  field: ControllerRenderProps<FieldValues, MappingConditionFormEnum>
  /** The items of the list. */
  items: ExpressionItem[]
  /** The callback handler for the item click. */
  onItemClick: (value: string, field: any) => void
}

/**
 * The expression list from mapping condition.
 */
export const ExpressionList = (props: ExpressionListProps) => {
  const { field, items, onItemClick } = props

  return (
    <List dense disablePadding>
      {items.map((item) => {
        const listItemContent = (
          <ListItemButton onClick={() => onItemClick(item.value, field)}>
            <ListItemText
              primary={
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  {getReturnTypeIcon(item.returnType, {
                    sx: {
                      fontSize: '1rem',
                    },
                  })}

                  <Box
                    sx={{
                      display: 'flex',
                      gap: 3,
                      alignItems: 'center',
                      flex: 1,
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography className="min-w-[200px]" component="span">
                      {item.name}
                    </Typography>

                    {item.name !== item.value && (
                      <Typography
                        component="span"
                        sx={{
                          color: 'text.secondary',
                          fontFamily: 'monospace',
                          fontSize: '0.8rem',
                        }}
                      >
                        {item.value}
                      </Typography>
                    )}
                  </Box>
                </Box>
              }
            />
          </ListItemButton>
        )

        return item.description ? (
          <Tooltip
            arrow
            key={item.name}
            placement="bottom"
            PopperProps={{
              sx: { zIndex: 1800 },
            }}
            title={
              <div style={{ whiteSpace: 'pre-line' }}>{item.description}</div>
            }
          >
            {listItemContent}
          </Tooltip>
        ) : (
          <div key={item.name}>{listItemContent}</div>
        )
      })}
    </List>
  )
}
