import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useSelectedTextSectionsContext } from '~/hooks/contexts/useSelectedTextSectionsContext'
import type { SelectedTextSectionsType } from '~/models/types/components/codeGenerationStrategy/ManageNodeSelection/SelectedTextSectionsType'
import type { RenderTextSectionNodeProps } from '~/models/types/components/codeGenerationStrategy/RenderTextSectionNodeProps'
import type { TextSection } from '~/services/GenerationStrategy.types'
import { getSelectedNodes } from './useManageNodeSelection.utils'

export type UseManageNodeSelectionProps = Pick<
  RenderTextSectionNodeProps,
  'node' | 'parent' | 'siblings'
>

type UseManageNodeSelectionHook = {
  /** Handler for deselect nodes click. */
  handleDeselectNodesClick: () => void
  /** Handler for node double click. */
  handleNodeDoubleClick: (node?: TextSection) => void
  /** Handler for select node click. */
  handleSelectNodeClick: (node?: TextSection) => void
  /** Indicates if the node is selectable (able to selected as a sibling group). */
  isSelectable: boolean
  /** Indicates is the node is selected. */
  isSelected: boolean
  /** The current selected text sections. */
  selectedTextSections: SelectedTextSectionsType | null
}

/**
 * Hook to manage the select / deselect
 * nodes feature.
 */
export const useManageNodeSelection = (
  props: UseManageNodeSelectionProps,
): UseManageNodeSelectionHook => {
  const { node, parent, siblings = [] } = props

  const { id } = node || {}

  // Keeps track of the number of clicks to distinguish single from double click.
  const clicksRef = useRef(0)

  // Stores the timer for the single click timeout.
  const timerRef = useRef<NodeJS.Timeout | null>(null)

  // Selected text sections states.
  const {
    editableNode,
    isMergeMode,
    setIsMergeMode,
    setIsMergeModeActivateByKeyboard,
    selectedTextSections,
    setSelectedTextSections,
  } = useSelectedTextSectionsContext()

  // Key down handler.
  const handleKeydown = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === 'Shift') {
        setIsMergeMode?.(true)
        setIsMergeModeActivateByKeyboard?.(true)
      }

      if (key === 'Escape') setSelectedTextSections?.(null)
    },
    [setIsMergeMode, setSelectedTextSections],
  )

  const handleKeyup = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === 'Shift') {
        setIsMergeMode?.(false)
        setIsMergeModeActivateByKeyboard?.(false)
      }
    },
    [setIsMergeMode],
  )

  // Add event handler to check if `shift` key is `down` and `up`.
  useEffect(() => {
    if (selectedTextSections?.selected?.length) {
      document.addEventListener('keydown', handleKeydown, false)
      document.addEventListener('keyup', handleKeyup, false)
    }

    return () => {
      document.removeEventListener('keydown', handleKeydown, false)
      document.removeEventListener('keyup', handleKeyup, false)
    }
  }, [handleKeydown, handleKeyup, selectedTextSections])

  // Is selectable (able to be selected with siblings).

  const isSelectable = useMemo(
    () => !!selectedTextSections?.siblings?.find((item) => item === id),
    [selectedTextSections?.siblings],
  )

  // Is selected.
  const isSelected = useMemo(
    () => !!selectedTextSections?.selected?.find((item) => item === id),
    [selectedTextSections?.selected],
  )

  // Handles the node selection.
  const handleNodeSelection = (node?: TextSection) => {
    const { id: nodeId } = node || {}

    if (!nodeId || !parent || (isMergeMode && !isSelectable)) return

    // If only one node selected, and it is clicked again, deselect it.
    if (
      JSON.stringify([nodeId]) ===
        JSON.stringify(selectedTextSections?.selected) &&
      !isMergeMode
    )
      return handleDeselectNodesClick()

    // Get selected nodes state.
    const newState = getSelectedNodes(
      node as TextSection,
      parent,
      siblings,
      selectedTextSections,
      isMergeMode,
    )

    // In case the current and future states are equal don't update.
    if (JSON.stringify(selectedTextSections) === JSON.stringify(newState))
      return

    // Mark node as selected.
    setSelectedTextSections?.(newState)
  }

  // Select node click handler.
  const handleSelectNodeClick = (node?: TextSection) => {
    // Clears timer if it already exists.
    if (timerRef.current) clearTimeout(timerRef.current)

    // Increments click count.
    clicksRef.current += 1

    // Sets a new timer to detect if the clicks count remains
    // as 1 after 300ms (indicating a single click).
    const newTimer = setTimeout(() => {
      // If click count is 1, handle node selection.
      if (clicksRef.current === 1) handleNodeSelection(node)

      // Otherwise, resets click count.
      clicksRef.current = 0
    }, 300)

    // Stores the new timer.
    timerRef.current = newTimer
  }

  // Node double click handler.
  const handleNodeDoubleClick = (node?: TextSection) => {
    const nodeId = node?.id
    if (
      editableNode !== nodeId &&
      !selectedTextSections?.selected?.find((selected) => selected === nodeId)
    )
      handleNodeSelection(node)

    // Clears timer if it already exists.
    if (timerRef.current) clearTimeout(timerRef.current)

    // Resets the click count.
    clicksRef.current = 0
  }

  // Deselect nodes click handler.
  const handleDeselectNodesClick = () => {
    setSelectedTextSections?.(null)
  }

  return {
    handleDeselectNodesClick,
    handleNodeDoubleClick,
    handleSelectNodeClick,
    isSelectable,
    isSelected,
    selectedTextSections,
  }
}
