import { Stack } from '@mui/material'
import { useState } from 'react'
import { Form } from 'react-router-dom'
import { ProcessForm } from '~/pages/business/components/ProcessForm'
import { FormFooter } from '~/pages/developer/components/FormFooter'

export function AddProcess() {
  const [currentStep, setCurrentStep] = useState(1)

  return (
    <Form method="post" id="form-domain-add-process" className="h-full">
      <Stack className="h-full">
        <ProcessForm
          currentStep={currentStep}
          descriptionFieldLabel="Add a comprehensive description of the process"
          nameFieldLabel="Process name"
          setCurrentStep={setCurrentStep}
          withCloseBtn
        />
        {currentStep === 2 && <FormFooter showReset={false} />}
      </Stack>
    </Form>
  )
}
