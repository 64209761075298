import { useIsFetching } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useForm, type FieldValues } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useLoadGitRepository } from '~/hooks/api/codeGenerationStrategy/useLoadGitRepository'
import { LoadRepositoryFormEnum } from '~/models/enums/forms/LoadRepositoryFormEnum'
import { FormHook } from '~/models/types/hooks/FormHook'
import { QueryMutationHook } from '~/models/types/hooks/QueryMutationHook'
import { queryKeyStrategy } from '~/services/GenerationStrategy'

type UseLoadRepositoryDataReturn = FormHook &
  QueryMutationHook & {
    /** Handler to close the dialog. */
    handleCloseDialog: () => void
    /** HHandler to oepn the dialog. */
    handleOpenDialog: () => void
    /** If true the dialog will be opened. */
    open: boolean
  }

/**
 * Hook to support the `LoadRepositoryData` component.
 */
export const useLoadRepositoryData = (): UseLoadRepositoryDataReturn => {
  let { generationStrategyId } = useParams()

  const [open, setOpen] = useState<boolean>(false)

  // Form set up.
  const {
    control,
    handleSubmit,
    reset: resetForm,
  } = useForm<FieldValues>({
    defaultValues: {
      [LoadRepositoryFormEnum.AUTHENTICATION_TOKEN]: '',
      [LoadRepositoryFormEnum.REPOSITORY_URL]: '',
    },
  })

  // Form submit mutation.
  const {
    error,
    isPending: isPendingMutation,
    isSuccess,
    mutate,
    reset: resetMutation,
  } = useLoadGitRepository({
    generationStrategyId,
  })

  // Query fetch state.
  const isFetchingStrategy = Boolean(
    useIsFetching({
      queryKey: queryKeyStrategy(generationStrategyId as string),
    }),
  )

  // Reset form state.
  useEffect(() => {
    if (!open) {
      resetForm?.()
      resetMutation?.()
    }
  }, [open])

  // Reset `showForm` state.
  useEffect(() => {
    if (isSuccess) handleCloseDialog()
  }, [isSuccess])

  // Handles the form submit.
  const handleFormSubmit = (data: FieldValues) => {
    mutate(data)
  }

  const handleOpenDialog = () => {
    setOpen(true)
  }

  const handleCloseDialog = () => {
    setOpen(false)
  }

  return {
    control,
    error,
    handleCloseDialog,
    handleFormSubmit,
    handleOpenDialog,
    handleSubmit,
    isPending: isPendingMutation || isFetchingStrategy,
    open,
  }
}
