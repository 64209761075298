import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { ActionFunctionArgs, generatePath, redirect } from 'react-router-dom'
import { DeveloperRoutesEnum } from '~/models/enums/routes/DeveloperRoutesEnum'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'
import { queryKeyDevelopment } from '~/services/Development'
import { getLocalUserId } from '~/utils/getUserId'

export const actionDeveloperPublishQuery =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ request, params, context }: ActionFunctionArgs) => {
    const userId = getLocalUserId(pca)
    const { aggregateId, boundedContext, organisationId, platformId, queryId } =
      params

    const formData = await request.formData()
    const commitMessage = formData.get('commitMessage')
    const branch = formData.get('branch')
    // DTO to send data
    const postData = {
      aggregateId,
      queryId,
      developerId: userId,
      commitMessage,
      branch,
    }

    // Setups and send to server
    const url = DevelopmentAggregateAPI.PublishQuery
    const req = await serviceDevelopmentAggregate.post(url, postData)

    if (req.status === 200) {
      await queryClient.refetchQueries({
        queryKey: queryKeyDevelopment(platformId),
      })
      return redirect(
        generatePath(DeveloperRoutesEnum.DEVELOPER_AGGREGATE, {
          aggregateId: aggregateId || '',
          boundedContext: boundedContext || '',
          organisationId: organisationId || '',
          platformId: platformId || '',
        }),
      )
    }

    throw req
  }
