import { Button, Stack } from '@mui/material'
import { Plus } from '@phosphor-icons/react'
import { Link, useParams } from 'react-router-dom'
import { BusinessChildrenParams } from '~/routes/business/routes.types'

export function ButtonAddProcess() {
  const params = useParams<BusinessChildrenParams>()

  const path = params.processId
    ? `/${params.organisationId}/${params.platformId}/business/${params.processId}/add-process`
    : `/${params.organisationId}/${params.platformId}/business/add-process`

  return (
    <Stack
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Button
        color="secondary"
        component={Link}
        fullWidth
        size="small"
        startIcon={<Plus size={15} />}
        to={path}
        variant="outlined"
      >
        Add
      </Button>
    </Stack>
  )
}
