import CloudIcon from '@mui/icons-material/Cloud'
import FolderIcon from '@mui/icons-material/Folder'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import type { FolderOrFileStructure } from '~/services/GenerationStrategy.types'

export type TreeItemLabelIconProps = { node: FolderOrFileStructure }

/**
 * The icon to be displayed in the
 * tree item label.
 */
export const TreeItemLabelIcon = (props: TreeItemLabelIconProps) => {
  const { node } = props
  const { children, name } = node || {}

  const iconProps = {
    className: 'text-base',
  }

  if (name === 'root') return <CloudIcon {...iconProps} />

  if (!!children?.length) return <FolderIcon {...iconProps} />

  return <InsertDriveFileOutlinedIcon {...iconProps} />
}
