/**
 * Routes related to `business` a `developer` routes:
 * these are the sub routes that
 * together will compose the full path.
 */
export enum BusinessDeveloperRoutesEnum {
  /** Commits history route. */
  COMMITS_HISTORY = 'commits-history',
  /** Edit aggregate route. */
  EDIT_AGGREGATE = 'edit-details',
  /** Edit command route. */
  EDIT_COMMAND = 'edit-command/:commandId',
  /** Edit reaction route. */
  EDIT_REACTION = 'edit-reaction/:reactionId',
  /** Edit read model route. */
  EDIT_READ_MODEL = 'edit-readmodel/:readModelId',

  ADD_QUERY = 'add-query',
  EDIT_QUERY = 'edit-query/:queryId',
  DELETE_QUERY = 'delete-query/:queryId',
  PUBLISH_QUERY = 'publish-query/:queryId',
  /** Edit type route. */
  EDIT_TYPE = 'edit-type/:typeId',
}
