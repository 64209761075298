const GradientLoader = () => {
  return (
    <div
      className="relative min-h-6 min-w-6"
      role="progressbar"
      aria-label="Loading"
    >
      {/* Spinning outer gradient */}
      <div className="absolute inset-0 animate-[spin_3s_linear_infinite] overflow-hidden rounded-full">
        <div
          className="absolute inset-0 animate-[gradient_2s_linear_infinite]"
          style={{
            background: `linear-gradient(90deg, 
                            #2ca6a4 0%,
                            #23c0c0 12.5%,
                            #0a2342 25%,
                            #ffc300 37.5%,
                            #ff6b6b 50%,
                            #ff8c42 62.5%,
                            #175467 75%,
                            #2ca6a4 87.5%,
                            #23c0c0 100%
                        )`,
            backgroundSize: '200% 100%',
          }}
        />
      </div>

      {/* Counter-rotating inner gradient */}
      <div className="absolute inset-0 animate-[spin_4s_linear_infinite_reverse] overflow-hidden rounded-full opacity-70">
        <div
          className="absolute inset-0 animate-[gradient_3s_linear_infinite_reverse]"
          style={{
            background: `linear-gradient(90deg, 
                            #ff6b6b 0%,
                            #ff8c42 20%,
                            #ffc300 40%,
                            #23c0c0 60%,
                            #2ca6a4 80%,
                            #ff6b6b 100%
                        )`,
            backgroundSize: '200% 100%',
          }}
        />
      </div>

      {/* Pulsing overlay */}
      <div className="absolute inset-0 animate-[pulse_2s_ease-in-out_infinite] rounded-full bg-gradient-to-r from-transparent via-white to-transparent opacity-30" />

      <style>{`
                @keyframes gradient {
                    0% { background-position: 100% 0; }
                    100% { background-position: -100% 0; }
                }
                @keyframes pulse {
                    0%, 100% { opacity: 0.3; }
                    50% { opacity: 0; }
                }
            `}</style>
    </div>
  )
}

export default GradientLoader
