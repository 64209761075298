import { Typography } from '@mui/material'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  Form,
  useActionData,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { ButtonAutoGenerate } from '~/components/ButtonAutoGenerate'
import { Column } from '~/components/Column'
import { ResultType } from '~/components/ResultType'
import { useGetDomainReadModelTypes } from '~/hooks/api/business/useGetDomainReadModelTypes'
import { FormActionName } from '~/pages/developer/components/FormActionName/index'
import { FormAttributes } from '~/pages/developer/components/FormAttributes'
import { getAggregateTypesOptions } from '~/pages/developer/components/FormAttributes/FormAttributes.utils'
import { FormFooter } from '~/pages/developer/components/FormFooter'
import { Attribute } from '~/services/Development.types'
import { useAggregateActionForm } from '../hooks/useAggregateActionForm'
import { renamePropertiesToAttributes } from '../utils'

export function DeveloperAddQuery() {
  const [searchParams] = useSearchParams()
  const { aggregateId } = useParams()

  const dataAutoGeneratedAttributes = useActionData() as {
    formName: string
    attributes: Attribute[]
  }

  const methods = useForm({
    defaultValues: {
      action: {
        name: '',
        typeId: '',
        attributes: [{ name: '', type: 'string', atributes: [] }],
        query: {
          resultType: {
            is_array: false,
            type: 'string',
          },
        },
      },
    },
  })

  const { data: aggregateTypes } = useGetDomainReadModelTypes({
    aggregateId: aggregateId,
  })

  const options = getAggregateTypesOptions(aggregateTypes)

  const { formProps, submitButtonRef } = useAggregateActionForm()

  useEffect(() => {
    if (
      dataAutoGeneratedAttributes &&
      dataAutoGeneratedAttributes?.formName === 'query' &&
      dataAutoGeneratedAttributes?.attributes.length > 0
    ) {
      methods.setValue(
        'action.attributes',
        renamePropertiesToAttributes(dataAutoGeneratedAttributes?.attributes),
      )
    }
  }, [dataAutoGeneratedAttributes, methods])

  return (
    <>
      <Column className="gap-4 pb-12">
        <FormProvider {...methods}>
          <Form method="post" id="form-aggregate-add-query" {...formProps}>
            <Column className="gap-4">
              <FormActionName inputLabel="Name" inputName="query.name">
                <input
                  type="hidden"
                  value={searchParams.get('context') || ''}
                  {...methods.register('action.typeId')}
                />

                <FormAttributes
                  autoGenerateComponent={<ButtonAutoGenerate name="query." />}
                  inputLabel="Parameters"
                  formLabel="Parameters"
                  formName="action"
                />

                <Column className="pt-4">
                  <Typography>Return type</Typography>

                  <ResultType
                    options={options}
                    formName="action.query.resultType"
                  />
                </Column>
              </FormActionName>
            </Column>

            <FormFooter submitButtonRef={submitButtonRef} />
          </Form>
        </FormProvider>
      </Column>
    </>
  )
}
