import { PropsWithChildren } from 'react'
import { useDropzone } from 'react-dropzone'
import { useParams } from 'react-router-dom'
import { useUploadFilesSnackbar } from '~/components/snackbars/UploadFilesSnackbar/useUploadFilesSnackbar'
import { useUploadCode } from '~/hooks/api/codeGenerationStrategy/useUploadCode'
import { filesValidator } from '~/utils/uploadFiles/filesValidator'

type UploadFilesButtonProps = PropsWithChildren & {
  /** The class name applied to the containing node. */
  className?: string
}

/**
 * Upload files button.
 * Despite the name, the button needs to be provided as `children`.
 */
export const UploadFilesButton = (props: UploadFilesButtonProps) => {
  const { children, className } = props

  // React Router Dom.
  const params = useParams()
  const { generationStrategyId } = params

  // React Query.
  const { isPending, isSuccess, mutate, reset } = useUploadCode({
    generationStrategyId,
  })

  // Snackbar.
  useUploadFilesSnackbar({
    isPending,
    isSuccess,
    reset,
  })

  // React Dropzone.
  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: (files) => mutate({ files }),
    validator: filesValidator,
  })

  return (
    <div className={className} {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </div>
  )
}
