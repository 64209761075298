import CompressIcon from '@mui/icons-material/Compress'
import { twMerge } from '^/tailwind.config'
import { PanelResizeHandle } from 'react-resizable-panels'
import { useMuiDialogBackdropStatus } from '~/hooks/ui/useMuiDialogBackdropStatus'

type ResizeHandleProps = {
  className?: string
  id?: string
}

export default function ResizeHandle(props: ResizeHandleProps) {
  const { className = '', id } = props

  const { isBackdropOpen } = useMuiDialogBackdropStatus()

  return (
    <PanelResizeHandle
      className={twMerge(
        'relative shrink-0 grow-0 basis-2 bg-transparent outline-none hover:bg-slate-300 data-[resize-handle-active]:bg-slate-600',
        className,
      )}
      disabled={isBackdropOpen}
      id={id}
    >
      <div className={twMerge('absolute inset-1 rounded border transition')}>
        <CompressIcon className="invisible absolute left-[calc(50%-0.25rem)] top-[calc(50%-0.25rem)] h-2 w-2 rotate-90 text-slate-400" />
      </div>
    </PanelResizeHandle>
  )
}
