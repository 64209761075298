import { Box, List, ListItemButton, ListItemText } from '@mui/material'

const CATEGORIES = [
  { id: 'all', label: 'All' },
  { id: 'suggestions', label: 'Common Expressions' },
  { id: 'previouslyConfigured', label: 'Previously Configured' },
  { id: 'operators', label: 'Operators' },
  { id: 'functions', label: 'Functions' },
  { id: 'parameters', label: 'Parameters' },
] as const

type CategoryListProps = {
  /** The selected category. */
  selectedCategory: string
  /** The callback handler for category change. */
  onCategoryChange: (category: string) => void
}

/**
 * The category list.
 */
export const CategoryList = ({
  selectedCategory,
  onCategoryChange,
}: CategoryListProps) => (
  <Box sx={{ width: '200px', borderRight: 1, borderColor: 'divider' }}>
    <List dense disablePadding>
      {CATEGORIES.map(({ id, label }) => (
        <ListItemButton
          key={id}
          selected={selectedCategory === id}
          onClick={() => onCategoryChange(id)}
        >
          <ListItemText primary={label} />
        </ListItemButton>
      ))}
    </List>
  </Box>
)
