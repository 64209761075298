import { Box, Typography } from '@mui/material'
import { Handle, Position, type Node, type NodeProps } from '@xyflow/react'
import type { NodeStatement } from '~/services/Process.types'
import { nodeHeight, nodeWidth } from '../config'

export function EventNode(props: NodeProps<Node<NodeStatement>>) {
  const { data, id } = props

  return (
    <Box
      width={nodeWidth}
      height={nodeHeight / 1.5} // making graph more compact
      bgcolor={'#020890'}
      display={'flex'}
      flexDirection={'column'}
      border="1px solid #000467"
      borderRadius={'6px'}
    >
      <Typography
        display={'flex'}
        flex={1}
        alignItems={'center'}
        justifyContent={'center'}
        textAlign={'center'}
        style={{ wordWrap: 'break-word' }}
        color={'white'}
        fontWeight={'bold'}
      >
        {data.label}
      </Typography>

      <Handle
        id={`${id}-target-handle`}
        type="target"
        position={Position.Left}
        style={{
          left: '-2px',
          visibility: 'hidden',
        }}
        isConnectable={false}
      />

      <Handle
        id={`${id}-source-handle`}
        type="source"
        position={Position.Right}
        style={{
          right: '-2px',
          visibility: 'hidden',
        }}
      />
    </Box>
  )
}
