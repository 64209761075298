import { InteractionStatus } from '@azure/msal-browser'
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react'
import MenuIcon from '@mui/icons-material/Menu'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Stack,
  Theme,
  Toolbar,
  useMediaQuery,
} from '@mui/material'
import { Gear, SketchLogo } from '@phosphor-icons/react'
import { useState } from 'react'
import {
  Link,
  generatePath,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import ArcPilotLogoDark from '~/assets/icons/arc-pilot-logo-dark.svg?react'
import ArcPilotLogo from '~/assets/icons/arc-pilot-logo.svg?react'
import { UserMenu } from '~/components/UserMenu'
import { DialogUpgradePlan } from '~/components/dialogs/DialogUpgradePlan'
import { constants } from '~/config'
import { loginRequest } from '~/config/auth'
import { FEATURE_TOGGLE } from '~/config/featureToggle'
import { useGetUserEmail } from '~/hooks/useGetAccount'
import { BusinessRoutesEnum } from '~/models/enums/routes/BusinessRoutesEnum'
import { DeveloperChildrenParams } from '~/routes/developer/routes.types'
import { useGlobalStore } from '~/store'

export function Header() {
  const { instance, inProgress } = useMsal()

  // User email.
  const { email = '' } = useGetUserEmail()

  // Global store states.
  const colorMode = useGlobalStore((state) => state.colorMode)
  const isMenuOpen = useGlobalStore((state) => state.isMenuOpen)
  const processId = useGlobalStore((state) => state.processId)
  const toggleIsMenuOpen = useGlobalStore((state) => state.toggleIsMenuOpen)

  // States.
  const [isUpgradePlanDialogOpen, setIsUpgradePlanDialogOpen] = useState(false)

  // Media query.
  const styleMatches = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('md'),
  )
  const isSmallDevice = useMediaQuery('only screen and (max-width : 767px)')

  // React Router Dom.
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams<DeveloperChildrenParams>()
  const { organisationId, platformId } = params

  const { pathname } = location
  const isBusinessPage = pathname.includes('business')

  // Local constants.
  const currentPlan = 'Free' // Process | Free | Full
  const buttonText = isSmallDevice ? 'Upgrade' : 'Upgrade Plan'

  // Methods.
  async function handleLogin() {
    if (inProgress === InteractionStatus.None) {
      await instance.loginRedirect({
        redirectUri: constants.msal.redirectUri,
        ...loginRequest,
      })
    }
  }

  async function handleLogout() {
    if (inProgress === InteractionStatus.None) {
      // Logout and redirect to login page.
      await instance.logoutRedirect()
    }
  }

  function handleToggleDialog() {
    setIsUpgradePlanDialogOpen((toggle) => !toggle)
  }

  // Constants.
  const businessProcessPath = generatePath(
    BusinessRoutesEnum.BUSINESS_PROCESS,
    {
      organisationId: organisationId || '',
      platformId: platformId || '',
      processId: processId || '',
    },
  )

  const isBusinessProcessPath = matchPath(
    BusinessRoutesEnum.BUSINESS_PROCESS,
    pathname,
  )

  return (
    <Box
      sx={{
        '.MuiAppBar-root': {
          boxShadow:
            colorMode === 'dark'
              ? 0
              : '0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 3%), 0px 1px 10px 0px rgb(0 0 0 / 3%)',
          borderBottom: colorMode === 'dark' ? 0 : '1px solid #8e8e8e3b',
          bgcolor: 'background.paper',
        },
      }}
    >
      <AppBar
        position="static"
        color="transparent"
        enableColorOnDark={true}
        className="h-64s"
      >
        <Toolbar>
          {!styleMatches && !isBusinessPage ? (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleIsMenuOpen}
            >
              {isMenuOpen ? <MenuOpenIcon /> : <MenuIcon />}
            </IconButton>
          ) : null}

          <Stack
            sx={{ flexGrow: 1, alignItems: 'center' }}
            direction="row"
            gap={2}
          >
            {colorMode === 'dark' ? (
              <ArcPilotLogoDark className="h-[2.2em] w-auto" />
            ) : (
              <ArcPilotLogo className="h-[2.2em] w-auto" />
            )}
          </Stack>

          <AuthenticatedTemplate>
            <Box className="text-right md:hidden" mr={2}>
              <Button
                color="secondary"
                component={Link}
                data-view-id="businessPage"
                disableElevation
                sx={{
                  width: 150,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
                to={businessProcessPath}
                variant={isBusinessProcessPath ? 'contained' : 'outlined'}
              >
                SmartMap™
              </Button>

              <Button
                color="secondary"
                component={Link}
                data-view-id="developmentPage"
                disableElevation
                sx={{
                  width: 150,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
                to={`/${organisationId}/${platformId}/developer`}
                variant={
                  pathname.includes(
                    `/${organisationId}/${platformId}/developer`,
                  )
                    ? 'contained'
                    : 'outlined'
                }
              >
                ArcMap™
              </Button>
            </Box>

            {FEATURE_TOGGLE.UPGRADE_PLAN ? (
              <div className="pr-2">
                <Button
                  className="bg-secondary-100"
                  color="secondary"
                  onClick={handleToggleDialog}
                  startIcon={<SketchLogo />}
                  variant="outlined"
                >
                  {buttonText}
                </Button>

                <DialogUpgradePlan
                  open={isUpgradePlanDialogOpen}
                  onClose={handleToggleDialog}
                  currentPlan="Free"
                />
              </div>
            ) : null}

            {!FEATURE_TOGGLE.UPGRADE_PLAN ? (
              <Button color="inherit" onClick={handleLogout}>
                Logout
              </Button>
            ) : null}

            {FEATURE_TOGGLE.UPGRADE_PLAN ? (
              <UserMenu currentPlan={currentPlan} user={email} />
            ) : null}

            {FEATURE_TOGGLE.MULTIPLE_ORGS ? (
              <>
                {platformId ? (
                  <IconButton
                    className="ml-2"
                    color="secondary"
                    component={Link}
                    to={`/${organisationId}/${platformId}/configuration/profile`}
                  >
                    <Gear size={27} weight="fill" />
                  </IconButton>
                ) : (
                  <IconButton
                    className="ml-2"
                    color="secondary"
                    component={Link}
                    to={`/${organisationId}/configuration/profile`}
                  >
                    <Gear size={27} weight="fill" />
                  </IconButton>
                )}
              </>
            ) : null}
          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
            <Button color="inherit" onClick={handleLogin}>
              Login
            </Button>
          </UnauthenticatedTemplate>
        </Toolbar>

        <Box className="hidden w-full md:inline-flex" mr={2}>
          <Button
            className="w-full"
            color="secondary"
            data-view-id="businessPage"
            onClick={() => navigate(businessProcessPath)}
            disableElevation
            variant={isBusinessProcessPath ? 'contained' : 'outlined'}
            sx={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
          >
            SmartMap™
          </Button>

          <Button
            className="w-full"
            color="secondary"
            data-view-id="developmentPage"
            onClick={() =>
              navigate(`/${organisationId}/${platformId}/developer`)
            }
            disableElevation
            variant={
              pathname.includes(`/${organisationId}/${platformId}/developer`)
                ? 'contained'
                : 'outlined'
            }
            sx={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          >
            ArcMap™
          </Button>
        </Box>
      </AppBar>
    </Box>
  )
}
