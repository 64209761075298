import { useMemo } from 'react'

function createWatermarkDataUrl(text: string, color: string) {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')

  if (!context) return ''

  // Set fixed canvas size and font size
  const canvasSize = 300 // Fixed size for all watermarks
  const fontSize = 24 // Fixed font size for all watermarks
  const fontFamily = 'Arial'

  canvas.width = canvasSize
  canvas.height = canvasSize

  // Clear canvas
  context.clearRect(0, 0, canvasSize, canvasSize)

  // Set context properties
  context.font = `bold ${fontSize}px ${fontFamily}`
  context.fillStyle = color
  context.globalAlpha = 0.05
  context.textAlign = 'center'
  context.textBaseline = 'middle'

  // Apply diagonal rotation
  context.translate(canvasSize / 2, canvasSize / 2)
  context.rotate(-Math.PI / 6) // Rotate by -30 degrees

  // Draw text horizontally
  context.fillText(text, 0, 0, canvasSize * 0.8) // Limit text width to 80% of canvas

  return canvas.toDataURL()
}

const GroupNode = ({ data }: any) => {
  const { color, context, contextName } = data

  const contextLabel = contextName || context

  const watermarkUrl = useMemo(() => {
    return createWatermarkDataUrl(contextLabel, color)
  }, [color, contextLabel])

  return (
    <>
      <span
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          paddingLeft: '15px',
          fontSize: '38px',
          fontWeight: 'bold',
        }}
      >
        {contextLabel}
      </span>

      <div
        style={{
          padding: '10px',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          overflow: 'hidden',
          background: `url(${watermarkUrl}) repeat`,
          backgroundSize: '300px 300px', // Match the canvas size
        }}
      >
        {/* Your existing group node content */}
      </div>
    </>
  )
}

export default GroupNode
