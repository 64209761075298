import {
  ArrowSquareDown,
  ArrowSquareLeft,
  ArrowSquareRight,
  ArrowSquareUp,
  KeyReturn,
  List,
  RocketLaunch,
  Star,
} from '@phosphor-icons/react'
import { Column } from '~/components/Column'
import { Row } from '~/components/Row'
import { Text } from '~/components/Text'

const iconProps = {
  className: 'text-secondary',
  size: 25,
}

const iconsArrowProps = {
  ...iconProps,
  className: '-m-[2px]',
  size: 15,
}

export function ExplanationDialogContent() {
  return (
    <>
      <Text className="pb-6 text-xl">How to use dot-star</Text>

      <Column className="gap-4">
        <ExplanationStep
          icon={
            <div className="grid grid-cols-3">
              <div />
              <ArrowSquareUp {...iconsArrowProps} />
              <div />
              <ArrowSquareLeft {...iconsArrowProps} />
              <ArrowSquareDown {...iconsArrowProps} />
              <ArrowSquareRight {...iconsArrowProps} />
            </div>
          }
          description="Use the arrow keys to navigate through the steps in the process"
        />

        <ExplanationStep
          icon={<KeyReturn {...iconProps} />}
          description="To add or modify an existing step, simply describe the step using natural language. Add, remove or modify steps as required"
        />

        <ExplanationStep
          icon={<Star {...iconProps} />}
          description="When you are happy with the description of the step, mark it as a 'feature'. This step will be transformed in to a service, where code can be generated"
        />

        <ExplanationStep
          icon={<List {...iconProps} />}
          description="Adjust the feature's input and output properties to meet your requirements"
        />

        <ExplanationStep
          icon={<RocketLaunch {...iconProps} />}
          description="Connect to your source code repository and automatically generate service code"
        />
      </Column>
    </>
  )
}

type ExplanationStepProps = {
  step?: string
  icon?: React.ReactNode
  description?: string
}

export function ExplanationStep({ icon, description }: ExplanationStepProps) {
  return (
    <Row className="gap-3">
      <div className="mt-1 min-w-[34px]">{icon}</div>

      <Text className="text-sm">{description}</Text>
    </Row>
  )
}
