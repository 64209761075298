import { useEffect, useState } from 'react'

/**
 * Checks if there is a MUI Dialog backdrop opened or not.
 */
export const useMuiDialogBackdropStatus = () => {
  const [isBackdropOpen, setIsBackdropOpen] = useState<boolean>(false)

  useEffect(() => {
    // Check for MUI backdrop.
    const checkBackdrop = () => {
      const dialogBackdrop = document.querySelector(
        '.MuiBackdrop-root[aria-hidden="true"][class*="MuiDialog-backdrop"]',
      )
      setIsBackdropOpen(!!dialogBackdrop)
    }

    // Initial check.
    checkBackdrop()

    // Set up mutation observer to watch for backdrop changes.
    const observer = new MutationObserver(checkBackdrop)
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    })

    return () => observer.disconnect()
  }, [])

  return { isBackdropOpen }
}
