import { Typography } from '@mui/material'
import { useDropzone } from 'react-dropzone'
import { useParams } from 'react-router-dom'
import { useUploadFilesSnackbar } from '~/components/snackbars/UploadFilesSnackbar/useUploadFilesSnackbar'
import { Text } from '~/components/Text'
import { useUploadCode } from '~/hooks/api/codeGenerationStrategy/useUploadCode'
import type { FileRejectionType } from '~/models/types/components/files/FileRejectionType'
import type { FileType } from '~/models/types/components/files/FileType'
import { filesValidator } from '~/utils/uploadFiles/filesValidator'

/**
 * Upload files component.
 */
export const UploadFiles = () => {
  // React Router Dom.
  const params = useParams()
  const { generationStrategyId } = params

  // React Query.
  const { isPending, isSuccess, mutate, reset } = useUploadCode({
    generationStrategyId,
  })

  // Snackbar.
  useUploadFilesSnackbar({
    isPending,
    isSuccess,
    reset,
  })

  // React Dropzone.
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      onDropAccepted: (files) => mutate({ files }),
      validator: filesValidator,
    })

  const acceptedFileItems = acceptedFiles.map((file: FileType) => (
    <li className="text-xs" key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  const fileRejectionItems = fileRejections.map(
    ({ file, errors }: FileRejectionType) => (
      <li className="text-xs" key={file.path}>
        {file.path} - {file.size} bytes
        <ul className="my-1">
          {errors.map((e) => (
            <li key={e.code}>{e.message}</li>
          ))}
        </ul>
      </li>
    ),
  )

  return (
    <section className="container">
      <div
        {...getRootProps({
          className: 'border-dashed border-2 border-sky-500 p-3',
        })}
      >
        <input {...getInputProps()} />

        <Text className="m-0 text-center">
          Drag and drop some files here, or click to select files
        </Text>
      </div>

      {(!!acceptedFileItems.length || !!fileRejectionItems.length) && (
        <aside className="py-4">
          {!!acceptedFileItems.length && (
            <div data-testid="acceptedFiles">
              <Typography className="text-sm font-bold" component="h4">
                Accepted files
              </Typography>

              <ul className="my-2">{acceptedFileItems}</ul>
            </div>
          )}

          {!!fileRejectionItems.length && (
            <div data-testid="rejectedFiles">
              <Typography className="text-sm font-bold" component="h4">
                Rejected files
              </Typography>

              <ul className="my-2">{fileRejectionItems}</ul>
            </div>
          )}
        </aside>
      )}
    </section>
  )
}
