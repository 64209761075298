import {
  Autocomplete,
  AutocompleteChangeReason,
  Button,
  TextField,
  Typography,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import {
  useEffect,
  useState,
  type MouseEvent,
  type SyntheticEvent,
} from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import {
  Form,
  useActionData,
  useLoaderData,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { ButtonAutoGenerate } from '~/components/ButtonAutoGenerate'
import { Column } from '~/components/Column'
import { ActionIcon } from '~/components/Icons/ActionIcon'
import { ReactionIcon } from '~/components/Icons/ReactionIcon'
import { Row } from '~/components/Row'
import { FormActionName } from '~/pages/developer/components/FormActionName'
import { FormAttributes } from '~/pages/developer/components/FormAttributes'
import { FormFooter } from '~/pages/developer/components/FormFooter'
import { RaisedDomainEvent } from '~/pages/developer/components/FormRaisedDomainEvent'
import { loaderDeveloperAddReaction } from '~/routes/developer/add-reaction/loader'
import { DeveloperChildrenParams } from '~/routes/developer/routes.types'
import { queryDevelopmentDomainsForCommands } from '~/services/Development'
import { Attribute, DomainEvent } from '~/services/Development.types'
import { useAggregateActionForm } from '../hooks/useAggregateActionForm'
import { renamePropertiesToAttributes } from '../utils'

export function DeveloperAddReaction() {
  const [searchParams] = useSearchParams()
  // React Router Dom.
  const dataAutoGeneratedAttributes = useActionData() as {
    formName: string
    attributes: Attribute[]
  }
  const initialData = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof loaderDeveloperAddReaction>>
  >
  const params = useParams<DeveloperChildrenParams>()

  // Queries.
  const { data } = useQuery({
    ...queryDevelopmentDomainsForCommands(params.platformId),
    initialData,
    select: (response) => response.data,
  })

  // React Hook Form.
  const methods = useForm({
    defaultValues: {
      events: [
        {
          name: '',
          attributes: [
            {
              name: '',
              type: 'string',
            },
          ],
        },
      ],
      action: {
        name: '',
        typeId: '',
        attributes: renamePropertiesToAttributes(
          dataAutoGeneratedAttributes?.attributes || [
            {
              name: '',
              type: 'string',
            },
          ],
        ),
      },
    },
  })

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'events',
  })

  // States.
  // Controls for autocomplete
  const [autoCompleteValue, setAutoCompleteValue] = useState<
    DomainEvent | null | undefined
  >(data?.domainEvents[0])
  const [autoCompleteInputValue, setAutoCompleteInputValue] = useState('')

  // Hooks.
  const { formProps, submitButtonRef } = useAggregateActionForm()

  // Methods.
  function handleAutoCompleteChange(
    _: SyntheticEvent<Element, Event>,
    value: DomainEvent | null | undefined,
    reason: AutocompleteChangeReason,
  ) {
    if (reason === 'clear') {
      setAutoCompleteInputValue('')
      setAutoCompleteValue(null)
    }
    if (reason === 'selectOption') {
      setAutoCompleteInputValue(`${value?.aggregate} > ${value?.name}`)
      setAutoCompleteValue(value)
    }
  }

  function handleRemoveEvent(event: MouseEvent<HTMLButtonElement>) {
    const index = event.currentTarget.dataset.index
    if (index) {
      remove(Number(index))
    }
  }

  function handleAddRaisedDomainEvent() {
    append({ name: '', attributes: [] })
  }

  // Lifecycle.
  useEffect(() => {
    if (
      dataAutoGeneratedAttributes &&
      dataAutoGeneratedAttributes?.formName === 'action' &&
      dataAutoGeneratedAttributes?.attributes.length > 0
    ) {
      methods.setValue(
        'action.attributes',
        renamePropertiesToAttributes(dataAutoGeneratedAttributes?.attributes),
      )
    }
  }, [dataAutoGeneratedAttributes, methods])

  if (data) {
    return (
      <Column className="gap-4 pb-12">
        <FormProvider {...methods}>
          <Form method="post" id="form-aggregate-add-reaction" {...formProps}>
            <Column className="gap-4">
              <Row className="items-center gap-2">
                <ActionIcon />

                <Autocomplete
                  id="domain-events"
                  options={data.domainEvents}
                  groupBy={(option) => option.boundedContext}
                  getOptionLabel={(option) =>
                    `${option.aggregate} > ${option.name}`
                  }
                  value={autoCompleteValue}
                  inputValue={autoCompleteInputValue}
                  onChange={handleAutoCompleteChange}
                  onInputChange={(_, newInputValue) => {
                    setAutoCompleteInputValue(newInputValue)
                  }}
                  sx={{ width: 1 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Reacting to Domain Event"
                      name="subscriptionName" // won't use it
                      required
                    />
                  )}
                />
              </Row>

              <input
                type="hidden"
                value={JSON.stringify(autoCompleteValue)}
                name="subscriptionValues"
              />

              <FormActionName inputLabel="Action Name" inputName="action.name">
                <input
                  type="hidden"
                  value={searchParams.get('context') || ''}
                  {...methods.register('action.typeId')}
                />
                <FormAttributes
                  autoGenerateComponent={<ButtonAutoGenerate name="action." />}
                  formName="action"
                />
              </FormActionName>

              <Row className="gap-1">
                <ReactionIcon />

                <Typography>Raised Domain Events ({fields.length})</Typography>
              </Row>

              {fields.map((field, index) => (
                <RaisedDomainEvent
                  key={field.id}
                  defaultValue={field.name}
                  index={index}
                  handleRemoveEvent={handleRemoveEvent}
                  inputName={`events.${index}.name`}
                >
                  <FormAttributes
                    autoGenerateComponent={
                      <ButtonAutoGenerate name={`events.${index}.`} />
                    }
                    formName={`events.${index}`}
                  />
                </RaisedDomainEvent>
              ))}

              <Button
                color="secondary"
                onClick={handleAddRaisedDomainEvent}
                variant="outlined"
              >
                Add raised domain event
              </Button>
            </Column>

            <FormFooter submitButtonRef={submitButtonRef} />
          </Form>
        </FormProvider>
      </Column>
    )
  }

  return null
}
