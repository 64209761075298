import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGetConditionalMappings } from '~/hooks/api/codeGenerationStrategy/useGetConditionalMappings'
import type { CodeGenerationStrategyDialogProps } from '~/models/types/components/codeGenerationStrategy/CodeGenerationStrategyDialogProps'
import {
  FUNCTIONS,
  OPERATORS,
  PARAMETERS,
  SUGGESTIONS,
  type ExpressionItem,
} from './MappingCondition.constants'
import {
  getConditionalMappingsAsPreviouslyConfigured,
  getDataDomainDictionaryAsParameters,
} from './useExpressionBuilder.utils'

type UseExpressionBuilderProps = Pick<
  CodeGenerationStrategyDialogProps,
  'dataDomainDictionary'
>

/**
 * Provides the expression builder resources.
 */
export const useExpressionBuilder = (props: UseExpressionBuilderProps) => {
  const { dataDomainDictionary } = props

  // States.
  const [selectedCategory, setSelectedCategory] = useState<string>('all')
  const [searchQuery, setSearchQuery] = useState('')

  // React Router Dom.
  const params = useParams()
  const { generationStrategyId } = params

  // Hooks.
  const { data: conditionalMappings } = useGetConditionalMappings({
    generationStrategyId,
  })

  // Filter items based on the typed value.
  const filterItems = (items: ExpressionItem[]) => {
    if (!searchQuery) return items
    const query = searchQuery.toLowerCase()
    return items.filter(
      (item) =>
        item.name.toLowerCase().includes(query) ||
        item.description?.toLowerCase().includes(query) ||
        item.value.toLowerCase().includes(query),
    )
  }

  // Previously configured data.
  const previouslyConfigured = useMemo(() => {
    const formattedData =
      getConditionalMappingsAsPreviouslyConfigured(conditionalMappings)

    return !!formattedData && !!formattedData.length ? formattedData : []
  }, [conditionalMappings]) as ExpressionItem[]

  // Parameters data.
  const parameters = useMemo(() => {
    const formattedData = getDataDomainDictionaryAsParameters(
      dataDomainDictionary,
    ) as ExpressionItem[]

    return !!formattedData && !!formattedData.length
      ? formattedData
      : PARAMETERS
  }, [dataDomainDictionary])

  // Provides the items per category.
  const getCategoryItems = (category: string) => {
    switch (category) {
      case 'all':
        return filterItems([
          ...SUGGESTIONS,
          ...previouslyConfigured,
          ...OPERATORS,
          ...FUNCTIONS,
          ...parameters,
        ])
      case 'previouslyConfigured':
        return filterItems(previouslyConfigured)
      case 'operators':
        return filterItems(OPERATORS)
      case 'suggestions':
        return filterItems(SUGGESTIONS)
      case 'functions':
        return filterItems(FUNCTIONS)
      case 'parameters':
        return filterItems(parameters)
      default:
        return []
    }
  }

  return {
    selectedCategory,
    setSelectedCategory,
    searchQuery,
    setSearchQuery,
    getCategoryItems,
  }
}
