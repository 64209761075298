import { useQuery } from '@tanstack/react-query'
import invariant from 'tiny-invariant'
import { ERROR_MESSAGES } from '~/constants/api/codeGenerationStrategy/errorMessages'
import { QueryKeysEnum } from '~/models/enums/api/QueryKeysEnum'
import type { CodeGenerationStrategyApiProps } from '~/models/types/api/codeGenerationStrategy/CodeGenerationStrategyApiProps'
import type { ErrorResponseType } from '~/models/types/http/ErrorResponseType'
import {
  GenerationStrategyAPI,
  serviceGenerationStrategy,
} from '~/services/base'
import { getQueryMutationError } from '~/utils/api/codeGenerationStrategy/getQueryMutationError'

/**
 * Hook that implements react query
 * to get a generation strategy for tree view.
 */
export const useGetGenerationStrategyForTreeView = (
  props: Pick<CodeGenerationStrategyApiProps, 'generationStrategyId'>,
) => {
  const { generationStrategyId } = props

  const {
    error: queryError,
    isError,
    ...restQuery
  } = useQuery({
    enabled: !!generationStrategyId,
    queryKey: [QueryKeysEnum.GENERATION_STRATEGY_BY_ID, generationStrategyId],
    queryFn: async () => {
      invariant(generationStrategyId, ERROR_MESSAGES.generationStrategyId)

      const url = GenerationStrategyAPI.GetGenerationStrategyForTreeView
      return serviceGenerationStrategy.get(url, {
        params: {
          id: generationStrategyId,
        },
      })
    },
    select: (response) => response.data,
  })

  const error = getQueryMutationError(isError, queryError as ErrorResponseType)

  return { ...restQuery, error, isError }
}
