import { forwardRef } from 'react'
import OutlineTrashIcon from '~/assets/icons/outline-trash.svg?react'
import DialogConfirm, {
  type DialogConfirmHandle,
  type DialogConfirmProps,
} from '../DialogConfirm/DialogConfirm'

export type DialogConfirmDeleteHandle = DialogConfirmHandle

/**
 * Dialog (modal) to confirm the delete of something.
 */
const DialogConfirmDelete = forwardRef<
  DialogConfirmDeleteHandle,
  DialogConfirmProps
>((props: DialogConfirmProps, ref) => {
  const {
    actionButtonClassName = 'enabled:bg-rose-500 hover:bg-rose-600',
    actionButtonIcon = <OutlineTrashIcon />,
    confirmBtnText = 'Delete',
  } = props

  return (
    <DialogConfirm
      actionButtonClassName={actionButtonClassName}
      actionButtonIcon={actionButtonIcon}
      confirmBtnText={confirmBtnText}
      {...props}
      ref={ref}
    />
  )
})

export default DialogConfirmDelete
