import WrapTextIcon from '@mui/icons-material/WrapText'
import React, { useRef } from 'react'
import { useMatches } from 'react-router-dom'
import DeleteIcon from '~/assets/icons/trash.svg?react'
import { ActionIcon } from '~/components/Icons/ActionIcon'
import { ReactionIcon } from '~/components/Icons/ReactionIcon'

export function useModalTitleByLocation() {
  const matches = useMatches()
  const [modalTitle, setModalTitle] = React.useState('Adding Service')
  const modalTitleIcon = useRef<JSX.Element | null>(null)

  // Changes outlet title according to route
  React.useEffect(() => {
    const matchPatterns = [
      { pattern: 'add-command', title: 'Command', icon: <ActionIcon /> },
      { pattern: 'add-reaction', title: 'Reaction', icon: <ReactionIcon /> },
      {
        pattern: 'edit-command',
        title: 'Editing Command',
        icon: <ActionIcon />,
      },
      {
        pattern: 'edit-reaction',
        title: 'Editing Reaction',
        icon: <ReactionIcon />,
      },
      { pattern: 'delete-command', title: 'Deleting Command', icon: null },
      { pattern: 'delete-reaction', title: 'Deleting Reaction', icon: null },
      { pattern: 'confirm', title: 'Initialising Service', icon: null },
      { pattern: 'publish-command', title: 'Publishing Command', icon: null },
      { pattern: 'publish-reaction', title: 'Publishing Reaction', icon: null },
      { pattern: 'edit-details', title: 'Editing Aggregate', icon: null },
      { pattern: 'edit-hosting', title: 'Editing Service Hosting', icon: null },
      {
        pattern: 'edit-persistence',
        title: 'Editing Service Persistence',
        icon: null,
      },
      {
        pattern: 'edit-git-credentials',
        title: 'Editing Service Git Credentials',
        icon: null,
      },
      { pattern: 'publish-all', title: 'Publishing All', icon: null },
      { pattern: 'add-readmodel', title: 'Add query', icon: null },
      { pattern: 'delete-readmodel', title: 'Delete query', icon: null },
      { pattern: 'edit-readmodel', title: 'Editing query', icon: null },
      {
        pattern: 'edit-code-generation-strategies',
        title: 'Edit AI Blueprints',
        icon: null,
      },
      { pattern: 'add-query', title: 'Add Query', icon: <WrapTextIcon /> },
      { pattern: 'edit-query', title: 'Editing Query', icon: <WrapTextIcon /> },
      { pattern: 'delete-query', title: 'Delete Query', icon: <DeleteIcon /> },
      { pattern: 'add-type', title: 'Add Type', icon: null },
      { pattern: 'edit-type', title: 'Editing Type', icon: null },
    ]
    const matchedPattern = matchPatterns.find(({ pattern }) =>
      matches.some((match) => match.pathname.includes(pattern)),
    )
    if (matchedPattern) {
      setModalTitle(matchedPattern.title)
      modalTitleIcon.current = matchedPattern.icon
    } else {
      setModalTitle('Adding Service')
      modalTitleIcon.current = null
    }
  }, [matches])

  return { modalTitle, modalTitleIcon: modalTitleIcon.current }
}
