import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'
import { Button, Stack, TextField, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import {
  Form,
  useActionData,
  useNavigation,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { queryDevelopment } from '~/services/Development'

export function DeveloperDetails() {
  // React Router Dom.
  const navigation = useNavigation()
  const isButtonDisabled = navigation.state !== 'idle'

  const errors = useActionData() as GenericObj<string> | undefined

  const params = useParams()
  const { boundedContext, platformId } = params

  const [searchParams] = useSearchParams()
  const isNew = searchParams.get('isNew')

  // React Query.
  const { data } = useQuery({
    ...queryDevelopment(platformId),
    select: (response) => response.data,
  })

  const defaultBoundedContext = {
    ...(!isNew && {
      value: data?.boundedContexts.find(
        (context) => context.name.toLocaleLowerCase() === boundedContext,
      )?.name,
    }),
  }

  return (
    <>
      <Stack gap={2}>
        <Typography>Please enter the service details:</Typography>

        <Form method="post" id="form-aggregate-details">
          <Stack gap={2}>
            <TextField
              error={!!errors?.boundedContext}
              helperText={errors?.boundedContext ? errors.boundedContext : ''}
              label="Bounded Context"
              name="boundedContext"
              required
              variant="outlined"
              {...defaultBoundedContext}
            />

            <TextField
              error={!!errors?.name}
              helperText={errors?.name ? errors.name : ''}
              label="Service Name"
              name="name"
              variant="outlined"
            />

            <Button
              color="secondary"
              disabled={isButtonDisabled}
              endIcon={isButtonDisabled ? null : <KeyboardReturnIcon />}
              sx={{ width: 'fit-content' }}
              type="submit"
              variant="contained"
            >
              {isButtonDisabled ? 'Saving...' : 'Next'}
            </Button>
          </Stack>
        </Form>
      </Stack>
    </>
  )
}
