import { TextFields } from '@mui/icons-material'
import {
  Button,
  Menu,
  MenuItem,
  Tooltip as MuiTooltip,
  menuClasses,
  styled,
  tooltipClasses,
  type TooltipProps,
} from '@mui/material'
import { twMerge } from '^/tailwind.config'
import { useRef, useState, type RefObject } from 'react'
import { v4 as uuid } from 'uuid'
import { getColorClass, highlightFilteredValue } from './ColoredParams.utils'

const CASE_OPTIONS = [
  'Raw',
  'Plural',
  'Upper',
  'Lower',
  'Camel',
  'Kebab',
  'Pascal',
  'Snake',
  'PluralSnake',
  'PluralCamel',
  'PluralKebab',
  'PluralLower',
  'PluralUpper',
  'PluralPascal',
]

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    padding: 0,
  },
}))

export type ColoredParamsProps = {
  /**
   * The ref of the content anchor:
   * A component to indicate where
   * the text content actually starts.
   */
  contentAnchorRef?: RefObject<HTMLSpanElement>
  /**
   * Any filtered value by the user:
   * used to highlight the matching strings.
   */
  filteredValue?: string
  /** The class name applied to the highlighted containing node. */
  highlightClassName?: string
  /** Flag to determine if this is a group node. */
  isGroupNode?: boolean
  /** Array of parameter strings to match against. */
  params: string[]
  /** The value input string to process and render. */
  value: string
}

/**
 * This component takes a string value and an array of params.
 * It renders the value with special formatting for content
 * within curly braces that matches any of the params.
 * Matched content is wrapped in a Tooltip component with custom styling.
 */
export const ColoredParams = (props: ColoredParamsProps) => {
  const {
    contentAnchorRef,
    filteredValue,
    highlightClassName,
    isGroupNode,
    params,
    value,
  } = props

  // Regex to match content within curly braces.
  const regex = /\{([^}]+)\}/g

  // Refs.
  const uniqueId = useRef(uuid()).current

  // States.
  const [selectedCase, setSelectedCase] = useState(CASE_OPTIONS[0] as string)
  const [openTooltip, setOpenTooltip] = useState<boolean>(false)
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(menuAnchorEl)

  const handleToggleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setMenuAnchorEl(null)
  }

  const handleCaseChange = (newCase: string) => {
    setSelectedCase(newCase)
    handleMenuClose()
  }

  // Find the first non-whitespace character position.
  const firstNonWhitespaceIndex = value.search(/\S/) // matches any non-whitespace character.

  // Handle cases where the string is all whitespace or empty.
  const leadingWhitespace =
    firstNonWhitespaceIndex === -1
      ? value
      : value.slice(0, firstNonWhitespaceIndex)

  const remainingContent =
    firstNonWhitespaceIndex === -1 ? '' : value.slice(firstNonWhitespaceIndex)

  // Helper function to preserve whitespace characters in HTML.
  const renderWhitespace = (text: string) => {
    return text.split('\n').map((line, i, arr) => (
      <span key={i}>
        {line.replace(/ /g, '\u00A0')}
        {i < arr.length - 1 && <br />}
      </span>
    ))
  }

  // Split the input value into parts, separating the content inside curly braces.
  const parts = remainingContent.split(regex)

  // The rendered parts: value checked against the params.
  const renderedParts = parts.map((part, index) => {
    if (index % 2 === 0) {
      // Even indices: text outside curly braces, render as-is.
      return (
        <span key={`${uniqueId}-${index}`} data-value={part}>
          {highlightFilteredValue(part, filteredValue)}
        </span>
      )
    } else {
      // Odd indices: content inside curly braces.
      // Find a param that partially matches the content inside curly braces.
      const cleanedPart = part?.split(':')[0]?.trim()
      const matchedParam = params.find((param) => part.includes(param))

      // If a match is found, render within Tooltip.
      if (matchedParam) {
        const classNames = getColorClass(matchedParam)
        const [backgroundColor] = classNames?.split(' ') || []

        const [path, caseValue] = part.split(':').map((s) => s.trim())
        const paramId = `${uniqueId}-${index}`

        return (
          <Tooltip
            onClose={() => setOpenTooltip(false)}
            onOpen={() => setOpenTooltip(true)}
            open={openTooltip || openMenu}
            placement="bottom-end"
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: { offset: [0, -12] },
                },
              ],
            }}
            title={
              <Button
                className={twMerge(
                  'text-weight-light cursor-pointer bg-white font-mono text-xs text-gray-700 ',
                )}
                id="open-menu-button"
                onClick={handleToggleMenuClick}
              >
                <TextFields className="ml-1 mr-0.5 h-4 w-4 align-text-bottom" />

                {selectedCase || caseValue || CASE_OPTIONS[0]}
              </Button>
            }
          >
            <span
              className={twMerge(
                'group relative mx-[1px] inline-block rounded px-1 py-0.5 font-mono text-black hover:z-20',
                backgroundColor,
                highlightClassName,
              )}
              key={paramId}
            >
              {highlightFilteredValue(`{${cleanedPart}}`, filteredValue)}
            </span>
          </Tooltip>
        )
      } else {
        // If no matching param is found, render the content as plain text.
        return (
          <span key={`${uniqueId}-${index}`} data-value={part}>
            {highlightFilteredValue(`{${cleanedPart}}`, filteredValue)}
          </span>
        )
      }
    }
  })

  // Wrap all rendered parts in a span, with conditional styling.
  return (
    <span
      className={twMerge(
        (value === '' || value === `\r\n`) && !isGroupNode ? 'px-1' : '',
      )}
    >
      {/* Render leading whitespace with preserved formatting. */}
      {leadingWhitespace && (
        <span data-value={leadingWhitespace}>
          {renderWhitespace(leadingWhitespace)}
        </span>
      )}

      {/* Add the anchor span after all leading whitespace. */}
      <span
        className="h-0 w-0"
        data-popper-anchor="true"
        ref={contentAnchorRef}
      />

      {renderedParts}

      <Menu
        anchorEl={menuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        id="options-menu"
        MenuListProps={{
          'aria-labelledby': 'open-menu-button',
        }}
        onClose={handleMenuClose}
        open={openMenu}
        sx={{
          [`& .${menuClasses.paper}`]: { mt: 1 },
          zIndex: 9999,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {CASE_OPTIONS.map((option) => (
          <MenuItem
            className="font-mono text-sm"
            key={option}
            onClick={() => handleCaseChange(option)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </span>
  )
}
