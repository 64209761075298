import EastIcon from '@mui/icons-material/East'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import { useNavigation } from 'react-router-dom'
import { WizardButton } from '~/styled/WizardButton'

type WizardFooterButtonsProps = {
  clearButton?: boolean
}
export function WizardFooterButtons({
  clearButton = true,
}: WizardFooterButtonsProps) {
  const navigation = useNavigation()
  const isButtonDisabled = navigation.state !== 'idle'

  return (
    <div className="mt-6 flex justify-end gap-4">
      {clearButton ? (
        <WizardButton
          color="secondary"
          disabled={isButtonDisabled}
          size="large"
          startIcon={<RestartAltIcon />}
          type="reset"
          variant="outlined"
        >
          Clear
        </WizardButton>
      ) : null}
      <WizardButton
        color="secondary"
        disabled={isButtonDisabled}
        endIcon={isButtonDisabled ? null : <EastIcon />}
        size="large"
        type="submit"
        variant="contained"
      >
        {isButtonDisabled ? 'Saving...' : 'Next'}
      </WizardButton>
    </div>
  )
}
