import CodeIcon from '@mui/icons-material/Code'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material'
import { Handle, Position } from '@xyflow/react'
import React, { useRef, useState, type MouseEvent } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import DialogConfirmDelete, {
  type DialogConfirmDeleteHandle,
} from '~/components/dialogs/DialogConfirmDelete/DialogConfirmDelete'
import { useDeleteCommand } from '~/hooks/api/developer/useDeleteCommand'
import { useDeleteQuery } from '~/hooks/api/developer/useDeleteQuery'
import { useDeleteReaction } from '~/hooks/api/developer/useDeleteReaction'
import { useGetSubscriptionPlanCapabilities } from '~/hooks/useGetSubscriptionPlanCapabilities'
import { ServiceAndEventsActionTypeEnum } from '~/models/enums/developer/serviceMap/ServiceAndEventsActionTypeEnum'
import { DeveloperRoutesEnum } from '~/models/enums/routes/DeveloperRoutesEnum'
import { ServiceAndEventsAction } from '~/models/types/developer/serviceMap/ServiceAndEventsType'
import { GenerateAttributes } from '~/pages/developer/components/ServiceMap/GenerateAttributes/GenerateAttributes'
import { PropertiesList } from '~/pages/developer/components/ServiceMap/PropertiesList/PropertiesList'
import { ServiceActionIcon } from './ServiceActionIcon'

const TOOLTIP_PROPS = {
  arrow: true,
}

const ACTION_TYPE_COLORS = {
  [ServiceAndEventsActionTypeEnum.COMMAND]: '#547CC3',
  [ServiceAndEventsActionTypeEnum.QUERY]: '#9C27B0',
  [ServiceAndEventsActionTypeEnum.REACTION]: '#669A5F',
  [ServiceAndEventsActionTypeEnum.EVENT]: '#FF9800',
}

type ServiceActionBoxProps = {
  action: ServiceAndEventsAction
  actionType: ServiceAndEventsActionTypeEnum
  aggregateId?: GUID
  typeId?: GUID
}

export const ServiceActionBox: React.FC<ServiceActionBoxProps> = (props) => {
  const { action, actionType, aggregateId = '', typeId } = props
  const [expanded, setExpanded] = useState(false)

  const dialogConfirmDeleteRef = useRef<DialogConfirmDeleteHandle>(null)
  const navigate = useNavigate()
  const params = useParams()
  const { boundedContext = '', organisationId = '', platformId = '' } = params

  const { codeGeneration: isCodeGenerationAllowed } =
    useGetSubscriptionPlanCapabilities()

  const {
    isPending: isPendingDeleteCommand,
    mutateAsync: mutateDeleteCommand,
  } = useDeleteCommand()
  const { isPending: isPendingDeleteQuery, mutateAsync: mutateDeleteQuery } =
    useDeleteQuery()
  const {
    isPending: isPendingDeleteReaction,
    mutateAsync: mutateDeleteReaction,
  } = useDeleteReaction()

  const urlActionType = actionType.toLowerCase()

  const handleGenerateCodeClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    navigate(
      `${generatePath(DeveloperRoutesEnum.DEVELOPER_AGGREGATE, {
        aggregateId,
        boundedContext,
        organisationId,
        platformId,
      })}/generate-code/${urlActionType}/${action.id}`,
    )
  }

  const handleEditClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    navigate(
      `${generatePath(DeveloperRoutesEnum.DEVELOPER_AGGREGATE, {
        aggregateId,
        boundedContext,
        organisationId,
        platformId,
      })}/edit-${urlActionType}/${action.id}`,
    )
  }

  const handleConfirmDeleteClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    dialogConfirmDeleteRef.current?.handleOpenDialog()
  }

  const handleDeleteClick = () => {
    const payload = {
      aggregateId,
      actionId: action.id,
    }

    if (actionType === ServiceAndEventsActionTypeEnum.COMMAND)
      return mutateDeleteCommand(payload).then(() => {
        dialogConfirmDeleteRef.current?.handleCloseDialog()
      })

    if (actionType === ServiceAndEventsActionTypeEnum.QUERY)
      return mutateDeleteQuery(payload).then(() => {
        dialogConfirmDeleteRef.current?.handleCloseDialog()
      })

    if (actionType === ServiceAndEventsActionTypeEnum.REACTION)
      return mutateDeleteReaction(payload).then(() => {
        dialogConfirmDeleteRef.current?.handleCloseDialog()
      })
  }

  const toggleExpanded = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setExpanded(!expanded)
  }

  return (
    <Paper
      sx={{
        backgroundColor: '#ffffff',
        '& .MuiIconButton-root': {
          color: 'white',
        },
      }}
      elevation={0}
      style={{
        marginBottom: '16px',
        position: 'relative',
        border: 'solid 1px #d3d3d3',
      }}
    >
      <Box
        sx={{
          padding: '8px',
          borderRadius: '4px 4px 0 0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: ACTION_TYPE_COLORS[action.type],
          color: 'white',
          position: 'relative',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <ServiceActionIcon type={action.type} color="white" />
          <Typography variant="body1" style={{ color: 'white' }}>
            {action.name}
          </Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip {...TOOLTIP_PROPS} title={expanded ? 'Collapse' : 'Expand'}>
            <IconButton size="small" onClick={toggleExpanded}>
              {expanded ? (
                <ExpandLessIcon fontSize="small" />
              ) : (
                <ExpandMoreIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
          {isCodeGenerationAllowed && (
            <Tooltip {...TOOLTIP_PROPS} title="Generate code">
              <IconButton size="small" onClick={handleGenerateCodeClick}>
                <CodeIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip {...TOOLTIP_PROPS} title="Edit">
            <IconButton size="small" onClick={handleEditClick}>
              <EditIcon fontSize="small" style={{ height: 14, width: 14 }} />
            </IconButton>
          </Tooltip>
          <Tooltip {...TOOLTIP_PROPS} title="Delete">
            <IconButton size="small" onClick={handleConfirmDeleteClick}>
              <DeleteIcon fontSize="small" style={{ height: 14, width: 14 }} />
            </IconButton>
          </Tooltip>
        </div>
        {action.type === ServiceAndEventsActionTypeEnum.REACTION && (
          <>
            <Handle
              id={`${action.id}-target-handle`}
              type="target"
              position={Position.Left}
              style={{
                left: '0',
                top: '50%',
                visibility: 'hidden',
              }}
            />
            <Handle
              id={`${action.id}-target-handle-self`}
              type="target"
              position={Position.Right}
              style={{
                right: '0',
                top: '50%',
                visibility: 'hidden',
              }}
            />
          </>
        )}
      </Box>

      <Collapse in={expanded}>
        <div className="m-2 mb-0 text-sm">
          <PropertiesList
            attributes={action.properties}
            contentIfEmpty={
              <GenerateAttributes
                aggregateId={aggregateId}
                entityType={action.type}
                entityName={action.name}
                typeId={action.id}
              />
            }
          />
        </div>
        <Box sx={{ padding: '8px', background: '#FFFFFF' }}>
          {action.events?.map((event) => (
            <>
              <div
                key={`${event.id}-${event.name}`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '2px',
                  marginTop: '3px',
                  marginLeft: '18px',
                  position: expanded ? 'relative' : 'inherit',
                }}
              >
                <ServiceActionIcon
                  type={ServiceAndEventsActionTypeEnum.EVENT}
                  color="primary"
                />
                <Typography variant="body2">{event.name}</Typography>
                <Handle
                  id={`${typeId || aggregateId}--${event.name}--source-handle`}
                  type="source"
                  position={Position.Right}
                  style={{
                    right: expanded ? '-6px' : '0',
                    top: '50%',
                    visibility: 'hidden',
                  }}
                />
              </div>
              <div className="ml-6 text-sm">
                <PropertiesList attributes={event.attributes} />
              </div>
            </>
          ))}
        </Box>
      </Collapse>
      {action.type === ServiceAndEventsActionTypeEnum.QUERY && (
        <Handle
          id={`${typeId || aggregateId}-${action.id}-source-handle`}
          type="source"
          position={Position.Right}
          style={{
            right: '-8px',
            top: '50%',
            visibility: 'hidden',
            transform: 'translateY(-50%)',
          }}
        />
      )}
      <DialogConfirmDelete
        isLoading={
          isPendingDeleteCommand ||
          isPendingDeleteQuery ||
          isPendingDeleteReaction
        }
        onConfirmClick={handleDeleteClick}
        ref={dialogConfirmDeleteRef}
      />
    </Paper>
  )
}
