import type { Dispatch, MutableRefObject, SetStateAction } from 'react'
import type { MoveStatementOptionsEnum } from '~/models/enums/api/MoveStatementOptionsEnum'
import type { AggregateType } from './Development.types'

export type PayloadCreateProcess = {
  name: string
  ownerId: GUID
  processId: GUID
  subdomain: string
}

export type PayloadUpdateStatementParsingInfoCommand = {
  processId: string
  statementId: string
  parsingInfo: {
    actor: string
    action: string
    aggregate: string
    boundedContext?: string | null
    raisedDomainEvents: Array<{
      id: string
      name: string
    }>
    requiredData: Nullable<string[]>
    requiredDataInfo: Nullable<string[]>
    type: 'Command' | 'Reaction'
  }
}

export type ResponseCreateProcess = {
  identity: GUID
  name: string
  ownerId: GUID
  statements: never[]
  subdomain: string
}

export type ResponseGetSasToken = {
  url: string
  container: string
  sasToken: string
}

export type PayloadAddStatement = {
  content: string
  id: GUID
  processId: GUID
}

export const STATEMENT_PARSING_INFO_TYPE = {
  Command: 'Command',
  Reaction: 'Reaction',
  Query: 'Query',
  Error: 'Error',
} as const

export type StatementParsingInfoType = keyof typeof STATEMENT_PARSING_INFO_TYPE

export const STATEMENT_STATE_NAME = {
  Unpublished: 'Unpublished',
  Published: 'Published',
} as const

export type StatementStateName = keyof typeof STATEMENT_STATE_NAME

export type StatementParsingInfo = {
  action?: string | null
  actor?: string | null
  aggregate?: string | null
  boundedContext?: string | null
  errorMessage?: string | null
  raisedDomainEvent?: RaisedDomainEvent
  raisedDomainEvents?: RaisedDomainEvent[]
  reactingToDomainEvent?: ReactingToDomainEvent | null
  requiredData?: Nullable<StatementsRequiredData[]>
  requiredDataInfo?: Nullable<StatementsRequiredData[]>
  success?: boolean
  type?: StatementParsingInfoType
}

export type RaisedDomainEvent = {
  /** The data ID. */
  id: number
  /**
   * Indicates if it is a local data:
   * used when creating adding a data before submit.
   */
  isLocal?: boolean
  /** The data name. */
  name: string
}

export type ReactingToDomainEvent = {
  aggregate: string
  boundedContext: string
  id: string | null
  name: string
}

export type StatementsRequiredData = {
  /** The data ID. */
  identity: string
  /**
   * Indicates if it is a local data:
   * used when creating adding a data before submit.
   */
  isLocal?: boolean
  /** The data name. */
  name: string
}

export type Statement = {
  /** The ID of any related aggregate to the current node. */
  aggregateId?: GUID
  /**
   * In case it is a root statement (the very first one of the hierarchy),
   * this field informs the direct child of the root.
   * It is used when creating a new root statement, so the backend
   * will have a reference of the previous root statement, and set it as a child
   * of the new root one.
   */
  beforeStatementId?: GUID
  /** */
  content?: string
  /** The statement ID. */
  id?: string
  /** Indicates if the statement is deleted. */
  isDeleted?: boolean
  /** Error from edit mutation. */
  isEditMutationError?: boolean
  /** Indicates if the statement was marked as feature. */
  isFeature?: boolean
  /** Indicates if the statement data is fetching. */
  isFetching?: boolean // This is not from the API, it is only used on the client side.
  /** */
  isInsert?: boolean // This is not from the API, it is only used on the client side.
  /** Indicates if it is a local node (not post to API yet.) */
  isLocal?: boolean // This is not from the API, it is only used on the client side.
  /** The ID of the parent statement. */
  parentStatementId?: GUID | null
  /** The parsing info form Chat GPT. */
  parsingInfo?: StatementParsingInfo
  /** The platform ID. */
  platformId?: GUID
  /** The process ID. */
  processId?: GUID
  /** */
  requiredDataInfo?: Nullable<StatementsRequiredData[]> // This is not from the API, it is only used on the client side.
  /** Retry the edit mutation.  */
  retryEditMutation?: () => void
  /** */
  stakeholder?: string | null
  /** */
  state?: {
    name: StatementStateName
  }
  /** Used as a suggested description when creating a new statement. */
  suggestedDescription?: string // This is not from the API, it is only used on the client side.
}

/**
 * The statement data necessary to render
 * a reactflow custom node.
 */
export type NodeStatement<A = unknown> = Statement &
  StatementParsingInfo & {
    /** Any actions related to current statement. */
    actions?: A
    /** Any custom content provided as JSX. */
    content?: JSX.Element
    /** The node statement context. */
    context?: string
    /** Indicates if the node statement has a children nodes. */
    hasChildren?: boolean
    /**Indicates if there is an error. */
    hasError?: boolean
    /**
     * The data identity (used in case the ID is filled with another data).
     * Eg: type name.
     */
    identity?: GUID
    /** Indicates if the current data is an aggregate type. */
    isAggregateType?: boolean
    family: string | null
    /**
     * Indicates if the node statement is editable:
     * possible to update from the node component.
     */
    isEditable?: boolean
    /** Indicates if the statement is in editing mode. */
    isEditingMode?: boolean
    /** */
    isEmpty?: boolean
    /** Indicates if the node component is expanded. */
    isExpanded?: boolean
    /** Indicates if the process that the statement belongs to is in process on the background. */
    isGeneratingProcess?: boolean
    /** Indicates if the statement is selected. */
    isSelected?: boolean
    /** */
    isSwapModeEnabled?: boolean
    /** The node label text. */
    label?: string
    /**
     * Callback handler for `add local child`.
     * @param hasChildren Indicates if the current statement node has children nodes.
     * @param statementId The ID of the current statement node.
     */
    onAddLocalChild?: (hasChildren?: boolean, statementId?: GUID) => void
    /**
     * Callback handler for `add local next sibling`.
     * @param statementId The ID of the statement to add the next sibling from.
     */
    onAddLocalNextSibling?: (statementId?: GUID) => void
    /**
     * Callback handler for `add local child`.
     * @param parentStatementId The ID of the current statement node parent.
     * @param currentStatementId The ID of the current statement.
     */
    onAddLocalParent?: (
      parentStatementId?: GUID | null,
      currentStatementId?: GUID,
    ) => void
    /**
     * Callback handler for `add local previous sibling`.
     * @param statementId The ID of the statement to add the previous sibling from.
     */
    onAddLocalPreviousSibling?: (statementId?: GUID) => void
    /**
     * Callback handler for `add reaction` event.
     * @param suggestedDescription A suggested description for the reaction to be added.
     * @param targetStatementId The ID of the statement to be added from.
     */
    onAddReaction?: (
      suggestedDescription?: string,
      targetStatementId?: GUID,
    ) => void
    /**
     * Callback handler triggered when a draggable node is dropped into.
     * droppable areas.
     * @param currentNodeId The ID of current node being dragged into droppable areas.
     * @param targetNodeId The ID of the node which the droppable area refers to.
     * @param position The drop area position (top, bottom, left, or right).
     */
    onDropNodeIntoDropAreas?: (
      currentNodeId?: GUID,
      targetNodeId?: GUID,
      position?: MoveStatementOptionsEnum,
    ) => void
    /** Set state method for `is editing mode` state. */
    setIsEditingMode?: Dispatch<SetStateAction<boolean>>
    /** The ref for the statement input field. */
    statementInputRef?: MutableRefObject<HTMLElement | null>
    /** The data type description. */
    typeDescription?: AggregateType
    /** Mutation method to update the statement. */
    updateStatement?: (data: PayloadUpdateStatementParsingInfoCommand) => void
    statementInput: JSX.Element
  }

export type ResponseCreateStatement = {
  identity: GUID
  name: string
  ownerId: GUID
  statements: Statement[]
  subdomain: string
}

export type PayloadSetStatementStakeholder = {
  processId: GUID
  stakeholder: string
  statementId: GUID
}

export type ResponseSetStatementStakeholder = {
  identity: GUID
  name: string
  ownerId: GUID
  statements: Omit<Statement, 'stakeholder'> & { stakeholder: string }[]
  subdomain: string
}

export type ResponseStakeholder = {
  stakeholder: string
  statements: Omit<Statement, 'stakeholder'> & { stakeholder: string }[]
}

export type ResponseDiscoveryProcess = {
  description: string
  generateProcessBasedOnDescription: boolean
  identity: GUID
  isDeleted: boolean
  isGeneratingProcess: boolean
  /** Indicates if files processing is running on the background. */
  isProcessingFiles?: boolean
  mockups:
    | {
        fileUrl: string
        operations: unknown
      }[]
    | []
  name: string
  organisationId: GUID
  ownerId: GUID
  platformId: string
  statements: Statement[]
  subdomain: string
}

export type RecordResponseRaisedDomainEventsByOrganisationId = {
  boundedContext: string
  aggregate: Nullable<string>
  name: string
  id: number
}
export type ResponseRaisedDomainEventsByOrganisationId =
  RecordResponseRaisedDomainEventsByOrganisationId[]

export type ResponseGetProcessListByOwnerId = {
  name: string
  description: string
  identity: string
}[]

export type ResponseGetProcessListByOrganisationId = {
  name: string
  description: string
  identity: string
}[]
