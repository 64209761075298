import {
  ListBullets,
  PencilSimple,
  RocketLaunch,
  Trash,
} from '@phosphor-icons/react'
import type { Node, NodeProps } from '@xyflow/react'
import ActionIconButton from '~/components/buttons/ActionIconButton/ActionIconButton'
import DialogConfirmDelete from '~/components/dialogs/DialogConfirmDelete/DialogConfirmDelete'
import type { ServiceAndEventsAction } from '~/models/types/developer/serviceMap/ServiceAndEventsType'
import type { NodeStatement } from '~/services/Process.types'
import { getDeleteTooltipText } from './NodeToolbar.utils'
import { useNodeToolbar } from './useNodeToolbar'

type NodeToolbarProps = Pick<
  NodeProps<Node<NodeStatement<ServiceAndEventsAction[]>>>,
  'data'
>

/**
 * A toolbar with additional actions for the referenced node.
 */
export const NodeToolbar = (props: NodeToolbarProps) => {
  const {
    dialogConfirmDeleteRef,
    handleConfirmDeleteClick,
    handleDeleteClick,
    handleDetailsClick,
    handleEditClick,
    handleGenerateCodeClick,
    isCodeGenerationAllowed,
    isErrorDelete,
    isPendingDelete,
    showDetailsButton,
  } = useNodeToolbar(props)

  return (
    <div className="absolute -top-[45px] flex w-full justify-center gap-2">
      {isCodeGenerationAllowed && (
        <ActionIconButton
          color="secondary"
          Icon={RocketLaunch}
          iconProps={{ size: 20 }}
          tooltipPlacement="top"
          tooltipTitle="Generate code"
          onClick={handleGenerateCodeClick}
        />
      )}

      {showDetailsButton && (
        <ActionIconButton
          color="secondary"
          Icon={ListBullets}
          iconProps={{ size: 20 }}
          tooltipPlacement="top"
          tooltipTitle="Details"
          onClick={handleDetailsClick}
        />
      )}

      <ActionIconButton
        color="secondary"
        Icon={PencilSimple}
        iconProps={{ size: 20 }}
        tooltipPlacement="top"
        tooltipTitle="Edit"
        onClick={handleEditClick}
      />

      <ActionIconButton
        color="secondary"
        Icon={Trash}
        iconProps={{ size: 20 }}
        loading={isPendingDelete}
        onClick={handleConfirmDeleteClick}
        tooltipPlacement="top"
        tooltipTitle={getDeleteTooltipText({
          isError: isErrorDelete,
          isLoading: isPendingDelete,
        })}
      />

      <DialogConfirmDelete
        isLoading={isPendingDelete}
        onConfirmClick={handleDeleteClick}
        ref={dialogConfirmDeleteRef}
      />
    </div>
  )
}
