import { IconButton, Tooltip } from '@mui/material'
import { Layout } from '@phosphor-icons/react'
import { useQueryClient } from '@tanstack/react-query'
import { twMerge } from '^/tailwind.config'
import { produce } from 'immer'
import { useEffect, useRef, type ChangeEvent } from 'react'
import type { FileRejection } from 'react-dropzone'
import { useUploadFilesSnackbar } from '~/components/snackbars/UploadFilesSnackbar/useUploadFilesSnackbar'
import { useUploadFiles } from '~/hooks/api/business/useUploadFiles'
import { useGetUserEmail } from '~/hooks/useGetAccount'
import { useGetUserId } from '~/hooks/useGetUserId'
import { queryKeyOrganisation } from '~/services/Discovery'
import { FREE_FEATURES_USAGE } from '~/services/Discovery.types'
import { filesValidator } from '~/utils/uploadFiles/filesValidator'
import { INCLUDE_EXTENSIONS_WITH_IMAGES_AND_DOCS } from '~/utils/uploadFiles/filesValidator.constants'

export const UploadMockupButton = () => {
  // Params.
  const { userId } = useGetUserId()
  const { email } = useGetUserEmail()

  // React Query.
  const queryClient = useQueryClient()

  const {
    error,
    isError,
    isPending,
    isProcessingFiles,
    isSuccess,
    mutate,
    reset,
  } = useUploadFiles()

  // Refs.
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  // Snackbar.
  const { handleRejected } = useUploadFilesSnackbar({
    error,
    isPending,
    isProcessingFiles,
    isSuccess,
    reset,
  })

  // Methods.
  const uploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []) as File[]

    // No files.
    if (!files) return

    // Invalid files.
    const invalidFiles: FileRejection[] = files
      .filter((file: File) =>
        filesValidator(file, INCLUDE_EXTENSIONS_WITH_IMAGES_AND_DOCS),
      )
      .map((file: File) => ({ file: { name: file.name } }) as FileRejection)

    // Handle invalid files.
    if (!!invalidFiles.length) handleRejected(invalidFiles)

    // In case all files are rejected.
    if (files.length === invalidFiles.length) return

    // Valid files.
    const validFiles = files.filter(
      (file: File) =>
        !filesValidator(file, INCLUDE_EXTENSIONS_WITH_IMAGES_AND_DOCS),
    )
    mutate(validFiles)

    queryClient.setQueryData(
      queryKeyOrganisation(userId, email),
      (organisation: any) =>
        produce(organisation, (draft: any) => {
          draft.data.subscriptionPlan[
            `${FREE_FEATURES_USAGE.MOCKUP_ANALYSIS}Remaining`
          ] =
            organisation.data.subscriptionPlan[
              `${FREE_FEATURES_USAGE.MOCKUP_ANALYSIS}Remaining`
            ] - 1 || 0
        }),
    )
  }

  // Lifecycle: reset input value.
  useEffect(() => {
    if ((isError || isSuccess) && fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }, [isError, isSuccess])

  return (
    <Tooltip
      title={isPending ? 'A mockup upload is in progress' : 'Upload mockup'}
    >
      <span className="inline-block">
        <IconButton color="secondary" component="label" disabled={isPending}>
          <input
            data-testid="fileInput"
            hidden
            multiple
            onChange={uploadFile}
            ref={fileInputRef}
            type="file"
          />

          <Layout className={twMerge(isPending && 'opacity-30')} />
        </IconButton>
      </span>
    </Tooltip>
  )
}
