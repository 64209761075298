import { MarkerType } from '@xyflow/react'
import { v4 as uuid } from 'uuid'
import type { NodesRelationshipType } from '../types/NodesRelationshipType'
import type { ServiceMapThemePalette } from './reactFlowColors'

// Define the width for the edge stroke.
const arrowWidth = '2px'

/**
 * Creates a reactflow edge object - for connecting two types' nodes.
 * @param parent The source node data.
 * @param target The target node identifier.
 * @param colors The color palette for styling.
 */
export const createTypeEdge = (
  parent: NodesRelationshipType,
  target: string,
  colors: ServiceMapThemePalette,
) => ({
  id: `${parent.id}-${uuid()}`,
  source: parent.id,
  sourceHandle: `${parent.id}-source-handle`,
  target,
  targetHandle: `${target}-target-handle`,
  style: {
    stroke: colors.typeRelationshipColors.line,
    strokeDasharray: 5,
    strokeWidth: arrowWidth,
  },
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 15,
    height: 15,
    color: colors.typeRelationshipColors.line,
  },
  label: '',
  labelStyle: {
    fontWeight: 'bold',
    fill: colors.typeRelationshipColors.label,
  },
  type: 'smoothstep',
})
