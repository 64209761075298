import InfoIcon from '@mui/icons-material/Info'
import SaveIcon from '@mui/icons-material/Save'
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet'
import TextFieldsIcon from '@mui/icons-material/TextFields'
import {
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  inputBaseClasses,
  styled,
  tooltipClasses,
  type TooltipProps,
} from '@mui/material'
import { twMerge } from '^/tailwind.config'
import {
  memo,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  type ElementRef,
  type MouseEvent,
} from 'react'
import { useParams } from 'react-router-dom'
import { Row } from '~/components/Row'
import { Text } from '~/components/Text'
import { DialogMappingCondition } from '~/components/dialogs/DialogMappingCondition'
import { ControlledContentEditable } from '~/components/form/ControlledContentEditable/ControlledContentEditable'
import { useDeleteProperty } from '~/hooks/api/codeGenerationStrategy/useDeleteProperty'
import { useGetBindingContextOptions } from '~/hooks/api/codeGenerationStrategy/useGetBindingContextOptions'
import { useUpdatePropertyBindingContext } from '~/hooks/api/codeGenerationStrategy/useUpdatePropertyBindingContext'
import { useUpdateTextSection } from '~/hooks/api/codeGenerationStrategy/useUpdateTextSection'
import { useSelectedTextSectionsContext } from '~/hooks/contexts/useSelectedTextSectionsContext'
import { ValueBindingTreeItemFormEnum } from '~/models/enums/forms/ValueBindingTreeItemFormEnum'
import type { ValueBindingTreeItemProps } from '~/models/types/components/codeGenerationStrategy/ValueBindingTreeItemProps'
import { ColoredParams } from '~/routes/configuration/code-generation-strategies/edit/components/ColoredParams/ColoredParam'
import { RevertToOriginalValue } from '~/routes/configuration/code-generation-strategies/edit/components/RevertToOriginalValue/RevertToOriginalValue'
import type { TextSection } from '~/services/GenerationStrategy.types'
import { EditableFieldFormFields } from '../EditableFieldFormFields/EditableFieldFormFields'
import { MenuToReplaceText } from '../MenuToReplaceText/MenuToReplaceText'
import { ParametersTable } from '../ParametersTable/ParametersTable'
import { useManageNodeSelection } from '../RenderTextSections/useManageNodeSelection'
import { ValueBindingToolbar } from './ValueBindingToolbar'
import { Form, getIsContentNodeClasses } from './ValueBindingTreeItem.styles'
import { useMenuReplaceText } from './useMenuReplaceText'
import { useValueBindingTreeItem } from './useValueBindingTreeItem'
import { useValueBindingTreeItemStyles } from './useValueBindingTreeItemStyles'

const ToolbarTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  zIndex: 1600,

  [`& .${tooltipClasses.tooltip}`]: {
    padding: 0,
    backgroundColor: 'transparent',
  },
}))

/**
 * TODO: 20230213 - Consider creating a form component to avoid duplicates.
 *    Not done for now avoid a high risk of regression.
 */
export const ValueBindingTreeItem = memo((props: ValueBindingTreeItemProps) => {
  const {
    dataDomainDictionary,
    fileId,
    initialValue,
    isContent,
    isParentSelectable,
    isParentSelected,
    isValueAsText,
    node,
    parent,
    selectedDomainDictionaryItem,
    siblings,
  } = props

  const { children, name, originalValue, propertyName, sectionType } =
    node || {}
  const { _t, hasDelimiter } = sectionType || {}

  // React Router Dom.
  const params = useParams()
  const { generationStrategyId } = params

  // States.
  const [toggleParametersPanel, setToggleParametersPanel] = useState(false)
  const [valueEditable, setValueEditable] = useState<boolean>(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [bindingContextAnchor, setBindingContextAnchor] =
    useState<null | HTMLElement>(null)
  const [isRevertDialogOpen, setIsRevertDialogOpen] = useState(false)

  // State to manage the confirmation dialog.
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

  // Ref.
  const readOnlyTextRef = useRef<HTMLSpanElement>(null)
  const textFieldRef = useRef<ElementRef<'textarea'>>(null)

  // Selected text sections states.
  const { editableNode, isMergeMode, setEditableNode } =
    useSelectedTextSectionsContext()

  // Component main hook.
  const {
    configForMenuToReplace,
    control,
    handleFormSubmit,
    handleSubmit,
    handleValueFieldBlur,
    isPending,
    resetFormToInitialValues,
    setConfigForMenuToReplace,
    setFormValue,
    watchForm,
  } = useValueBindingTreeItem({ initialValue, fileId, node })

  // Node selection manager hook.
  const {
    handleNodeDoubleClick,
    handleSelectNodeClick,
    isSelectable,
    isSelected,
  } = useManageNodeSelection({
    node: node as TextSection,
    parent,
    siblings,
  })

  // Styles hook.
  const { formHoveredClass, setIsFormHovered } = useValueBindingTreeItemStyles()

  // Context menu hook.
  const { handleContextMenu, handleContextMenuClose, handleMenuItemClick } =
    useMenuReplaceText({
      dataDomainDictionary,
      fieldRef: textFieldRef,
      selectedDomainDictionaryItem,
      setConfigForMenuToReplace,
      setFormValue,
      watchForm,
    })

  const { data: bindingContextOptions } = useGetBindingContextOptions({
    generationStrategyId,
  })

  // Field values.
  const textFieldValue = watchForm?.(ValueBindingTreeItemFormEnum.VALUE) || ''
  const paramsFieldValue = watchForm?.(ValueBindingTreeItemFormEnum.PARAMETERS)
  const parsedParams = JSON.parse(paramsFieldValue || '[]')

  // Indicates if the value is editable.
  const isValueEditable =
    node?._t === 'TextSection' ||
    (isValueAsText && node?._t === 'ValuePropertyBinding')
      ? editableNode === node?.id
      : valueEditable

  // Manage the active toolbar tooltip anchor ref.
  const [activeToolbarTooltipRef, setActiveToolbarTooltipRef] =
    useState(readOnlyTextRef)

  useEffect(() => {
    setActiveToolbarTooltipRef(isValueEditable ? textFieldRef : readOnlyTextRef)
  }, [isValueEditable])

  const handleTextClickToEdit = (
    event: MouseEvent<HTMLSpanElement>,
    node?: TextSection,
  ) => {
    // in case the button (param) inside was clicked
    if (
      (event.target as HTMLButtonElement).name === 'param' ||
      isPending ||
      isMergeMode
    )
      return

    if (
      node?._t === 'TextSection' ||
      (isValueAsText && node?._t === 'ValuePropertyBinding')
    ) {
      // Necessary to distinguish single from double click behavior.
      handleNodeDoubleClick(node)
      // Set the editable text section node.
      setEditableNode?.(node?.id || null)
    } else {
      // Set if the value field is editable.
      setValueEditable(true)
    }

    setTimeout(() => textFieldRef.current?.focus(), 200)
  }

  // Makes ESC close the modal / dropdown / dialog
  // and leave editable mode.
  useLayoutEffect(() => {
    const closeMenu = (event: KeyboardEvent) => {
      // Do nothing if the event was already processed
      if (event.defaultPrevented) return

      if (event.key === 'Escape') {
        handleContextMenuClose()

        if (editableNode || valueEditable) {
          setEditableNode?.(null)
          setValueEditable(false)
          resetFormToInitialValues?.()
        }
      }
    }

    document.addEventListener('keydown', closeMenu)

    return () => {
      document.removeEventListener('keydown', closeMenu)
    }
  }, [isValueEditable])

  // The form mouse enter handler.
  const handleFormMouseEnter = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()

    // Avoid the standard hover behavior when `isMergeMode` is active.
    if (!isMergeMode) setIsFormHovered(true)
  }

  // The form mouse leave handler.
  const handleFormMouseLeave = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setIsFormHovered(false)
  }

  // Dialog handlers
  const handleOpenDialog = (event: MouseEvent<HTMLSpanElement>) => {
    event.preventDefault()
    event.stopPropagation()

    // Prevent opening dialog if in merge mode or if pending
    if (isMergeMode || isPending) return

    // Close any open editable fields
    if (editableNode || valueEditable) {
      setEditableNode?.(null)
      setValueEditable(false)
      resetFormToInitialValues?.()
    }

    setIsDialogOpen(true)
  }

  const handleCloseDialog = () => {
    // Only close if not in pending state
    if (!isPending) {
      setIsDialogOpen(false)
    }
  }

  const handleBindingContextClick = (event: MouseEvent<HTMLSpanElement>) => {
    event.preventDefault()
    event.stopPropagation()

    // Prevent opening if in merge mode or if pending
    if (isMergeMode || isPending) return

    setBindingContextAnchor(activeToolbarTooltipRef.current)
  }

  const handleBindingContextClose = () => {
    setBindingContextAnchor(null)
  }

  // Update the handler to actually update the binding context
  const { mutate: updateBindingContext, isPending: isBindingContextPending } =
    useUpdatePropertyBindingContext({
      generationStrategyId,
      fileId,
      propertyBindingId: node?.id,
    })

  const handleBindingContextSelect = (value: string) => {
    updateBindingContext(
      { bindingContext: value },
      {
        onSuccess: () => {
          setFormValue?.(ValueBindingTreeItemFormEnum.BINDING_CONTEXT, value)
          handleBindingContextClose()
        },
      },
    )
  }

  // Add the delete mutation
  const { mutate: deleteProperty, isPending: isDeletePending } =
    useDeleteProperty({
      generationStrategyId,
      fileId,
      propertyBindingId: node?.id,
    })

  // Update the delete confirmation handler
  const handleDeleteConfirm = () => {
    deleteProperty(undefined, {
      onSuccess: () => {
        setIsDeleteDialogOpen(false)
      },
    })
  }

  // Function to open the delete confirmation dialog
  const handleOpenDeleteDialog = () => {
    setIsDeleteDialogOpen(true)
  }

  const { mutate: updateTextSection, isPending: isRevertPending } =
    useUpdateTextSection()

  const handleRevertConfirm = () => {
    updateTextSection(
      {
        fileId,
        id: node?.id,
        value: node?.originalValue || '',
        node: JSON.stringify(node),
      },
      {
        onSuccess: () => {
          setFormValue?.(
            ValueBindingTreeItemFormEnum.VALUE,
            node?.originalValue || '',
          )
          setIsRevertDialogOpen(false)
        },
      },
    )
  }

  const handleOpenRevertDialogClick = (
    hasValueChanged?: boolean | string | null,
  ) => {
    setIsRevertDialogOpen(Boolean(hasValueChanged) || false)
  }

  // Render null.
  // Section type "list" without delimiter should not be rendered.
  if (!node || (_t === 'List' && !hasDelimiter)) return null

  // Indicates empty and `empty group` (empty value with children) nodes.
  const isEmpty = textFieldValue === ''
  const isGroupNode = !!children?.length && isEmpty

  if (isContent) {
    return (
      <ToolbarTooltip
        key={isValueEditable ? 'editing' : 'reading'}
        placement="top-start"
        PopperProps={{
          anchorEl: activeToolbarTooltipRef.current,
        }}
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: { offset: [0, -5] },
              },
            ],
          },
        }}
        title={
          <ValueBindingToolbar
            node={node}
            onBindingContextClick={handleBindingContextClick}
            onOpenDeleteDialogClick={handleOpenDeleteDialog}
            onOpenMappingConditionDialogClick={handleOpenDialog}
            onOpenRevertDialogClick={handleOpenRevertDialogClick}
          />
        }
      >
        <Form
          className={twMerge('inline align-middle', formHoveredClass)} // Use a custom group class
          name={name}
          onMouseEnter={handleFormMouseEnter}
          onMouseLeave={handleFormMouseLeave}
        >
          <span
            className={twMerge(
              getIsContentNodeClasses(
                isMergeMode,
                isSelectable,
                isSelected,
                isParentSelectable,
                isParentSelected,
              ),
              'relative',
            )}
            data-testid="editableField"
            onClick={() => {
              if (!isValueEditable) handleSelectNodeClick?.(node as TextSection)
            }}
            onDoubleClick={(event) =>
              handleTextClickToEdit?.(event, node as TextSection)
            }
          >
            <DialogMappingCondition
              dataDomainDictionary={dataDomainDictionary}
              handleCloseDialog={handleCloseDialog}
              isDialogOpen={isDialogOpen}
              selectedDomainDictionaryItem={selectedDomainDictionaryItem}
              fileId={fileId}
              propertyBinding={node}
            />

            <Dialog
              open={isDeleteDialogOpen}
              onClose={() => !isDeletePending && setIsDeleteDialogOpen(false)}
              sx={{
                zIndex: 1700,
              }}
            >
              <DialogTitle>Delete Node</DialogTitle>

              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete this node?
                  <br />
                  <i>This action cannot be undone.</i>
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button
                  color="secondary"
                  disabled={isDeletePending}
                  onClick={() => setIsDeleteDialogOpen(false)}
                >
                  Cancel
                </Button>

                <Button
                  color="secondary"
                  disabled={isDeletePending}
                  onClick={handleDeleteConfirm}
                  startIcon={
                    isDeletePending ? <CircularProgress size={20} /> : null
                  }
                  variant="contained"
                >
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={isRevertDialogOpen}
              onClose={() => setIsRevertDialogOpen(false)}
              sx={{
                zIndex: 1700,
              }}
            >
              <DialogTitle>Revert to Original Value</DialogTitle>

              <DialogContent>
                <DialogContentText>
                  Are you sure you want to revert to the original value?
                  <div className="mt-2 space-y-2">
                    <div className="rounded p-2">
                      <strong>Current value:</strong>
                      <pre className="mt-1 whitespace-pre-wrap rounded bg-amber-100/50 p-2 font-mono text-sm">
                        {textFieldValue}
                      </pre>
                    </div>
                    <div className="rounded p-2">
                      <strong>Original value:</strong>
                      <pre className="mt-1 whitespace-pre-wrap rounded bg-green-100/50 p-2 font-mono text-sm">
                        {node.originalValue}
                      </pre>
                    </div>
                  </div>
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button
                  color="secondary"
                  disabled={isRevertPending}
                  onClick={() => setIsRevertDialogOpen(false)}
                >
                  Cancel
                </Button>

                <Button
                  color="secondary"
                  disabled={isRevertPending}
                  onClick={handleRevertConfirm}
                  startIcon={
                    isRevertPending ? <CircularProgress size={20} /> : null
                  }
                  variant="contained"
                >
                  Revert to Original
                </Button>
              </DialogActions>
            </Dialog>

            {/*
             * TODO: Consider moving this to `EditableFieldFormFields`,
             * in case a wider usage is necessary.
             */}
            {isValueEditable && (
              <ControlledContentEditable
                className="py-2"
                control={control}
                disabled={isPending}
                name={ValueBindingTreeItemFormEnum.VALUE}
                onBlur={handleSubmit?.(handleFormSubmit)}
                onContextMenu={handleContextMenu}
                ref={textFieldRef}
                setValue={setFormValue}
                watch={watchForm}
              />
            )}

            <EditableFieldFormFields control={control} onlyHiddenFields />

            {!isValueEditable && (
              <span>
                <ColoredParams
                  contentAnchorRef={readOnlyTextRef}
                  isGroupNode={isGroupNode}
                  params={parsedParams}
                  value={textFieldValue}
                />
              </span>
            )}

            {isEmpty && !isGroupNode && (
              <Tooltip
                arrow
                placement="top"
                title="This is an empty node (no content)."
              >
                <TextFieldsIcon
                  aria-label="Empty node"
                  className="pr-1 text-base"
                  color="secondary"
                />
              </Tooltip>
            )}

            {isGroupNode && (
              <Tooltip arrow placement="top" title="This is a group node.">
                <SettingsEthernetIcon
                  aria-label="Node group"
                  className="pr-1 text-base"
                  color="secondary"
                />
              </Tooltip>
            )}

            <Menu
              anchorEl={bindingContextAnchor}
              open={Boolean(bindingContextAnchor)}
              onClose={handleBindingContextClose}
              sx={{
                zIndex: 1700,
              }}
            >
              {bindingContextOptions?.map(
                (option: { value: string; label: string; depth: number }) => (
                  <MenuItem
                    key={option.value}
                    onClick={() => handleBindingContextSelect(option.value)}
                    sx={{
                      paddingLeft: `${(option.depth + 1) * 16}px`,
                      backgroundColor:
                        node.bindingContext === option.value
                          ? 'rgba(0, 0, 0, 0.04)'
                          : 'inherit',
                    }}
                    disabled={isBindingContextPending}
                    selected={node.bindingContext === option.value}
                  >
                    {option.label}
                  </MenuItem>
                ),
              )}
            </Menu>
          </span>

          <MenuToReplaceText
            dataDomainDictionary={dataDomainDictionary}
            onMenuClose={handleContextMenuClose}
            onMenuItemClick={handleMenuItemClick}
            options={configForMenuToReplace}
          />
        </Form>
      </ToolbarTooltip>
    )
  }

  return (
    <form className="w-full" onSubmit={handleSubmit?.(handleFormSubmit)}>
      <Row className="my-1 w-full items-center justify-start gap-1">
        <Text>{propertyName}</Text>

        <Tooltip
          arrow
          placement="top"
          title={
            <RevertToOriginalValue
              originalValue={originalValue}
              setFormValue={setFormValue}
              value={textFieldValue}
            />
          }
          componentsProps={{
            tooltip: {
              className: twMerge(
                (originalValue?.length && originalValue.length > 35) ||
                  textFieldValue.length > 35
                  ? 'max-w-[500px]'
                  : '',
              ),
            },
          }}
        >
          <div className="flex p-1">
            <InfoIcon className="text-base text-slate-500 opacity-70" />
          </div>
        </Tooltip>

        <div
          className="relative min-h-[40px] rounded border-solid border-slate-100 bg-slate-50 px-[14px] py-[6px] hover:cursor-pointer hover:border-solid hover:border-slate-300 hover:bg-white"
          data-testid="editableField"
          onMouseUp={handleTextClickToEdit}
        >
          <EditableFieldFormFields
            control={control}
            isEditable={isValueEditable}
            isPending={isPending}
            onValueBlur={handleValueFieldBlur}
            onValueContextMenu={handleContextMenu}
            valueClassName={twMerge(
              'text border-transparent bg-transparent hover:border-solid hover:border-gray-300 hover:bg-white focus:bg-white',
              isValueEditable
                ? `absolute top-0 left-0 right-0 bottom-0 [&_.${inputBaseClasses.root}]:absolute [&_.${inputBaseClasses.root}]:top-0 [&_.${inputBaseClasses.root}]:left-0 [&_.${inputBaseClasses.root}]:right-0 [&_.${inputBaseClasses.root}]:bottom-0`
                : 'hidden',
            )}
            valueRef={textFieldRef}
          />

          <ColoredParams value={textFieldValue} params={parsedParams} />
        </div>

        <MenuToReplaceText
          dataDomainDictionary={dataDomainDictionary}
          onMenuClose={handleContextMenuClose}
          onMenuItemClick={handleMenuItemClick}
          options={configForMenuToReplace}
        />

        <IconButton disabled={isPending} size="small" type="submit">
          {isPending ? (
            <CircularProgress size={20} />
          ) : (
            <SaveIcon className="text-xl text-primary opacity-70" />
          )}
        </IconButton>
      </Row>

      <Collapse in={toggleParametersPanel} mountOnEnter unmountOnExit>
        <div className="flex flex-col items-center">
          <ParametersTable
            isDisabled={isPending}
            setFormValue={setFormValue}
            watchForm={watchForm}
          />
        </div>
      </Collapse>
    </form>
  )
})
