import { getBezierPath, type EdgeProps } from '@xyflow/react'

export function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerStart,
  markerEnd,
  label,
  labelStyle,
  data,
}: EdgeProps) {
  const { isReturningEdge } = data || {}

  const [edgePath] = isReturningEdge
    ? generateSmoothCurve(sourceX, sourceY, targetX, targetY)
    : getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
      })

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
        markerStart={markerStart}
      />

      <text dy={-4} style={labelStyle}>
        <textPath href={`#${id}`} startOffset="50%" textAnchor="middle">
          {label}
        </textPath>
      </text>
    </>
  )
}

/**
 * Generates a smooth curve path between two points using a cubic Bezier curve.
 * The curve adapts to the relative positions of the start and end points,
 * creating an S-shaped curve that attempts to avoids intersecting with nodes.
 *
 * @param sourceX The x-coordinate of the starting point of the edge.
 * @param sourceY The y-coordinate of the starting point of the edge.
 * @param targetX The x-coordinate of the ending point of the edge.
 * @param targetY The y-coordinate of the ending point of the edge.
 * @returns A string representing an SVG path for a cubic Bezier curve.
 */
function generateSmoothCurve(
  sourceX: number,
  sourceY: number,
  targetX: number,
  targetY: number,
) {
  // Calculate the horizontal and vertical distances between points.
  const dx = Math.abs(targetX - sourceX)
  const dy = Math.abs(targetY - sourceY)

  // Determine the curvature based on the smaller of dx or dy:
  // This ensures the curve isn't too extreme for long distances.
  const curveFactor = Math.min(dx, dy) * 0.5

  // Determine if the edge should curve upward or downward:
  // If the source is below the target, curve upward; otherwise, curve downward.
  const curveUpward = sourceY > targetY

  // Calculate control points for the Bezier curve:
  // These points determine the shape and direction of the curve.
  const controlPoint1X = sourceX + dx * 0.25 // 25% of the way from source to target.
  const controlPoint1Y = sourceY + (curveUpward ? -curveFactor : curveFactor)
  const controlPoint2X = targetX - dx * 0.25 // 25% of the way from target to source.
  const controlPoint2Y = targetY + (curveUpward ? curveFactor : -curveFactor)

  // Construct the SVG path string for a cubic Bezier curve:
  // M: Move to the starting point;
  // C: Cubic Bezier curve command, followed by two control points and the end point.
  return [
    `M ${sourceX} ${sourceY} 
          C ${controlPoint1X} ${controlPoint1Y}, 
            ${controlPoint2X} ${controlPoint2Y}, 
            ${targetX} ${targetY}`,
  ]
}
