import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { produce } from 'immer'
import { ActionFunctionArgs } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { IS_DEV } from '~/config/constants'
import {
  getAttributesFromForm,
  redirectToDeveloperHomeOrAggregate,
} from '~/routes/developer/utils'
import { queryKeyDevelopment } from '~/services/Development'
import { queryKeyOrganisation } from '~/services/Discovery'
import { FREE_FEATURES_USAGE } from '~/services/Discovery.types'
import { queryKeyProcess } from '~/services/Process'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'
import { getUserEmail } from '~/utils/getUserEmail'
import { getUserId } from '~/utils/getUserId'

export const actionDeveloperEditDetails =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ request, params }: ActionFunctionArgs) => {
    const userId = getUserId(pca)
    const email = getUserEmail(pca)
    const { aggregateId, platformId, processId } = params

    invariant(platformId, 'No platformId found')
    invariant(userId, 'No userId found')

    // Get form data
    const formData = await request.formData()
    const boundedContext = formData.get('aggregate.boundedContext')
    const aggregateName = formData.get('aggregate.name')
    const allForm = Object.fromEntries(formData)

    // This will return early in case we are only auto generating attributes for commands
    // It will also return the data from it available at useActionData()
    const autoGenerateButton = Object.keys(allForm).find((key) =>
      key.includes('AutoGenerateAttributes'),
    )

    if (autoGenerateButton) {
      if (aggregateId && typeof aggregateId === 'string') {
        const reqUrl = DevelopmentAggregateAPI.GenerateAttributes
        const data = {
          aggregateId: aggregateId?.toString() || '',
          entityType: 'Aggregate',
          entityName:
            allForm[
              autoGenerateButton?.replace('AutoGenerateAttributes', 'name') ||
                ''
            ]?.toString() || '',
        }

        try {
          const res = await serviceDevelopmentAggregate.post(reqUrl, data)
          queryClient.setQueryData(
            queryKeyOrganisation(userId, email),
            (organisation: any) =>
              produce(organisation, (draft: any) => {
                draft.data.subscriptionPlan[
                  `${FREE_FEATURES_USAGE.ATTRIBUTE_AUTO_GENERATION}Remaining`
                ] =
                  organisation.data.subscriptionPlan[
                    `${FREE_FEATURES_USAGE.ATTRIBUTE_AUTO_GENERATION}Remaining`
                  ] - 1 || 0
              }),
          )
          return {
            formName: autoGenerateButton?.replace(
              '.AutoGenerateAttributes',
              '',
            ),
            attributes: res.data?.value?.attributes,
          }
        } catch (error) {
          if (IS_DEV) {
            console.log(error)
          }
          return []
        }
      }
    }

    // end of auto generation of attributes for commands
    const isCopyButtonPressed = Object.keys(allForm).find((key) =>
      key.includes('.copyButton'),
    )

    if (isCopyButtonPressed) {
      const attributes = getAttributesFromForm({
        formEntries: allForm,
        kind: isCopyButtonPressed.replace('copyButton', ''),
      })
      await navigator.clipboard.writeText(JSON.stringify(attributes))
      return null
    }

    const actionAttributes = getAttributesFromForm({
      formEntries: allForm,
      kind: 'aggregate.',
    })

    // Validate errors
    const errors: GenericObj<string> = {}
    if (
      !aggregateName ||
      typeof aggregateName !== 'string' ||
      aggregateName.trim().length <= 1
    ) {
      errors.aggregateName = 'Required'
    }
    if (Object.keys(errors).length) {
      return errors
    }

    // Builds the data to send to server
    const postData = {
      properties: actionAttributes,
      aggregateId,
      name: aggregateName,
      boundedContext,
    }

    // Setups and send to server
    const url = DevelopmentAggregateAPI.EditAggregate
    const req = await serviceDevelopmentAggregate.post(url, postData)

    // Invalidates the cache
    queryClient.invalidateQueries({ queryKey: queryKeyDevelopment(platformId) })
    await queryClient.invalidateQueries({
      queryKey: queryKeyProcess(processId),
    })

    const requestUrl = new URL(request.url)
    const isBusiness = requestUrl.pathname.includes('business')

    if (isBusiness) {
      return null
    }

    // redirects
    return redirectToDeveloperHomeOrAggregate(req, params)
  }
