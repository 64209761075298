import { QueryClient } from '@tanstack/react-query'
import { ActionFunctionArgs, generatePath, redirect } from 'react-router-dom'
import { BusinessRoutesEnum } from '~/models/enums/routes/BusinessRoutesEnum'
import { DeveloperRoutesEnum } from '~/models/enums/routes/DeveloperRoutesEnum'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'
import { queryAggregate } from '~/services/Development'

export const actionDeveloperGitCredentials =
  (queryClient: QueryClient) =>
  async ({ request, params }: ActionFunctionArgs) => {
    const { aggregateId, boundedContext, organisationId, platformId } = params

    // Get form data
    const formData = await request.formData()
    const repositoryUrl = formData.get('repositoryUrl')
    const token = formData.get('token')

    // Validate errors
    const errors: GenericObj<string> = {}
    if (
      !repositoryUrl ||
      typeof repositoryUrl !== 'string' ||
      repositoryUrl.trim().length <= 1
    ) {
      errors.repositoryUrl = 'Required'
    }
    if (!token || typeof token !== 'string' || token.trim().length <= 1) {
      errors.token = 'Required'
    }
    if (Object.keys(errors).length) {
      return errors
    }

    // Builds the data to send to server
    const postData = {
      aggregateId,
      repositoryUrl,
      token,
    }

    // Setups and send to server
    const url = DevelopmentAggregateAPI.ConfigureGitRepo
    await serviceDevelopmentAggregate.post(url, postData)

    // Logic for redirect
    const requestUrl = new URL(request.url)
    const isInitialising = requestUrl.search.includes('initialise')
    const isBusiness = requestUrl.pathname.includes('business')

    const eventId = requestUrl.searchParams.get('initialise')
    const eventType = requestUrl.searchParams.get('type')

    const requestAggregate = await queryClient.fetchQuery(
      queryAggregate(aggregateId),
    )
    const configAggregate = requestAggregate.data

    // Paths.
    const developerPath = generatePath(
      DeveloperRoutesEnum.DEVELOPER_AGGREGATE,
      {
        aggregateId: aggregateId || '',
        boundedContext: boundedContext || '',
        organisationId: organisationId || '',
        platformId: platformId || '',
      },
    )

    if (isInitialising && isBusiness && configAggregate) {
      const processId = params.processId

      if (!configAggregate.host) {
        return redirect(
          `/${organisationId}/${platformId}/business/${processId}/${aggregateId}/edit-hosting?initialise=${eventId}&type=${eventType}`,
        )
      }
      if (!configAggregate.persistence) {
        return redirect(
          `/${organisationId}/${platformId}/business/${processId}/${aggregateId}/edit-persistence?initialise=${eventId}&type=${eventType}`,
        )
      }
      if (!configAggregate.gitRepositoryUrl) {
        return redirect(
          `/${organisationId}/${platformId}/business/${processId}/${aggregateId}/edit-git-credentials?initialise=${eventId}&type=${eventType}`,
        )
      }
      if (
        configAggregate.host &&
        configAggregate.persistence &&
        configAggregate.gitRepositoryUrl
      ) {
        return redirect(
          `/${organisationId}/${platformId}/business/${processId}/${aggregateId}/confirm?initialise=${eventId}&type=${eventType}`,
        )
      }
    }

    if (isInitialising && !isBusiness && configAggregate) {
      if (!configAggregate.host) {
        return redirect(
          `${developerPath}/edit-hosting?initialise=${eventId}&type=${eventType}`,
        )
      }
      if (!configAggregate.persistence) {
        return redirect(
          `${developerPath}/edit-persistence?initialise=${eventId}&type=${eventType}`,
        )
      }
      if (!configAggregate.gitRepositoryUrl) {
        return redirect(
          `${developerPath}/edit-git-credentials?initialise=${eventId}&type=${eventType}`,
        )
      }
      if (
        configAggregate.host &&
        configAggregate.persistence &&
        configAggregate.gitRepositoryUrl
      ) {
        return redirect(
          `${developerPath}/confirm?initialise=${eventId}&type=${eventType}`,
        )
      }
    }

    return isBusiness
      ? redirect(
          generatePath(BusinessRoutesEnum.BUSINESS, {
            organisationId: organisationId || '',
            platformId: platformId || '',
          }),
        )
      : redirect(developerPath)
  }
