import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { ActionFunctionArgs, generatePath, redirect } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { IS_DEV } from '~/config/constants'
import { BusinessRoutesEnum } from '~/models/enums/routes/BusinessRoutesEnum'
import { DeveloperRoutesEnum } from '~/models/enums/routes/DeveloperRoutesEnum'
import { PayloadSelectGenerationStrategies } from '~/services/Development.types'
import { queryOrganisation } from '~/services/Discovery'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'
import { getUserEmail } from '~/utils/getUserEmail'
import { getLocalUserId } from '~/utils/getUserId'

const SUBMIT_INTENT = ['submit-full', 'submit-git-credentials'] as const
export type ConfigGenerateCodeSubmitIntent = (typeof SUBMIT_INTENT)[number]

export const actionConfigGenerateCode =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ request, params }: ActionFunctionArgs) => {
    // processId is optional, if the user is coming from the business side
    // eventType and eventId are optional, used to publish command, reaction or query (read model)
    const {
      aggregateId,
      boundedContext,
      eventId,
      eventType,
      organisationId,
      platformId,
      processId,
    } = params

    invariant(aggregateId, 'aggregateId is required')
    invariant(platformId, 'platformId is required')

    // Get submit type
    const formData = await request.formData()
    const formIntent = formData.get(
      'intent',
    ) as ConfigGenerateCodeSubmitIntent | null

    invariant(formIntent, 'Submit intention is required')

    // Submit the form to configure git credentials
    if (formIntent === 'submit-git-credentials') {
      // Get form data
      const inputRepositoryUrl = formData.get('repositoryUrl')
      const inputToken = formData.get('token')

      // Validate errors
      const errors: GenericObj<string> = {}
      if (
        !inputRepositoryUrl ||
        typeof inputRepositoryUrl !== 'string' ||
        inputRepositoryUrl.trim().length <= 1
      ) {
        errors.repositoryUrl = 'Required'
      }

      if (
        !inputToken ||
        typeof inputToken !== 'string' ||
        inputToken.trim().length <= 1
      ) {
        errors.token = 'Required'
      }

      // If there are errors, return them
      if (Object.keys(errors).length) {
        return errors
      }

      // Git Credentials: Builds the data to send to server
      const postDataGit = {
        aggregateId,
        repositoryUrl: inputRepositoryUrl,
        token: inputToken,
      }

      // Git Credentials: Setups and send to server

      const urlServiceGit = DevelopmentAggregateAPI.ConfigureGitRepo
      try {
        await serviceDevelopmentAggregate.post(urlServiceGit, postDataGit)
        queryClient.invalidateQueries()

        return { gitConfigured: true }
      } catch (error) {
        if (IS_DEV) {
          console.log(error)
        }
        return { gitConfigured: false }
      }
    }

    // Submit the form to generate code
    if (formIntent === 'submit-full') {
      // Get form data
      const inputStrategy = formData.getAll('strategy') as string[]
      const inputHosting = formData.get('hosting')
      const inputPersistence = formData.get('persistence') // as Omit<FormDataEntryValue, 'File'>[]
      const inputType = formData.get('type')
      const inputCommitMessage = formData.get('commitMessage')
      const inputBranch = formData.get('branch')
      const inputEnableAIAssistant = formData.get('enableAIAssistant') || false
      const aiContext = formData.get('aiContext') || false

      // Validate errors
      const errors: GenericObj<string> = {}
      if (
        !inputType ||
        typeof inputType !== 'string' ||
        (inputType === 'custom' && !inputStrategy.length) ||
        (inputType === 'regular' && !inputHosting)
      ) {
        errors.type = 'Required'
      }

      // If there are errors, return them
      if (Object.keys(errors).length) {
        return errors
      }

      // Pre-configured Strategy
      if (inputType === 'regular') {
        // Hosting
        const postDataHosting = {
          aggregateId,
          name: inputHosting,
        }
        const urlServiceHosting = DevelopmentAggregateAPI.SelectHostingStrategy

        try {
          await serviceDevelopmentAggregate.post(
            urlServiceHosting,
            postDataHosting,
          )
        } catch (error) {
          if (IS_DEV) {
            console.log(error)
          }
          return {
            apiError: true,
            apiErrorMessage: error,
          }
        }

        // Persistence
        const postDataPersistence = {
          aggregateId,
          name: inputPersistence,
        }
        const urlServicePersistence =
          DevelopmentAggregateAPI.SelectPersistenceStrategy

        try {
          await serviceDevelopmentAggregate.post(
            urlServicePersistence,
            postDataPersistence,
          )
        } catch (error) {
          if (IS_DEV) {
            console.log(error)
          }
          return {
            apiError: true,
            apiErrorMessage: error,
          }
        }
      }

      // Custom Strategy
      if (inputType === 'custom') {
        const postDataCustom: PayloadSelectGenerationStrategies = {
          aggregateId,
          generationStrategyIds: inputStrategy.length ? inputStrategy : [],
        }

        // Setups and send to server
        const urlServiceCustom =
          DevelopmentAggregateAPI.SelectGenerationStrategies
        await serviceDevelopmentAggregate.post(urlServiceCustom, postDataCustom)
      }

      // Safe Checks

      // Let's check if the user configured the git credentials before submitting any further data.
      const inputAggregateGitRepositoryUrl = formData.get(
        'aggregateGitRepositoryUrl',
      )
      const inputRepositoryUrl = formData.get('repositoryUrl')
      const inputToken = formData.get('token')

      // If they didn't configure git before and didn't put any url in the git repository field / token, we'll return an error.
      if (
        (!inputAggregateGitRepositoryUrl &&
          !inputRepositoryUrl &&
          !inputToken) ||
        (!inputAggregateGitRepositoryUrl && !inputToken)
      ) {
        return {
          gitConfigured: false,
        }
      }

      // If they didn't configure git before but put all required data to configure it, we just configure it by ourselves and continue
      if (!inputAggregateGitRepositoryUrl && inputRepositoryUrl && inputToken) {
        // Git Credentials: Builds the data to send to server
        const postDataGit = {
          aggregateId,
          repositoryUrl: inputRepositoryUrl,
          token: inputToken,
        }

        // Git Credentials: Setups and send to server
        try {
          const urlServiceGit = DevelopmentAggregateAPI.ConfigureGitRepo
          await serviceDevelopmentAggregate.post(urlServiceGit, postDataGit)

          queryClient.invalidateQueries()

          // do not return from here, we want to continue to submit the code
          // return { gitConfigured: true }
        } catch (error) {
          if (IS_DEV) {
            console.log(error)
          }
          return { gitConfigured: false }
        }
      }

      // End Safe Checks

      // Get common data for the functions below
      const userId = getLocalUserId(pca)

      // Initialise
      // if (
      //   inputType === 'regular' &&
      //   aggregateData?.status.name !== 'Initialised'
      // ) {
      //   const postDataConfirm = {
      //     commitMessage: `Generate infra code for service ${aggregateData?.name}`,
      //     developerId: userId,
      //     aggregateId,
      //     branch: null,
      //   }
      //   const urlServiceConfirm = DevelopmentAggregateAPI.Initialise
      //   await serviceDevelopmentAggregate.post(urlServiceConfirm, postDataConfirm)
      // }

      // Publish common data
      const email = getUserEmail(pca)
      const qOrganisation = queryOrganisation(userId, email)
      const fetchOrganisation = await queryClient.ensureQueryData(qOrganisation)
      const ecosystemName = fetchOrganisation.data.ecosystemName
      const requestUrl = new URL(request.url)
      const isBusiness = requestUrl.pathname.includes('business')
      const strategyType = inputType === 'regular' ? 'PreDefined' : 'Custom'

      // Publish Command
      // Publish Reaction
      // Publish Query (Read Model)
      if (eventType && eventId) {
        const postData = {
          aggregateId,
          developerId: userId,
          ecosystemName: fetchOrganisation.data.ecosystemName,
          commitMessage: inputCommitMessage,
          branch: inputBranch,
          strategyType: strategyType,
          enableAIAssistant: inputEnableAIAssistant,
          aiInstructions: aiContext,
        }
        // Setups and send to server
        const getEvent = () => {
          if (eventType === 'command') {
            return 'PublishCommand'
          }
          if (eventType === 'reaction') {
            return 'PublishReaction'
          }
          if (eventType === 'type') {
            return 'PublishType'
          }
          if (eventType === 'read-model') {
            return 'PublishReadModel'
          }
          if (eventType === 'query') {
            return 'PublishQuery'
          }

          return 'PublishCommand'
        }
        const urlServicePublishEvent = DevelopmentAggregateAPI[getEvent()]

        const getPostData = () => {
          if (eventType === 'reaction') {
            return {
              ...postData,
              reactionId: eventId,
            }
          }
          if (eventType === 'read-model') {
            return {
              ...postData,
              readModelId: eventId,
            }
          }
          if (eventType === 'query') {
            return {
              ...postData,
              queryId: eventId,
            }
          }
          if (eventType === 'type') {
            return {
              ...postData,
              typeId: eventId,
            }
          }

          return {
            ...postData,
            commandId: eventId,
          }
        }

        try {
          // Post data
          await serviceDevelopmentAggregate.post(
            urlServicePublishEvent,
            getPostData(),
          )

          // Invalidate queries but don't await
          queryClient.invalidateQueries()

          // Redirects
          let redirectPath = generatePath(
            DeveloperRoutesEnum.DEVELOPER_AGGREGATE,
            {
              aggregateId,
              boundedContext: boundedContext || '',
              organisationId: organisationId || '',
              platformId,
            },
          )

          if (isBusiness && !processId) {
            redirectPath = generatePath(BusinessRoutesEnum.BUSINESS, {
              organisationId: organisationId || '',
              platformId: platformId || '',
            })
          }

          if (isBusiness && processId) {
            redirectPath = generatePath(BusinessRoutesEnum.BUSINESS_PROCESS, {
              organisationId: organisationId || '',
              platformId: platformId || '',
              processId: processId || '',
            })
          }

          return redirect(redirectPath)
        } catch (error) {
          if (IS_DEV) {
            console.log(error)
          }
          return {
            apiError: true,
            apiErrorMessage: error,
          }
        }
      }

      // Publish All
      if (!eventType || !eventId) {
        // Setups and send to server
        const postDataPublish = {
          commitMessage: inputCommitMessage,
          developerId: userId,
          aggregateId,
          ecosystemName,
          branch: inputBranch,
          strategyType: strategyType,
          enableAIAssistant: inputEnableAIAssistant,
          aiInstructions: aiContext,
        }
        const urlServicePublish = DevelopmentAggregateAPI.PublishAggregate

        try {
          await serviceDevelopmentAggregate.post(
            urlServicePublish,
            postDataPublish,
          )

          queryClient.invalidateQueries()
        } catch (error) {
          if (IS_DEV) {
            console.log(error)
          }
          return {
            apiError: true,
            apiErrorMessage: error,
          }
        }
      }

      return null
    }
  }
