type NodeToolbarUtilsProps = {
  /** Indicates if there is an error from the API. */
  isError?: {}
  /** Indicates if an API request is in progress. */
  isLoading?: boolean
}

/**
 * Provides the tooltip text for the delete button.
 */
export const getDeleteTooltipText = ({
  isError,
  isLoading,
}: NodeToolbarUtilsProps) => {
  if (isLoading) return 'Loading...'

  if (!!isError) return 'Retry delete'

  return 'Delete'
}
