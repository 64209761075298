import {
  ArrowCounterClockwise,
  PencilSimple,
  PencilSimpleSlash,
  Star,
} from '@phosphor-icons/react'
import type { NodeStatement } from '~/services/Process.types'

export const iconProps = {
  className: 'text-secondary',
  size: 20,
}

type SideMenuUtilsProps = {
  /** Error from the API. */
  error?: {}
  /** Indicates if an API request is in progress. */
  isProcessing?: boolean
  /** Indicates is it is already marked as feature. */
  markedAsFeature?: boolean
}

/**
 * Provides the tooltip text for the
 * `mark as feature` button.
 */
export const getMarkAsFeatureTooltipText = ({
  error,
  isProcessing,
  markedAsFeature,
}: SideMenuUtilsProps) => {
  if (isProcessing) return 'Processing.'

  if (!!error) return 'Retry mark the step as feature.'

  if (markedAsFeature) return 'Marked as feature.'

  return 'Mark this step as a feature to be able to generate code.'
}

/**
 * Provides the icon component for the
 * `mark as feature` button.
 */
export const getMarkAsFeatureIcon = ({
  error,
  isProcessing,
  markedAsFeature,
}: SideMenuUtilsProps) => {
  if (isProcessing) return null

  if (!!error) return <ArrowCounterClockwise {...iconProps} />

  if (markedAsFeature) return <Star {...iconProps} weight="fill" />

  return <Star {...iconProps} />
}

/**
 * Provides the tooltip text for the
 * `edit statement` button.
 */
export const getEditTooltipText = ({
  isEditable,
  isFetching,
}: Pick<NodeStatement, 'isEditable' | 'isFetching'>) => {
  if (isFetching) return 'Edit in progress.'

  if (isEditable) return 'Stop editing.'

  return 'Start editing.'
}

/**
 * Provides the icon component for the
 * `edit statement` button.
 */
export const getEditTooltipIcon = ({
  isEditable,
}: Pick<NodeStatement, 'isEditable'>) => {
  if (isEditable) return <PencilSimpleSlash {...iconProps} />

  return <PencilSimple {...iconProps} />
}
