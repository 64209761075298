import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import { CodeGenerationPages } from '~/components/CodeGenerationPages'
import { CommonLayout } from '~/components/CommonLayout'
import { loaderCommonLayout } from '~/components/CommonLayout/loader'
import { FEATURE_TOGGLE } from '~/config/featureToggle'
import { BusinessDeveloperRoutesEnum } from '~/models/enums/routes/BusinessDeveloperRoutesEnum'
import { Business } from '~/pages/business'
import { BusinessLayout } from '~/pages/business/Layout'
import { loaderBusinessLayout } from '~/pages/business/Layout/loader'
import { loaderBusiness } from '~/pages/business/loader'
import { Developer } from '~/pages/developer'
import { DeveloperLayout } from '~/pages/developer/Layout'
import { loaderDeveloperLayout } from '~/pages/developer/Layout/loader'
import { PlatformSetupModal } from '~/pages/developer/components/PlatformSetupModal'
import { loaderPlatformSetup } from '~/pages/developer/components/PlatformSetupModal/loader'
import { loaderDeveloper } from '~/pages/developer/loader'
import { Plans } from '~/pages/plans'
import SelectedTextSectionsContextProvider from '~/providers/SelectedTextSectionsContextProvider'
import { Index } from '~/routes'
import { CommitsHistory } from '~/routes/business-developer/commits-history/CommitsHistory'
import { AddProcess } from '~/routes/business/add-process'
import { actionBusinessAddProcess } from '~/routes/business/add-process/action'
import { loaderBusinessAddProcess } from '~/routes/business/add-process/loader'
import { ConfigBusiness } from '~/routes/business/config-business'
import { loaderBusinessConfigBusiness } from '~/routes/business/config-business/loader'
import { ViewProcess } from '~/routes/business/view-process'
import { loaderBusinessViewProcess } from '~/routes/business/view-process/loader'
import { CodeGenerationStrategies } from '~/routes/configuration/code-generation-strategies'
import { CodeGenerationStrategiesAdd } from '~/routes/configuration/code-generation-strategies/add'
import { actionConfigurationGenerationStrategyCreate } from '~/routes/configuration/code-generation-strategies/add/action'
import { actionConfigurationGenerationStrategyDelete } from '~/routes/configuration/code-generation-strategies/delete/action'
import { CodeGenerationStrategiesEdit } from '~/routes/configuration/code-generation-strategies/edit'
import { actionCodeGenerationStrategyEdit } from '~/routes/configuration/code-generation-strategies/edit/action'
import { loaderCodeGenerationStrategyEdit } from '~/routes/configuration/code-generation-strategies/edit/loader'
import { loaderCodeGenerationStrategies } from '~/routes/configuration/code-generation-strategies/loader'
import { CodeGenerationStrategiesTest } from '~/routes/configuration/code-generation-strategies/test'
import { ConfigurationProfile } from '~/routes/configuration/profile'
import { ConfigurationProfileEdit } from '~/routes/configuration/profile/edit'
import { actionConfigurationProfileEdit } from '~/routes/configuration/profile/edit/action'
import { loaderConfigurationProfile } from '~/routes/configuration/profile/loader'
import { ConfigurationUser } from '~/routes/configuration/users'
import { ConfigurationOrganisationUsers } from '~/routes/configuration/users/add'
import { actionConfigurationOrganisationUserAdd } from '~/routes/configuration/users/add/action'
import { actionConfigurationOrganisationUserDelete } from '~/routes/configuration/users/delete/action'
import { loaderConfigurationOrganisationUsers } from '~/routes/configuration/users/loader'
import { DeveloperAddCommand } from '~/routes/developer/add-command'
import { actionDeveloperAddCommand } from '~/routes/developer/add-command/action'
import { loaderDeveloperAddCommand } from '~/routes/developer/add-command/loader'
import { DeveloperAddQuery } from '~/routes/developer/add-query'
import { actionDeveloperAddQuery } from '~/routes/developer/add-query/action'
import { loaderDeveloperAddQuery } from '~/routes/developer/add-query/loader'
import { DeveloperAddReaction } from '~/routes/developer/add-reaction'
import { actionDeveloperAddReaction } from '~/routes/developer/add-reaction/action'
import { loaderDeveloperAddReaction } from '~/routes/developer/add-reaction/loader'
import { DeveloperAddReadModel } from '~/routes/developer/add-readmodel'
import { actionDeveloperAddReadModel } from '~/routes/developer/add-readmodel/action'
import { loaderDeveloperAddReadModel } from '~/routes/developer/add-readmodel/loader'
import { DeveloperAddType } from '~/routes/developer/add-type'
import { actionDeveloperAddType } from '~/routes/developer/add-type/action'
import { loaderDeveloperAddType } from '~/routes/developer/add-type/loader'
import { DeveloperCloudProvider } from '~/routes/developer/cloud-provider'
import { actionDeveloperCloudProvider } from '~/routes/developer/cloud-provider/action'
import { ConfigCodeGenerationStrategies } from '~/routes/developer/code-generation-strategies'
import { actionConfigCodeGenerationStrategies } from '~/routes/developer/code-generation-strategies/action'
import { loaderConfigCodeGenerationStrategies } from '~/routes/developer/code-generation-strategies/loader'
import { DeveloperConfirm } from '~/routes/developer/confirm'
import { actionDeveloperConfirm } from '~/routes/developer/confirm/action'
import { loaderDeveloperConfirm } from '~/routes/developer/confirm/loader'
import { DeveloperDeleteCommand } from '~/routes/developer/delete-command'
import { actionDeveloperDeleteCommand } from '~/routes/developer/delete-command/action'
import { DeveloperDeleteQuery } from '~/routes/developer/delete-query'
import { actionDeveloperDeleteQuery } from '~/routes/developer/delete-query/action'
import { DeveloperDeleteReaction } from '~/routes/developer/delete-reaction'
import { actionDeveloperDeleteReaction } from '~/routes/developer/delete-reaction/action'
import { DeveloperDeleteReadModel } from '~/routes/developer/delete-readmodel'
import { actionDeveloperDeleteReadModel } from '~/routes/developer/delete-readmodel/action'
import { DeveloperDeleteType } from '~/routes/developer/delete-type'
import { actionDeveloperDeleteType } from '~/routes/developer/delete-type/action'
import { DeveloperDetails } from '~/routes/developer/details'
import { actionDeveloperDetails } from '~/routes/developer/details/action'
import { DeveloperEditCommand } from '~/routes/developer/edit-command'
import { actionDeveloperEditCommand } from '~/routes/developer/edit-command/action'
import { loaderDeveloperEditCommand } from '~/routes/developer/edit-command/loader'
import { DeveloperEditDetails } from '~/routes/developer/edit-details'
import { actionDeveloperEditDetails } from '~/routes/developer/edit-details/action'
import { loaderDeveloperEditDetails } from '~/routes/developer/edit-details/loader'
import { DeveloperEditQuery } from '~/routes/developer/edit-query'
import { actionDeveloperEditQuery } from '~/routes/developer/edit-query/action'
import { loaderDeveloperEditQuery } from '~/routes/developer/edit-query/loader'
import { DeveloperEditReaction } from '~/routes/developer/edit-reaction'
import { actionDeveloperEditReaction } from '~/routes/developer/edit-reaction/action'
import { loaderDeveloperEditReaction } from '~/routes/developer/edit-reaction/loader'
import { DeveloperEditReadModel } from '~/routes/developer/edit-readmodel'
import { actionDeveloperEditReadModel } from '~/routes/developer/edit-readmodel/action'
import { loaderDeveloperEditReadModel } from '~/routes/developer/edit-readmodel/loader'
import { DeveloperEditType } from '~/routes/developer/edit-type'
import { actionDeveloperEditType } from '~/routes/developer/edit-type/action'
import { loaderDeveloperEditType } from '~/routes/developer/edit-type/loader'
import { ConfigGenerateCode } from '~/routes/developer/generate-code'
import { actionConfigGenerateCode } from '~/routes/developer/generate-code/action'
import { loaderConfigGenerateCode } from '~/routes/developer/generate-code/loader'
import { DeveloperGitCredentials } from '~/routes/developer/git-credentials'
import { actionDeveloperGitCredentials } from '~/routes/developer/git-credentials/action'
import { loaderDeveloperGitCredentials } from '~/routes/developer/git-credentials/loader'
import { DeveloperHosting } from '~/routes/developer/hosting'
import { actionDeveloperHosting } from '~/routes/developer/hosting/action'
import { loaderDeveloperHosting } from '~/routes/developer/hosting/loader'
import { ContextPersistence as DeveloperPersistence } from '~/routes/developer/persistence'
import { actionDeveloperPersistence } from '~/routes/developer/persistence/action'
import { loaderDeveloperPersistence } from '~/routes/developer/persistence/loader'
import { DeveloperPublishAll } from '~/routes/developer/publish-all'
import { actionDeveloperPublishAll } from '~/routes/developer/publish-all/action'
import { loaderDeveloperPublishAll } from '~/routes/developer/publish-all/loader'
import { PublishCommand as DeveloperPublishCommand } from '~/routes/developer/publish-command'
import { actionDeveloperPublishCommand } from '~/routes/developer/publish-command/action'
import { loaderDeveloperPublishCommand } from '~/routes/developer/publish-command/loader'
import { PublishQuery } from '~/routes/developer/publish-query'
import { actionDeveloperPublishQuery } from '~/routes/developer/publish-query/action'
import { loaderDeveloperPublishQuery } from '~/routes/developer/publish-query/loader'
import { PublishReaction as DeveloperPublishReaction } from '~/routes/developer/publish-reaction'
import { actionDeveloperPublishReaction } from '~/routes/developer/publish-reaction/action'
import { loaderDeveloperPublishReaction } from '~/routes/developer/publish-reaction/loader'
import { PublishReadModel as DeveloperPublishReadModel } from '~/routes/developer/publish-readmodel'
import { actionDeveloperPublishReadModel } from '~/routes/developer/publish-readmodel/action'
import { loaderDeveloperPublishReadModel } from '~/routes/developer/publish-readmodel/loader'
import { Error } from '~/routes/error'
import { loaderIndex } from '~/routes/loader'
import { Onboarding } from '~/routes/onboarding'
import { loaderOnboarding } from '~/routes/onboarding/loader'
import { loaderRawRoot } from '~/routes/rawroot-loader'
import { Root } from '~/routes/root'
import { loaderRoot } from '~/routes/root/loader'
import { PlatformCloud } from '~/routes/setup/platform/cloud'
import { actionPlatformCloud } from '~/routes/setup/platform/cloud/action'
import { loaderPlatformCloud } from '~/routes/setup/platform/cloud/loader'
import { PlatformConfirm } from '~/routes/setup/platform/confirm'
import { actionPlatformConfirm } from '~/routes/setup/platform/confirm/action'
import { loaderPlatformConfirm } from '~/routes/setup/platform/confirm/loader'
import { PlatformGitCredentials } from '~/routes/setup/platform/git-credentials'
import { actionPlatformGitCredentials } from '~/routes/setup/platform/git-credentials/action'
import { loaderPlatformGitCredentials } from '~/routes/setup/platform/git-credentials/loader'
import { PlatformHosting } from '~/routes/setup/platform/hosting'
import { actionPlatformHosting } from '~/routes/setup/platform/hosting/action'
import { loaderPlatformHosting } from '~/routes/setup/platform/hosting/loader'
import { PlatformRouting } from '~/routes/setup/platform/routing'
import { actionPlatformRouting } from '~/routes/setup/platform/routing/action'
import { loaderPlatformRouting } from '~/routes/setup/platform/routing/loader'
import { Welcome } from '~/routes/welcome'
import { actionWelcome } from '~/routes/welcome/action'
import { Step1Organisation } from '~/routes/welcome/step1'
import { actionStep1 } from '~/routes/welcome/step1/action'
import { Step2Ecosystem } from '~/routes/welcome/step2'
import { actionStep2 } from '~/routes/welcome/step2/action'
import { loaderStep2 } from '~/routes/welcome/step2/loader'

type UseRouter = {
  queryClient: QueryClient
  msalInstance: PublicClientApplication
}
export const useRouter = ({ queryClient, msalInstance }: UseRouter) => {
  const router = React.useMemo(() => {
    const routes = [
      {
        path: '/',
        element: <Index />,
        loader: loaderIndex(queryClient, msalInstance),
        errorElement: <Error />,
      },
      {
        path: 'welcome',
        element: <Root />,
        errorElement: <Error />,
        loader: loaderRoot(queryClient, msalInstance),
        children: [
          {
            index: true,
            element: <Welcome />,
            action: actionWelcome(queryClient, msalInstance),
          },
          {
            path: 'step1',
            element: <Step1Organisation />,
            action: actionStep1(queryClient, msalInstance),
          },
          {
            path: 'step2',
            element: <Step2Ecosystem />,
            loader: loaderStep2(queryClient, msalInstance),
            action: actionStep2(queryClient, msalInstance),
          },
        ],
      },
      {
        path: 'onboarding',
        element: <Onboarding />,
        loader: loaderOnboarding(queryClient, msalInstance),
      },

      FEATURE_TOGGLE.MULTIPLE_ORGS
        ? {
            path: ':organisationId',
            loader: loaderRawRoot,
            children: [
              {
                element: <CommonLayout />,
                loader: loaderCommonLayout(queryClient, msalInstance),
                id: 'configuration-root',
                path: ':platformId/configuration',
                errorElement: <Error />,
                children: [
                  FEATURE_TOGGLE.CODE_GENERATION_STRATEGIES
                    ? {
                        path: 'code-generation-strategies',
                        element: <CodeGenerationStrategies />,
                        id: 'code-generation-strategies',
                        loader: loaderCodeGenerationStrategies(
                          queryClient,
                          msalInstance,
                        ),
                        children: [
                          {
                            path: 'add',
                            element: <CodeGenerationStrategiesAdd />,
                            action: actionConfigurationGenerationStrategyCreate(
                              queryClient,
                              msalInstance,
                            ),
                          },
                          {
                            path: 'delete',
                            action: actionConfigurationGenerationStrategyDelete(
                              queryClient,
                              msalInstance,
                            ),
                          },
                        ],
                      }
                    : {},
                  FEATURE_TOGGLE.CODE_GENERATION_STRATEGIES
                    ? {
                        path: 'code-generation-strategies/test/:generationStrategyId',
                        element: <CodeGenerationStrategiesTest />,
                        loader: loaderCodeGenerationStrategyEdit(
                          queryClient,
                          msalInstance,
                        ),
                        action: actionCodeGenerationStrategyEdit(
                          queryClient,
                          msalInstance,
                        ),
                      }
                    : {},
                  FEATURE_TOGGLE.CODE_GENERATION_STRATEGIES
                    ? {
                        path: 'code-generation-strategies/edit/:generationStrategyId',
                        element: (
                          <SelectedTextSectionsContextProvider>
                            <CodeGenerationStrategiesEdit />
                          </SelectedTextSectionsContextProvider>
                        ),
                        id: 'code-edit-generation-strategy',
                        loader: loaderCodeGenerationStrategyEdit(
                          queryClient,
                          msalInstance,
                        ),
                        action: actionCodeGenerationStrategyEdit(
                          queryClient,
                          msalInstance,
                        ),
                      }
                    : {},
                  {
                    path: 'users/:organisationId?',
                    element: <ConfigurationUser />,
                    id: 'configuration-organisation-users',
                    loader: loaderConfigurationOrganisationUsers(
                      queryClient,
                      msalInstance,
                    ),
                    children: [
                      {
                        path: 'add',
                        element: <ConfigurationOrganisationUsers />,
                        action: actionConfigurationOrganisationUserAdd(
                          queryClient,
                          msalInstance,
                        ),
                      },
                      {
                        path: 'delete',
                        action: actionConfigurationOrganisationUserDelete(
                          queryClient,
                          msalInstance,
                        ),
                      },
                    ],
                  },
                  {
                    path: 'profile',
                    element: <ConfigurationProfile />,
                    id: 'configuration-profile',
                    loader: loaderConfigurationProfile(
                      queryClient,
                      msalInstance,
                    ),
                    children: [
                      {
                        path: 'edit',
                        element: <ConfigurationProfileEdit />,
                        action: actionConfigurationProfileEdit(
                          queryClient,
                          msalInstance,
                        ),
                      },
                    ],
                  },
                ],
              },
            ],
          }
        : {},

      FEATURE_TOGGLE.UPGRADE_PLAN
        ? {
            path: 'plans',
            element: <Plans />,
          }
        : {},

      {
        path: ':organisationId',
        loader: loaderRawRoot,
        children: [
          {
            element: <DeveloperLayout />,
            errorElement: <Error />,
            loader: loaderDeveloperLayout(queryClient, msalInstance),
            children: [
              {
                path: ':platformId/developer/:boundedContext?/:aggregateId?',
                element: <Developer />,
                loader: loaderDeveloper(queryClient, msalInstance),
                id: 'developer-root',
                children: [
                  {
                    path: 'details',
                    element: <DeveloperDetails />,
                    action: actionDeveloperDetails(queryClient, msalInstance),
                  },
                  {
                    path: 'edit-details',
                    element: <DeveloperEditDetails />,
                    loader: loaderDeveloperEditDetails(queryClient),
                    action: actionDeveloperEditDetails(
                      queryClient,
                      msalInstance,
                    ),
                  },
                  {
                    path: `${BusinessDeveloperRoutesEnum.EDIT_AGGREGATE}/${BusinessDeveloperRoutesEnum.COMMITS_HISTORY}`,
                    element: <CommitsHistory showAggregateHistory={true} />,
                    action: actionDeveloperEditDetails(
                      queryClient,
                      msalInstance,
                    ),
                  },
                  {
                    path: 'add-command',
                    element: <DeveloperAddCommand />,
                    action: actionDeveloperAddCommand(
                      queryClient,
                      msalInstance,
                    ),
                    loader: loaderDeveloperAddCommand,
                  },
                  {
                    path: 'add-reaction',
                    element: <DeveloperAddReaction />,
                    action: actionDeveloperAddReaction(
                      queryClient,
                      msalInstance,
                    ),
                    loader: loaderDeveloperAddReaction(queryClient),
                  },
                  {
                    path: BusinessDeveloperRoutesEnum.EDIT_COMMAND,
                    element: (
                      <DeveloperEditCommand showBoundedContextEdit={false} />
                    ),
                    action: actionDeveloperEditCommand(
                      queryClient,
                      msalInstance,
                    ),
                    loader: loaderDeveloperEditCommand(
                      queryClient,
                      msalInstance,
                    ),
                  },
                  {
                    path: `${BusinessDeveloperRoutesEnum.EDIT_COMMAND}/${BusinessDeveloperRoutesEnum.COMMITS_HISTORY}`,
                    element: <CommitsHistory />,
                  },
                  {
                    path: BusinessDeveloperRoutesEnum.EDIT_REACTION,
                    element: (
                      <DeveloperEditReaction showBoundedContextEdit={false} />
                    ),
                    action: actionDeveloperEditReaction(
                      queryClient,
                      msalInstance,
                    ),
                    loader: loaderDeveloperEditReaction(queryClient),
                  },
                  {
                    path: `${BusinessDeveloperRoutesEnum.EDIT_REACTION}/${BusinessDeveloperRoutesEnum.COMMITS_HISTORY}`,
                    element: <CommitsHistory />,
                  },
                  {
                    path: BusinessDeveloperRoutesEnum.EDIT_READ_MODEL,
                    element: <DeveloperEditReadModel />,
                    action: actionDeveloperEditReadModel(
                      queryClient,
                      msalInstance,
                    ),
                    loader: loaderDeveloperEditReadModel(queryClient),
                  },
                  {
                    path: `${BusinessDeveloperRoutesEnum.EDIT_READ_MODEL}/${BusinessDeveloperRoutesEnum.COMMITS_HISTORY}`,
                    element: <CommitsHistory />,
                  },
                  {
                    path: `${BusinessDeveloperRoutesEnum.EDIT_QUERY}/${BusinessDeveloperRoutesEnum.COMMITS_HISTORY}`,
                    element: <CommitsHistory />,
                    action: actionDeveloperEditQuery(queryClient, msalInstance),
                  },
                  {
                    path: 'delete-command/:commandId',
                    element: <DeveloperDeleteCommand />,
                    action: actionDeveloperDeleteCommand(queryClient),
                  },
                  {
                    path: 'delete-reaction/:reactionId',
                    element: <DeveloperDeleteReaction />,
                    action: actionDeveloperDeleteReaction(queryClient),
                  },
                  {
                    path: 'delete-readmodel/:readModelId',
                    element: <DeveloperDeleteReadModel />,
                    action: actionDeveloperDeleteReadModel(queryClient),
                  },
                  {
                    path: 'add-readmodel',
                    element: <DeveloperAddReadModel />,
                    action: actionDeveloperAddReadModel(
                      queryClient,
                      msalInstance,
                    ),
                    loader: loaderDeveloperAddReadModel,
                  },
                  {
                    path: 'add-type',
                    element: <DeveloperAddType />,
                    action: actionDeveloperAddType(queryClient, msalInstance),
                    loader: loaderDeveloperAddType,
                  },
                  {
                    path: 'edit-type/:typeId',
                    element: <DeveloperEditType />,
                    action: actionDeveloperEditType(queryClient, msalInstance),
                    loader: loaderDeveloperEditType(queryClient),
                  },
                  {
                    path: `${BusinessDeveloperRoutesEnum.EDIT_TYPE}/${BusinessDeveloperRoutesEnum.COMMITS_HISTORY}`,
                    element: <CommitsHistory />,
                  },
                  {
                    path: 'delete-type/:typeId',
                    element: <DeveloperDeleteType />,
                    action: actionDeveloperDeleteType(queryClient),
                  },
                  {
                    path: 'config-cloud-provider',
                    element: <DeveloperCloudProvider />,
                    action: actionDeveloperCloudProvider(
                      queryClient,
                      msalInstance,
                    ),
                  },
                  {
                    path: BusinessDeveloperRoutesEnum.ADD_QUERY,
                    element: <DeveloperAddQuery />,
                    action: actionDeveloperAddQuery(queryClient, msalInstance),
                    loader: loaderDeveloperAddQuery,
                  },
                  {
                    path: BusinessDeveloperRoutesEnum.EDIT_QUERY,
                    element: <DeveloperEditQuery />,
                    action: actionDeveloperEditQuery(queryClient, msalInstance),
                    loader: loaderDeveloperEditQuery(queryClient),
                  },
                  {
                    path: BusinessDeveloperRoutesEnum.DELETE_QUERY,
                    element: <DeveloperDeleteQuery />,
                    action: actionDeveloperDeleteQuery(queryClient),
                  },
                  {
                    element: <CodeGenerationPages />,
                    children: [
                      {
                        path: 'edit-code-generation-strategies',
                        element: <ConfigCodeGenerationStrategies />,
                        loader: loaderConfigCodeGenerationStrategies(
                          queryClient,
                          msalInstance,
                        ),
                        action: actionConfigCodeGenerationStrategies(
                          queryClient,
                          msalInstance,
                        ),
                      },
                      FEATURE_TOGGLE.CODE_GENERATION_STRATEGIES_CONFIG
                        ? {
                            path: 'generate-code/:eventType?/:eventId?',
                            element: <ConfigGenerateCode />,
                            loader: loaderConfigGenerateCode(
                              queryClient,
                              msalInstance,
                            ),
                            action: actionConfigGenerateCode(
                              queryClient,
                              msalInstance,
                            ),
                          }
                        : {},
                      {
                        path: 'hosting',
                        element: <DeveloperHosting />,
                        loader: loaderDeveloperHosting(
                          queryClient,
                          msalInstance,
                        ),
                        action: actionDeveloperHosting(
                          queryClient,
                          msalInstance,
                        ),
                      },
                      {
                        path: 'edit-hosting',
                        element: <DeveloperHosting />,
                        loader: loaderDeveloperHosting(
                          queryClient,
                          msalInstance,
                        ),
                        action: actionDeveloperHosting(
                          queryClient,
                          msalInstance,
                        ),
                      },
                      {
                        path: 'persistence',
                        element: <DeveloperPersistence />,
                        loader: loaderDeveloperPersistence(
                          queryClient,
                          msalInstance,
                        ),
                        action: actionDeveloperPersistence(queryClient),
                      },
                      {
                        path: 'edit-persistence',
                        element: <DeveloperPersistence />,
                        loader: loaderDeveloperPersistence(
                          queryClient,
                          msalInstance,
                        ),
                        action: actionDeveloperPersistence(queryClient),
                      },
                      {
                        path: 'git-credentials',
                        element: <DeveloperGitCredentials />,
                        loader: loaderDeveloperGitCredentials(queryClient),
                        action: actionDeveloperGitCredentials(queryClient),
                      },
                      {
                        path: 'edit-git-credentials',
                        element: <DeveloperGitCredentials />,
                        loader: loaderDeveloperGitCredentials(queryClient),
                        action: actionDeveloperGitCredentials(queryClient),
                      },
                      {
                        path: 'confirm',
                        element: <DeveloperConfirm />,
                        loader: loaderDeveloperConfirm(queryClient),
                        action: actionDeveloperConfirm(
                          queryClient,
                          msalInstance,
                        ),
                      },
                      {
                        path: 'publish-command/:commandId',
                        element: <DeveloperPublishCommand />,
                        action: actionDeveloperPublishCommand(
                          queryClient,
                          msalInstance,
                        ),
                        loader: loaderDeveloperPublishCommand(queryClient),
                      },
                      {
                        path: 'publish-reaction/:reactionId',
                        element: <DeveloperPublishReaction />,
                        action: actionDeveloperPublishReaction(
                          queryClient,
                          msalInstance,
                        ),
                        loader: loaderDeveloperPublishReaction(queryClient),
                      },
                      {
                        path: 'publish-readmodel/:readModelId',
                        element: <DeveloperPublishReadModel />,
                        action: actionDeveloperPublishReadModel(
                          queryClient,
                          msalInstance,
                        ),
                        loader: loaderDeveloperPublishReadModel(queryClient),
                      },
                      {
                        path: 'publish-all',
                        element: <DeveloperPublishAll />,
                        action: actionDeveloperPublishAll(
                          queryClient,
                          msalInstance,
                        ),
                        loader: loaderDeveloperPublishAll(queryClient),
                      },
                      {
                        path: BusinessDeveloperRoutesEnum.PUBLISH_QUERY,
                        element: <PublishQuery />,
                        action: actionDeveloperPublishQuery(
                          queryClient,
                          msalInstance,
                        ),
                        loader: loaderDeveloperPublishQuery(queryClient),
                      },
                      {
                        path: 'platform-setup',
                        element: <PlatformSetupModal />,
                        loader: loaderPlatformSetup(queryClient, msalInstance),
                        children: [
                          {
                            index: true,
                            element: <PlatformCloud />,
                            loader: loaderPlatformCloud(
                              queryClient,
                              msalInstance,
                            ),
                            action: actionPlatformCloud(
                              queryClient,
                              msalInstance,
                            ),
                          },
                          {
                            path: 'routing',
                            element: <PlatformRouting />,
                            loader: loaderPlatformRouting(
                              queryClient,
                              msalInstance,
                            ),
                            action: actionPlatformRouting(
                              queryClient,
                              msalInstance,
                            ),
                          },
                          {
                            path: 'hosting',
                            element: <PlatformHosting />,
                            loader: loaderPlatformHosting(
                              queryClient,
                              msalInstance,
                            ),
                            action: actionPlatformHosting(
                              queryClient,
                              msalInstance,
                            ),
                          },
                          {
                            path: 'git-credentials',
                            element: <PlatformGitCredentials />,
                            loader: loaderPlatformGitCredentials(
                              queryClient,
                              msalInstance,
                            ),
                            action: actionPlatformGitCredentials(
                              queryClient,
                              msalInstance,
                            ),
                          },
                          {
                            path: 'confirm',
                            element: <PlatformConfirm />,
                            loader: loaderPlatformConfirm(
                              queryClient,
                              msalInstance,
                            ),
                            action: actionPlatformConfirm(
                              queryClient,
                              msalInstance,
                            ),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            element: <BusinessLayout />,
            errorElement: <Error />,
            loader: loaderBusinessLayout(queryClient, msalInstance),
            children: [
              {
                path: ':platformId/business/:processId?',
                element: <Business />,
                id: 'business-root',
                loader: loaderBusiness(queryClient, msalInstance),
                children: [
                  {
                    path: 'config-business',
                    element: <ConfigBusiness />,
                    loader: loaderBusinessConfigBusiness(
                      queryClient,
                      msalInstance,
                    ),
                    children: [
                      {
                        path: 'add-process',
                        element: <AddProcess />,
                        action: actionBusinessAddProcess(
                          queryClient,
                          msalInstance,
                        ),
                        loader: loaderBusinessAddProcess(
                          queryClient,
                          msalInstance,
                        ),
                      },
                    ],
                  },
                  {
                    path: 'add-process',
                    element: <AddProcess />,
                    action: actionBusinessAddProcess(queryClient, msalInstance),
                    loader: loaderBusinessAddProcess(queryClient, msalInstance),
                  },
                  {
                    path: 'view-process',
                    element: <ViewProcess />,
                    loader: loaderBusinessViewProcess(
                      queryClient,
                      msalInstance,
                    ),
                  },
                  {
                    path: BusinessDeveloperRoutesEnum.EDIT_COMMAND,
                    element: (
                      <DeveloperEditCommand showBoundedContextEdit={true} />
                    ),
                    action: actionDeveloperEditCommand(
                      queryClient,
                      msalInstance,
                    ),
                    loader: loaderDeveloperEditCommand(
                      queryClient,
                      msalInstance,
                    ),
                  },
                  {
                    path: `${BusinessDeveloperRoutesEnum.EDIT_COMMAND}/${BusinessDeveloperRoutesEnum.COMMITS_HISTORY}`,
                    element: <CommitsHistory />,
                    action: actionDeveloperEditCommand(
                      queryClient,
                      msalInstance,
                    ),
                  },
                  {
                    path: BusinessDeveloperRoutesEnum.EDIT_REACTION,
                    element: (
                      <DeveloperEditReaction showBoundedContextEdit={true} />
                    ),
                    action: actionDeveloperEditReaction(
                      queryClient,
                      msalInstance,
                    ),
                    loader: loaderDeveloperEditReaction(queryClient),
                  },
                  {
                    path: `${BusinessDeveloperRoutesEnum.EDIT_REACTION}/${BusinessDeveloperRoutesEnum.COMMITS_HISTORY}`,
                    element: <CommitsHistory />,
                    action: actionDeveloperEditReaction(
                      queryClient,
                      msalInstance,
                    ),
                  },
                  {
                    path: BusinessDeveloperRoutesEnum.EDIT_READ_MODEL,
                    element: <DeveloperEditReadModel />,
                    action: actionDeveloperEditReadModel(
                      queryClient,
                      msalInstance,
                    ),
                    loader: loaderDeveloperEditReadModel(queryClient),
                  },
                  {
                    path: 'add-readmodel',
                    element: <DeveloperAddReadModel />,
                    action: actionDeveloperAddReadModel(
                      queryClient,
                      msalInstance,
                    ),
                    loader: loaderDeveloperAddReadModel,
                  },
                  {
                    path: 'delete-readmodel/:readModelId/:aggregateId',
                    element: <DeveloperDeleteReadModel />,
                    action: actionDeveloperDeleteReadModel(queryClient),
                  },
                  {
                    path: 'add-type',
                    element: <DeveloperAddType />,
                    action: actionDeveloperAddType(queryClient, msalInstance),
                    loader: loaderDeveloperAddType,
                  },
                  {
                    path: 'edit-type/:typeId',
                    element: <DeveloperEditType />,
                    action: actionDeveloperEditType(queryClient, msalInstance),
                    loader: loaderDeveloperEditType(queryClient),
                  },
                  {
                    path: 'delete-type/:typeId/:aggregateId',
                    element: <DeveloperDeleteType />,
                    action: actionDeveloperDeleteType(queryClient),
                  },
                  {
                    path: BusinessDeveloperRoutesEnum.ADD_QUERY,
                    element: <DeveloperAddQuery />,
                    action: actionDeveloperAddQuery(queryClient, msalInstance),
                    loader: loaderDeveloperAddQuery,
                  },
                  {
                    path: ':aggregateId',
                    element: <CodeGenerationPages />,
                    children: [
                      FEATURE_TOGGLE.CODE_GENERATION_STRATEGIES_CONFIG
                        ? {
                            path: 'generate-code/:eventType?/:eventId?',
                            element: <ConfigGenerateCode />,
                            loader: loaderConfigGenerateCode(
                              queryClient,
                              msalInstance,
                            ),
                            action: actionConfigGenerateCode(
                              queryClient,
                              msalInstance,
                            ),
                          }
                        : {},
                      {
                        path: 'hosting',
                        element: <DeveloperHosting />,
                        loader: loaderDeveloperHosting(
                          queryClient,
                          msalInstance,
                        ),
                        action: actionDeveloperHosting(
                          queryClient,
                          msalInstance,
                        ),
                      },
                      {
                        path: 'edit-hosting',
                        element: <DeveloperHosting />,
                        loader: loaderDeveloperHosting(
                          queryClient,
                          msalInstance,
                        ),
                        action: actionDeveloperHosting(
                          queryClient,
                          msalInstance,
                        ),
                      },
                      {
                        path: 'persistence',
                        element: <DeveloperPersistence />,
                        loader: loaderDeveloperPersistence(
                          queryClient,
                          msalInstance,
                        ),
                        action: actionDeveloperPersistence(queryClient),
                      },
                      {
                        path: 'edit-persistence',
                        element: <DeveloperPersistence />,
                        loader: loaderDeveloperPersistence(
                          queryClient,
                          msalInstance,
                        ),
                        action: actionDeveloperPersistence(queryClient),
                      },
                      {
                        path: 'git-credentials',
                        element: <DeveloperGitCredentials />,
                        loader: loaderDeveloperGitCredentials(queryClient),
                        action: actionDeveloperGitCredentials(queryClient),
                      },
                      {
                        path: 'edit-git-credentials',
                        element: <DeveloperGitCredentials />,
                        loader: loaderDeveloperGitCredentials(queryClient),
                        action: actionDeveloperGitCredentials(queryClient),
                      },
                      {
                        path: 'confirm',
                        element: <DeveloperConfirm />,
                        loader: loaderDeveloperConfirm(queryClient),
                        action: actionDeveloperConfirm(
                          queryClient,
                          msalInstance,
                        ),
                      },
                      {
                        path: 'publish-command/:commandId',
                        element: <DeveloperPublishCommand />,
                        action: actionDeveloperPublishCommand(
                          queryClient,
                          msalInstance,
                        ),
                        loader: loaderDeveloperPublishCommand(queryClient),
                      },
                      {
                        path: 'publish-reaction/:reactionId',
                        element: <DeveloperPublishReaction />,
                        action: actionDeveloperPublishReaction(
                          queryClient,
                          msalInstance,
                        ),
                        loader: loaderDeveloperPublishReaction(queryClient),
                      },
                      {
                        path: 'publish-readmodel/:readModelId',
                        element: <DeveloperPublishReadModel />,
                        action: actionDeveloperPublishReadModel(
                          queryClient,
                          msalInstance,
                        ),
                        loader: loaderDeveloperPublishReadModel(queryClient),
                      },
                      {
                        path: BusinessDeveloperRoutesEnum.PUBLISH_QUERY,
                        element: <PublishQuery />,
                        action: actionDeveloperPublishQuery(
                          queryClient,
                          msalInstance,
                        ),
                        loader: loaderDeveloperPublishQuery(queryClient),
                      },
                      {
                        path: 'publish-all',
                        element: <DeveloperPublishAll />,
                        action: actionDeveloperPublishAll(
                          queryClient,
                          msalInstance,
                        ),
                        loader: loaderDeveloperPublishAll(queryClient),
                      },
                      {
                        path: 'platform-setup',
                        element: <PlatformSetupModal />,
                        loader: loaderPlatformSetup(queryClient, msalInstance),
                        children: [
                          {
                            index: true,
                            element: <PlatformCloud />,
                            loader: loaderPlatformCloud(
                              queryClient,
                              msalInstance,
                            ),
                            action: actionPlatformCloud(
                              queryClient,
                              msalInstance,
                            ),
                          },
                          {
                            path: 'routing',
                            element: <PlatformRouting />,
                            loader: loaderPlatformRouting(
                              queryClient,
                              msalInstance,
                            ),
                            action: actionPlatformRouting(
                              queryClient,
                              msalInstance,
                            ),
                          },
                          {
                            path: 'hosting',
                            element: <PlatformHosting />,
                            loader: loaderPlatformHosting(
                              queryClient,
                              msalInstance,
                            ),
                            action: actionPlatformHosting(
                              queryClient,
                              msalInstance,
                            ),
                          },
                          {
                            path: 'git-credentials',
                            element: <PlatformGitCredentials />,
                            loader: loaderPlatformGitCredentials(
                              queryClient,
                              msalInstance,
                            ),
                            action: actionPlatformGitCredentials(
                              queryClient,
                              msalInstance,
                            ),
                          },
                          {
                            path: 'confirm',
                            element: <PlatformConfirm />,
                            loader: loaderPlatformConfirm(
                              queryClient,
                              msalInstance,
                            ),
                            action: actionPlatformConfirm(
                              queryClient,
                              msalInstance,
                            ),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ]

    const returnRoutes = createBrowserRouter(routes)

    return returnRoutes
  }, [queryClient, msalInstance])

  return router
}
