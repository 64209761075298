import tailwindConfig from '^/tailwind.config'
import resolveConfig from 'tailwindcss/resolveConfig'

const fullConfig = resolveConfig(tailwindConfig)

type ServiceMapThemeColors = {
  /** Color for the edge. */
  line: string
  /** Color for the edge label. */
  label: string
}

export type ServiceMapThemePalette = {
  publishEdgeColors: ServiceMapThemeColors
  subscribeEdgeColors: ServiceMapThemeColors
  typeRelationshipColors: ServiceMapThemeColors
}

export type ServiceMapTheme = {
  dark: ServiceMapThemePalette
  light: ServiceMapThemePalette
}

export const colors: ServiceMapTheme = {
  dark: {
    publishEdgeColors: {
      line: '#FF00D6',
      label: '#FF51E3',
    },
    subscribeEdgeColors: {
      line: '#00FFD1',
      label: '#78F8E1',
    },
    typeRelationshipColors: {
      line: fullConfig.theme.colors.gray[300],
      label: fullConfig.theme.colors.gray[500],
    },
  },
  light: {
    publishEdgeColors: {
      line: '#EC26CC',
      label: '#8A069F',
    },
    subscribeEdgeColors: {
      line: '#2AD6B7',
      label: '#147C69',
    },
    typeRelationshipColors: {
      line: fullConfig.theme.colors.gray[300],
      label: fullConfig.theme.colors.gray[500],
    },
  },
}
