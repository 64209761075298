import {
  Button,
  FormControl,
  FormLabel,
  TextField,
  type ButtonProps,
} from '@mui/material'
import { twMerge } from '^/tailwind.config'
import { useEffect, useState, type ChangeEvent } from 'react'
import {
  Controller,
  useWatch,
  type Control,
  type ControllerRenderProps,
  type FieldValues,
} from 'react-hook-form'

type AddTypeButtonGroupProps = {
  /** The class name applied to the containing node. */
  className?: string
  /** The form field control from react hook form. */
  control: Control<any>
  /** Indicates if the buttons should be disabled. */
  disabled?: boolean
  /** Any errors related to this field. */
  error?: string
  /** The class name applied to the button group node. */
  groupClassName?: string
  /**
   * Indicates if there will be an `other` field:
   * This option will have a text field inside
   * to type a customized value.
   */
  hasOtherOption?: boolean
  /** The field set label. */
  label?: string
  /** The field label. */
  name: string
  /** The field options. */
  options: string[]
}

/**
 * A form button group for add type component.
 */
export const AddTypeButtonGroup = (props: AddTypeButtonGroupProps) => {
  const {
    className,
    control,
    disabled,
    error,
    groupClassName,
    hasOtherOption,
    label,
    name,
    options,
  } = props

  // Local state to manage the value of the "Other" text field.
  const [otherValue, setOtherValue] = useState('')

  // Watch the form value for this field.
  const value = useWatch({
    control,
    name,
  })

  // Check if the "Other" option is currently selected.
  const isOtherSelected = value && !options.includes(value)

  // Synchronize the "Other" text field with the form value when "Other" is selected.
  useEffect(() => {
    if (isOtherSelected && value !== otherValue) setOtherValue(value)
  }, [value, isOtherSelected])

  // Methods.
  const handleOtherButtonClick = (
    field: ControllerRenderProps<FieldValues, string>,
  ) => {
    // Select "Other" option when the button is clicked.
    if (!isOtherSelected) field.onChange(otherValue)
  }

  const handleOtherFieldChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: ControllerRenderProps<FieldValues, string>,
  ) => {
    const newValue = e.target.value
    setOtherValue(newValue)

    // Update form value with the value of the "Other" text field.
    field.onChange(newValue)
  }

  const handleOtherFieldFocus = (
    field: ControllerRenderProps<FieldValues, string>,
  ) => {
    // Select "Other" option when the text field is focused.
    if (!isOtherSelected) field.onChange(otherValue)
  }

  // Button constants.
  const buttonProps = {
    disableElevation: true,
    disableRipple: true,
  } as Partial<ButtonProps>

  const btnStyles = (isSelected: boolean) =>
    twMerge(
      'not-disabled:bg-white not-disabled:text-secondary not-disabled:border-secondary',
      isSelected && 'not-disabled:bg-secondary not-disabled:text-white',
    )

  return (
    <FormControl
      className={twMerge(
        'rounded border-[1px] border-solid border-primary bg-slate-200 p-3',
        !!error && 'border-red-500',
        className,
      )}
      component="fieldset"
      fullWidth
    >
      {label && <FormLabel component="legend">{label}</FormLabel>}

      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <div className={twMerge('grid gap-2', groupClassName)}>
            {options.map((option) => (
              <Button
                {...buttonProps}
                className={btnStyles(value === option)}
                disabled={disabled}
                key={option}
                onClick={() => field.onChange(option)}
                variant={value === option ? 'contained' : 'outlined'}
              >
                {option}
              </Button>
            ))}

            {hasOtherOption && (
              <Button
                {...buttonProps}
                className={btnStyles(isOtherSelected)}
                disabled={disabled}
                onClick={() => handleOtherButtonClick(field)}
                variant={isOtherSelected ? 'contained' : 'outlined'}
              >
                <TextField
                  disabled={disabled}
                  inputProps={{ className: 'p-0 text-center' }}
                  InputProps={{
                    className: twMerge(
                      'bg-transparent text-secondary p-0',
                      isOtherSelected && 'text-white',
                    ),
                    disableUnderline: true,
                  }}
                  onChange={(e) => handleOtherFieldChange(e, field)}
                  onClick={(e) => e.stopPropagation()}
                  onFocus={() => handleOtherFieldFocus(field)}
                  placeholder="Other"
                  value={otherValue}
                  variant="standard"
                />
              </Button>
            )}
          </div>
        )}
      />

      {!!error && (
        <span className="ml-1 mt-2 text-xs text-red-600">{error}</span>
      )}
    </FormControl>
  )
}
