type ActionIconProps = React.SVGProps<SVGSVGElement> & {
  /**
   * In case the font size is set to `custom`,
   * the custom dimensions needs to be set via
   * `height` and `width` props.
   */
  fontSize?: 'medium' | 'small' | 'custom'
}

export function ActionIcon({
  fontSize = 'medium',
  height = 24,
  width = 24,
  ...props
}: ActionIconProps) {
  const sizes = {
    ['medium']: {
      width: 24,
      height: 24,
    },
    ['small']: {
      width: 12,
      height: 12,
    },
    ['custom']: {
      width,
      height,
    },
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...sizes[fontSize]}
      {...props}
    >
      <path
        fill="#B6F1E6"
        stroke="#080D97"
        d="M19 12 6.25 19.794V4.206L19 12Z"
      />
    </svg>
  )
}
