import { Highlight, themes } from 'prism-react-renderer'
import {
  Fragment,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  type RefCallback,
} from 'react'
import type { ControllerRenderProps, FieldValues } from 'react-hook-form'
import Editor from 'react-simple-code-editor'
import type { MappingConditionFormEnum } from '~/models/enums/forms/MappingConditionFormEnum'

const theme = themes.nightOwlLight

const styles = {
  root: {
    boxSizing: 'border-box',
    fontFamily: '"Dank Mono", "Fira Code", monospace',
    backgroundColor: theme.plain.backgroundColor,
    fontSize: '14px',
  },
} as const

type ExpressionEditorProps = ControllerRenderProps<
  FieldValues,
  MappingConditionFormEnum
> & {
  /** The controlled field ref. */
  fieldRef: RefCallback<HTMLTextAreaElement>
  /** The name of the field. */
  name: string
  /** The callback handler for blur. */
  onBlur?: () => void
  /** The callback handler for change. */
  onChange: (value: string) => void
  /** The callback handler for context menu. */
  onContextMenu?: (event: EventFor<'div', 'onContextMenu'>) => void
  /** The placeholder for the field. */
  placeholder?: string
  /** The value for the field. */
  value: string
}

/**
 * IMPORTANT: The `ref` will be pointed to the textarea
 * part of the `Editor` component.
 */
export const ExpressionEditor = forwardRef<
  HTMLTextAreaElement,
  ExpressionEditorProps
>((props: ExpressionEditorProps, ref) => {
  const {
    fieldRef,
    name,
    onBlur,
    onChange,
    onContextMenu,
    value,
    placeholder,
    ...rest
  } = props

  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (wrapperRef.current) {
      const textarea = wrapperRef.current.querySelector('textarea')
      if (textarea) {
        fieldRef(textarea)

        if (typeof ref === 'function') {
          ref(textarea)
        } else if (ref) {
          ref.current = textarea
        }
      }
    }
  }, [ref])

  const highlight = useCallback(
    (code: string) => (
      <Highlight code={code} theme={theme} language="jsx">
        {({ tokens, getLineProps, getTokenProps }) => (
          <Fragment>
            {tokens.map((line, i) => (
              <div key={i} {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </Fragment>
        )}
      </Highlight>
    ),
    [],
  )

  return (
    <div ref={wrapperRef}>
      <Editor
        {...rest}
        className="rounded border border-solid border-gray-300 hover:border-secondary focus:border-primary"
        highlight={highlight}
        name={name}
        onBlur={onBlur}
        onContextMenu={onContextMenu}
        onValueChange={onChange}
        padding={10}
        placeholder={placeholder}
        style={styles.root}
        value={value}
      />
    </div>
  )
})
