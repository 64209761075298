import { Stack, TextField } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Form, useActionData, useLoaderData, useParams } from 'react-router-dom'
import { ButtonAutoGenerate } from '~/components/ButtonAutoGenerate'
import { FormActionName } from '~/pages/developer/components/FormActionName/index'
import { FormAttributes } from '~/pages/developer/components/FormAttributes'
import { FormFooter } from '~/pages/developer/components/FormFooter'
import { DeveloperChildrenParams } from '~/routes/developer/routes.types'
import { queryDevelopment } from '~/services/Development'
import { Attribute, DomainByPlatformId } from '~/services/Development.types'
import { typesByAggregate } from '~/utils/transform'
import { useAggregateActionForm } from '../hooks/useAggregateActionForm'
import { renamePropertiesToAttributes } from '../utils'

export function DeveloperEditType() {
  const dataAutoGeneratedAttributes = useActionData() as {
    formName: string
    attributes: Attribute[]
  }
  const params = useParams<DeveloperChildrenParams & { typeId: GUID }>()
  const initialData = useLoaderData() as Awaited<
    AxiosResponse<DomainByPlatformId | null, unknown>
  >
  const { data } = useQuery({
    ...queryDevelopment(params.platformId),
    initialData,
    select: (response) => response.data,
  })

  // Hooks.
  const { formProps, submitButtonRef } = useAggregateActionForm()

  const types = typesByAggregate(data)

  const selectedType = useMemo(
    () => types.find((type) => type.identity === params.typeId),
    [params.typeId, types],
  )

  const methods = useForm({
    defaultValues: {
      action: {
        name: selectedType?.name,
        family: selectedType?.family,
        attributes: renamePropertiesToAttributes(
          dataAutoGeneratedAttributes?.attributes || selectedType?.attributes,
        ),
      },
    },
  })

  useEffect(() => {
    if (
      dataAutoGeneratedAttributes &&
      dataAutoGeneratedAttributes?.formName === 'type' &&
      dataAutoGeneratedAttributes?.attributes.length > 0
    ) {
      methods.setValue(
        'action.attributes',
        renamePropertiesToAttributes(dataAutoGeneratedAttributes?.attributes),
      )
    }
  }, [dataAutoGeneratedAttributes, methods])

  return (
    <Stack gap={2} sx={{ pb: 6 }}>
      <FormProvider {...methods}>
        <Form method="post" id="form-aggregate-edit-type" {...formProps}>
          <Stack gap={2}>
            <FormActionName
              inputLabel="Name"
              inputName="type.name"
              defaultValue={selectedType?.name}
            >
              <TextField
                label="Family"
                variant="outlined"
                name="type.family"
                defaultValue={selectedType?.family}
                fullWidth
                sx={{
                  mb: 2,
                }}
              />

              <FormAttributes
                aggregateId={selectedType?.aggregateId}
                autoGenerateComponent={<ButtonAutoGenerate name="type." />}
                formName="action"
              />
            </FormActionName>
          </Stack>

          <FormFooter submitButtonRef={submitButtonRef} />
        </Form>
      </FormProvider>
    </Stack>
  )
}
