import { IconButton, InputAdornment, TextField } from '@mui/material'
import { FileArrowUp, Minus, Plus, X } from '@phosphor-icons/react'
import { isEqual } from 'lodash'
import type { ChangeEvent } from 'react'
import ActionIconButton from '~/components/buttons/ActionIconButton/ActionIconButton'
import { UploadFilesButton } from '../UploadFiles/UploadFilesButton'

type FilesTreeToolbarProps = {
  /** The value of the filter input field. */
  filterInputValue: string
  /** Indicates if the toggle should not be available. */
  noToggle?: boolean
  /** The callback click handler of the clear filter input. */
  onClearFilterInputClick: () => void
  /** The callback click handler of the toggle button. */
  onToggleClick: () => void
  /** The callback change handler of the filter input field. */
  onFilterInputChange: (e: ChangeEvent<HTMLInputElement>) => void
  /** The ID of the root node (if any). */
  rootNodeId?: GUID
  /** Indicates if the tree is expanded. */
  treeExpanded: string[]
}

/**
 * Toolbar with utilities for the files tree:
 * - Filter field
 * - Upload files button
 * - Expand / collapse buttons
 */
export const FilesTreeToolbar = (props: FilesTreeToolbarProps) => {
  const {
    filterInputValue,
    noToggle,
    onClearFilterInputClick,
    onToggleClick,
    onFilterInputChange,
    rootNodeId,
    treeExpanded,
  } = props

  const isTreeExpanded = !isEqual(treeExpanded, [rootNodeId])

  return (
    <div className="mb-2 flex items-center gap-1">
      <TextField
        fullWidth
        InputProps={{
          className: 'text-xs pr-1',
          endAdornment: (
            <InputAdornment position="end">
              {filterInputValue && (
                <IconButton
                  onClick={onClearFilterInputClick}
                  edge="end"
                  size="small"
                >
                  <X size={18} />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
        onChange={onFilterInputChange}
        placeholder="Filter files or folders"
        size="small"
        value={filterInputValue}
        variant="outlined"
      />

      <UploadFilesButton>
        <ActionIconButton
          color="secondary"
          Icon={FileArrowUp}
          iconProps={{ size: 23 }}
          tooltipPlacement="top"
          tooltipTitle="Upload files"
        />
      </UploadFilesButton>

      {!noToggle && (
        <ActionIconButton
          color="secondary"
          Icon={isTreeExpanded ? Minus : Plus}
          iconProps={{ size: 20 }}
          onClick={onToggleClick}
          tooltipPlacement="top"
          tooltipTitle={isTreeExpanded ? 'Collapse' : 'Expand'}
        />
      )}
    </div>
  )
}
