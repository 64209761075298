import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { BoundedContextEnum } from '~/models/enums/BoundedContextEnum'
import { queryDevelopment } from '~/services/Development'

/**
 * Provides the utilities to validate
 * the route params.
 */
export const useDeveloperRouteParams = () => {
  const { aggregateId, boundedContext, platformId } = useParams()

  const { data } = useQuery({
    ...queryDevelopment(platformId),
    select: (response) => response.data,
  })

  // Utilities.
  const isValidBoundedContext = !!data?.boundedContexts.some((item) => {
    // Checks if bounded contents is the one specific for empty strings.
    if (boundedContext === BoundedContextEnum.NO_BOUNDED_CONTEXT) {
      return item.name.trim() === ''
    }

    // Empty string can't be considered directly, that's why there is a check for `NO_BOUNDED_CONTEXT`.
    return boundedContext !== '' && item.name.toLowerCase() === boundedContext
  })

  const isValidAggregate = !!data?.boundedContexts.some(
    (item) =>
      item.aggregates &&
      item.aggregates.some((aggregate) => aggregate.identity === aggregateId),
  )

  return {
    isValidAggregate,
    isValidBoundedContext,
  }
}
