import { useRef } from 'react'

/**
 * Hook to support the aggregate action form -
 * the forms to create and edit commands, reactions,
 * read models and queries.
 * This hook provides utilities to support how
 * the form will work.
 *
 * It is important to mention that all of these forms
 * are different components, and they don't share
 * common components - so the core reason for these hook
 * is to act as a workaround to implement common features
 * for the forms.
 *
 * Ideally, this should be refactored but, due to
 * the amount of effort, it will be for a second stage.
 */
export const useAggregateActionForm = () => {
  // Refs.
  const submitButtonRef = useRef<HTMLButtonElement>(null)

  /**
   * Use key down event listener to control which `submit` type button
   * will be triggered.
   * The form has 2 submit type buttons: the auto generate one, and the
   * submit form one.
   * Ideally, the components should be refactored and remove the `type=submit`
   * from the `auto generate` button, but that will required:
   * - Adding a on click handler calling the endpoint - ideally using react-query
   *  - This submit will be part of ButtonAutoGenerate component and should
   *    account for all different types of actions.
   * - Remove the `auto complete` handler from all actions from all forms
   *   which will be at least these ones:
   *    - Add/edit command
   *    - Add/edit reaction
   *    - Add/edit query
   *    - Add/edit read models
   *    - Add/edit types
   */
  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (
      event.key === 'Enter' &&
      event.target instanceof HTMLElement &&
      event.target.tagName !== 'TEXTAREA'
    ) {
      event.preventDefault()
      submitButtonRef.current?.click()
    }
  }

  const formProps = {
    onKeyDown: handleKeyDown,
  }

  return {
    formProps,
    submitButtonRef,
  }
}
