import AutorenewIcon from '@mui/icons-material/Autorenew'
import BoltIcon from '@mui/icons-material/Bolt'
import SearchIcon from '@mui/icons-material/Search'
import SendIcon from '@mui/icons-material/Send'
import React from 'react'
import { ServiceAndEventsActionTypeEnum } from '~/models/enums/developer/serviceMap/ServiceAndEventsActionTypeEnum'

type ServiceActionIconProps = {
  type: ServiceAndEventsActionTypeEnum
  color?: string
  width?: number | string
  height?: number | string
}

export const ServiceActionIcon: React.FC<ServiceActionIconProps> = ({
  type,
  color = 'inherit',
  width = 14,
  height = 14,
}) => {
  const iconStyle = {
    color,
    width,
    height,
  }

  switch (type) {
    case ServiceAndEventsActionTypeEnum.COMMAND:
      return <SendIcon style={iconStyle} />
    case ServiceAndEventsActionTypeEnum.REACTION:
      return <AutorenewIcon style={{ height: 18, width: 18 }} />
    case ServiceAndEventsActionTypeEnum.QUERY:
      return <SearchIcon style={{ height: 18, width: 18 }} />
    case ServiceAndEventsActionTypeEnum.EVENT:
      return <BoltIcon style={iconStyle} />
    default:
      return null
  }
}
