import {
  TextField,
  TextFieldProps,
  Typography,
  type InputProps,
} from '@mui/material'
import { twMerge } from '^/tailwind.config'
import { forwardRef } from 'react'
import { Controller, type UseFormReturn } from 'react-hook-form'

type ControlledTextFieldProps = Partial<Pick<UseFormReturn, 'control'>> &
  Partial<TextFieldProps>

/**
 * A controlled `text field` component
 * made for update statement feature.
 */
export const ControlledTextField = forwardRef<
  HTMLElement,
  ControlledTextFieldProps
>((props: ControlledTextFieldProps, ref) => {
  const { className, control, InputProps, label, name, onBlur, ...rest } = props
  const { className: classNameInputProps, ...restInputProps } =
    (InputProps as InputProps) || {}

  if (!control || !name) return null

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onBlur: fieldOnBlur, ...restField } }) => (
        <TextField
          {...rest}
          {...restField}
          className={className}
          inputRef={ref}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            className: twMerge(
              'border-secondary-200 text-sm',
              classNameInputProps,
            ),
            ...restInputProps,
          }}
          label={
            label && (
              <Typography className="text-[10px] uppercase" component={'span'}>
                {label}
              </Typography>
            )
          }
          onBlur={(e) => {
            fieldOnBlur()
            onBlur?.(e)
          }}
          size="small"
        />
      )}
    />
  )
})
