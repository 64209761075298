import { styled } from '@mui/material'
import {
  ArrowsInLineVertical,
  ArrowsOutLineVertical,
} from '@phosphor-icons/react'
import { ControlButton as XyControlButton } from '@xyflow/react'
import type { NodeStatement } from '~/services/Process.types'

export type ReactFlowControlProps = Pick<NodeStatement, 'isExpanded'> & {
  /** Callback handler for expand button click. */
  onToggleExpandClick?: () => void
}

const ControlButton = styled(XyControlButton)`
  &.react-flow__controls-button svg {
    max-width: 20px;
    min-height: 20px;
  }
`

/**
 * Custom control component for the `React Flow`.
 */
export const ReactFlowControl = (props: ReactFlowControlProps) => {
  const { isExpanded, onToggleExpandClick } = props

  return (
    <ControlButton
      onClick={onToggleExpandClick}
      title={isExpanded ? 'click to collapse' : 'click to expand'}
    >
      {isExpanded ? <ArrowsInLineVertical /> : <ArrowsOutLineVertical />}
    </ControlButton>
  )
}
