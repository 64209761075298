import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { Node, NodeProps } from '@xyflow/react'
import { useRef, type MouseEvent } from 'react'
import {
  generatePath,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import type { DialogConfirmDeleteHandle } from '~/components/dialogs/DialogConfirmDelete/DialogConfirmDelete'
import { useGetSubscriptionPlanCapabilities } from '~/hooks/useGetSubscriptionPlanCapabilities'
import { DeveloperRoutesEnum } from '~/models/enums/routes/DeveloperRoutesEnum'
import type { ServiceAndEventsAction } from '~/models/types/developer/serviceMap/ServiceAndEventsType'
import {
  queryKeyDevelopment,
  queryKeyDevelopmentDomainsForCommands,
} from '~/services/Development'
import type { NodeStatement } from '~/services/Process.types'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'

type UseNodeToolbarProps = Pick<
  NodeProps<Node<NodeStatement<ServiceAndEventsAction[]>>>,
  'data'
>

type DeletePayload = {
  /** The ID of the aggregate to be deleted. */
  aggregateId?: GUID
  /** The ID of the type to be deleted. */
  typeId?: GUID
}

/**
 * Hook to support the `NodeToolbar` component.
 */
export const useNodeToolbar = (props: UseNodeToolbarProps) => {
  const { data } = props
  const {
    aggregateId: propAggregateId = '',
    identity: typeId,
    isAggregateType,
  } = data || {}

  // Ref.
  const dialogConfirmDeleteRef = useRef<DialogConfirmDeleteHandle>(null)

  // React Router Dom.
  const navigate = useNavigate()

  const params = useParams()
  const {
    aggregateId: paramAggregateId = '',
    boundedContext = '',
    organisationId = '',
    platformId = '',
  } = params

  const aggregateId = paramAggregateId || propAggregateId

  const [searchParams] = useSearchParams()
  const searchParamAggregate = searchParams.get('aggregate')

  // Type used to build types related paths to navigate to.
  const urlType = typeId

  // React Query.
  const queryClient = useQueryClient()

  const {
    isError: isErrorDeleteAggregate,
    isPending: isPendingDeleteAggregate,
    mutateAsync: mutateDeleteAggregate,
  } = useMutation({
    mutationFn: ({ aggregateId }: DeletePayload) => {
      const url = DevelopmentAggregateAPI.DeleteAggregate
      return serviceDevelopmentAggregate.post(url, {
        aggregateId,
      })
    },
    onSettled: () => {
      if (!!platformId) {
        queryClient.invalidateQueries({
          queryKey: queryKeyDevelopment(platformId),
        })
      }
    },
  })

  const {
    isError: isErrorDeleteType,
    isPending: isPendingDeleteType,
    mutateAsync: mutateDeleteType,
  } = useMutation({
    mutationFn: ({ aggregateId, typeId }: DeletePayload) => {
      const url = DevelopmentAggregateAPI.DeleteType
      return serviceDevelopmentAggregate.post(url, {
        aggregateId,
        typeId,
      })
    },
    onSettled: () => {
      if (!!platformId) {
        queryClient.invalidateQueries({
          queryKey: queryKeyDevelopment(platformId),
        })
        queryClient.invalidateQueries({
          queryKey: queryKeyDevelopmentDomainsForCommands(platformId),
        })
      }
    },
  })

  // Hooks.
  const { codeGeneration: isCodeGenerationAllowed } =
    useGetSubscriptionPlanCapabilities()

  // Methods.
  const handleGenerateCodeClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    let path = `${generatePath(DeveloperRoutesEnum.DEVELOPER_AGGREGATE, {
      aggregateId,
      boundedContext,
      organisationId,
      platformId,
    })}/generate-code`
    if (!!searchParamAggregate)
      path = `${path}?aggregate=${searchParamAggregate}`
    if (isAggregateType) path = `${path}/type/${typeId}?type=${urlType}`

    navigate(path)
  }

  const handleDetailsClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    navigate(
      generatePath(DeveloperRoutesEnum.DEVELOPER_AGGREGATE, {
        aggregateId,
        boundedContext,
        organisationId,
        platformId,
      }),
    )
  }

  const handleEditClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    let path = generatePath(DeveloperRoutesEnum.DEVELOPER_AGGREGATE, {
      aggregateId,
      boundedContext,
      organisationId,
      platformId,
    })
    if (!isAggregateType) path = `${path}/edit-details`
    if (!!searchParamAggregate)
      path = `${path}?aggregate=${searchParamAggregate}`
    if (isAggregateType) path = `${path}/edit-type/${typeId}?type=${urlType}`

    navigate(path)
  }

  const handleConfirmDeleteClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    dialogConfirmDeleteRef.current?.handleOpenDialog()
  }

  const handleDeleteClick = () => {
    if (isAggregateType)
      return mutateDeleteType({ aggregateId, typeId: typeId || '' }).then(
        () => {
          dialogConfirmDeleteRef.current?.handleCloseDialog()
        },
      )

    return mutateDeleteAggregate({ aggregateId }).then(() => {
      dialogConfirmDeleteRef.current?.handleCloseDialog()
    })
  }

  return {
    dialogConfirmDeleteRef,
    handleConfirmDeleteClick,
    handleDeleteClick,
    handleDetailsClick,
    handleEditClick,
    handleGenerateCodeClick,
    isCodeGenerationAllowed,
    isErrorDelete: isErrorDeleteAggregate || isErrorDeleteType,
    isPendingDelete: isPendingDeleteAggregate || isPendingDeleteType,
    showDetailsButton: !!searchParamAggregate,
  }
}
