import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { FieldValues } from 'react-hook-form'
import invariant from 'tiny-invariant'
import { ERROR_MESSAGES } from '~/constants/api/codeGenerationStrategy/errorMessages'
import { QueryKeysEnum } from '~/models/enums/api/QueryKeysEnum'
import { MappingConditionFormEnum } from '~/models/enums/forms/MappingConditionFormEnum'
import type { CodeGenerationStrategyApiProps } from '~/models/types/api/codeGenerationStrategy/CodeGenerationStrategyApiProps'
import type { ErrorResponseType } from '~/models/types/http/ErrorResponseType'
import type { PayloadUpdatePropertyMappingCondition } from '~/services/GenerationStrategy.types'
import {
  GenerationStrategyAPI,
  serviceGenerationStrategy,
} from '~/services/base'
import { getQueryMutationError } from '~/utils/api/codeGenerationStrategy/getQueryMutationError'

/**
 * Hook that implements react query `mutation`
 * to update a property mapping condition.
 */
export const useUpdatePropertyBindingMappingCondition = (
  props: Pick<CodeGenerationStrategyApiProps, 'fileId' | 'propertyBindingId'>,
) => {
  const { fileId, propertyBindingId } = props

  const queryClient = useQueryClient()

  const {
    error: mutationError,
    isError,
    ...restMutation
  } = useMutation({
    mutationFn: (fieldValues: FieldValues) => {
      invariant(fileId, ERROR_MESSAGES.fileId)
      invariant(propertyBindingId, ERROR_MESSAGES.propertyBindingId)

      const url = GenerationStrategyAPI.UpdatePropertyMappingCondition

      const mappingCondition = fieldValues[
        MappingConditionFormEnum.MAPPING_CONDITION
      ] as string | null
      const postData: PayloadUpdatePropertyMappingCondition = {
        fileId,
        propertyBindingId,
        condition: mappingCondition,
      }

      return serviceGenerationStrategy.post(url, postData)
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QueryKeysEnum.GENERATION_STRATEGY_ITEM, fileId],
      }),
  })

  const error = getQueryMutationError(
    isError,
    mutationError as ErrorResponseType,
  )

  return { ...restMutation, error, isError }
}
