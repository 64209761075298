import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { produce } from 'immer'
import { ActionFunctionArgs } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { IS_DEV } from '~/config/constants'
import {
  getAttributesFromForm,
  redirectToDeveloperHomeOrAggregate,
} from '~/routes/developer/utils'
import {
  queryKeyDevelopment,
  queryKeyDevelopmentDomainsForCommands,
} from '~/services/Development'
import { queryKeyOrganisation } from '~/services/Discovery'
import { FREE_FEATURES_USAGE } from '~/services/Discovery.types'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'
import { getUserEmail } from '~/utils/getUserEmail'
import { getUserId } from '~/utils/getUserId'

export const actionDeveloperAddQuery =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ request, params }: ActionFunctionArgs) => {
    const userId = getUserId(pca)
    const email = getUserEmail(pca)

    const { aggregateId, platformId, processId } = params
    invariant(aggregateId, 'No aggregateId found')
    invariant(userId, 'No userId found')
    invariant(platformId, 'No platformId found')

    // Get form data
    const formData = await request.formData()
    const allForm = Object.fromEntries(formData)
    const name = formData.get('query.name')
    const typeId = formData.get('action.typeId')

    // This will return early in case we are only auto generating attributes for reactions
    // It will also return the data from it available at useActionData()
    const autoGenerateButton = Object.keys(allForm).find((key) =>
      key.includes('AutoGenerateAttributes'),
    )
    if (autoGenerateButton) {
      if (aggregateId && typeof aggregateId === 'string') {
        const reqUrl = DevelopmentAggregateAPI.GenerateAttributes
        const data = {
          aggregateId: aggregateId?.toString() || '',
          entityType: 'Query Parameters',
          entityName: name?.toString(),
          saveAttributesOnType: false,
          existingTypeId: null,
        }

        try {
          const res = await serviceDevelopmentAggregate.post(reqUrl, data)
          queryClient.setQueryData(
            queryKeyOrganisation(userId, email),
            (organisation: any) =>
              produce(organisation, (draft: any) => {
                draft.data.subscriptionPlan[
                  `${FREE_FEATURES_USAGE.ATTRIBUTE_AUTO_GENERATION}Remaining`
                ] =
                  organisation.data.subscriptionPlan[
                    `${FREE_FEATURES_USAGE.ATTRIBUTE_AUTO_GENERATION}Remaining`
                  ] - 1 || 0
              }),
          )
          return {
            formName: autoGenerateButton?.replace(
              '.AutoGenerateAttributes',
              '',
            ),
            attributes: res.data?.value?.attributes,
          }
        } catch (error) {
          if (IS_DEV) {
            console.log(error)
          }
          return []
        }
      }
    }
    // end of auto generation of attributes for reactions

    const isCopyButtonPressed = Object.keys(allForm).find((key) =>
      key.includes('.copyButton'),
    )
    if (isCopyButtonPressed) {
      const attributes = getAttributesFromForm({
        formEntries: allForm,
        kind: isCopyButtonPressed.replace('copyButton', ''),
      })
      await navigator.clipboard.writeText(JSON.stringify(attributes))
      return null
    }

    // Builds the data to send to server
    const attributes = getAttributesFromForm({
      formEntries: allForm,
      kind: 'action.',
    })

    const resultType = formData.get('action.query.resultType.type')
    const isCollectionResult = Boolean(
      formData.get('action.query.resultType.is_array'),
    )

    const postData = {
      aggregateId,
      processId,
      name,
      typeId,
      resultType,
      isCollectionResult,
      parameters: attributes,
    }

    // Setups and send to server
    const url = DevelopmentAggregateAPI.AddQuery
    const req = await serviceDevelopmentAggregate.post(url, postData)

    // Invalidates the cache
    queryClient.invalidateQueries({ queryKey: queryKeyDevelopment(platformId) })
    queryClient.invalidateQueries({
      queryKey: queryKeyDevelopmentDomainsForCommands(platformId),
    })

    // redirects
    return redirectToDeveloperHomeOrAggregate(req, params)
  }
