import { useTheme } from '@mui/material'
import type { Node, NodeProps } from '@xyflow/react'
import tailwindConfig from '^/tailwind.config'
import resolveConfig from 'tailwindcss/resolveConfig'
import type { NodeStatement } from '~/services/Process.types'
import { designSystemTokens } from '~/styles/designSystemTokens'
import { useStep } from '../Step/useStep'
import { StepHeaderTitle } from '../StepHeaderTitle/StepHeaderTitle'

const fullConfig = resolveConfig(tailwindConfig)

type HeaderProps = { data: NodeProps<Node<NodeStatement>> }

export function Header(props: HeaderProps) {
  const {
    data: { data },
  } = props

  const {
    hasError: hasStatementError,
    isEditMutationError,
    isEmpty,
    isExpanded,
    isFetching,
    isSelected,
    retryEditMutation,
    type,
  } = data || {}

  // Hooks.
  const theme = useTheme()

  const { isCommand, isReaction } = useStep({ type })

  // Constants.
  const hasError = hasStatementError || isEditMutationError

  const headerData: {
    backgroundColor: string
    titleColor: string
    titleText: string
  } = {
    backgroundColor: 'transparent',
    titleColor: isCommand
      ? fullConfig.theme.colors.command.DEFAULT
      : isReaction
        ? fullConfig.theme.colors.reaction.DEFAULT
        : designSystemTokens.colors.secondary.DEFAULT,
    titleText: data?.action || '',
    ...(hasError && {
      backgroundColor: '#FFE8E8',
      titleColor: '#D62121',
      titleText: 'Unable to parse process step',
    }),
    ...(isEmpty && {
      backgroundColor: isSelected
        ? designSystemTokens.colors.secondary.DEFAULT
        : 'transparent',
      titleColor: isSelected
        ? theme.palette.background.panel
        : designSystemTokens.colors.secondary.DEFAULT,
      titleText: '+ Create a new step',
    }),
  }

  return (
    <>
      {isEmpty ? (
        <div className="h-[50px] w-[525px]">{data.statementInput}</div>
      ) : (
        <>
          {/*{!isEmpty && isSelected && data.statementInput}*/}
          <StepHeaderTitle
            className="flex items-center justify-center gap-1"
            isEditMutationError={isEditMutationError}
            isExpanded={isExpanded}
            isFetching={isFetching}
            onEditMutationRetry={retryEditMutation}
            style={{
              bgcolor: headerData.backgroundColor,
              color: headerData.titleColor,
              padding: `12px`,
              textAlign: 'center',
            }}
            title={headerData.titleText}
          />
        </>
      )}
    </>
  )
}
