import { LoadingButton } from '@mui/lab'

import type { CodeGenerationStrategyDialogProps } from '~/models/types/components/codeGenerationStrategy/CodeGenerationStrategyDialogProps'
import { MappingCondition } from '~/routes/configuration/code-generation-strategies/edit/components/MappingCondition'
import DialogForm from '../DialogForm/DialogForm'
import { useDialogMappingCondition } from './useDialogMappingCondition'

/**
 * Dialog to add/edit a mapping condition.
 */
export function DialogMappingCondition(
  props: CodeGenerationStrategyDialogProps,
) {
  const {
    dataDomainDictionary,
    handleCloseDialog,
    isDialogOpen,
    selectedDomainDictionaryItem,
  } = props

  const {
    control,
    error,
    handleFormSubmit,
    handleSubmit,
    isPending,
    setFormValue,
    watchForm,
  } = useDialogMappingCondition(props)

  return (
    <DialogForm
      actions={
        <LoadingButton
          color="secondary"
          loading={isPending}
          type="submit"
          variant="contained"
        >
          Save
        </LoadingButton>
      }
      closeIconButton
      content={
        <MappingCondition
          control={control!}
          dataDomainDictionary={dataDomainDictionary}
          selectedDomainDictionaryItem={selectedDomainDictionaryItem}
          setFormValue={setFormValue}
          watchForm={watchForm}
        />
      }
      disableEscapeKeyDown={isPending}
      error={error as string}
      formProps={{ onSubmit: handleSubmit?.(handleFormSubmit) }}
      onClose={handleCloseDialog}
      open={isDialogOpen}
      sx={{
        zIndex: 1700,

        '.MuiPaper-root': {
          maxWidth: 'unset',
          width: '80%',
        },
      }}
      title={'Conditional Mapping'}
    />
  )
}
