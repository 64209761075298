import { Button, TextField } from '@mui/material'
import { ArrowArcLeft, ArrowElbowDownLeft } from '@phosphor-icons/react'
import { AxiosResponse } from 'axios'
import { Form, Link, useNavigation, useRouteLoaderData } from 'react-router-dom'
import { ResponseDiscoveryOrganisation } from '~/services/Discovery.types'

export function ConfigurationProfileEdit() {
  const data = useRouteLoaderData('configuration-profile') as {
    organisation: Awaited<
      AxiosResponse<ResponseDiscoveryOrganisation | null, unknown>['data']
    >
    profile: Awaited<
      AxiosResponse<{ id: GUID; name?: string; email: string }, unknown>['data']
    >
  }
  const navigation = useNavigation()

  return (
    <>
      <Form method="POST" className="flex gap-2">
        <TextField
          label="Edit name"
          placeholder="My Name"
          name="name"
          variant="outlined"
          size="small"
          className="min-w-[300px]"
        />

        <input type="hidden" name="email" value={data?.profile.email} />

        <input
          type="hidden"
          name="organisationId"
          value={data?.organisation?.identity}
        />

        <Button
          color="secondary"
          disabled={navigation.state === 'submitting'}
          size="small"
          startIcon={<ArrowElbowDownLeft size={17} />}
          type="submit"
          variant="contained"
        >
          Submit
        </Button>

        <Button
          color="secondary"
          component={Link}
          size="small"
          startIcon={<ArrowArcLeft size={17} />}
          to="../"
          variant="outlined"
        >
          Cancel
        </Button>
      </Form>
    </>
  )
}
