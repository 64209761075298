import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItemText,
  Typography,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { format } from 'date-fns'
import { useLoaderData, useParams } from 'react-router-dom'
import type { BusinessInitialData } from '~/pages/business'
import { queryDevelopment } from '~/services/Development'
import type { CommitHistoryFile } from '~/services/Development.types'
import { getCommitHistory } from './CommitsHistory.utils'

export type CommitsHistoryProps = {
  showAggregateHistory?: boolean
}

/**
 * Page component for the `commits-history` route.
 */
export const CommitsHistory = (props: CommitsHistoryProps) => {
  const { showAggregateHistory } = props

  // React Router Dom.
  const loaderData = useLoaderData() as BusinessInitialData
  const params = useParams()
  const { aggregateId, commandId, platformId, reactionId, queryId, typeId } =
    params

  // Queries.
  const { data: developmentData } = useQuery({
    ...queryDevelopment(platformId),
    initialData: loaderData?.development,
  })

  // Commit history.
  const commitHistory = getCommitHistory(
    developmentData?.data?.boundedContexts || [],
    {
      aggregateId: showAggregateHistory === true ? aggregateId : undefined,
      commandId,
      reactionId,
      queryId,
      typeId,
    },
  )

  if (!commitHistory || !commitHistory.length)
    return (
      <Typography className="block" color="text" component="p" variant="body2">
        There are no commits.
      </Typography>
    )

  return (
    <List>
      {commitHistory.map(({ comment, files, sha, timestamp }) => (
        <ListItemText
          className="m-0 border-0 border-t border-solid border-gray-200 py-2 last:border-b"
          key={sha}
          secondary={
            <>
              <Typography
                className="block font-bold"
                color="primary"
                component="span"
                variant="body1"
              >
                {sha}
              </Typography>

              <Typography
                className="block"
                color="text"
                component="span"
                variant="body2"
              >
                {comment}
              </Typography>

              <Typography color="text" component="span" variant="body2">
                {format(new Date(timestamp), "MMM d, uuuu 'at' h:mm aa")}
              </Typography>

              {!!files?.length && (
                <Accordion className="mt-2">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="files-content"
                    id="files-header"
                  >
                    <Typography color="text" component="span" variant="body2">
                      Files
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails className="overflow-auto">
                    {files.map(({ filePath }: CommitHistoryFile) => (
                      <Typography
                        className="block"
                        color="text"
                        component="span"
                        key={filePath}
                        variant="body2"
                      >
                        {filePath}
                      </Typography>
                    ))}
                  </AccordionDetails>
                </Accordion>
              )}
            </>
          }
        />
      ))}
    </List>
  )
}
