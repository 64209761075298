import {
  useMemo,
  type Dispatch,
  type RefObject,
  type SetStateAction,
} from 'react'
import { ValueBindingTreeItemFormEnum } from '~/models/enums/forms/ValueBindingTreeItemFormEnum'
import { CodeGenerationStrategyBaseProps } from '~/models/types/components/codeGenerationStrategy/CodeGenerationStrategyBaseProps'
import type { ConfigForMenuToReplace } from '~/models/types/components/codeGenerationStrategy/ValueBindingTreeItemProps'
import type { FormHook } from '~/models/types/hooks/FormHook'
import {
  getComponentValueKey,
  getSelectionStartEnd,
} from './ValueBindingTreeItem.utils'

type UseMenuReplaceTextProps = Pick<FormHook, 'setFormValue' | 'watchForm'> &
  Pick<
    CodeGenerationStrategyBaseProps,
    'dataDomainDictionary' | 'selectedDomainDictionaryItem'
  > & {
    /** The name of the parameters field. */
    fieldParametersName?: string
    /** The name of the value field. */
    fieldValueName?: string
    /** The `inputRef` from the value field. */
    fieldRef?: RefObject<HTMLTextAreaElement>
    /** The set state method for `configForMenuToReplace`. */
    setConfigForMenuToReplace: Dispatch<SetStateAction<ConfigForMenuToReplace>>
  }

type UseMenuReplaceTextHook = {
  /** Handler for context menu event. */
  handleContextMenu: (e: EventFor<'div', 'onContextMenu'>) => void
  /** Handler for `context menu` close event. */
  handleContextMenuClose: () => void
  /** Handler for menu item click. */
  handleMenuItemClick: (e: EventFor<'li', 'onClick'>) => void
}

export const useMenuReplaceText = (
  props: UseMenuReplaceTextProps,
): UseMenuReplaceTextHook => {
  const {
    dataDomainDictionary,
    fieldParametersName = ValueBindingTreeItemFormEnum.PARAMETERS,
    fieldValueName = ValueBindingTreeItemFormEnum.VALUE,
    fieldRef,
    selectedDomainDictionaryItem,
    setConfigForMenuToReplace,
    setFormValue,
    watchForm,
  } = props

  // Get current domain dictionary item data.
  const selectedDomainDictionaryItemData = useMemo(
    () =>
      dataDomainDictionary?.find(
        (item) => item.key === selectedDomainDictionaryItem,
      ),
    [dataDomainDictionary, selectedDomainDictionaryItem],
  )
  // Form field values.
  const paramsFieldValue = watchForm?.(fieldParametersName)
  const parsedParams = JSON.parse(paramsFieldValue || '[]')

  // Handler for `context menu` event.
  const handleContextMenu = (event: EventFor<'div', 'onContextMenu'>) => {
    event.preventDefault()
    event.stopPropagation()
    const textVal = fieldRef?.current

    if (textVal) {
      setConfigForMenuToReplace((prevState) =>
        prevState === null
          ? {
              mouseX: event.clientX + 10,
              mouseY: event.clientY + 20,
            }
          : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
            // Other native context menus might behave different.
            // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
            null,
      )
    }
  }

  // Handler for `context menu` close event.
  const handleContextMenuClose = () => {
    setConfigForMenuToReplace(null)
  }

  const handleMenuClickCommonAction = (event: EventFor<'li', 'onClick'>) => {
    event.stopPropagation()
    handleContextMenuClose()
  }

  // Handles the text replace feature.
  const handleTextReplace = (_: EventFor<'li', 'onClick'>, text?: string) => {
    const textAreaElement = fieldRef?.current

    if (textAreaElement) {
      // Get the cursor position.
      const { selectionStart: cursorStart, selectionEnd: cursorEnd } =
        getSelectionStartEnd(textAreaElement)

      const paramKeyToReplace = text || selectedDomainDictionaryItemData?.key
      if (paramKeyToReplace) {
        const indexOfParamKeyInParamsAdded =
          parsedParams.indexOf(paramKeyToReplace)

        // Add to the table in case it doesn't exist
        if (indexOfParamKeyInParamsAdded === -1) {
          setFormValue?.(
            fieldParametersName,
            JSON.stringify([...parsedParams, paramKeyToReplace]),
            { shouldDirty: true },
          )
        }

        // Get the proper value key: this is necessary when the field
        // is a `content editable` component not an input.
        let valueKey = getComponentValueKey(textAreaElement)

        // Replace the text either way
        const targetText = textAreaElement[valueKey] as string
        const beginText = targetText?.substring(0, cursorStart)
        const endText = targetText?.substring(cursorEnd, targetText.length)
        const replacedText = `${beginText}{${paramKeyToReplace}}${endText}`

        setFormValue?.(fieldValueName, replacedText, {
          shouldDirty: true,
        })
      }
    }
  }

  // Handler for menu item click.
  const handleMenuItemClick = (event: EventFor<'li', 'onClick'>) => {
    const buttonValue = event.currentTarget.dataset.value

    if (buttonValue) handleTextReplace(event, buttonValue)

    handleMenuClickCommonAction(event)
  }

  return {
    handleContextMenu,
    handleContextMenuClose,
    handleMenuItemClick,
  }
}
