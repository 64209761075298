import ReadMoreIcon from '@mui/icons-material/ReadMore'
import WrapTextIcon from '@mui/icons-material/WrapText'
import { Chip, List, Stack, Tooltip } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useGetSubscriptionPlanCapabilities } from '~/hooks/useGetSubscriptionPlanCapabilities'
import { ListAttributes } from '~/pages/developer/components/ListAttributes'
import { MenuMore } from '~/pages/developer/components/MenuMore'
import {
  ListItemForDeveloper,
  ListItemHeader,
} from '~/pages/developer/components/styled/ListItem'
import { AggregateQuery } from '~/services/Development.types'

const HEIGHT = 15
const WIDTH = 15

type ListQueriesProps = {
  queries: AggregateQuery[]
}
export function ListQueries({ queries }: ListQueriesProps) {
  const { codeGeneration: isCodeGenerationAllowed } =
    useGetSubscriptionPlanCapabilities()
  return (
    <>
      {queries.length ? (
        <Stack mx={1}>
          <List>
            {queries.map((query, index) => (
              <ListItemForDeveloper key={query.name + index.toString()}>
                <Stack sx={{ flexGrow: 1 }} gap={1}>
                  <ListItemHeader>
                    <Stack
                      direction="row"
                      sx={{ alignItems: 'center' }}
                      gap={1}
                    >
                      <ReadMoreIcon fontSize="small" />
                      <Typography>
                        <strong>{query.name}</strong>
                      </Typography>
                    </Stack>
                    <Stack gap={1} direction="row" alignItems="center">
                      {isCodeGenerationAllowed && (
                        <Chip
                          label={query.state?.name}
                          color={
                            query.state?.name === 'Published'
                              ? 'success'
                              : 'warning'
                          }
                          size="small"
                          variant="outlined"
                        />
                      )}
                      <MenuMore
                        linkPublish={`generate-code/query/${query.identity}`}
                        linkEdit={`edit-query/${query.identity}`}
                        linkDelete={
                          query.state?.name === 'Published'
                            ? undefined
                            : `delete-query/${query.identity}`
                        }
                        type="Query"
                        eventId={query.identity}
                      />
                    </Stack>
                  </ListItemHeader>
                  <ListAttributes
                    attributes={query.parameters}
                    title="Parameters"
                  />

                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    columnGap={1}
                    paddingTop={1}
                  >
                    <Tooltip title="Return type" placement="top" arrow>
                      <WrapTextIcon
                        style={{
                          height: HEIGHT,
                          width: WIDTH,
                        }}
                      />
                    </Tooltip>
                    <Typography>
                      <small>
                        {query.result.isCollection ? 'Array of ' : ''}
                      </small>
                      {query.result.type}
                    </Typography>
                  </Stack>
                </Stack>
              </ListItemForDeveloper>
            ))}
          </List>
        </Stack>
      ) : null}
    </>
  )
}
