import { Stack, TextField, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { Form, useLoaderData, useParams } from 'react-router-dom'
import { FormFooter } from '~/pages/developer/components/FormFooter'
import { queryDevelopment } from '~/services/Development'
import { DomainByPlatformId } from '~/services/Development.types'

export function PublishQuery() {
  const params = useParams()
  const initialData = useLoaderData() as Awaited<
    AxiosResponse<DomainByPlatformId | null, unknown>
  >
  const {
    data,
    // isInitialLoading, // etc
  } = useQuery({
    ...queryDevelopment(params.platformId),
    initialData,
    select: (data) => {
      const selectedAggregate =
        data && params.aggregateId
          ? data.data?.boundedContexts.find((context) =>
              context.aggregates.find(
                (aggregate) => aggregate.identity === params.aggregateId,
              ),
            )
          : undefined
      const selectedQuery =
        params.queryId && selectedAggregate
          ? selectedAggregate?.aggregates[0]?.queries.find(
              (query) => query.identity === params.queryId,
            )
          : undefined

      return selectedQuery
    },
  })

  return (
    <>
      <Stack gap={2}>
        <Typography>
          Please commit your changes for query: {data?.name}
        </Typography>
        <Form method="post" id="form-aggregate-publish-query">
          <Stack gap={2}>
            <TextField
              label="Commit"
              variant="outlined"
              name="commitMessage"
              fullWidth
              required
            />
            <TextField
              label="Branch"
              variant="outlined"
              name="branch"
              fullWidth
            />
            <input
              type="hidden"
              name="commandName"
              value={params.commandName}
            />
            <FormFooter
              submittingText="Generating code..."
              submitText="Generate code"
            />
          </Stack>
        </Form>
      </Stack>
    </>
  )
}
