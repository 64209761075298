import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import { forwardRef, useImperativeHandle } from 'react'
import { Controller } from 'react-hook-form'
import { Column } from '~/components/Column'
import { Row } from '~/components/Row'
import FormError from '~/components/form/FormError/FormError'
import { LoadRepositoryFormEnum } from '~/models/enums/forms/LoadRepositoryFormEnum'
import { useLoadRepositoryData } from './useLoadRepositoryData'

export type LoadRepositoryDataHandle = {
  /** Open the dialog. */
  handleOpenDialog: () => void
}

/**
 * Load Repository Data component
 * part of the Code Generation Strategy (AI Blueprint) edition.
 */
export const LoadRepositoryData = forwardRef<LoadRepositoryDataHandle>(
  (_, ref) => {
    const {
      control,
      error,
      handleCloseDialog,
      handleFormSubmit,
      handleOpenDialog,
      handleSubmit,
      isPending,
      open,
    } = useLoadRepositoryData()

    useImperativeHandle(ref, () => ({
      handleOpenDialog,
    }))

    return (
      <Dialog
        onClose={handleCloseDialog}
        open={open}
        PaperProps={{
          className: 'rounded-lg p-1',
        }}
      >
        <DialogTitle>Repository</DialogTitle>

        <DialogContent className="min-w-[500px]">
          <form role="form" onSubmit={handleSubmit?.(handleFormSubmit)}>
            <Column className="gap-5 pt-2">
              <Controller
                control={control}
                disabled={isPending}
                name={LoadRepositoryFormEnum.REPOSITORY_URL}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Repository URL"
                    size="small"
                    {...field}
                  />
                )}
              />

              <Controller
                control={control}
                disabled={isPending}
                name={LoadRepositoryFormEnum.AUTHENTICATION_TOKEN}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Authentication Token"
                    size="small"
                    type="password"
                    {...field}
                  />
                )}
              />

              <FormError className="mb-0.5 text-xs" error={error as string} />

              <Row className="mt-2 justify-end gap-2">
                <Button
                  color="secondary"
                  disabled={isPending}
                  onClick={handleCloseDialog}
                  size="small"
                  variant="outlined"
                >
                  Close
                </Button>

                <LoadingButton
                  className="max-w-fit"
                  color="secondary"
                  loading={isPending}
                  variant="outlined"
                  type="submit"
                >
                  Load repository
                </LoadingButton>
              </Row>
            </Column>
          </form>
        </DialogContent>
      </Dialog>
    )
  },
)
