import { AutoAwesome } from '@mui/icons-material'
import { Button, Tooltip } from '@mui/material'
import GradientLoader from '~/components/GradientLoader/GradientLoader'
import useGenerateAttributes from '~/hooks/api/developer/useGenerateAttributes'

type GenerateAttributesProps = {
  aggregateId: string
  entityType: string
  entityName: string
  typeId: string
}

export const GenerateAttributes = (props: GenerateAttributesProps) => {
  const { aggregateId, entityType, entityName, typeId } = props

  const generateAttributes = useGenerateAttributes()

  const handleGenerateClick = async () => {
    const result = await generateAttributes.mutateAsync({
      aggregateId: aggregateId,
      entityName: entityName,
      entityType: entityType,
      typeId: typeId,
    })
    console.log(result)
  }

  return (
    <Tooltip
      title={
        generateAttributes.isPending
          ? 'Generating attributes...'
          : 'Auto-generate attributes'
      }
    >
      <Button
        color="secondary"
        disabled={generateAttributes.isPending}
        sx={{ width: 'fit-content' }}
        onClick={handleGenerateClick}
        variant="text"
      >
        {generateAttributes.isPending ? <GradientLoader /> : <AutoAwesome />}
      </Button>
    </Tooltip>
  )
}
