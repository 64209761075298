import type { AttributeOption } from '~/components/Attribute'
import { AGGREGATE_TYPES } from '~/constants/components/aggregateTypes/aggregateTypes'
import type { AggregateType } from '~/services/Development.types'

type GroupedTypes = {
  [key: string]: AggregateType[]
}

/**
 * Provides the types options for the `Attributes` component.
 * These options will be used for the `type` field.
 * @param types The aggregate types from the API.
 */
export const getAggregateTypesOptions = (
  types?: AggregateType[],
): AttributeOption[] => {
  if (!!types?.length) {
    // Group the types by family.
    const groupedTypes = types.reduce(
      (acc: GroupedTypes, item: AggregateType) => {
        const { family = '' } = item

        // Add the family as a key in case it does not exist.
        if (!acc[family]) acc[family] = []

        // Add the item to the family group array.
        acc[family].push(item)

        return acc
      },
      {},
    )

    // Transform the grouped types into the `AttributeOption` format type.
    // Sort families alphabetically and transform into AttributeOption format
    const formattedTypes = Object.entries(groupedTypes)
      .sort(([a], [b]) => a.localeCompare(b))
      .flatMap(([family, items]) => [
        {
          depth: 0,
          id: family,
          label: family,
        },
        ...items
          .sort((a, b) =>
            (a?.label || a.name || '').localeCompare(b.label || b.name || ''),
          )
          .map(({ identity, label, name }: AggregateType) => ({
            depth: 1,
            id: identity,
            label: label || name,
            name,
          })),
      ]) as unknown as AggregateType[]

    return AGGREGATE_TYPES.concat(formattedTypes)
  }

  return AGGREGATE_TYPES
}

/**
 * Scrolls an element into view within a scrollable container, with a customizable threshold.
 * @param element The HTML element to scroll into view.
 * @param threshold The distance (in pixels) from the container's edges where the element is considered in view.
 */
export const scrollIntoViewWithThreshold = (
  element: HTMLElement | null,
  threshold: number,
) => {
  // If no element is provided, exit the function.
  if (!element) return

  // Get the bounding rect of the current element.
  const rect = element.getBoundingClientRect()
  // Find the closest ancestor with overflow-y-scroll class (the scrollable container).
  const scrollContainer = element.closest('.overflow-y-scroll') as HTMLElement

  if (scrollContainer) {
    // Get the bounding react of the scroll container.
    const containerRect = scrollContainer.getBoundingClientRect()

    // Check if the element is already in view, considering the threshold.
    const isInView =
      rect.bottom <= containerRect.bottom + threshold && // Element's bottom is above container's bottom (plus threshold).
      rect.top >= containerRect.top - threshold // Element's top is below container's top (minus threshold).

    // If the element is not in view, scroll to it.
    if (!isInView) {
      // Calculate the new scroll position.
      const scrollTop =
        rect.bottom - // Distance from top of page to bottom of element.
        containerRect.height + // Subtract container height.
        threshold + // Add threshold.
        scrollContainer.scrollTop // Add current scroll position.

      scrollContainer.scrollTo({
        top: scrollTop,
        behavior: 'smooth',
      })
    }
  }
}
