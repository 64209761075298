import { Alert, AlertTitle, Button } from '@mui/material'
import { PencilSimple } from '@phosphor-icons/react'
import { useQuery } from '@tanstack/react-query'
import { twMerge } from '^/tailwind.config'
import { AxiosResponse } from 'axios'
import { Link, Outlet, useLoaderData, useOutlet } from 'react-router-dom'
import { Column } from '~/components/Column'
import { LayoutConfiguration } from '~/components/LayoutConfiguration'
import { Text } from '~/components/Text'
import { shouldShowOnlyInDev } from '~/config/environments'
import { queryUserProfileByOrganisation } from '~/services/Discovery'
import {
  ResponseDiscoveryOrganisation,
  ResponseGetUserProfileByOrganisationId,
} from '~/services/Discovery.types'

export function ConfigurationProfile() {
  const outlet = useOutlet()
  const initialData = useLoaderData() as {
    organisationId: ResponseDiscoveryOrganisation['identity']
    profile: Awaited<
      AxiosResponse<ResponseGetUserProfileByOrganisationId | null, unknown>
    >
  }
  const { data: dataProfile } = useQuery({
    ...queryUserProfileByOrganisation(initialData.organisationId),
    initialData: initialData.profile,
    select: (response) => response.data,
  })

  return (
    <LayoutConfiguration selected="Profile">
      <Text className="pl-2 text-2xl">Profile</Text>

      <Column className="mt-2 gap-2 rounded-lg bg-neutral-low-100 p-4 shadow-md">
        <div>
          <Text>View your profile information and update your name.</Text>
        </div>

        <Column className="gap-2 rounded-md bg-white p-4">
          {shouldShowOnlyInDev() ? (
            <Alert severity="warning">
              <AlertTitle>Warning!</AlertTitle>This screen does not work
              locally. <strong>It may throw errors.</strong>
            </Alert>
          ) : null}

          <div>
            <Text>E-Mail: {dataProfile?.email ? dataProfile.email : '-'}</Text>
          </div>

          <div
            className={twMerge(
              outlet &&
                'rounded-md border border-solid border-highlight-200 bg-white p-4',
              'transition-all',
            )}
          >
            <div>
              {outlet ? null : (
                <Column className="gap-2">
                  <Text>
                    Name: {dataProfile?.name ? dataProfile.name : '-'}
                  </Text>

                  <Button
                    className="w-fit"
                    color="secondary"
                    component={Link}
                    startIcon={<PencilSimple />}
                    to="edit"
                    variant="outlined"
                  >
                    Edit name
                  </Button>
                </Column>
              )}
            </div>

            <div>
              <Outlet />
            </div>
          </div>
        </Column>
      </Column>
    </LayoutConfiguration>
  )
}
